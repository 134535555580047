@import (reference) 'quiz-responsive.less';
@import (reference) 'utils.less';


#quiz-arrow-button {
    .glyphicon-arrow-left:before { 
        height: 50px;
        width: 50px;
        background-color: #e1e7ed;
        position: fixed;
        right: 85%;
        bottom: 2%;
        border-radius: 50%;
        font-size: 26px;
        padding-top: 10px;
        z-index: 24;
    }
}

.btn-custom-for-matches {
    width: 28%;
    background-color: #5340ff;
    color: #ffffff;
    text-align: center;
    font-weight: bolder;
    height: 52px;
    font-size: 18px;
    border-radius: 5px;
}

.btn-custom-div {
    width: 100%;
    position: fixed;
    bottom: 2%;
    .btn-custom {
        width: 28%;
        background-color: #5340ff;
        color: #ffffff;
        text-align: center;
        font-weight: bolder;
        height: 52px;
        font-size: 18px;
        border-radius: 5px;
    }
    .btn-custom-full {
        width: 50%;
        background-color: #5340ff;
        color: #ffffff;
        text-align: center;
        font-weight: bolder;
        height: 52px;
        font-size: 18px;
        border-radius: 5px;
    }
    .btn-custom-secondary {
        width: 28%;
        background-color: #748eac;
        color: #ffffff;
        text-align: center;
        font-weight: bolder;
        height: 52px;
        font-size: 18px;
        border-radius: 5px;
    }
}

.btn-custom:hover,
.btn-custom-full:hover,
.btn-custom-for-matches:hover,
.btn-custom-secondary:hover {
    color: #ffffff;
}

#responses-modal {
    position: absolute;
    outline: 0px none;
    width: auto;
    margin-left: auto;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-clip: inherit;
    overflow: visible;
    &.modal.fade.in {
        top: 0%;
    }
    .modal-dialog {
        position: fixed;
        margin-top: 80px;
        max-height: 90%;
        overflow: auto;
    }

    /*SCROLLBAR*/
     ::-webkit-scrollbar {
        width: 12px;
    }

    /* Track */
     ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    /* Handle */
     ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgb(91, 160, 208);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
     ::-webkit-scrollbar-thumb:window-inactive {
        background: rgb(91, 160, 208);
    }
}
.quiz-modal-container{
    overflow: hidden;
    background-color: #f4f6f8 !important;
    max-width: 600px;
    margin: 0 auto;
}
.quiz-modal-container,
body#view-matches,
.quiz-modal {
    // Wrapping everything inside body#quiz...
    .quiz-header {
                  background-color: #454556;
                  background-size: 100%;
        h1,
        p {
            color: #454556;
            text-align: center;
        }
        h1 {
            /*margin-top: -150px;*/
            font-size: 50px;
            font-weight: 700;
        }
        p {
            font-size: 18px;
            font-weight: 300;
        }
        img {
            margin: 0 auto;
        }
        .container {
            /*margin-top: -1px;*/
            padding: 150px 50px;
        }
    }
    .long-title{
            font-size: 1.2em;
    }//
    // Quiz Tracker
    // ------------
    .quiz-tracker {
        background-color: transparent;
        height: auto;
        width: 100vw;
        position: fixed;
        z-index: 3000;
        top: 0;
        left: 0px;
        .container {
            max-width: 100%;
            padding: 0;
        }
    }

    .container-progress-bar {
        .progress {
            display: inherit;
            background-image: none;
            background-repeat: none;
        }
        .progress-markers {
            position: relative;
            ul {
                padding: 0px;
                width: 100%;
                position: absolute;
                top: 28px;
                left: 2px;
            }
            li {
                background-color: #616060;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset;
                width: 15px;
                height: 15px;
                /*margin-right: 82px;*/
                border-radius: 8px;
                display: inline-block;
                &:last-child {
                    margin-right: 0;
                    position: relative;
                    right: -3px;
                }
            }
            .marker {
                background-color: #7ad1f7;
                transition: width 0.6s ease 0s;
            }
        }

        .progress {
            background-color: #616060;
            height: 7px;
            margin-top: 32px;
            .progress-bar {
                background-color: #7ad1f7;
                &.marker-one {
                    width: 14%;
                }
                &.marker-two {
                    width: 29%;
                }
                &.marker-three {
                    width: 43%;
                }
                &.marker-four {
                    width: 57%;
                }
                &.marker-five {
                    width: 71%;
                }
                &.marker-six {
                    width: 85%;
                }
                &.marker-seven {
                    width: 100%;
                }
            }
        }
    } //
    // Quiz Body
    // ---------
    .hidden {
        transition: height 0.6s ease 0s;
    }
    .question, .radio-button {
        display: inline-block;
    } // Circle and Checks
    .option-container-radio
     {
        width: 80%;
    }
    #question-23, #question-28 {
        .option-container-checkbox{
            width: 80%;
        }
    }
    .option-container-radio,
    .option-container-checkbox {
        clear: both;
        display: inline-table;
        //margin-right: 30px;
        position: relative;
        &:last-child {
            margin-right: 0;
        }
    }
    .option-container-radio.trainer-gender,
    .option-container-radio.gender
    //.option-container-checkbox.week
    {
        width: auto !important;
        clear: none !important;
        display: inline-block;
        .answer-icon {
            padding-right: 0 !important;
        }
    }
    .option-container-radio.trainer-gender{
        float: none !important;
    }
    .option-container-radio.trainer-gender:last-child{
        margin-right: 0 !important;
    }
    .option-text {
        font-size: 16px;
        text-align: center;
        margin: 0 auto;
        font-weight: 600;
        color: #141e2c; // Veritcally align text
        position: relative;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 50%;

    }
    .option-can-check {
        height: 8vh;
        background-color: #fff;
        border-radius: 50px;
        float: left;
        margin-bottom: 15px;
        cursor: pointer;
    }

    .option-can-check.gender {
        width: 30vw ;
        //height: 4em;
        .option-text{
            //width: 40%;
        }
    }
    .option-can-check.gender.age {
        border-radius: 2em !important;
        margin-bottom: 0.5em;
        margin-left: 1em;
        margin-right: 1em;
        .option-text{
           width: 100% !important;
        }
    }
    .option-can-check.week {
        width: 25vw ;
        height: 9vh;
        border-radius: 2em !important;
        margin-right: 0.3em;
        display: inline-grid !important;
        .option-text{
            margin-left: 0 !important;
            width: 100%;
            float: none !important;
            padding-top: 2.5vh;

        }
    }
    .option-can-check.trainer-gender {
       width: 31vw;
       border-radius: 2em !important;
       margin-left: 5px;
        .option-text{
                width: 60%;
        }
    }
    .option-can-check:active{
        background-color: #ffffff;
    }
    .option-can-check input:checked{
        background-color: #748eac;
    }
    .option-was-selected {
        position: absolute;
        top: 0;
        left: 0;
    }
    .next-question:hover {
        text-decoration: none;
    }
    .question-title {
        font-family: "Tiempos Head Line";
        margin-bottom: 0;
        text-align: center;
        font-size: 30px !important;
        color: #000000;
        font-weight: bold;
        margin-bottom: 5%;
    }
    .question-help-text {
        font-size: 4vw;
        font-weight: 8px;
        text-align: center;
        color: #614b4b;
        margin-bottom: 6%;
    }
    .checkmark-container {
        /*        background: url('/static/images/quiz-check2.png') no-repeat 0 0 rgba(68, 185, 235, .5);
        width: 125px;
        height: 125px;*/
        border-radius: 75px;
    }
    #form-quiz {
        padding-right: 0.5%;
        padding-left: 0.5%;
        .form-group {
            //width: 100vw;
            textarea {
                &:extend(.form-control);
            }
        }
        .form-control,
        .form-group input {
            height: 6vh;
            border-radius: 0;
        }
        .form-group textarea {
            height: 220px;
            width: 98%;
            border-radius: 0;
        }
        .form-group label {
            padding-left: 0 !important;
            color: black;
            //display: inherit;
            text-align: center;
            font-weight: 400;
            font-size: 18px;
            padding-bottom: 4px;
        }
        textarea {
            width: 80%;
            margin: auto;
        }
    }
    /*.child-questions {
        margin-top: 50px;
    }*/
    .one-child {
        .form-group {
            float: none;
        }
        input {
            &.form-control {
                width: 290px;
                ;
                margin: 0 auto;
                display: table;
            }
        }
    }
    .two-child {
        .form-group {
            /*float: left;*/
            /*width: 50%;*/
        }
        .form-control {
            width: 80%;
            margin: 0 auto;
            border-radius: 0;
        }
        .container-question {
            width: 738px;
            /*margin: 0 auto;*/
            display: table;
        }
        label {
            margin-bottom: 15px;
        }
    }
    .three-child {
        .form-control {
            width: 80%;
            margin: 0 auto;
        }
    }
    .container-checkbox {
        //float: left;
        display: inline-block;
        font-size: 20px;
        label {
            font-size: 18px;
            font-weight: 100;
        }
    }

    .question-divider {
        border-bottom: 1px solid rgb(240, 222, 222);
        width: 139%;
        margin-left: -20%;
        margin-top: 40px;
    } //dynamic elemement so this will break someday
    //#question-39 {
    //    .container-checkbox {
    //        margin-right: 0;
    //    }
    //}

    /*#question-15 {
        .form-group {
            float: none;
            width: inherit;
        }
    }*/

    .quiz-form,
    .quiz-thanks {
        .btn-primary {
            font-family: 'Avenir Next Medium';
            font-weight: 600;
            font-size: 18px;
            color: #fff;
            display: inline-block;
            background: #464747;
            padding: 12px 20px;
            text-decoration: none;
            background: #43b8ea;
            border: #43b8ea;
            -webkit-border-radius: 3px;
            -webkit-background-clip: padding-box;
            -moz-border-radius: 3px;
            -moz-background-clip: padding;
            border-radius: 3px;
            background-clip: padding-box;
            -moz-transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
        .btn-lg {
            padding: 12px 50px;
        }
        .btn-primary:hover {
            background: #5ac1ed;
            -webkit-border-radius: 3px;
        }
        .center-block {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        div.required {
            text-align: center;
            color: #ff5f4c;
            ul {
                padding: 0;
            }
        }
        /*input[name=primary-location]{
        max-width: 500px;
        margin: 50px auto;
    }*/
    }
    /* Text Animation */
    @-webkit-keyframes blinker {
        from {
            opacity: 1.0;
        }
        to {
            opacity: 0.0;
        }
    }
    .text-animation {
        -webkit-animation-name: blinker;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: cubic-bezier(1.0, 0, 0, 1.0);
        -webkit-animation-duration: 1s;
    } //
    // Quiz Footer
    // ------------
    .quiz-thanks {
        background-color: @brand-primary;
        color: #fff;
        .btn {
            margin: 0 auto;
            display: table;
        }
    }

    #primary-location-error {
        margin-top: 10%;
    }

    .footer-title,
    .footer-info {
        text-align: center;
    }
    .footer-title {
        margin-top: 30px;
        margin-bottom: 30px;
        font-weight: 700;
    }
    .footer-info {
        margin-bottom: 20px;
        font-size: 17px;
        font-weight: 300;
    }
    .extra-footer-container {
        margin: 55px auto 180px auto;
    }


    .container-social {
        background-color: #5c5b5b;
        text-align: center;
        h2,
        a {
            font-weight: 600;
            letter-spacing: .05em;
            color: #fff;
        }
        h2 {
            margin: 30px auto;
            font-size: 25px;
            font-weight: 600;
            letter-spacing: .05em;
            color: #fff;
        }
        a {
            margin: 30px auto;
            font-size: 25px;
            display: block;
        }
        ul {
            display: table;
            margin: 0 auto;
        }
        li {
            background: url('/static/images/sprite-social.png') no-repeat 0 0 transparent;
            float: left;
            width: 30px;
            height: 30px;
            margin: 0 40px 0 0;
            &:last-child {
                margin: 0;
            }
            a {
                float: left;
                display: block;
                width: 100%;
                height: 100%;
                margin: auto;
                text-indent: -999em;
            }
        }
        .footer-facebook {
            width: 17px;
        }
        .footer-instagram {
            background-position: -58px 0;
        }
        .footer-twitter {
            background-position: -124px 0;
            width: 28px;
        }
        .footer-pinterest {
            background-position: -193px 0;
            width: 23px;
        }
    }

    .container-newsletter {
        text-align: center;
        background-color: #494545;
        h2 {
            margin: 30px auto 25px;
            font-size: 25px;
            font-weight: 600;
            letter-spacing: .05em;
            color: #fff;
        }
        .two-child {
            .email-address {
                margin-right: 10px;
            }
             ::-webkit-input-placeholder {
                color: #c8c8c8;
            }
             ::-moz-placeholder {
                color: #c8c8c8;
            }
             :-ms-input-placeholder {
                color: #c8c8c8;
            }
            input {
                display: inline;
                height: 53px;
                margin: 0px 0px 30px;
                border-radius: 4px;
                font-size: 19px;
            }
        }
        .btn {
            &:extend(.btn-grey);
            width: 175px !important;
        }
    }
    footer {
        margin-top: 0;
    } //
    // Modal
    // ------
    .modal-title {
        color: #FFF;
        font-size: 30px;
    }
    .modal-footer {
        .btn-primary:hover {
            background: #5ac1ed;
            -webkit-border-radius: 3px;
        }
    }
    #carouselstart{
        .carousel-inner {
        height: auto;
        width: 65vw;
        max-height: none !important;
        display: inline-block;
    }
    }


    @media (max-width: 767px) {
        #quiz-arrow-button {
            .glyphicon-arrow-left:before { 
                bottom: 1%;
            }
        }

        #quiz-modal {
            .alert-danger {
                position: absolute;
                top: 10px;
            }
        }
        
        .btn-custom-div {
            bottom: 1%;
        }

        .container {
            width: auto;
        }
        .touch .modal {
            width: inherit !important;
            margin-left: auto !important;
        }
        .radio,
        .checkbox {
            padding: 0; // over write bootstrap 2
            color: black;
        }
        .option-text {
            font-size: 2vh;
            text-align: center;
            font-weight: 600;
            color: #2C2C2C; // Veritcally align text
            position: relative;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }
        .touch {
            .quiz-header {
                h1 {
                    margin-top: -73px;
                    font-size: 26px;
                }
                p {
                    margin-top: -10px;
                    margin-bottom: 0;
                    font-size: 16px;
                }
            }
            .container-progress-bar {
                .progress {
                    height: 7px !important; // over write bootstrap 2
                }
                .progress-markers {
                    ul {
                        top: 28px;
                    }
                    li {
                        margin-right: 8%;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
            .question-divider {
                border-bottom: 1px solid #f0dede;
                width: 110%;
                margin-left: -6%;
                margin-top: 40px;
            }
            .five,
            .four,
            .three,
            .two {
                width: 320px;
            }
            .five,
            .four {
                .option-container-radio,
                .option-container-checkbox {
                    margin: 0 20px 20px 0;
                    &:nth-of-type(2) {
                        margin-right: 0;
                    }
                    &:nth-of-type(3) {
                        margin-right: 20px;
                    }
                    &:nth-of-type(4) {
                        margin-left: 0;
                        margin-right: 0;
                    }
                    &:nth-of-type(5) {
                        margin-left: 85px;
                    }
                }
            }
            .three {
                .option-container-checkbox {
                    //margin: 0 20px 20px 0;
                    &:nth-of-type(2) {
                        margin-right: 0;
                    }
                    &:nth-of-type(3) {
                        //margin-left: 85px;
                    }
                }
                .next-question {
                    margin: 0 15px 0 0;
                    display: inline-block;
                    &:nth-of-type(2) {
                        margin-right: 0;
                    }
                    &:nth-of-type(3) {
                        margin-left: 85px;
                    }
                    .option-container-checkbox {
                        margin: 0;
                    }
                }
            }
            .two {
                .next-question {
                    margin: 0 15px 0 0;
                    display: inline-block;
                    &:nth-of-type(2) {
                        margin-right: 0;
                    }
                    .option-container-checkbox {
                        margin: 0;
                    }
                }
            }
            .two-child {
                .form-group {
                    float: none;
                    width: auto;
                }
                .container-question {
                    width: 260px;
                }
            }
            .container-checkbox {
                margin-right: 0;
                float: none;
                .checkbox>label {
                    font-size: 16px;
                }
            }
        }
    } // quiz question break
    @media (min-width: 768px) and (max-width: 991px) {
        .btn-custom-div .btn-custom {
            margin-left: 25px;
        }
        
        .quiz-header {
            h1 {
                margin-top: -130px;
            }
        }
        .container-progress-bar {
            .progress-markers {
                li {
                    margin-right: 82px;
                    &:last-child {
                        right: -5px;
                    }
                }
            }
        }
        .option-container-radio,
        .option-container-checkbox {
            margin-bottom: 30px;
        }
        .five {
            width: 580px;
            .option-container-radio {
                &:nth-of-type(3) {
                    margin-right: 0;
                }
                &:nth-of-type(4) {
                    margin-left: 90px;
                }
            }
        }
        .four {
            .option-container-checkbox {
                &:nth-of-type(2) {
                    margin-right: 0;
                }
            }
        }
        #responses-modal {
            .modal-dialog {
                left: 13%;
            }
        }
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        .container-progress-bar {
            .progress-markers {
                ul {
                    top: 28px;
                }
                li {
                    margin-right: 113px;
                    &:last-child {
                        right: -6px;
                    }
                }
                .question-divider {
                    border-bottom: 1px solid #f0dede;
                    width: 106%;
                    margin-left: -6%;
                    margin-top: 40px;
                }
            }
        }
        #responses-modal {
            .modal-dialog {
                left: 33%;
            }
        }
    }

    @media (min-width: 1200px) {

        .container-progress-bar {
            .progress-markers {
                ul {
                    top: 28px;
                }
                li {
                    margin-right: 12.65%;
                    margin-left: -0.2%;
                    &:last-child {
                        right: -3px;
                    }
                }
            }
        }
        #responses-modal {
            .modal-dialog {
                left: 33%;
            }
        }
    }
    @media (max-width: @screen-xs-max) {
        .quiz-header {
            background-color: #454556;
            background-size: cover;
            h1 {
                margin: 70px;
            }
        }
        .five,
        .four{
            width: auto;
            .option-container-radio,
            .option-container-checkbox {
                display: inline-block;
            }
        }
    }
}

/*NEW PROGRESS BAR*/
.quiz-modal {
    background-color: #f4f6f8 !important;
    .modal-dialog {
        .modal-content {
            border-radius: 0;
            border: none !important;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            -o-box-shadow: none;
            box-shadow: none;
            background-color: #f4f6f8 !important;
            .title {
                text-align: center;
                background: #fff;
                padding-top: 10px;
                border: none !important;
                border-radius: 0;
                h1 {
                    color: #454545 !important;
                }
            }
            #carousel-example-generic {
                padding-right: 0.5%;
                padding-left: 0.5%;
                padding-top: 1em;
                margin-top: 1em;
            }
            .buttons-container {
                text-align: center;
                padding: 30px 0;
            }
            .alert-danger {
                position: absolute;
                top: 20px;
            }
        }
    }

    .progress-bar-container {
        background-color: #fff;
        height: 70px;
    }

    ul.progress-bar {
        background-color: #fff;
        width: 100%;
        margin: 0;
        padding: 0 15%;
        font-size: 0;
        list-style: none;
        box-shadow: none ! important;
    }

    li.section {
        display: inline-block;
        padding-top: 45px;
        font-size: 13px;
        font-weight: bold;
        line-height: 16px;
        color: #818181;
        vertical-align: top;
        position: relative;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    li.section:before {
        content: 'x';
        position: absolute;
        top: 2px;
        left: calc(50% - 10px);
        z-index: 1;
        border-radius: 17px;
        line-height: 30px;
        background: gray;
    }

    .status-bar {
        height: 4px;
        width: 100%;
        background: gray;
        position: relative;
        margin: 0 auto;
    }
    .current-status {
        height: 4px;
        width: 0;
        border-radius: 1px;
        background: #89d2fa;
    }

    @keyframes changeBackground {
        from {
            background: gray
        }
        to {
            background: #89d2fa
        }
    }

    li.section.visited:before {
        content: '\2714';
        animation: changeBackground .5s linear;
        animation-fill-mode: forwards;
    }

    li.section.visited.current:before {
        box-shadow: 0 0 0 2px #89d2fa;
        /*color: white;*/
    }

    .spinner-container {
        margin-bottom: 100px;
        .spinner {
            min-height: 50px;
        }
        .spinner-text-container {

            .spinner-text:after {
                overflow: hidden;
                display: inline-block;
                vertical-align: bottom;
                -webkit-animation: ellipsis steps(4, end) 900ms infinite;
                animation: ellipsis steps(4, end) 900ms infinite;
                content: "\2026";
                /* ascii code for the ellipsis character */
                width: 0px;
            }

            @keyframes ellipsis {
                to {
                    width: 1.25em;
                }
            }

            @-webkit-keyframes ellipsis {
                to {
                    width: 1.25em;
                }
            }
        }
    }

    @media (min-width: @screen-md-min) {
        .modal-dialog {
            width: 900px;
        }
        .progress-bar-container {
            /*padding: 20px 200px;*/
        }
        li.section.visited {
            color: white;
        }
        .current-status {
            margin-left: 22%;
        }
    }

    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        /*.progress-bar-container {
            padding: 20px 50px;
        }*/
        .five{
            margin-left: 5%;
        }
        .four {
            margin-left: 20%;
        }
    }

    @media (min-width: @screen-sm-min) {
        li.section:before {
            width: 30px;
            height: 30px;
        }
        .status-bar {
            top: 20px;
        }
    }

    @media (max-width: @screen-xs-max) {
        .progress-bar-container {
            padding: 20px 0;
            overflow: hidden;
        }
        ul.progress-bar {
            max-height: 50px;
        }
        li.section {
            color: #818181;
        }
        li.section:before {
            width: 25px;
            height: 25px;
        }
        .status-bar {
            top: 15px;
        }
    }
}

body#find-personal-trainers-near-me, 
body#pricing-page {
    .quiz-container {
          background-color: #f4f6f8 !important;
    }
}


body#quiz {
    font-family: 'Avenir Next Medium';
    height: 100%;
    height: -webkit-fill-available;
    .quiz-container {
        background-color: #f4f6f8 !important;
      }
    .how-works-home-button{
        opacity: 0;
    }
    .modal-content {
        background-color: #f4f6f8 !important;
    }
}

@media (max-width: 767px) {
    .container {
        /*width: auto;*/
    }
    .touch .modal {
        width: inherit !important;
        margin-left: auto !important;
    }

    #question-41 {
        .three.centered {
            display: flex;
            padding-bottom: 15px;
        }
    }

    .quiz-modal-container .question-title {
        font-size: 24px !important;
        margin-bottom: 30px;
    }
}

@media (min-width: 767px) and (max-width: 992px) {
    #quiz-arrow-button {
        .glyphicon-arrow-left:before { 
            bottom: 1%
        }
    }
    
    .btn-custom-div {
        bottom: 1%
    }
}

body#find-personal-trainers-near-me,
body#pricing-page,
body#quiz,
body.ACE-partnership {
    .pac-container {
        z-index: 100000 !important;
    }
    .ui-datepicker {
        .ui-datepicker-next span {
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 6px solid black;
        }
        .ui-datepicker-prev span {
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-right: 6px solid black;
        }
    }
    .quiz-container {
        //background: url('../img/quiz/hero.jpeg') no-repeat;
        background-size: cover;
        color: #454556;
        text-align: center;
        height: 100%;
        .zipcode-form {
            margin:auto;
            color: #ffffff;
            font-size: 25px;
            p {
                font-size: 19px;
                font-weight: unset;

            }
            input {
                height: 3em;
                width: 230px;
                border-radius: 17px;
                text-align: center;
                @media (min-width: @screen-sm-min) {

                }
             input[type="text"]{

             }
            }
            .form-group{
                  margin-bottom: 1em;
                  width: 100%;
            }
            .btn.btn-primary {
                font-size: 15px;
                width: 100%;
                height: 48px;
                margin-top: 3px;
                border-radius: 0;
                position: fixed;
                right: 0;
                bottom: 0;
                @media (min-width: @screen-sm-min) {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    } //
    .search-top-trainers {
        height: 350px;
    };
    //#trainers_count{
    //    padding-top: 3em;
    //    padding-left: 1em;
    //    padding-right: 1em;


    //}
    #prev-modal {
        margin-right: -15px;
        .modal-dialog {
            .modal-content {
                border-radius: 0;
                background-color: #54b7f0;
                color: white;
                text-align: center;

                h2 {
                    font-weight: bold;
                }
                p {
                    font-size: 15px;
                    margin-top: 20px;
                }
            }
        }
    }
    .close-modal-x {
        position: absolute;
        top: 10px;
        right: 20px;
        background: none;
        border: none;
    }
    .five,
    .four {
        display: inline-block;
        text-align: center;
    }
}
    .front-image{
        position: absolute;
        left: 20%;
        width: 55%;
        z-index: 1000;
        height: auto;
        top: 30%

    }
    .trainers_count{
      color: #4ccea5;

    }
@media (min-width: @screen-sm-min) {

    body#quiz,
    body#find-personal-trainers-near-me,
    body#pricing-page,
    body.ACE-partnership {
        .search-top-trainers,
        .search-bottom-trainers {
            .zipcode-form {
                input {
                    border-radius: 4px;
                }
                input[name=zipcode] {
                    margin: 5px auto;
                    padding: 18px;
                    min-width: 450px;
                    height: 48px;
                }
                .btn {
                    height: 48px;
                    width: 100px;
                    margin-left: 10px;
                }
            }
        }
        .search-bottom-trainers {
            height: 300px;
        }
        .quiz-container {
            //height: 100vh;
            .zipcode-form {
            }
        }
        .search-bottom-trainers {
            height: 300px;
        }
        #prev-modal {
            .modal-dialog {
                max-width: 700px;
                .modal-content {
                    padding: 70px 0;
                }
            }
        }
        #quiz-modal {
            .checkmark-container {
                background: url('/static/images/quiz-check2.png') no-repeat 0 0 rgba(68, 185, 235, .5);
                width: 125px;
                height: 125px;
            }
            .three {
                //margin-left: 23%;
            }
            .four {
                //margin-left: 13%;
            }
            .five {
                margin-left: 4%;
            }
            .two-child {
                .form-group {
                    float: left;
                    width: 100%;
                }
                .container-question {
                    margin-left: 90px;
                }
            }
            .checkbox {
                margin-top: 0px;
            }
            .question.text-area {
                width: 40em;
                .form-group{
                        width: 40em;

                }
            }
            .spinner-text-container {
                width: 350px;
                margin: 50px auto;
                .spinner-text {
                    font-size: 20px;
                }
            }
            .alert-danger {
                position: absolute;
                top: 20px;
            }

        }
    }
    body.ACE-partnership {
        .search-top-trainers {
            position: absolute;
            bottom: 0;
            left: 0;
            background: rgba(73, 69, 69, .7);
            width: 100%;
            padding: 15px 0;
        }
        .btn.youtube-video {
            margin-top: 30px;
        }
    }
}

@media (max-width: @screen-xs-max) {
    body#find-personal-trainers-near-me,
    body#pricing-page,
    body#quiz {
        .pac-container {
            position: fixed;
            display: block;
            /*margin-top: -210px;*/
        }
    }
    .quiz-container {
        //height: 100vh;
        .zipcode-form {
            width: 100%;
            .input-group {
                margin: auto;
            }
        }
    }
    .search-top-trainers {
        input[name=zipcode] {
            width: 250px;
            height: 48px;
            border-radius: 17px !important;
        }
        .btn {
            width: 100px;
            height: 48px;
            border-radius: 0 !important;
        }
        .btn-search {
            width: 300px;
        }
    }
    #prev-modal {
        .modal-dialog {
            width: 100%;
            margin: 0;
            .modal-content {
                height: 100%;
                padding: 60px 20px;
            }
        }
    }
    #quiz-modal {
        display: flex;
        background: white;
        -webkit-align-items: center;
        -webkit-box-align: center;
         align-items: center;
        .modal-dialog {
            margin: 0;
            background: white;
            .title {
                display: none;
            }
            .modal-content {
                height: 100%;
                background: white;
                .title {
                    display: none;
                }
                .question-title {
                    font-size: 18px;
                }
                .question-help-text {
                    margin: 0;
                    font-size: 13px;
                }
                .question {
                    margin: 15px 0;
                }
                .five {
                    .option-container-radio,
                    .option-container-checkbox {
                        margin: 10px 2px;
                    }
                }
                .four {
                    .option-container-radio,
                    .option-container-checkbox {
                        margin: 10px 20px;
                    }
                }
                //.three {
                //    .option-container-radio,
                //    .option-container-checkbox {
                //        margin: 10px 3px;
                //    }
                //}
                .option-can-check {
                    width: 90px;
                    height: 90px;
                }
                .option-text {
                    font-size: 12px;
                    padding: 0;
                }
                .checkmark-container {
                    background: url('/static/images/quiz-check-xs.png') no-repeat 0 0 rgba(68, 185, 235, .5);
                    width: 90px;
                    height: 90px;
                }
                .child-questions {
                    margin: 0;
                    .checkbox {
                        margin-top: 10px !important;
                    }
                }
                .form-control {
                    height: 30px;
                    width: 80%;
                    label {
                        font-size: 12px;
                    }
                }
                .form-group {
                    margin-bottom: 0;
                    text-align: center;
                    float: left;
                    width: 100%;
                    label {
                        display: inline-block !important;
                        font-size: 12px;
                    }
                }
                .buttons-container {
                    margin-top: 0;
                    padding: 0;
                }
                .alert {
                    padding: 0;
                }
                .container-checkbox {
                    font-size: 12px;
                    label {
                        font-size: 12px;
                    }
                }
                .question.text-area {
                    padding: 0 20px;
                }
                .spinner-text-container {
                    width: 90%;
                    margin: 100px auto;
                    .spinner-text {
                        font-size: 15px;
                    }
                }
                #question-21 label,
                #question-22 label,
                #question-24 label {
                    margin-top: 15px;
                }
            }
        }
    }
}

.button-fulldown  {
    font-size: 15px;
    width: 100%;
    height: 48px;
    margin-top: 3px;
    border-radius: 0;
    position: fixed;
    right: 0;
    bottom: 0;
}

 .answer-icon{
     float: left;
     padding-left: 0.5em;
     padding-right: 3vw;
     img{
         height: auto;
         width: 7vw;
         max-height: 3.5em;

     }
 }

input#text-question-24{
     text-align: center;
     border-radius: 3em !important;
 }
.child-questions{
    clear: both;
}

.check-title{
    margin-bottom: 1em;
}
.second-title {
    font-size: 4vw;
    font-weight: 8px;
    text-align: center;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 15px;
}


.img-slide{
    background-size: cover;
    background-repeat: no-repeat;
    height: 58vw;
    width: 93%;
}

#first-slide{
    background-image: url("/static/img/start-animation/home.svg");

}
#second-slide{
    background-image: url("/static/img/start-animation/gym.svg");
}
#third-slide{
    background-image: url("/static/img/start-animation/outdoors.svg");
}

span.back{
    float: left;
    cursor: pointer;
    font-size: large;
}
.progress{
    height: 0.5em !important;
    border-radius: 0 !important;
}
.progress-bar-success{
    background-color:#5340ff !important;
}
.client{
    fill: aliceblue;
}
.timeline-icon{
    display: inline-block;
    width: 2em;
}
.timeline-cont{
    margin-right: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.client-active{
    fill:#4ccea5 !important
}
#quiz-modal{
  background-color:#f4f6f8 !important;
  display: none;
  height: 100vh;
  min-height: 100%;
  margin-top: -3rem;
}

.option-selected, .option-checked{
    background-color: #748eac !important;
}

#question-34 input[type="text"]{
    text-align: center;
    border-radius: 3em !important;
}

.how-img{
    max-height: 6em;
    width: 15vw;
    margin-left: 1em;
    margin-top: -1em;
}
#pre-result{
    display: table;
    width: 100%;
    height: 90vh;
}


.item-animated{
    //float: left;
    width: 100%;
    position: relative;
    text-align: left;
    margin-bottom: 1em;
    -webkit-transition: opacity 2.0s ease-in;
    -moz-transition: opacity 2.0s ease-in;
    -o-transition: opacity 2.0s ease-in;
    transition: opacity 2.0s ease-in;
    opacity: 1;
    span{
        text-align: left;
        float: left;
        margin-right: 2em;
    }
    p{
        font-weight: 500 !important;
        font-size: 2.3vh;
        text-align: justify;
    }
}
@supports (-ms-ime-align:auto)
   and (not (-ms-accelerator:true)) {
  .item-animated {  transition: left;}
}
.out{
   opacity: 0;
}
.container-animation{
    display: table-cell;
    vertical-align: middle;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 8vh;
    h3{
        margin-bottom: 1em;
    }

}
.title-preresult{
    font-size: 5vw;
    font-weight: bold;
}
.pre-result-foot{
    clear: both;
    margin-top: 5vh;
    position: relative;
    -webkit-transition: opacity 2.0s ease-in;
    -moz-transition: opacity 2.0s ease-in;
    -o-transition: opacity 2.0s ease-in;
    transition: opacity 2.0s ease-in;
    opacity: 1;
    button{
        width: 100%;
        position: fixed;
        height: 2.5em;
        margin-top: 2.5em;
        right: 0;
        border-radius: 0 !important;
        bottom: 0;
    }
    #text-f{
        font-size: 3.2vw;

    }
    span{
        float: left;
        img{
            width: 14vw;
            max-height: 4em;
            margin-left: 1.5em;

        }
    }
    @media (max-width: @screen-xs-max) { 
        margin-top: 1vh;
    }
}
.hdn{
    opacity: 0;
}
//#trainers_count{
//    h2{
//        padding-right: 10%;
//        margin-bottom: 1em;
//        padding-left: 10%;
//    }
//}
p.subtitle{
    padding: 10%;
    font-size: large;
    font-weight: 600 !important;
}
h1.number-text{
    display: none;
    color: #4ccea5;
    font-size: 20vw;
}
//#question-34 .question-title{
//    color: white !important;
//}
.field-error {
    position: absolute;
    top: 10vh;
    width: 100%;
}
#question-38, #question-38{
    display: grid !important;

}
#question-33 .form-group, .question-title, .question-help-text{

    padding-right: 1.5em;
    padding-left: 1.5em;


}

.loader {
    left: 8vw;
    position: absolute;
    float: left;
    width: 1.5em;
    height: 1.5em;
    margin-left: 10vw;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.final-load{
    float: left;
    text-align: center;
    width: 100%;

}
.carousel-inner{
    max-height: none !important;
}

.centerSpiner{
    position: absolute;
    top: 10%;
    margin-top: 0;
    height: 50px;
    left: 50%;
}

//TRAINERS-NEAR-ME-----------------------------------------------------------------------------------------------------<

.hero-container{
    height: 900px;
    background: url(../img/home/home-hero.webp) no-repeat;
    background-size: 100%;
    position: relative;
    background-position-y: 40%;
    div.hero-match-titles {
        padding-top: 8%;
        padding-left: 7.5%;
        h1 {
            color: #FFFFFF;
            font-family: 'Avenir Next Medium';
            font-style: normal;
            font-size: 46px;
            font-weight: 600;
            letter-spacing: 1.24px;
            line-height: 52.5px;
        }
        h2 {
            color: #FFFFFF;
            font-family: 'Avenir Next Medium';
            font-weight: 500;
            font-style: normal;
            font-size: 24px;
            line-height: 30px;
        }
        p {
            color: #FFFFFF;
            font-family: 'Avenir Next Medium';
            font-weight: 500;
            font-style: normal;
            font-size: 24px;
            line-height: 30px;
        }
        .form-match {
            margin: 0;
            .input-group {
                width: 60%;
                margin: 0;
                input#submit-id- {
                    max-width: 100%;
                    background-color: #5340FF;
                    border-radius: 5px;
                    font-weight: 700;
                    font-size: 20px;
                }
            }
        }
    }
}
@media screen{
    @media (max-width:@screen-xs-min) {
        .hero-container {
            height: 625px;
            background-size: 975px;
            background-position-x: 72.5%;
            background-position-y: 0;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            div.hero-match-titles {
                padding-top: 0;
                padding-left: 5%;
                padding-right: 5%;
                h1 {
                    font-size: 42.5px;
                    line-height: 45px;
                }
                .form-match {
                    max-width: 100%;
                    .input-group {
                        width: 100%;
                    }
                }
            }
        }
    }
    @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
        .hero-container {
            height: 625px;
            background-size: 975px;
            background-position-x: 72.5%;
            background-position-y: 0;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            div.hero-match-titles {
                padding-top: 0;
                padding-left: 5%;
                padding-right: 5%;
                h1 {
                    font-size: 42.5px;
                    line-height: 45px;
                }
                .form-match {
                    max-width: 100%;
                    .input-group {
                        width: 100%;
                    }
                }
            }
        }
    }
    @media (min-width: @screen-xs-max) and (max-width: @screen-sm-max) {
        .hero-container {
            height: 550px;
            background-position-y: 0;
        }
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        .hero-container {
            height: 700px;
            background-position-y: 0;
        }        
    }
}

////////////////////////
@media (min-width: 650px) {

  .option-can-check{
        width: 40em !important;
  }
  .answer-icon img {
      height: auto;
      width: 3em;
      max-height: 3.5em;

  }
  .answer-icon{
       padding-right: 0 !important;
  }
  .front-image{
      max-height: 18em;
      left: 20%;
      top: 37%;

  }
  .img-slide{
        max-width: 28em;
        max-height: 30em;

  }
    .item.center-block{
        width: 30em;
    }
    #carouselstart{
        .carousel-inner{
            width: auto !important;
        }
    }
    .zipcode-form{
        .btn.btn-primary{
            width: 100%;

            position: relative !important;
        }
    }
    .checkmark-container{
        display: none;
    }
    .question-help-text{
        font-size: larger !important;
    }
    .question-title{
        font-size: 1.7em!important;
        padding-top: 1.24em;
    }
    #quiz-modal{
        height: 90vh !important;
        flex-direction: column;
        justify-content: center;
    }

    .second-title{
        font-size: larger;
    }
    .option-can-check.gender{
      width: 10vw!important;
    }
    .option-text{
        font-size: large !important;
    }
    .option-container-radio.None, .option-container-checkbox, .question .text-area  {
        display: inline-table !important;
    }
    .option-can-check.week{
        width: 8em !important;
        .option-text{
            height: 1.5em;
            padding-top: 0 !important;
        }
    }
    .option-can-check.trainer-gender{
        width: 13em !important;
        .option-text{
            width: 80% !important;
        }
    }
    h4.check-title{
        color: black;
    }
    .title-preresult {
        font-size: 1.7em;
        font-weight: 700;
        margin-top: 2.4em;
    }
    .pre-result-foot {
        span {
            img {
                width: 4.5em;
                max-height: 4.5em;
                margin-left: 0;
                transform: scale(1.4, 1.4);
                -ms-transform: scale(1.4, 1.4);
                -webkit-transform: scale(1.4, 1.4);
            }
        }
        #text-f {
            font-size: larger;
        }
        button {
                    width: auto;
                    position: relative;
                    padding-left: 1em;
                    padding-right: 1em;
                    margin-bottom: 2em;
        }
    }
    .button-fulldown{
            width: auto !important;
            padding-left: 2em;
            padding-right: 2em;
            margin-top: 2em;
            position: relative !important;
    }
    #question-33{
        .form-group{
            padding: 0 !important;
        }
    }
    .item-animated{
        span{
            margin-right: 0 !important;
            margin-left: -1em;
        }
    }
    .how-img{
        margin-left: 0em;
        margin-right: -1em;
    }
    .loader{
        left: 25%;
    }
    .centerSpiner{

            top: 5%;
    }
    .long-title{
        font-size: 1em;
    }
}

.container.no-padding {
   padding: 0;
}

input#id_zipcode{
    margin-bottom: 1rem;
    font-weight: 400;
    text-align: center;
    height: 3.4em;
    border: solid 1px #696464;
    border-radius: 5px;
}
input#submit-id-{
    width: 100%;
}

.form-match{
    max-width: 40em;
    margin: auto;
    .input-group{
        width: 80%;
        margin: auto;
        //input#zip-code{
        //    height: 3em;
        //}
        input#submit-id-{
            max-width: 15em;
            margin: auto;
            height: 2.3em;
        }
    }
}

@media (max-width: 649px) {
    body#quiz {
        .five {
            display: contents;
        }
    }

    .quiz-modal-container .option-can-check {
        width: 350px;
    }
}

@media (min-width: 300px) and (max-width: 400px) {
    body#quiz {
        .five {
            display: block;
        }
    }

    .check-title {
        font-size: 16px;
    }

    body#quiz .four {
        text-align: center;
        margin-right: 15%;
    }

    .quiz-modal-container {
        .option-can-check {
            width: 310px;
            margin-bottom: 8px;
        }
        .question-title {
            margin-bottom: 15px;
            font-size: 22px !important;
        } 
        .question-help-text {
            margin-bottom: 20px;
            font-size: 18px !important;
        }
    }

    .btn-custom-div .btn-custom {
        height: 42px;
        font-size: 16px;
    }

    #quiz-arrow-button {
        .glyphicon-arrow-left:before { 
            height: 42px;
            width: 42px;
            border-radius: 50%;
            font-size: 20px;
            padding-top: 9px;
        }
    }
    
}

@media screen and (device-aspect-ratio: 40/71) {
    body#quiz .four {
        text-align: start;
    }
    .question-title {
        font-size: 18px !important;
    }
    .question-help-text {
        .second-title {
            margin-bottom: 10px;
        }
        p {
            font-size: 12px;
        }
    }
}

// @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
//     body#quiz .four {
//         display: table-cell;
//         margin
//     }
// }

@media (min-width: 401px) and (max-width: 649px) {
    body#quiz .four {
        display: block;
        margin-right: 25%;
    }
}

