@import (reference) 'lesshat.less';
@import (reference) 'bootstrap_less/bootstrap.less';

@import (reference) 'colors.less';
@import (reference) 'fonts.less';

.home-items {
    .item {
        .clearfix();
        padding: 35px 0;
        &:nth-child(even){
            background-color: white;
        }
        &:nth-child(odd){
          background-color: lighten(@light-gray, 7%);
        }
        .info-box-container{
            .container();
        }
    }
    @media screen {
        @media (min-width: 480px) {
            .image-container{
                text-align: center;
                img {
                    max-width: 100%;
                    &.left {
                        margin-right: 20px;
                    }
                    &.right {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .info-box {
        text-align: center;
        .title {
            .extrabold(45px);
            color: @dark-gray;
            margin: 0 0 25px;
        }
        img.title-image {
            height: 4em;
            margin: 2em 0.2em;
        }
        .info {
            .regular(18px);
            color: lighten(@gray, 20%);
            line-height: 180%;
            margin: 7% 12%;
        }
        .btn {
            white-space: initial;
            outline: none;
        }
        .btn:focus {
            color: #5cb6ec;
            text-decoration: none;
        }
        .btn-wireframe-cyan:hover:hover {
            color: #fff;
            text-decoration: none;
        }
    }
    .owl-item img{
        width:100%;
    }
}

.featured-trainer {
    .info {
        color: white;
        position: absolute;
        bottom: 25px;
        width: 100%;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.4);
        > * {
            .regular(16px);
            margin: 5px 10%;
            &.full-name {
                .bold(16px);
            }
        }
    }
    .carousel-buttons {
        position: absolute;
        top: 50%;
        width: 100%;
        button {
            position: absolute;
            border: none;
            background-color: transparent;
            // width: 60px;
            // height: 50px;
            // color: white;
            &.prev {
                left: 15px;
            }
            &.next {
                right: 15px;
            }
            font-size: 50px;
            &:hover {
                color: @light-gray;
            }
        }
    }
}
@media screen{
    @media (min-width:769px){
        .home-items{
            .item{
                &:nth-child(odd){
                    .info-box{
                        width:50%;
                        padding-left: 10px;
                        float: right;
                    }
                    .image-container{
                        width: 50%;
                        float: left;
                    }
                }
                &:nth-child(even){
                    .info-box{
                        width:50%;
                        float: left;
                    }
                    .image-container{
                        width: 50%;
                        float: right;
                    }
                }
                .info-box{
                    .title{
                        margin-top: 25px;
                        margin-bottom: 10px;
                    }
                    .info {
                        margin: 3% 12%;
                    }
                }
            }
        }
    }
    @media (min-width:992px){
        .home-items {
            .item {
                .info-box {
                    .title {
                        .extrabold(55px);
                        margin-top: 50px;
                    }
                }
            }
        }
    }
    @media (min-width:1200px){
        .home-items {
            .item {
                .info-box {
                    .title {
                        margin-top: 102px;
                    }
                }
            }
        }
    }
    @media (min-width:768px) and (max-width:991px){
        .home-items {
            .item {
                .info-box {
                    .info {
                        .regular(15px);
                    }
                }
            }
        }
    }
}