@import (reference) 'bootstrap_less/bootstrap.less';
@import (reference) 'utils.less';
/*
#inbox {
    margin-top: 10px;
    background-color: #FCFCFC;
    border-color: #ddd;
    border-width: 1px;
    border-radius: 9px;
    -webkit-box-shadow: none;
    box-shadow: none;

    table {
        width: 100%;
        tbody tr td {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .date-column {
        width: 20%;
    }
    .recipient-column {
        width: 27%;
    }
    .subject-column {
        width: 28%;
    }
    .actions-column {
        width: 25%;
    }

    .btn {
        width: 60px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
    .replied-msg {
        padding: 9px;
    }
}

@media (min-width: @screen-sm-min){
    #inbox table {
        tbody tr td, thead tr th {
            &.date-column {
                padding-left: 15px;
            }
        }
    }
}

@media (max-width: @screen-xs-max){
    .msg-title {
        max-width: 60px;
    }
    .msg-sender, .msg-recipient {
        max-width: 60px;
    }
    #inbox table tbody tr td {
        font-size: 12px;
    }
}
*/

#messages-counter {
    border-radius: 3px;
    background-color: #c12e2a;
    color: #fff;
    padding: 0px 6px;
    position: relative;
    top: 30px;
    right: 7px;
    float: right;
    font-size: 10px;
    font-weight: 600;

}

.panel-message {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05)
}

.panel-body {
  padding: 15px
}

.panel-title {
    color: inherit;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Avenir Next Medium';
}

.panel-title>a {
    color: inherit
}

.panel-default {
    border-color: #ddd;
}

.panel-default>.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
}

.panel-heading {
    position: relative;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel-heading .panel-title a{
    color: #2a6496;
    cursor: pointer;
}

.submit-btn-box {
    text-align: center;
    margin: 10px;
}

/*
 * chat-like
 */

#inbox {
    .media-left {
        img { height: 100px; }
        @media (max-width: @screen-xs-max) {
            img {height: 50px; }
        }
    }
}