form {
  label {
    font-weight: normal;
  }
  .form-control,
  input,
  textarea {
    border-radius: 0;
    border: solid 1px rgb(204, 204, 204);
    padding: 4px 6px;
    &:focus {
      border-color: rgba(82, 168, 236, 0.8);
      outline: 0;
      outline: thin dotted \9;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(82, 168, 236, 0.6);
      -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(82, 168, 236, 0.6);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(82, 168, 236, 0.6);
    }
  }
  .submit-btn-box {
    text-align: center;
    margin: 10px;
  }
  .class-49 {
    color: #9c9c00;
  }
  .class-59,
  .class-99 {
    color: #ff5722f5;
  }
  .class-29,
  .class-39,
  .class-69,
  .class-79 {
    color: green;
  }
  .class-119,
  .class-149 {
    color: red;
  }
  #id_subscription_price,
  #id_pricing-and-payment-subscription_price,
  #id_virtual_subscription_price,
  #id_pricing-and-payment-virtual_subscription_price {
    option:nth-child(1) {
      color: green;
    }
    option:nth-child(2) {
      color: green;
    }
    option:nth-child(3) {
      color: #9c9c00;
    }
    option:nth-child(4) {
      color: #ff5722f5;
    }
    option:nth-child(5) {
      color: green;
    }
    option:nth-child(6) {
      color: green;
    }
    option:nth-child(7) {
      color: #ff5722f5;
    }
    option:nth-child(8) {
      color: red;
    }
    option:nth-child(9) {
      color: red;
    }
  }
  #id_pricing-and-payment-subscription_price {
    option:nth-child(2) {
      color: #9c9c00;
    }
    option:nth-child(3) {
      color: #ff5722f5;
    }
    option:nth-child(4) {
      color: green;
    }
    option:nth-child(5) {
      color: green;
    }
    option:nth-child(6) {
      color: #ff5722f5;
    }
    option:nth-child(7) {
      color: red;
    }
    option:nth-child(8) {
      color: red;
    }
  }
}

.virtual-subscription-price {
  margin: 20px 0;
  &.hidden {
    display: none;
  }
}

#div_id_agree,
#div_id_vaccine {
  width: 320px;
}

#settings_section,
#vaccination_section {
  margin-top: 40px;
}

#intro_pricing_fieldset,
#virtual_training_fieldset,
#off_peak_pricing_introducing_text {
  margin-top: 40px;
}
