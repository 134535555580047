/* override old css styles to make the price widget looks fine on
 * small screens */

@import (reference) 'bootstrap_less/grid.less';
@import (reference) 'colors.less';

@button-and-star-color: #36b6ec;
@subscription-color: #5bf1a7;
@border-color: #e5e5e5;
@back-color: #E9E9E9;
@body-bgcolor: #FAFAFA;

#trainer-profile,
#search-results {
    background-color: @body-bgcolor;
    .ace-profile {
        position: absolute;
        z-index: 1;
        bottom: 0;
        background-color: rgba(218, 56, 64, 0.47);
        text-align: center;
    }
    .carousel-indicators {
        bottom: 40px;
    }
}

#trainer-profile,
#account {
    .ace-cert {
        width: 50px;
        display: inline-block;
    }
    .ace-profile {
        width: 100%;
        height: 45px;
        img {
            width: 200px;
            margin-top: 15px;
        }
    }
}

#search-results {
    .ace-profile {
        width: 85%;
    }
}

.tooltip_templates {
    display: none;
}

// shared styles for trainer header name/location

.trainer-info-index{
  margin-bottom: 2em;
  .trainer-info {

    display: inline-block;
    a, a:focus, a:hover {text-decoration: none;}
    span.name {
        color: @cyan;
        font-weight: 600;
        font-size: 18px;
        vertical-align: middle; //&:focus, &:hover { text-decoration: none; }
        @media screen and (max-width: @screen-xs-max) {
            font-size: 16px;
        }
    }
    img.bolt-icon {
        width: 20px;
    }
    span.total-reviews {
        font-size: smaller;
    }
    span.trainer-reviews {
      img { height: 12px;}
    }
    span.reviews-avg {
        img { height: 14px; }
        margin-left: 10px;
        vertical-align: middle;
    }
  }
  .trainer-max-discount{
    float: right;
    text-align: right;
    span:extend(.session-widget .session-box p.session-price span){}
    p{
      margin-bottom: -3.25px;
      margin-top: -0.5em;
      color: #898888c7;
      font-weight: 625;
      font-size: 13.5px;
    }
  }
  @media screen and (max-width: @screen-xs-max) {
      margin-bottom: 3em;
      .trainer-max-discount{
        float:none;
        text-align:left;
        margin-top: 1em;
      }
      hr{display:none;}
  }
}
.trainer-location {
    .vo-badge {
        background-color: #EBE8FF;
        color: #333740;
        width: auto;
        padding: 1px 6px 1px 4px;
        border-radius: 2px;
        font-size: 16px;
        .vo-icon {
            margin: 0 3px 3px 3px;
        }
    }
    span {
        margin-right: 6px;
        font-size: 16px;
        .icon-virtual {
            height: 10px;
            margin-bottom: 3px;
            width: auto;
            margin-right: 5px;
        }
        .icon-loc {
            height: 22px;
            margin-bottom: 2px;
            width: auto;
        }
        .icon-home {
            height: 14px;
            margin-bottom: 3px;
            width: auto;
            margin-right: 5px;
        }
    }
}

.trainer-vaccination {
    span {
        font-size: 16px;
    }
    img {
        margin-bottom: 5px;
        margin-right: 3px;
    }
}

// ---
#trainer-profile header {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.save {
    border-right: 4px solid @body-bgcolor;
}

.pay-as-you-go {
    border-left: 4px solid @body-bgcolor;
}

.new-trainer-profile {
    .alert-success {
        display: none;
    }
    .breadcrumb {
        background: none;
    }
    .full-name {
        margin: 0;
    }
    .trainer-full-name h1 {
        margin-top: 0;
    }
    @media screen and (max-width: @screen-md-max) {
        .have-a-question {
            padding: 5px 0px;
            b {
                font-size: 10px;
            }
        }
    }
    @media screen and (max-width: @screen-xs-max) {
        .breadcrumb {
            margin: 0;
        }
    }
}

@media screen and (max-device-width: 480px) {
    .touch .time-content {
        float: left;
        margin: 0;
        width: 100% !important;
        text-align: center;
    }
    .subscription-box {
        padding-right: 0px !important;
    }
    .touch .price-content ul li:last-child {
        padding: 15px 0 !important;
    }
    .touch .price-content ul li:first-child {
        padding-top: 10px !important;
    }
    .subscription-box ul.price-final {
        width: 100% !important;
    }
    .single-box ul.price-full {
        width: 100% !important;
    }
    .btn-login a.login {
        display: block;
    }
    .booking-it .learn-more {
        position: initial;
    }
    .request-a-time {
        // width: 100%;
        a {
            width: 100%;
        }
    }
    .availability {
        .request-time-container {
            padding-left: 15px !important;
            min-height: 30px;
            padding: 5px;
            .modal_availability {
                float: left !important;
                #request-time-tooltip {
                    margin-top: 2px;
                }
            }
        }
        .pagination {
            margin: 10px 0 !important;
        }
    }
    .trainer-availability {
        tr.day-sessions-info td {
            position: relative;
        }
    }
    .session-list {
        z-index: 10;
        width: auto !important;
    }
    #modal-request-availability {
        .modal-header {
            p {
                font-size: 30px;
            }
        }
        .modal-footer {
            .btn {
                float: none;
            }
        }
    }
    #new-message-modal-trainer-profile {
        .modal-body {
            .title {
                font-size: 30px !important;
            }
        }
    }
    /* Firefox-specific rules go here */
    @-moz-document url-prefix() {
        .session-list {
            padding: 7px !important;
        }
    }
    .new-profile-individual-session {
        &:hover {
            background-color: darken(@cyan, 10%);
        }
    }
}

.trainer-bio-link {
    cursor: pointer;
    padding: 10px;
    color: #968888;
    text-decoration: none;
    /*border-bottom: 1px solid rgb(203, 187, 187);*/
    &:hover {
        text-decoration: none;
        color: #968888;
    }
}

.new-profile-links {
    &:hover {
        text-decoration: none;
    }
    &:visited {
        text-decoration: none;
    }
}

.training-location {
    margin-top: 10px;
    a {
        color: @button-and-star-color;
    }
}

.specialties {
    p.data-trainer-location {
        font-size: 13px;
        font-weight: 700;
    }
    ul {
        margin-bottom: 10px;
    }
    .specialty {
        float: left;
        padding: 3px;
        .specialty_label {
            color: #484848;
            background-color: #E9E9E9;
            font-size: 12px;
            font-weight: inherit;
        }
    }
}

.education {
    p.data-trainer-location {
        font-size: 13px;
        font-weight: 700;
        color: @button-and-star-color;
    }
}

.base-trainer-full-name {
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 10px;
    margin-bottom: 15px;
    @media and screen(max-width: 480px) {
        font-size: 22px;
    }
}

.base-custom-icon {
    background-color: @button-and-star-color;
    font-size: 10px;
    padding: 8px;
    color: white;
    border-radius: 25px;
}

.trainer-full-name-top {
    &:extend(.base-trainer-full-name);
    .custom-icon {
        &:extend(.base-custom-icon);
    }
    .full-name {
        margin-bottom: 0px;
    }
    img.pending,
    img.verified {
        display: inline-block;
    }
    img.pending {
        width: 150px;
        margin-top: 10px;
    }
    img.verified {
        width: 25px;
        margin-top: 15px;
    }
    padding-top: 10px;
}

.trainer-full-name {
    &:extend(.base-trainer-full-name); //border-bottom: 1px solid rgb(203, 187, 187);
    .custom-icon {
        &:extend(.base-custom-icon);
    }
    img.bolt-icon {
        width: 30px;
        margin-bottom: 10px;
    }
    img.pending,
    img.verified {
        display: inline-block;
    }
    img.pending {
        width: 150px;
        margin-top: 10px;
    }
    img.verified {
        width: 25px;
        margin-top: 15px;
    }
    p {
        float: left;
        margin-top: 6px;
        margin-right: 11px;
    }
}

.btns-after-widget {
    margin-top: 15px;
    .btn {
        margin: 10px auto;
        width: 95%;
    }
}


.trainer-actions {
    vertical-align: middle;
    .col-xs-4 {
        padding: .2em;
    }
    .btn {
        margin: 10px 0px;
        width: 100%;
    }
    p {
        margin: 0;
    }
}

.info {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
}

.data {
    color: #898888;
    font-size: 13px;
}


.trainer-stars {
    display: inline-block;
    margin: 0px 5px;
    color: @cyan;
    font-size: 10px;
    .review-avg {
        display: inline-block;
        margin: 0px 5px;
        @media screen and (max-width: @screen-xs-max) {
            img {
                width: 12px;
                height: 12px;
            }
        }
    }
    a {
        display: inline-block;
        text-decoration: none;
        color: @cyan;
    }
}

.trainer-bio {
    font-size: 12px;
    color: rgb(81, 78, 78); //padding-top: 10px;
    max-width: 600px;
}

.availability {
    padding-top: 20px;
    padding-bottom: 20px;
    .book-with-trainer {
        /*color: #6a6a6a;*/
        color: #36B6EC;
        font-size: 20px;
        font-weight: 600;
        padding-top: 10px;
    }
    .modal_availability {
        margin-top: 9px;
        margin-right: 3px;
    }
    .request-time-container {
        .tooltip-container {
            background-color: white;
            border: 0px;
            margin-top: 14px;
            position: absolute;
            padding: 1px;
            #request-time-tooltip {
                color: @button-and-star-color;
            }
        }
    }
    .pagination {
        margin: 7px 0;
    }
}

.chain-and-gym-info {
    .chain-name {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px;
        a,
        p {
            color: black;
        }
    }
    .no-membership {
        color: #37B4EB;
    }
    .areas-list {
        padding-left: 0 !important;
    }
}

.open-icon {
    display: inline-table !important;
}

#new-message-modal-trainer-profile,
#sessions-modal-mobile,
#modal-image {
    .modal-header {
        background-color: white;
        border-bottom: 0px;
        .close {
            color: rgb(190, 185, 185)
        }
    }
    .modal-body {
        margin: 15px 55px 0 30px;
        .title {
            font-size: 40px;
            font-weight: 600;
        }
        .subtitle {
            font-size: 15px;
        }
        ul.message-recomendations {
            list-style-type: circle;
            a {
                text-decoration: underline;
            }
        }
        form {
            padding: 10px 0 0 25px;
        }
    }
}

#modal-image {
    .modal-body {
        padding: 0px 15px 15px 15px;
        margin-top: 27px;
        display: inherit;
    }
}

#sessions-modal-mobile {
    .modal-body {
        padding: 0px;
        margin-top: 27px;
        .session-list-mobile {
            padding: 0px;
            text-align: center;
            a {
                font-size: 18px;
                margin-bottom: 10px;
            }
        }
        .day-for-mobile {
            font-size: 20px;
        }
    }
}

#subscription-modal {
    .modal-header {
        background-color: white;
        border-bottom: 0px;
        .close {
            color: rgb(190, 185, 185);
        }
    }
    .modal-body {
        margin-top: 40px;
        margin-bottom: 40px;
        padding: 0px;
        min-width: 70%;
        .header-content {
            background-color: @subscription-color;
            padding: 15px;
        }
        .middle-content {
            border: solid 1px @border-color;
        }
        .footer-content {
            .list-group {
                margin-bottom: 0px;
                .list-group-item {
                    padding-left: 26% !important;
                }
                .list-group-item:last-child {
                    border-radius: 0px;
                }
                .list-group-item:first-child {
                    border-radius: 0px;
                    border-top: 0px;
                }
            }
        }
    }
    .modal-footer {
        border-top: 0px;
    }
}

.second-left-container {
    clear: left;
    /* important. */
    margin: 1em 0em;
    .title {
        font-weight: 600;
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .map-canvas {
        height: 400px;
    }
    .areas-list,
    .info-container {
        background-color: #F7F6F6;
        padding: 20px;
    }
}

.image-container {
    margin-bottom: 15px;
    img {
        width: 100%;
    }
}

.new-profile-individual-session {
    font-weight: 700;
    color: white;
    background-color: @cyan;
    border: solid 1px;
    border-radius: 6px;
    padding: 2px 5px 2px 5px;
    cursor: pointer;
    margin-bottom: 3px;
    a {
        color: white !important;
    }
    a:active,
    a:visited {
        text-decoration: none;
    }
}

.new-profile-individual-session:active,
.new-profile-individual-session:visited {
    text-decoration: none;
}

.trainer-interactive {
    .interactive-options {
        a {
            color: black;
            &:hover {
                text-decoration: none;
            }
            &:a:visited {
                text-decoration: none;
            }
        }
    }
}

.trainer-verified {
    font-size: 15px;
    color: @button-and-star-color;
    font-weight: normal;
}

.fix-avialability-mobile {
    position: static;
}

.reviews-container {
  border-top: 1px solid rgb(203, 187, 187);
  margin-top: 15px;
  margin-bottom: 40px;
  .title-reviews {
    text-decoration: none;
    color: black;
    font-size: 20px;
    font-weight: 600;
    margin-top: 25px;
  }
  .single-review {
    margin-top: 20px;
    .review-content {
      margin-top: 10px;
      color: #7B7B7B;
    }
    .single-review {
        margin-top: 20px;
        .review-content {
            margin-top: 10px;
            color: #7B7B7B;
        }
        .review-author {
            font-size: 14px;
            font-weight: 600;
        }
    }
    a[name=see-reviews] {
        text-decoration: none;
        color: black;
        &:hover {
            color: black;
        }
    }
    #see-reviews {
        display: inline-block;
    }
    #leave-a-review {
        float: left;
    }
    #write-a-review {
        float: right;
        text-decoration: underline;
    }
}

    .write-review {
        cursor: pointer;
    }

    .video-layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .3);

        img.play {
            @size: 80px;
            width: @size;
            height: @size;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -@size/2px;
            margin-left: -@size/2px;
        }
    }

    .request-a-time {
        margin: 20px 0px;
    }

    .book-a-time {
        font-size: 15px;
        padding: 4px;
    }

    .details_trainer_thumb {
      background: #eee;
    }
}

.export-link{
  float: right;
    padding: 6px 12px;
    color: #fff;
    margin-top: 2px;
    text-decoration: underline;
    font-weight: 400;
    font-size: 18px;
}
.export-link:hover{
  color: #fff;
  font-weight: 600;
}

.different-timezone-banner {
    background-color: #EBE8FF;
    color: #333740;
    font-size: 16px;
    text-align: center;
    padding: 3px 0;
    margin: 0 -15px;
}