@import (reference) 'bootstrap_less/grid.less';
@import (reference) 'utils.less';

#root-trainer-calendar {
  background-color: white;

  .loading {
    position: absolute;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 77, 113, 0.15);
  }

  .calendar-trainer::-webkit-scrollbar {
    width: 8px;
  }
  .calendar-trainer::-webkit-scrollbar-track {
      border-radius: 10px;
  }
  .calendar-trainer::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #d0cece;
  }
  

  .calendar-trainer {
    padding: 1em;
    position: relative;
    min-height: 14em;
    max-height: 80vh;
    overflow-y: overlay;
    @media screen and (max-width: @screen-xs-max) {
      min-height: 5em;
    }
  }
  
  .calendar-days {
    text-align: center;
  }
  
  .calendar-day {
    padding: 0 .3em;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    float: left;

  }
  .calendar-day-single {
    display: block;
  }

  .day-header h4 {
    margin-bottom: .2em;
  }

  .calendar-pagination {
    padding: 0 1em;
  }
  
  .calendar-pagination-btn {
    margin: .6em 0;
    padding: 1em;
    cursor: pointer;
    position: absolute;

    &.next {
      right: -.2em;
      z-index: 10;
    }
  
    &.prev {
      left: -.2em;
      z-index: 10;
    }
    @media (max-width: 767px) {
      &.next {
        right: 2.25em;
      }
    
      &.prev {
        left: 2.25em;
      }
    }
  }
  
  .calendar-timeslot {
    margin-bottom: .6em;
  }
  
  .calendar-timeslot-btn {
    color: white;
    background: #36B6EC;
    font-family: 'Avenir Next Medium';
    font-size: 1.1em;
    font-weight: 600;
    display: inline-block;
    width: 100%;
    padding: .2em;
    line-height: 1.5;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .calendar-timeslot-btn:hover, .calendar-timeslot-btn:focus {
    background: #12759e;
    color: white;
    text-decoration: none;
  }

  .calendar-timeslot-btn-green {
    .calendar-timeslot-btn();
    background: #52dabc;
  }

  .calendar-timeslot-btn-green:hover, .calendar-timeslot-btn-green:focus {
    background: #14b18e;
    text-decoration: none;
    color: white;
  }
  
  .calendar-timeslot-inline {
    display: inline-block;
    margin-right: .4em;
    width: 6em;
  }
  
  .calendar-timeslot .btn, .calendar-timeslot-inline .btn {
    padding: .2em;
    font-size: 1.1em;
    width: 100%;
  }
  
  .calendar-timeslot.empty {
    color: grey;
  }
  
  .timeslots {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .btn-message-trainer, .btn-message-trainer:hover {
    background: #a7a7a7;
  }
}

@media screen and (min-width: @screen-xs-max) {
  .calendar-trainer {
    .btn-primary { margin-top: 4em; }
  }
}  