@import (reference) 'lesshat.less';
@import (reference) 'bootstrap_less/bootstrap.less';

@import (reference) 'colors.less';
@import (reference) 'fonts.less';

.why-a-trainer {
    .container-fluid();
    padding: 0;
    text-align: center;
    background-color: @light-gray;
    margin: 0;
    .title {
        .extrabold(30px);
        color: @dark-gray;
        margin: 35px;
    }
    .carousel-container {
        .carousel-item {
            .background-slide(@name){
                background: url("../img/@{name}-slide.jpg") center 0 no-repeat;
                background-size: cover;
            }
            &.fast {
                .background-slide('fast');
            }
            &.strong {
                .background-slide('strong');
            }
            &.smart {
                .background-slide('smart');
            }
            &.safe {
                .background-slide('safe');
            }
            &.fun {
                .background-slide('fun');
            }
            width: 100%;
            height: 600px;
            padding: 0;
            text-align: center;
            .info {
                .container();
                color: white;
                .title {
                    .extrabold(60px);
                    margin-top: 10%;
                    color: white;
                }
                .quote {
                    .extrabold(30px);
                    font-style: italic;
                    margin: 3% 20% 1%;
                    line-height: 110%;
                }
                .who-says {
                    .bold(18px);
                }
                .text {
                    margin: 3% 15%;
                    .bold(18px);
                }
            }
            .note-container{
                .hidden-xs();
                position: absolute;
                padding: 0 10px;
                bottom: 2%;
                width: 100%;
                .note {
                    .bold(12px);
                    color: white;
                }
            }
        }
    }
}

@media (max-width: @screen-sm-max){
    .why-a-trainer .carousel-container .carousel-item {
        height: 700px;
    }
}

@media (max-width: @screen-xs-max){
    .why-a-trainer .carousel-container .carousel-item {
        .info .quote {
            font-size: 22px;
        }
    }
}

@media (max-width: (@screen-xs-min - 1)){
    .why-a-trainer .carousel-container .carousel-item {
        height: 500px;
        .info {
            .title {
                .extrabold(50px);
            }
            .quote {
                .bold(18px);
                font-style: normal;
                line-height: 150%;
                margin: 3% 10% 1%;
            }
            .who-says {
                margin-top: 20px;
            }
            .text {
                display: none;
            }
        }
    }
}