.price-content {
    float: right;
    width: auto;
    color: #333;
    ul {
        float: left;
        clear: none;
        margin: 0;
        list-style: none;
        width: 100%;
        padding: 0px;

        &.price-full {
            border: 1px solid #DDD8E4;
            clear: left;
            position: relative;
            .line-through {
                background: #fe8277;
                display: block;
                width: 50px;
                height: 2px;
                opacity: 0.75;
                position: absolute;
                top: 40px;
                left: 25px;
            }

        }

        li {
            font-size: 16px;
            line-height: 1;
            display: block;
            text-align: center;
            sup {
                top: -1em;
                font-size: 15px;
            }
            &:first-child {
                background-color: silver;
                background-image: -moz-linear-gradient(top, #cacaca, #b2b2b2);
                background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#cacaca), to(#b2b2b2));
                background-image: -webkit-linear-gradient(top, #cacaca, #b2b2b2);
                background-image: -o-linear-gradient(top, #cacaca, #b2b2b2);
                background-image: linear-gradient(to bottom, #cacaca, #b2b2b2);
                background-repeat: repeat-x;
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFCACACA', endColorstr='#FFB2B2B2', GradientType=0);
                padding: 5px 15px;
                font-size: 12px;
                min-height: 36px;
            }
            &:last-child {
              background: #e6e6e6;
              padding: 10px 0;
            }
        }
        .price-saved {
          border: 1px solid #DDD8E4;
          .li {
              &:first-child {
                background: #fb5c32;
                filter: none;
                color: #fff;
                padding: 5px 15px;
              }
          }
        }

        &.price-final {
          border: 1px solid #DDD8E4;
        }

        .discount {
          border: 1px solid #DDD8E4;
        }

    }
    .single-box {
            padding-right: 0px !important;
        }

    .subscription-box {
        padding-left: 0px !important;
    }
}
.tooltip {
  font-size:12px;
  width:200px;
}

.base-class-header-price-widget-table {
  font-size: 12px;
  font-weight: 600;
  text-align:center;
  padding: 1px;
}

.price-widget {

  td {
    border: 5px solid white;
  }

  .pay-as-you-go {
    &:extend(.base-class-header-price-widget-table);
    background-color: @button-and-star-color;
  }

  .save {

    &-4 {
      &:extend(.base-class-header-price-widget-table);
      background-color: @4sess;
    }
    &-12 {
      &:extend(.base-class-header-price-widget-table);
      background-color: @8sess;
    }
    &-24 {
      &:extend(.base-class-header-price-widget-table);
      background-color: @12sess;
    }
  }

  td.table-body-content {
    background-color: #F7F6F6;
    padding: 0px;
    min-width: 130px;
    .text-price{
      font-size: 12px;
      padding: 8px 0px 0px 0px;
      text-align: center;
    }
    .price{
      font-size: 17px;
      text-align: center;
      font-weight: 600;
      .new-profile-tooltip {
        color: #c1bfbf;
      }
    }
  }
}