@import (reference) 'bootstrap_less/grid.less';

#gym {
  margin-bottom: 20px;
  .gym-name{
    font-size: 30px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    @media and screen(max-width: 480px) {
      font-size: 22px;
    }
  }
  #read-more {
    display: none;
    color: #44b7ea;
    font-size: 13px;
    font-weight: 600;
    margin-top: 5px;
  }
  color: #464646;
  h1 {
    margin-top: 0px;
    font-size: 30px;
    font-weight: 600;
  }
  h1.visible-xs {
    font-size: 18px;
    margin-top: 10px;
    font-weight: 400;
  }
  .gym-map-location {
    margin: 5px 0;
    #map-canvas {
      height: 250px;
      margin: 0px;
      padding: 0px;
    }
  }
  .gym-more-info {
    background: #fafafa;
    padding: 20px;
    font-size: 13px;
    h2 {
      margin-top: 0px;
    }
    .gym-name {
      font-size: 20px;
      font-weight: 600;
    }
    .chain-locations {
      margin: 15px 0;
    }
    .no-membership-legend {
      color: #44b7ea;
    }
  }
  hr {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .gym-amenities {
    margin-bottom: 10px;
    b {
      font-size: 15px;
    }
    span {
      font-size: 13px;
      color: #4A4A4A;
    }
  }
  .gym-rating {
    margin-bottom: 10px;
    b {
      font-size: 15px;
    }
    span {
      font-size: 11px;
      color: #4A4A4A;
    }
  }
  .gym-description {
    display: inline;
    font-size: 13px;
    color: #4A4A4A;
  }

  .btn-facility, .btn-favorite {
    background: #fff;
    padding: 3px;
    color: #44b7ea;
    border: 1px solid #44b7ea;
    font-size: 13px;
    width: 140px;
    margin-top: 10px;
  }

  .heart-icon {
      float: left;
      fill: #fff;
      border: #44b7ea;
      display: block;
      width: 18px;
      height: 17px;
      background: url(/static/img/heart.svg);
      background-size: 20px 27px;
      margin-left: 22px;
  }
  .trainers {
    background: #fafafa;
    padding: 10px;
    margin-top: 10px;
  }

  .gym-trainers {
    a { color: #44b7ea; }
    .title {font-size: 18px; font-weight: 600}
    .btn { color: #fff; font-size: 14px; margin: 3px 0; }
    .trainer {
      width: 100%;
      position: relative;
      .overlay {
        position: absolute;
        //visibility: hidden;
        width: 100%;
        height: 100%;
        background: #333;
        opacity: 0.4;
      }
      .trainer-thumbnail {
        padding-right: 20px;
        img {width: 200px;}
      }
      .trainer-info {
        width: 100%;
        .trainer-name {
          margin: 8px 0 5px;
          color: #44b7ea;
          font-size: 18px;
          img {
            height: 13px;
            margin-top: -1px;
          }
        }
        .trainer-name-xs {
          margin: 8px 0 5px;
          color: #44b7ea;
          font-size: 18px;
          margin-top: 0px;
        }
        .trainer-specialties,
        .trainer-price,
        .trainer-schedule{
          padding-right: 15px;
          font-size: 13px;
          span {
            color: #44b7ea;
          }
        }
      }
      .trainer-schedule {
        text-align: center;
        @media screen and (max-width: 480px) {
          margin-top: 15px;
        }
      }
    }
  }
  select {
     width: 90%;
     padding: 0px 7px;
     font-size: 13px;
     border: 1px solid #EFEFEF;
     height: 34px;
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     background: url(/static/img/icon-arrow-down-b-128.png) 96% no-repeat #fff;
  }

  .nopaddingleft{
    padding-left:0;
  }
}

@media (max-width: 478px) {
  #gym {
    #read-more {
      display: inline;
    }
    .gym-description {
      display: none;
    }
    .col-xs-12 {
      padding-right: 9px;
      padding-left: 9px;
    }
    margin-top: 0;
    .gym-section {
      h3 {display: none}
    }
    .trainers {
      margin: 10px -15px 0px -13px;
    }
    .gym-more-info {
      padding: 8px;
    }
    .gym-trainers {
      .trainer {
        .trainer-thumbnail {
          img {
            width: 130px;
          }
        }
      }
    }
  }
}
