@import (reference) 'colors.less';

.calendar-header {
  table { margin: 0px; }
}

tr.day-header td {
  text-align: center;
  background-color: @back-color;
  font-size: 13px;
  padding: 5px 12px 5px 12px !important;
  font-weight: 600;
}

.trainer-availability {
  margin: 0px;
  table { margin: 0px; }
  tr.day-sessions-info td {
    padding: 0.7em 0px;
    cursor: pointer;
    text-align: center;
    &.empty-day p {
      cursor: inherit;
      color: @text-grey;
    }
    &:nth-of-type(even) {
      background-color: @la-fafafa;
    }
    &:nth-of-type(odd) {
      background-color: white;
    }
    &:hover {
      background: darken(@la-fafafa, 5%);
    }
    .sessions-time {
      color: black;
      font-size: 14px;
      font-weight: 600;
      margin: 8px 0px;
      .close-sessions {
        display: none;
        color: #ABA3A3;
        margin-left: 1%;
        position: absolute;
        cursor: pointer;
      }
    }
    .total-sessions {
      font-size: 10px;
      color: @button-and-star-color;
    }
  }

  tbody > tr:first-child td {
    border-top: none !important;
  }

  // task 1902: Trainer profile schedule box. Right now every other column is shaded in gray.
  // Could you change this to shade Sat and Sun, and make the weekdays white?
  // I think this will help users distinguish days of the week more easily.
  &.weekday-theme {
    tbody > tr td {
      &[data-weekday=true] {
        background-color: white;
      }
      &[data-weekday=false] {
        background-color: @la-fafafa;
      }
      &:hover {
        background: darken(@la-fafafa, 5%);
      }
    }
  }

  .session-list {
    display: none;
    position: absolute;
    padding: 5px;
    border-radius: 5px;
    background-color: white;
    max-height: 140px;
    overflow: auto;
    z-index: 1;
    .box-shadow(0 8px 15px @light-gray);

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
        //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #d0cece;
        //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
    li:hover {
        background-color: darken(@cyan, 15%);
    }
    a {
      text-decoration: none;
      color: black;
    }
  }

}

/* old: Firefox-specific rules go here */
@-moz-document url-prefix() {
  .session-list {
    display: none;
    padding-left: 0;
    position: absolute;
    background-color: white;
    max-height: 140px;
    overflow: auto;
    padding: 6px;
    width: 12%;
    li:hover {
      background-color: @back-color;
    }
    a {
      text-decoration: none;
      color: black;
    }
  }
  .close-sessions {
    position: relative !important;
  }
}