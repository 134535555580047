@import (reference) 'bootstrap_less/grid.less';
@import (reference) 'colors.less';
@import (reference) 'utils.less';

html, body {
    overflow-x:hidden;
    ul {
        list-style:none;
    }
}

body {
    position: relative;
    min-width: 320px;
    font-family: 'Avenir Next Medium';

    // new style in replace of old style.css pages
    // like booking-flow, login/register, payment, etc
    &.minimal {
        // site header menu
        header.first {
            @media screen and (min-width: 480px) {
                .box-shadow(none); border-bottom: 1px solid @light-gray;
            }
        }
        // page header
        section#title {
            background: white;
            text-align: center;
            padding: 30px;
            h1, h2 { margin: 0px; font-weight: 700; }
            p { margin: 10px 0px 0px 0px; }
        }
        // page content
        section#content {
            margin: 0 auto;
            padding: 30px 0px;
            font-size: 15px;

            //default container size
            .container {
                max-width: 1000px;
                .trustpilot-col{
                    margin-top: 70px;
                }
                .subtitle{
                    border-bottom: 1px solid #eeeeee;
                    padding-bottom: 10px;
                    font-size: 17px;
                    @media (max-width: @screen-xs-max) {
                        margin-top: 50px;
                        font-size: 19px;
                    }
                }
            }

            a, a:hover, a:visited, a:link, a:active { text-decoration: none; }
            input.btn, button.btn, a.btn { font-size: 14px; font-weight: 700; }

            h4 {
                color: @gray;
                font-size: 15px;
                font-weight: 600;
            }

            form.slim {
                margin: 0 auto;
                max-width: 280px;
                ul { padding: 0px; }
            }

            @media screen and (max-width: 480px) {
                padding: 20px 0px;
            }
        }
    }
}

.preloader {
    position: fixed;
    left: 0; right: 0; top: 0; bottom: 0;
    background: rgba(0,0,0,.2);
    z-index: 9999;
    display: none;
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 40px;
        width: 40px;
        margin-left: -20px;
        margin-top: -20px;
    }
}

body > .container {
    background: white;
    padding: 0px;
}

.labels-left {
    label {
        float: left;
    }
}

iframe[name=google_conversion_frame] {
    position: absolute;
    bottom: 0;
}
.container{
    width:100%;
    max-width: 1270px;
    position: relative;
}

.fine-print {
    margin: 20px auto;
    p {
        color: #666;
        .regular(12px);
        line-height: 1.5;
        margin: 0 0 5px 30px;
        width: 90%;
    }
}

#payment, #book-complete, .gift-step{
    .payment-security-info {
        text-align: left;
        .logos-container {
            /*float: right;*/
            margin-bottom: 40px;
            .digicert {
                display: inline-block;
                vertical-align: middle;
                margin-left: 2em;
            }
            .stripe-img {
                display: inline-block;
                vertical-align: middle;
                margin-left: -1em;
            }
            @media screen and (max-width: 480px) {
                float: none;
            }
        }
    }
}

#payment{
    .logos-container{
        display: flex;
        .logos{
            margin: auto;
        }
    }
}

.password-change-input {
    margin-bottom: 15px;
}

.password-change-submit {
    margin-bottom: 15px;
    margin-left: 5px;
}

.label-password-change{
    margin-top:10px;
}

.password-change-header{
    font-size: 24.5px;
    font-weight: 400;
    line-height: 1.3;
    padding: 10px 0;
    margin: 0;
    text-align: center;
}

.password-change-errors {
    font-size: 16.5px;
    font-weight: 400;
    line-height: 1.3;
    padding: 10px 0;
    margin: 0;
    text-align: center;
    color: red;
}

.password-change-container {
    background: #f2f2f2;
    width: 100%;
    margin: 30px auto 0 auto;
    display: table;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

@media (min-width : 320px) and (max-width : 723px) {
    .fine-print p {
        width: 80%;
    }
}

.touch .account .up-next #map_canvas {
    display: none;
}

.validation-error {
    color: red;
    display: none;
    margin-top: 5px;
}

.carousel-inner {
    max-height: 500px;
}

.sans {
    font-family: 'Avenir Next Medium';
    &.sm { font-size: 13px; }
    &.bold {
        font-weight: 600;
    }
}

.select-a-start-time {
    background: #02295A;
    width: 100%;
    color: white;
    p {
        margin: 0px;
        padding: 10px;
        img.bolt-icon{
            width: 20px;
        }
    }
}

.pagination-area {
    margin: 20px 0px;
    display: flex;
    align-items: center;
    position: relative;
    a, a:hover {
        color: white;
    }
    .pagination-arrow {
        position:absolute;
        font-size: 25px;
        top: 0px;
        &.calendar-next {
            right: 10px;
        }
        &.calendar-prev {
            left: 10px;
        }
    }
}


.links {
    &:hover, &:focus, &:visited {
        text-decoration: none;
    }
}

.one-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

span.tag {
    color: #484848;
    background-color: #E9E9E9;
    font-size: 1em;
    padding: 4px;
    border-radius: 6px;
    margin: 0px 2px;
}

.input-group {
    input {
        z-index: auto !important;
    }
}

.trainers-minimal {
    .result-header {
        h2 {
            color: gray;
            font-size: 16px;
        }
    }
    .trainer-results {
        .trainer-container {
            height: 254px;
            margin: 0 auto;
            text-align: center;
        }
        .trainer {
            a, a:focus, a:visited, a:hover { text-decoration: none }
            margin-bottom: 20px;
            padding: 0px;

            .trainer-info {
                padding: 10px;
                .trainer-name {
                    font-size: 14px;
                    font-weight: 600;
                }
                .trainer-gym, .trainer-gym-location {
                    font-size: 12px;
                }
            }
        }
    }
}

.calendar-share-with-friends {
    font-size: 16px;
    color: rgb(103, 89, 89);
    font-weight: 600;
}

.vcenter {
    // applies for Bootstrap columns
    display: inline-block;
    vertical-align: middle;
    float: none;
}

a.stealth {
    color: inherit;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
    &.hover-underline:hover {
        text-decoration: underline;
    }
}

@media screen and (max-width: 768px) {
    .visible-xs-inline {
        display: inline-block !important;
    }
}

@media screen and (max-width: 768px) {
    .visible-xs-inline {
        display: inline-block !important;
    }
}

.subscription {
    &-4-sess {
        .title, .stripe { background-color: @4sess; }
    }
    &-12-sess {
        .title, .stripe { background-color: @8sess; }
    }
    &-24-sess {
        .title, .stripe { background-color: @12sess; }
    }
}
