@import (reference) "bootstrap_less/bootstrap.less";
@import (reference) 'utils.less';

#market-home{

	header {
        position: fixed;
        z-index: 2;
        width: 100%;
	}
	#main{
        margin-top: 59px;
    }

	// BEST OF

	#best-of-year{
		/*padding: 60px 0 60px 0;*/
		.show-sm{
			display: none;
		}
		.best-of-intro{
			text-align: center;
		    padding-top: 81px;
		    margin-top: 34px;
			.best-of-title{
				font-family: 'Avenir Next Medium';
	            color: #000000;
	            font-size: 35px;
	            margin-top: 0;
	            margin-bottom : 50px;
	            font-weight: 700;
	            line-height: 42.5px;
			}
		}
		#trainers-best-of{
			width: 80%;
			margin: 50px auto;
			padding-bottom: 50px;
    		margin-bottom: 0;
			.trainer-col{
				text-align: center;
			    margin-top: 60px;
			    .col-md-9{
			    	text-align: left;
			    }
			}
			/*.first-column{
				margin-left: 13.3%;
			}*/
		}
	}
	/*.home-items{
		background-color: #e6e6e6;
		h1{
			padding-top: 20px;
			font-weight: 800;
		}
		.carousel-inner {
			background-color: #e6e6e6;
    		min-height: 500px;
		}
		.item{
			padding: 0;
			padding-top: 35px;
			background-color: #e6e6e6;
		}
		.carousel-control{
			background: inherit;
			color: #1aaced;
			.glyphicon-chevron-left, .glyphicon-chevron-right{
				font-size: 40px;
			}
		}
	}*/
	.center-text {
		text-align: center;
	}
	.locate-group-philly {
	    background: none;
	    position: relative;
	}
	.best-of-nav {
		background-color: rgba(128, 128, 128, 0.1);
	    border-bottom: 1px solid rgba(128, 128, 128, 0.03);
	}
	.best-of-nav-text {
		width: 500px;
	    padding: 20px;
	    span {
	    	margin: 30px;
			text-align: center;
	    }
	}
	.trainer-preview {
		font-family: 'Avenir Next Medium';
		.trainer-preview-name {
            color: #000000;
            font-size: 20px;
          	text-decoration: none;
		}
		.show-more-preview-text{
			a {
				color: rgb(78, 169, 228);
				text-decoration: none;
			    font-weight: 550;
	    		font-size: 12px;
			}
		}
		.train-with {
			color: rgb(78, 169, 228);
			text-decoration: none;
		    font-weight: 550;
		    font-size: 14px;
		}
		.trainer-preview-image {
			max-width: 80px;
		}
		.trainer-preview-stars {
			color: #f3c571;
			font-size: 10px;
		}
		.trainer-preview-text {
			font-size: 11px;
		}
	}

    #best-of-year{
        /*padding: 60px 0 60px 0;*/
        .show-sm{
            display: none;
        }
        .best-of-intro{
            text-align: center;
            padding-top: 81px;
            margin-top: 34px;
            .best-of-title{
                font-family: 'Avenir Next Medium';
                color: #000000;
                font-size: 35px;
                margin-top: 0;
                margin-bottom : 50px;
                font-weight: 700;
                line-height: 42.5px;
            }
        }
        #trainers-best-of{
            width: 80%;
            margin: 50px auto;
            padding-bottom: 50px;
            margin-bottom: 0;
            .trainer-col{
                text-align: center;
                margin-top: 60px;
                .col-md-9{
                    text-align: left;
                }
            }
            /*.first-column{
                margin-left: 13.3%;
            }*/
            .view-all-trainers {
                margin: 70px 0 0;
                a {
                    border: 2.5px solid #36B6EC;
                    border-radius: 2.5px;
                    padding: 8px 15px;
                    font-weight: 600;
                    font-size: 17.5px;

                }
            }
        }
    }
    /*.home-items{
        background-color: #e6e6e6;
        h1{
            padding-top: 20px;
            font-weight: 800;
        }
        .carousel-inner {
            background-color: #e6e6e6;
            min-height: 500px;
        }
        .item{
            padding: 0;
            padding-top: 35px;
            background-color: #e6e6e6;
        }
        .carousel-control{
            background: inherit;
            color: #1aaced;
            .glyphicon-chevron-left, .glyphicon-chevron-right{
                font-size: 40px;
            }
        }
    }*/
    .center-text {
        text-align: center;
    }
    .locate-group-philly {
        background: none;
        position: relative;
    }
    .best-of-nav {
        background-color: rgba(128, 128, 128, 0.1);
        border-bottom: 1px solid rgba(128, 128, 128, 0.03);
    }
    .best-of-nav-text {
        width: 500px;
        padding: 20px;
        span {
            margin: 30px;
            text-align: center;
        }
    }
    .trainer-preview {
        font-family: 'Avenir Next Medium';
        .trainer-preview-name {
            color: #000000;
            font-size: 20px;
            text-decoration: none;
        }
        .show-more-preview-text{
            a {
                cursor: pointer;
                color: rgb(78, 169, 228);
                text-decoration: none;
                font-weight: 550;
                font-size: 12px;
            }
        }
        .train-with {
            color: rgb(78, 169, 228);
            text-decoration: none;
            font-weight: 550;
            font-size: 14px;
        }
        .trainer-preview-image {
            max-width: 80px;
        }
        .trainer-preview-stars {
            color: #f3c571;
            font-size: 10px;
        }
        .trainer-preview-text {
            font-size: 11px;
        }
    }


    .trainer-previews{
        margin-top: 10px;
    }
    .previews-image{
        max-width: 30px;
        float: left;
        margin-right: 10px;
    }
    .previews-user{
        font-size: 11px;
    }
    .previews-text{
        font-family: 'Avenir Next Medium';
        color: #000000;
        font-size: 14px;
        margin-top: 10px;
        overflow: hidden;
        max-height: 160px;
        text-overflow: ellipsis;
        display: inline-block;
    }


    // SPOTLIGHT

    #trainer-spotlight{
        background-color: #4e4e4e;
        color: white;
        padding: 30px 24%;
        letter-spacing: 0.5px;
        a.trainer-name-spotlight{
            color: white;
            text-decoration: none;
        }
        .trainer-spotlight-div{
            background-color: #312f2f;
            padding: 30px 50px;
            margin: auto;
        }
        .spotlight-avatar{
            width: 140px;
            margin-top: 20px;
        }
        .rating{
            color: rgb(154, 151, 151);
            margin-bottom: 20px;
        }
        .spotlight-text{
            margin: 10px;
        }
        .trainer-spotlight-stars{
            font-size: 13px;
            color: #f3c571;
        }
    }

    // MORE FYTS

    #more_fyts{
        margin-top: 50px;
        .trainer-info{
            font-size: 13px;
        }
        .reviews {
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            max-height: 36px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .trainer-info a{
            color: #1aace9;
        }
        .previews-more-fyts{
            font-size: 12px;
            margin-bottom: 5px;
        }

    }

    // STARS
    .fa-star {
        color: #f3c571;
    }
    .fa-star-o:before {
        color: #f3c571;
    }
    .fa-star-half-empty:before, .fa-star-half-full:before, .fa-star-half-o:before {
        color: #f3c571;
    }

    @media (min-width: @screen-md-min){
        .homepage-trainers .trainer .trainer-thumbnail {
            padding: 0;
            width: 13%;
        }
    }

    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        .hide-md{
            display: none;
        }
        .show-sm{
            display: block !important;
        }
        .row.trainers-best-of{
            padding: 0 100px 0 100px;
            text-align: center;
        }
        .home-items{
            .carousel-inner {
                min-height: 700px;
                img{
                    width:400px; /* you can use % */
                    height: auto;
                }
            }
        }
        #trainer-spotlight{
            padding: 30px 14%;
        }
    }

    @media (max-width: @screen-xs-max) {
        h3 { display: block !important; }
        .hide-xs{
            display: none;
        }
        div.best-of-intro {
            padding-bottom: 0;
        }
        .row.trainers-best-of{
            padding: 0 100px 0 100px;
            text-align: center;
            .first-column{
                margin-left: 0 !important;
            }
        }
        .trainer-preview-image {
            max-width: 100px;
        }
        .submit-search-trainer {
            margin-left: 0;
        }
        #trainer-spotlight{
            padding: 30px 14%;
            h2{
                font-size: 20px;
            }
            .trainer-spotlight-div{
                padding: 30px 20px;
            }
            .spotlight-avatar {
                width: 110px;
            }
            .rating {
                font-size: 12px;
            }
        }
        .home-items{
            h3{
                font-size: 25px;
            }
            .carousel-inner {
                min-height: 700px;
                img{
                    width: 290px;
                    height: auto;
                }
            }
            .info-box .info{
                font-size: 15px;
            }
            .carousel-control{
                .glyphicon-chevron-left, .glyphicon-chevron-right{
                    font-size: 30px;
                    margin-top: 50px;
                }
            }
        }
    }
}

#market-home, #national-home{
    .home-items-carousel{
        background-color: #e6e6e6;
        h1{
            padding-top: 20px;
            font-weight: 800;
        }
        .carousel-inner {
            background-color: #e6e6e6;
            min-height: 500px;
            max-height: none !important;
        }
        .item{
            padding: 0;
            padding-top: 35px;
            background-color: #e6e6e6;
        }
        .carousel-control{
            background: inherit;
            color: #1aaced;
            .glyphicon-chevron-left, .glyphicon-chevron-right{
                font-size: 40px;
            }
        }
        #market-carousel {
            padding-bottom: 50px;
            h2 {
                margin-top: 0px;
                padding-top: 1em;
            }
            ol.carousel-indicators{
                position: absolute;
                bottom: 10px;
            }
            .carousel-indicators li{
                background-color: #46aded;
                border: 1px solid #46aded;
            }
            .carousel-indicators .active{
                background-color: #0066ff;
                border: 1px solid #0066ff;
            }
        }
    }
}

#market-home, #national-home{
	.home-items-carousel{
		background-color: #e6e6e6;
		h1{
			padding-top: 20px;
			font-weight: 800;
		}
		.carousel-inner {
			background-color: #e6e6e6;
			min-height: 500px;
			max-height: none !important;
		}
		.item{
			padding: 0;
			padding-top: 35px;
			background-color: #e6e6e6;
		}
		.carousel-control{
			background: inherit;
			color: #1aaced;
			.glyphicon-chevron-left, .glyphicon-chevron-right{
				font-size: 40px;
			}
		}
		#market-carousel {
			padding-bottom: 50px;
			h2 {
				margin-top: 0px;
				padding-top: 1em;
			}
			ol.carousel-indicators{
				position: absolute;
    			bottom: 10px;
			}
			.carousel-indicators li{
				background-color: #46aded;
    			border: 1px solid #46aded;
			}
			.carousel-indicators .active{
				background-color: #0066ff;
    			border: 1px solid #0066ff;
			}
		}
	}
}
