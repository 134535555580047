@import (reference) 'bootstrap_less/bootstrap.less';
@import (reference) 'colors.less';
@import (reference) 'fonts.less';
@import (reference) 'utils.less';


.hero-coming-soon {
	&:extend(.parent-hero);
    h1{
        .extrabold(2.2em);
    }
    h3{
        .bold(18px);
        line-height: 1.3em;
        margin: 30px 2%;
    }
    #coming-soon-email {
        height: 47px;
    }
}

.personal-trainer-container {
    background-color: red;
    h4, a {
        .bold(15px);
        color:@cyan;
    }
    .footer_nav{
        padding: 0;
        li{
            list-style: none;
            .regular(13px);
            margin-bottom: 6px;
            a{
                color: #41B9F7;
                font-size: 14px;
            }
        }
    }
    .masonry_container{
        width:90%;
        margin: 0 auto;
    }
    .footer_col{
        width: 100%;
        text-align: center;
        padding: 0 8px;
    }
    @media screen {
        @media (min-width:480px){
            .footer_col{
                width:50%;
                text-align: left;
            }
        }
        @media (min-width:768px){
            .footer_col{
                width:20%;
                text-align: left;
            }
            .footer_brand{
                text-align: left;
                .logo_footer{
                    float: left;
                }
                .footer_inline-nav{
                    float: right;
                    margin: 0;
                }
            }
        }
    }
}

#thanks-modal-coming-soon, #neighborhood-modal {
    .modal-header {
        background-color: white;
        border-bottom: 0px;
        .close {
            color: rgb(190, 185, 185) 
        }
    }
    .modal-body {
        margin-bottom: 30px;
        #coming-soon-email, #coming-soon-email-neighborhood {
            height: 47px;
        }
        .body-title {
            font-size: 36px;
            font-weight: bold;
            margin-top: 30px;
        }
    }
}

#thanks-modal-coming-soon {
    .modal-body {
        text-align: center;
        margin-top: 27px;
        padding-top: 0px;
        .body-title {
            margin-top: 0px;
        }
    }
}

#neighborhood-modal {
    .modal-body {
        .checkbox {
            margin-left: 40px;
        }
        #signup_with_neighborhood {
            height: 47px;
        }
    }
}

@media screen{
    @media (max-width:@screen-xs-min){
        .hero-coming-soon, hero-work-with-us {
            padding: 25px 0 80px;
            h3 {
                margin: 30px 10%;
            }
            .container {
                margin-top: 110px;
            }
        }
        .iam-trainer {
            margin-bottom: 20px;
        }
        .hero-work-with-us {
            background: ~"url('../img/small-fun-slide-with-opacity.jpg') no-repeat";
            padding: 0px 0 11px;
        }
    }
    @media (min-width:600px){
        .hero-coming-soon, .hero-work-with-us {
            padding: 152px 0;
            h1{
                .extrabold(55px);
            }
        }
    }
    @media (min-width: @screen-xs-min) and (max-width: 599px){
        .hero-coming-soon, hero-work-with-us {
            padding: 25px 0 80px;
            h3 {
                margin: 30px 10%;
            }
            .container {
                margin-top: 110px;
            }
        }
        .hero-work-with-us {
            .iam-trainer {
                margin-bottom: 10px;
            }
        }
    }
    @media (min-width: 600px) and (max-width: @screen-xs-max){
        .hero-coming-soon, hero-work-with-us {
            h3 {
                margin: 30px 20%;
            }
        }
        .iam-trainer {
            margin-bottom: 20px;
        }
    }
    @media (min-width: @screen-lg){
        .hero-coming-soon {
            background-size: 100% !important;
        }

        #neighborhood-modal {
            .modal-dialog {
                width: 750px;
            }
        }
    }
}

.personal-trainers-in-city {
    &:extend(.how-find-our-trainers-works);
    background-color: #fafafa;  
    .title{
        color: @dark-gray;
        .semibold(30px);
    }
}