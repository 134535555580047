@import (reference) 'fonts.less';
@import (reference) 'colors.less';

.bootbox {
	.modal-dialog {
		width: 450px;
		.modal-content {
			.modal-body {
				.regular(18px);
				color: gray;
				text-align: center;
			}
			.modal-footer {
				border-top: none;
				padding-top: 0;
				button {
					padding: 5px 12px;
				}
			}
		}
	}
}