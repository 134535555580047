@import (reference) 'lesshat.less';
@import (reference) 'bootstrap_less/bootstrap.less';

@import (reference) 'colors.less';
@import (reference) 'fonts.less';
@import (reference) 'utils.less';

@hover-color: lighten(@light-gray, 8%);

@media screen {
    @media (max-width: 767px) {
        .side-menu {
                position: fixed;
                right: -220px;
                width: 200px;
                height: 100%;
                background-color: #fff;
                z-index: 1000;
                padding-left: 0;
                padding-right: 0;
            }
            .menu {
                margin-top: 0;
                width: 100%;
            }
            .navbar-toggler {
                z-index: 1000000;
                position: fixed;
                right: 10px !important;
            }
            .navbar-brand {
                padding-top: 5px !important;
            }
            .in {
                right: 0 !important;
                transition: all 0.5s ease 0s;
                overflow: hidden !important;
            }
    }
}

.menu-common(@d: inline-block) {
    float: right;
    padding: 0;
    li {
        list-style: none;
        display: @d;
        a {
            display: inline-block;
            .bold(15px);
            color: @gray;
            padding: 20px 10px;
            letter-spacing: 0.8px;
            &:hover {
                text-decoration: none;
            }
            span {
                font-family: 'Avenir Next Medium';
            }
        }
        /* #help::before {
            background-image: url(../img/help-icon.png);
            background-size: 20px 20px;
            background-repeat: no-repeat;
            display: inline-block;
            width: 20px;
            height: 21px;
            margin-bottom: -5px;
            content: "";
        } */
    }
}

header.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 24;
}

header {
    .box-shadow(0px 3px 17px 0px rgba(50, 50, 50, 0.25));
    background: #fff;
    min-height: 59px;
    &.first {
        .get-started {
            background-color: #5340FF;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
        }
        .header-left {
            float: left;
            display: table;
            width: 40%;
            height: 59px;
            @media screen and (max-width: @screen-xs-max) {
                width: 100%;
            }
        }
        .header-right {
            float: right;
            width: 60%;
            display: table;
            height: 59px;
            .trainer-sign-up {
                background-color: #d2f2ff;
                border-radius: 5px;
            }
            /*.get-started{
                background-color: #56CC9D;
                border-radius: 5px;
                color: white;
            }*/
            @media screen and (max-width: @screen-xs-max) {
                width: 0px;
            }
        }
    }
    .logo-area {
        display: table-cell;
        vertical-align: middle;
    }
    .header-search {
        padding-top: 15px;
        padding-left: 10px;
        padding-right: 10px;
        float: left;
        width: 240px;
        input {
            border: 0px;
            border: 1px solid @light-gray;
            padding: 5px;
        }
        @media screen and (max-width: @screen-xs-max) {
            display: none;
        }
    }
    .logo {
        //float: left;
        .logo(30px);
        display: inline-block;
        vertical-align: middle;
        a {
            img {
                width: 60px;
                height: 40px;
            }
            // background: url('../img/logo.png') 0 center no-repeat;
            // text-indent: -1000px;
            //overflow: hidden;
            //min-width: 241px;
            //color: black;
            //.your {
            //    color: @cyan;
            //}
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
    .market {
        font-family: 'Avenir Next Medium';
        display: inline-block;
        vertical-align: middle;
        font-weight: 600;
        color: #9A9A9A; //float: left;
        padding: 4px 8px;
        margin-top: 4px;
        margin-left: 8px;
        border-left: 1px solid #BFBFBF;
    }
    .bar {
        width: 29px;
        height: 3px;
        background: #000;
        margin-bottom: 5px;
        transform-origin: 0 0;
    }
    .navbar-toggler {
        background-color: #fff;
        border: none;
        float: right!important;
        padding-top: 10px;
    }
    .menu {
        .dropdown {
            font-size: 12px;
            top: -2px;
            .btn {
                background-color: transparent;
                font-weight: bold;
                font-family: 'Avenir Next Medium';
                padding: 20px 10px;
                margin-top: 1px;
                color: @dark-gray;
                outline: none;
                &:active {
                    border-radius: 0;
                }
            }

                a {
                    border-bottom: 1px solid #efefef;
                    width: 100%;
                    background: #fff;
                    &:hover {
                        text-decoration: none;
                        background-color: @hover-color;
                    }
                }
        }
        .menu-common(block);
        z-index: 100;
        li {
            a {
                border-bottom: 1px solid #efefef;
                width: 100%;
                background: #fff;
                &:hover {
                    text-decoration: none;
                    background-color: @hover-color;
                }
            }
        }
        .menu-icon {
            background: @cyan;
            position: absolute;
            left: -49px;
            padding: 20px 10px 15px;
            .bar {
                width: 29px;
                height: 3px;
                background: #fff;
                margin-bottom: 5px;
                .transition(transform 0.3s);
                -webkit-transform-origin: 0 0;
                transform-origin: 0 0;
            }
        }
        .coming-soon-link {
            .btn {
                color: grey;
                padding-top: 13px;
                margin-top: -4px;
                padding-bottom: 7px;
                &:hover {
                    text-decoration: none;
                    outline: solid 2px #337ab7;
                    color: #337ab7;
                    background-color: white;
                }
            }
        }
    }
    #messages-counter {
        border-radius: 3px;
        background-color: #69C9F0;
        color: #fff;
        padding: 2px 6px;
        position: relative;
        top: 1px;
        right: -5px;
        float: right;
        font-size: 10px;
        font-weight: 600;
    }
    .messages-counter {
        background-color: #69C9F0;
    }
}

.menu-icon {
    transition: all .3s;
}

.open {
    .top {
        .transform( rotate(45deg) translate(4px, -4px));
    }
    .middle {
        opacity: 0;
    }
    .bottom {
        .transform(rotate(-45deg) translate( -1px, 6px));
    }
}

@media screen {
    @media (min-width: 970px) and (max-width: 1024px) {
        header .menu li a {
            padding: 20px 6px;
            font-size: 12px;
        }
    }
    @media (max-width: 970px) {
        header .menu li a {
            padding: 20px 6px;
            font-size: 12px;
        }
        .menu {
            .dropdown {
                .btn {
                    font-size: 12px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 170px;
                }
            }
        }
        header .menu .dropdown .btn {
            font-size: 12px;
        }
        header.first,
        header.last {
            .logo {
                font-size: 20px;
                a {
                    min-width: 0;
                }
            }
            .market {
                font-size: 12px;
            }
        }
    }

    @media (min-width:767px) {
        header.first,
        header.last {
            .header-left {
                float: left;
            }

            .menu {
                li {
                    &.link.user-link {
                        display: none;
                    }

                    display: inline-block;

                    a {
                        border: 0;
                        height: 63px;
                    }

                    &.coming-soon-link {
                        a {
                            border: 0;
                            height: 44px;
                            padding-top: 13px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }

        .navbar-toggler {
            display: none;
        }

        .navbar-right {
            height: auto !important;
        }
    }
}

header.last .container .menu {
    .menu-icon {
        display: none;
    }
}

@media screen {
    @media (max-width: 767px) {
        header.sticky {
            top: auto;
        }
        .mobile-get-started {
            display: inline-block !important;
            float: right;
            margin-right: 50px;
            margin-top: 5px;
            font-weight: bold;
            font-size: 12px;
        }
        header.first {
            .logo a {
                font-size: 16px;
                img {
                    width: 45px;
                    height: 30px;
                }
            }
        }
        header.last {
            .container {
                .logo-area {
                    display: block;
                    margin: 18px;
                    text-align: center;
                }
                .logo {
                    margin-right: 5px;
                    margin-top: 4px;
                    float: initial;
                    vertical-align: middle;
                    display: inline-block;
                }
                .market {
                    float: initial;
                    text-align: center;
                    margin: 0px;
                    vertical-align: middle;
                    display: inline-block;
                }
                padding: 0;
                .menu {
                    .dropdown {
                        display: none;
                    }
                    position: static;
                    float: none;
                    width: 100%;
                    li a {
                        font-size: 14px;
                        border-top: 1px solid #efefef;
                        border-bottom: none;
                        text-align: center;
                    }
                }
            }

        }
        div#navbarTogglerDemo02 {
            position: fixed !important;
        }
    }
}
header.last {
    .header-search {
        display: none;
    }
    .logo {
        margin-top: 6px;
    }
}
@media (min-width: @screen-md-max) {
    header {
        .get-started {
            font-size: 17.5px;
            padding: 8px 25px;
        }
    }
}
@media (max-width: @screen-md-max) {
    header {
        .get-started {
            padding: 10px;
        }
    }
}
.mobile-header-search {
    display: none;
    padding: 1.2em;
    input {
        background: none;
    }
    input:focus,
    input:active {
        .box-shadow(none);
    }
    .input-group {
        background: none;
        width: 100%;
    }
}
.mobile-search-toggle {
    @media (min-width: @screen-xs-min) {
        display: none;
    }
    cursor: pointer;
    vertical-align: middle;
    padding-top: 15px;
    font-size: 20px;
}
@media (max-width: 568px) {
    header{
        .container{
            padding: 0;
        }
    }
    .mobile-get-started {
        margin-right: 55px;
    }
}

// ########## BLUE365-HEADER ##############

.header-blue365-right {
    float: right;
    width: 60%;
    display: table;
    height: 59px;
    padding: 3px 0 3px;

    .trainer-sign-up {
        background-color: #d2f2ff;
        border-radius: 5px;
    }
    @media screen and (max-width: @screen-xs-max) {
        width: 0px;
    }
    .side-text-blue365-container {
        float: left;
        width: 40%;
    }
    p.--side-text-blue365 {
        color: #1D2835;
        font-size: 22px;
        margin-top: 23px;
    }
    .blue365-logo-container {
        float: right;
        width: 60%;
    }
    .blue365-logo {
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width: @screen-xs-max) {
    width: 0px;
    .header-blue365-right{
        margin-left:auto;
        .blue365-logo-container {
            width: 100%;
            margin-top: 23px;
        }
        .blue365-logo {
            height: 50%;
            width: 50%;
            display:block;
            margin: auto;
        }
    }
}

/* Icon 1 */

.navbar-toggler-icon {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.navbar-toggler-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    background: #000000;
}

.navbar-toggler-icon span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.navbar-toggler-icon span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.navbar-toggler-icon span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.navbar-toggler-icon.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0px;
    left: 8px;
}

.navbar-toggler-icon.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

.navbar-toggler-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 21px;
    left: 8px;
}
