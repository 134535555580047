.navbar-notice, .navbar-warning {
    display:none;
    padding: 1px;
    text-align: center;
    h4 {
        font-size: 15px;
        font-weight: 600;
    }
    a {
        color:black;
        text-decoration: none;
    }
    span {
        vertical-align: middle;
    }
}

a.ribbon{
    color: black;
}
a.ribbon:hover{
    text-decoration: none;
}

.ribbon.trx{
    .navbar-notice, .navbar-warning{
        min-height: 60px;
        h4{
            margin-top: 20px;
            b.underline{
                text-decoration: underline;
            }
        }
    }
    .navbar-notice{
        background-color: rgb(255, 230, 0);
    }
    .navbar-warning{
        background-color: #e45752;
        span, b{
            color: white;
        }
    }
}

@media (max-width: 723px) {
    a.btn-trx{
        font-size: 15px;
    }
}
