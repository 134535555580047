.featured_areas {
	.container-fluid();
    text-align: center;
    background-color: white;
    padding: 32px 12%;
	.title {
		color: @dark-gray;
        .semibold(30px);
        margin: 0;
	}
	.items {
        .make-row();
        .item-container {
            margin: 32px 0;
            .make-sm-column(6);
            .make-lg-column(3);
            .item {
                position: relative;
                .image {
                    width: 100%;
                    position: relative;
                }
                .item_text {
					position: absolute;	
					top: 42%;
					color: white;
					.extrabold(27px);
					left: 0%;
  					width: 100%;
                }
                .pre_item_text {
                	position: absolute;	
					top: 32%;
					color: white;
					font-weight: 600 !important;
					.extrabold(18px);
					left: 0%;
  					width: 100%;	
                }
            }
        }
    }
    @media (max-width:@screen-xs-min){
        .items {
            .item-container {
                margin: 0px;
                .item {
                	margin-top: 30px;
	                .image {
	                    max-height: 200px;
	                }
	            }
            }
        }
    }
}