#stepper {

    border-top: 2px solid #eaeaea;
    border-bottom: 6px solid #d5d5d5;
    width: 100%;
    .options {
        padding: 40px 0 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        height: 70px;
        a.option {
            border-bottom: 6px solid #d5d5d5;
            padding: 22px 20px;
            margin: 0;
            display: none;
            font-size: 16px;
            font-weight: bold;
            color: #575459;
            cursor: pointer;
            &:hover {
                color: #5340ff;
                text-decoration: none;  
            }
            &.active {
                display: inline;
            }
        }
    }

} /* end #stepper */

#trainer-header {

    width: 100%;
    padding: 25px 0;
    height: 125px;
    background-color: #f4f6f8;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 85px;
    }
    div.time-slot-data {
        margin: 0 15px;
        height: 85px;
        width: auto;
        padding-top: 5px;
        p {
            margin-bottom: 2px;
            font-weight: bold;
            font-size: 17px;
            span {
                border-radius: 3px;
                font-size: 15px;
                color: #ffffff;
                padding: 1px 8px;
                margin-right: 6px;
                &.off_peak {
                    background-color: #52dabc;
                }
                &.virtually {
                    background-color: #EBE8FF;
                    color: #333740;
                }
            }
        }
    }

} /* end trainer-header */

#addressModal {
    margin-top: 10%;
    h1 {
        text-align: center;
        font-size: 22px;
        color: #000000;
        margin-bottom: 35px;
    }
    select, input {
        height: 55px;
        margin-bottom: 22px;
    }
    select option {
        text-align: center;
    }
    textarea {
        height: 110px;
    }
    input::placeholder,
    select::placeholder,
    textarea::placeholder {
        font-size: 16px;
    }
    #close {
        top: 10px; 
        right: 10px; 
        position: absolute;
        z-index: 2
    }
    .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 28px;
        .save-btn {
            width: 180px;
            height: 50px;
            background-color: #5340ff;
            color: #ffffff;
            font-weight: bold;
            border-radius: 5px;
            border: 1px solid transparent;
            font-size: 18px;
        }
        .cancel {
            cursor: pointer;
            margin-left: 25px;
            color: #b2b2b2;
            height: 50px;
            font-size: 18px;
            padding-top: 10px;
        }
    }
    #location-modal-error {
        color: #dd0101;
        font-size: 16px;
        text-align: center;
        margin-top: 20px;
    }
} /* end adressModal */

#step1_service {

    padding: 50px 0;
    text-align: center;
    h1 {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 1.5px;
    }
    h2 {
        color: #69666a;
        font-size: 16px;
        letter-spacing: 1px;
    }
    .service-boxes {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .service-box {
            padding: 13px 0;
            width: 220px;
            height: 160px;
            border: 2px solid #e8e8e8;
            border-radius: 10px;
            margin: 0 15px;
            color: #545156;
            cursor: pointer;
            i {
                font-size: 35px;
            }
            p {
                font-size: 14px;
            }
            h2 {
                color: #000000;
                font-weight: bold;
                font-size: 15px;
                letter-spacing: 0.5px;
                margin-bottom: 2px;
            }
        }
    }

} /* end step1_service */

#step2_location {

    padding: 50px 0;
    text-align: center;
    h1 {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 1.5px;
    }
    .location-boxes {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .location-box {
            padding: 22px;
            width: 220px;
            height: 160px;
            border: 2px solid #e8e8e8;
            border-radius: 10px;
            margin: 0 15px;
            color: #545156;
            cursor: pointer;
            i {
                font-size: 22px;
            }
            i#marker {
                color: #f93660;
            }
            i#virtual {
                color: #5340FF;
            }
            p {
                color: #a3a3a3;
                font-size: 15px;
                margin: 15px 0 0 0;
            }
            h2 {
                color: #000000;
                font-weight: bold;
                font-size: 16px;
                letter-spacing: 0.5px;
                margin-top: 8px;
                line-height: 20px;
            }
            a {
                z-index: 1;
                font-size: 15px;
                text-decoration: underline;
                color: #5496e4;
            }
            .surcharge {
                color: #c1c1c1;
            }
        }
    }
    .next-button-div {
        .next-button {
            margin: 30px auto;
            width: 170px;
            display: none;
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            border: 1px solid transparent;
            border-radius: 6px;
            background-color: #5340ff;
            color: #ffffff;
            padding: 12px 0;
        }
    }
} /* end step2_location */

#step3_plan {

    padding: 50px 0;
    text-align: center;
    h1 {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 1.5px;
    }
    h2 {
        color: #69666a;
        font-size: 16px;
        letter-spacing: 1px;
    }
    .plan-boxes {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .plan-box {
            width: 200px;
            height: 160px;
            border: 2px solid #e8e8e8;
            border-radius: 10px;
            margin: 0 15px;
            color: #545156;
            cursor: pointer;
            .plan-label {
                top: 0;
                width: 100%;
                height: 30px;
                text-align: center;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                padding-top: 5px;
                span {
                    color: #ffffff;
                    font-weight: bold;
                }
            }
            .plan-label.basic {background-color: #748eac;}
            .plan-label.mp {background-color: #4caaf1;}
            .plan-label.bv {background-color: #e0c85d;}
            p {
                color: #afacac;
                font-size: 15px;
                margin: 15px 0 0 0;
                &.flex {
                    font-size: 14px;
                    color: #52dabc;
                    font-weight: bold;
                }
            }
            h1 {
                color: #000000;
                font-weight: bold;
                font-size: 20px;
                margin-top: 8px;
            }
            h2 {
                color: #000000;
                font-weight: bold;
                font-size: 15px;
                letter-spacing: 0.5px;
                margin-top: 14px;
            }
        }
    }

} /* end step3_plan */

#step3_intro_price {
    padding: 50px 0;
    text-align: center;
    h1 {
        color: #000000;
        font-size: 26px;
        letter-spacing: 0.41px;
        line-height: 36px;
    }
    p {
        color: #545156;
        font-size: 16px;
        line-height: 22px;
    }
    .intro_offer_container {
        margin: 50px 0 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .intro_offer_box {
        border: 1.5px solid #E8E8E8;
        border-radius: 10px;
        width: 200px;
        height: 154px;
        .label-offer {
            background-color: #EEFE54;
            border-radius: 8px 8px 0 0;
            height: 30px;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: -1px;
            p {
                color: #4A4A4A;
                font-size: 16px;
                line-height: 19px;
                margin: 0;
            }
        }
        .sessions {
            box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.5);
            color: #000000;
            font-size: 22px;
            letter-spacing: 0.31px;
            line-height: 26px;
        }
        .for-price {
            color: #4A4A4A;
            font-size: 16px;
            letter-spacing: 0.23px;
            line-height: 22px;
        }
    }
    .lower-text {
        display: flex;
        justify-content: center;
    }
    .next-btn-div {
        text-align: center;
        margin-top: 16px;
        .next-btn {
            background-color: #5340FF;
            border-radius: 5px;
            color: #FFFFFF;
            height: 50px;
            font-size: 17px;
            letter-spacing: 0.4px;
            padding: 12px 0;
            width: 174px;
        }
    }
} /* end step3_intro_price */

#step4_payment {

    padding: 50px 0;
    text-align: center;
    h1 {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 1.5px;
    }
    #bookpay-row {
        margin-top: 65px;
    }
    h2 {
        color: #69666a;
        font-size: 18px;
        letter-spacing: 1px;
        a {color: #44bcee;}
    }
    p {
        color: #333333;
        font-size: 16px;
    }
    .loginBtn {margin-top: 30px;}
    .form-div {
        margin: 0 auto;
        width: 280px;
        height: auto;
    }
    .buttons {margin-top: 30px;}
    .terms {margin: 15px 0;}
    .bookpay {
        text-align: center;
        .row {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            #first-col {
                border-right: 2px solid #cbcbcb;
                margin-left: 10px;
                padding: 0 12%;
                #booking-spinner {
                    padding: 30px 0;
                }
                #payment_data {
                    margin: 35px 0;
                    justify-content: center;
                    text-align: center;
                    font-size: 16px;
                    span.bolder {
                        font-weight: bold;
                        color: #000000;
                    }
                    span.normal {
                        margin-left: 10px;
                        color: #555555;
                    }
                    a {
                        color: #468ee5;
                        margin-left: 15px;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    .error-box {
                        margin-top: 10px;
                        color: #dd0101;
                        width: 90%;
                        text-align: center;
                    }
                }
                .second-error-box {
                    color: #dd0101;
                    width: 100%;
                    text-align: center;
                    margin: 15px 0 30px 0;
                    display: none;
                    &.active {
                        display: block;
                    }
                }
            }
            #second-col {
                margin-top: 80px;
                padding: 0 70px;
                .handle-code {
                    display: flex;
                    margin: 25px 0 0 25px;
                    text-align: left;
                    color: #b8b8b8;
                    .promo-code {
                        font-size: 16px;
                        cursor: pointer;
                    }
                    #find-one {
                        text-decoration: underline;
                        font-weight: bold;
                        color: #b8b8b8;
                        font-size: 12px;
                        margin-left: 20px;
                        padding-top: 1px;
                        cursor: pointer;
                    }
                }
                .row#enter-code {
                    margin-left: 25px;
                    display: none;
                    margin-top: 15px;
                    &.active {
                        display: block;
                    }
                    .btn.btn-apply {
                        color: #5b585d;
                        font-weight: bold;
                        background-color: transparent;
                        border-color: #5b585d;
                        height: 40px;
                        float: left;
                    }
                }
                .error-msg {
                    color: #c11c1c;
                    font-size: 14px;
                    margin-top: -10px;
                    margin-left: 5px;
                    float: left;
                }
            }
        }
        h2 {
            margin-bottom: 40px;
            font-size: 24px;
            color: #000000;
            font-weight: bold;
            letter-spacing: 0px;
        }
        h3 {
            float: left;
            color: #706d71;
            font-size: 16px;
        }
        img {
            float: right;
            margin-top: 10px;
        }
        input {
            margin-bottom: 20px;
            height: 40px;
            border: 2px solid #cccccc;
            &::placeholder {
                font-size: 16px;
            }
        }
        .btn-payment {
            width: 95%;
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            border: 1px solid transparent;
            border-radius: 6px;
            background-color: #5340ff;
            color: #ffffff;
            padding: 12px 0;
            &:disabled {
                background-color: #e8e8e8;
            }
        }
        .checkbox {
            label {
                color: #a4a4a4;
                font-size: 12px;
                padding: 15px 0;
            }
            span {
                text-decoration: underline;
                z-index: 999;
                color: #a4a4a4;
                font-size: 12px;
                padding: 15px 0;
                margin-left: 4px;
                cursor: pointer;
            }
        }
    }
    .select-wrapper {
        .select-custom {
            display: flex;
            align-items: center;
            width: 100%;
            background-color: #f4f6f8;
            //background-color: #8f8f8f;
            height: 50px;
            border-radius: 10px;
            padding: 0 10px;
            margin-bottom: 15px;
            span.bolder {
                font-weight: bold;
                font-size: 17px;
                color: #000000;
            }
            span.normal {
                margin-left: 10px;
                font-weight: normal;
                font-size: 16px;
                color: #585858;
                width: 70%;
                text-align: left;
            }
            span.change {
                position: absolute;
                right: 70px;
                width: 15%;
                color: #585858;
                font-size: 16px;
                cursor: pointer;
            }
            &.no-border-radius-bottom {
                border-radius: 10px 10px 0 0;
            }
        }
        .custom-dropdown {
            width: 100%;
            height: auto;
            background-color: #f4f6f8;
            //background-color: #8f8f8f;
            display: none;
            top: -15px;
            position: relative;
            &.active {
                display: block;
            }
            .item {
                display: flex;
                align-items: center;
                padding: 10px 0;
                font-size: 16px;
                color: #585858;
                cursor: pointer;
                &:hover {
                    color: #5340ff;
                }
            }
        }
    }
    .location-div {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 10px;
        margin-top: 20px;
        span.bolder {
            font-weight: bold;
            font-size: 17px;
            color: #000000;
        }
        span.normal {
            margin-left: 10px;
            font-weight: normal;
            font-size: 16px;
            color: #585858;
        }
        span a {
            text-decoration: underline;
            font-size: 15px;
            color: #468ee5;
            margin-left: 8px;
            cursor: pointer;
        }
    }
    .pricing-div {
        margin-top: 60px;
        p {
            font-weight: bold;
            span.name {
                position: absolute;
                font-size: 16px;
                left: 85px;
            } 
            span.amount {
                position: absolute;
                font-size: 17px;
                right: 90px;
            }
        }
    }
    .total-div {
        p {
            font-weight: bold;
            span.name {
                position: absolute;
                font-size: 17px;
                left: 85px;
            } 
            span.amount {
                position: absolute;
                font-size: 18px;
                right: 90px;
            }
        }
    }
    .ticks {
        margin-top: 16px;
        .icon {
            color: #69e2b2;
            font-size: 18px;
        }
        .text {
            color: #545156; 
            font-size: 16px;
            padding-top: 1px;
        }
    }
}  /* end step4_payment */

#editCard_modal {
    .modal-body {
        display: block;
    }
    #payment_data {
        .error-box {
            color: #dd0101;
            width: 90%;
            text-align: center;
        }
    }
    #close {
        top: 10px; 
        right: 10px; 
        position: absolute;
        z-index: 2;
        color: #000000;
    }
    .form-content {
        margin: 0 auto;
        width: 80%;
    }
    h2 {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #000;
        text-align: center;
        margin-bottom: 30px;
    }
    input {
        margin-bottom: 20px;
        height: 40px;
        border: 2px solid #cccccc;
        &::placeholder {
            font-size: 16px;
        }
    }
    .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 28px;
        .save-btn {
            width: 180px;
            height: 50px;
            background-color: #5340ff;
            color: #ffffff;
            font-weight: bold;
            border-radius: 5px;
            border: 1px solid transparent;
            font-size: 18px;
            &:disabled {
                background-color: #e8e8e8;
            }
        }
        .cancel {
            cursor: pointer;
            margin-left: 25px;
            color: #b2b2b2;
            height: 50px;
            font-size: 18px;
            padding-top: 10px;
        }
    }
}

#step5_finish {

    padding: 50px 0;
    text-align: center;
    h1 {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 1.5px;
    }
    h2 {
        color: #69666a;
        font-size: 18px;
        letter-spacing: 1px;
        a {color: #44bcee;}
    }
    .training-with {
        margin: 20px 0;
        width: 100%;
        padding: 40px 0;
        height: 195px;
        background-color: #f4f6f8;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 115px;
        }
        div.time-slot-data {
            margin: 0 15px;
            height: 85px;
            width: auto;
            padding-top: 5px;
            p {
                margin-bottom: 2px;
                font-weight: bold;
                font-size: 17px;
                span {
                    background-color: #52dabc;
                    border-radius: 3px;
                    font-size: 15px;
                    color: #ffffff;
                    padding: 1px 8px;
                    margin-right: 6px;
                    &.off_peak {
                        background-color: #52dabc;
                    }
                    &.virtually {
                        background-color: #906bc0;
                    }
                }
            }
        }
    }
    .row.inputset {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        textarea, input {
            margin-bottom: 20px;
            height: 40px;
            border: 2px solid #cccccc;
            &::placeholder {
                font-size: 16px;
            }
        }
        .btn-finish {
            width: 70%;
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            border: 1px solid transparent;
            border-radius: 6px;
            background-color: #5340ff;
            color: #ffffff;
            padding: 12px 0;
            margin-top: 25px;
            &:disabled {
                background-color: #4BB543;
            }
        }
    }
} /* end step5_finish */

.hide-trainer-header {
    display: none;
    &.active {
        display: block;
    }
}

#buy-timeslot-with-suscription {
    padding: 50px 0;
    text-align: center;
    h1 {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 1.5px;
    }
    p {
        font-size: 17px;
        color: #000;
        margin-top: 25px;
        &.gray {
            margin-top: -7px;
            font-size: 15px;
            color: #a2a0a3;
        }
    }
    .sessions-remaining {
        margin: 40px 0;
        font-size: 18px;
        font-weight: bold;
        color: #000;
    }
    .btn-book {
        width: 300px;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        border: 1px solid transparent;
        border-radius: 6px;
        background-color: #5340ff;
        color: #ffffff;
        padding: 12px 0;
        &:disabled {
            background-color: #e8e8e8;
        }
    }
    .book-error{
        color: #dd0101;
        text-align: center;
        margin: 10px 0;
        padding: 0 10px;
    }
}

#termsModal {
    #close {
        top: 10px; 
        right: 10px; 
        position: absolute;
        z-index: 2
    }
    .terms-div {
        margin-top: 15px;
        overflow-y: scroll;
        height: 600px;
        p {
            text-align: left;
        }
    }
}


.StripeElement {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #949393;
    border-radius: 5px;
    color: #495057;
    font-weight: 400;
    font-size: 15.5px;
    height: 38px;
    line-height: 1.5;
    box-shadow: 0 -0.8px #adadad;
    padding: 8px 5px 0 5px;
}

.StripeElement--focus {
    border-color: #5340ff;
}

.StripeElement--invalid {
    border-color: #c90000;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}


@media (max-width: @screen-xs-max) {

    #stepper {
        .options {
            padding: 22px 0 15px;
            height: 45px;
            a.option {
                font-size: 12px;
                padding: 13px 10px;
            }
        }
    }

    #trainer-header {
        height: 90px;
        img {
            height: 60px;
        }
        div.time-slot-data {
            width: auto;
            padding: 13px 0;
            p {
                font-size: 14px;
            }
        }
    }

    #step1_service { 
        padding: 0;
        h1 {
            font-size: 20px;
        }
        h2 {
            font-size: 14px;
            margin-top: 5px;
            letter-spacing: 0;
        }
        .service-boxes {
            margin-top: 20px;
            flex-direction: column;
            .service-box {
                margin: 15px auto;
                width: 85%;
                height: 135px;
                i {
                    font-size: 22px;
                }
            }
        }
    }

    #step2_location { 
        padding: 0;
        h1 {
            font-size: 20px;
            margin-bottom: 10px;
        }
        .location-boxes {
            margin-top: 10px;
            flex-direction: column;
            .location-box {
                margin: 15px auto;
                width: 85%;
                height: 135px;
            }
        }
    }

    #step3_plan {
        padding: 0;
        h1 {
            font-size: 20px;
        }
        h2 {
            font-size: 14px;
            margin-top: 5px;
            letter-spacing: 0;
        }
        .plan-boxes {
            margin-top: 10px;
            flex-direction: column;
            .plan-box {
                margin: 15px auto;
                width: 85%;
                height: 150px;
            }
        }
    }

    #step4_payment {
        padding: 0 15px;
        .bookpay {
            h1 {
                font-size: 20px;
            }
            #bookpay-row {
                flex-direction: column-reverse;
                margin: 0;
                .select-wrapper .select-custom span.normal { 
                    width: 50%;
                }
            }
            h2 {
                font-size: 18px;
                margin: 5px 0;
                letter-spacing: 0;
            }
            .row {
                #first-col {
                    border: transparent;
                    padding: 2px;
                }
                #second-col {
                    margin-top: 0;
                    padding: 0;
                    .select-wrapper {
                        .select-custom {
                            .bolder {
                                font-size: 14px;
                            }
                            .normal {
                                font-size: 14px;
                            }
                            .change {
                                font-size: 14px;
                                right: 15px;
                            }
                        }
                        .custom-dropdown {
                            .item {
                                font-size: 14px;
                            }
                        }
                    }
                    .location-div span {
                        font-size: 14px;
                    }
                    .pricing-div {
                        margin-top: 30px;
                        .name {
                            left: 10px;
                        }
                        .amount {
                            right: 15px;
                        }
                    }
                    .total-div {
                        .name {
                            left: 10px;
                        }
                        .amount {
                            right: 15px;
                        }
                    }
                }
            }
        }
    }

    #editCard_modal {
        .form-content {
            margin: 0 auto;
            width: 100%;
        }
    }

    #step5_finish {
        padding: 0 15px 25px;
        h1 {
            font-size: 20px;
        }
        h2 {
            font-size: 14px;
            margin-top: 5px;
            letter-spacing: 0;
            line-height: 1.5;
        }
        .row.inputset {
            textarea, input {
                margin-bottom: 15px;
                height: 30px;
                border: 2px solid #cccccc;
                &::placeholder {
                    font-size: 14px;
                }
            }
            .btn-finish {
                width: 100%;
                margin: 0;
                font-size: 16px;
            }
        }
    }

    #addressModal {
        margin-top: 0;
    }

 }

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { 
    #editCard_modal {
        .form-content {
            margin: 0 auto;
            width: 95%;
        }
    }
    #step4_payment {
        .bookpay {
            .row {
                #first-col {
                    padding: 0 2%;
                }
                #second-col {
                    padding: 0 2%;
                    .select-wrapper .select-custom {
                        span.change {
                            right: 22px;
                        }
                        span.normal {
                            width: 70%;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
// @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { ... }
// @media (min-width: @screen-lg-min) { ... }