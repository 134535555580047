#login, #register{
	h3{
		margin-bottom: 30px;
	}
	a.create-account{
		font-size: 18px !important;
    	margin-bottom: 40px;
	}
	iframe{
		margin: auto;
	}
}

#thank-you{
	margin-top: 70px;
	text-align: center;
}

@media (max-width: @screen-xs-max){
	#register{
		#content .container{
			display:flex;
			flex-direction: column;
			text-align:center;
			.login-column{
				order: 2;
			}
			.signup-column{
				order: 1;
			}
		}
	}
}