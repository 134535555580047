.how-find-our-trainers-works {
	.container-fluid();
    text-align: center;
    background-color: white;
    padding: 32px 12%;
	.title {
		color: @dark-gray;
        .semibold(30px);
        margin: 0;
	}
	.items {
        .make-row();
        .item-container {
            /*margin: 32px 0;*/
            .make-sm-column(6);
            .make-lg-column(3);
            .item {
                position: relative;
                .image {
                    width: 100%;
                }
                .second-container {
                    height: 120px;
                    .icon {
                        margin: 25px 0;
                    }
                    .text {
                        margin: 0;
                        padding: 0 8% 25px;
                        .regular(16px);
                    }
                }
            }
        }
    }
    @media (max-width:@screen-xs-min){
        .items {
            .item-container {
                margin: 0px;
                .image {
                    max-height: 200px;
                }
                .second-container {
                    height: initial !important;
                }
            }
        }
    }
    @media (min-width: @screen-md-max){
        .items {
            display: flex;
            justify-content: center;
        }
    }
}