@import (reference) 'lesshat.less';
@import (reference) 'utils.less';

@font-face{
  .font-face( Open Sans, ~'../fonts/opensans-light-webfont', 100, normal);
}
@font-face{
  .font-face( Open Sans, ~'../fonts/opensans-regular-webfont', 400, normal);
}
@font-face{
  .font-face( Open Sans, ~'../fonts/opensans-semibold-webfont', 600, normal);
}
@font-face{
  .font-face( Open Sans, ~'../fonts/OpenSans-Bold-webfont', 700, normal);
}
@font-face{
  .font-face( Open Sans, ~'../fonts/OpenSans-ExtraBold-webfont', 800, normal);
}
@font-face{
  .font-face( Bariol Regular, ~'../fonts/bariol_regular-webfont', normal, normal);
}

.light(@a:1em){
  font-family: 'Avenir Next Medium';
  font-weight:100;
  font-size: @a;
}
.regular(@a:1em){
  font-family: 'Avenir Next Medium';
  font-weight:400;
  font-size: @a;
}
.semibold(@a:1em){
  font-family: 'Avenir Next Medium';
  font-weight:600;
  font-size: @a;  
}
.bold(@a:1em){
  font-family: 'Avenir Next Medium';
  font-weight:700;
  font-size: @a;  
}
.extrabold(@a:1em){
  font-family: 'Avenir Next Medium';
  font-weight:800;
  font-size: @a;
}

.logo(@a:1.3em){
  font-family: 'Avenir Next Medium';
  font-weight:normal;
  font-size: @a;
}