@import (reference) 'lesshat.less';
@import (reference) 'bootstrap_less/bootstrap.less';
@import (reference) 'utils.less';
@import (reference) 'colors.less';
@import (reference) 'fonts.less';
@import (reference) 'buttons.less';

body {
    &.login,
    &.register {
        section#content {
            .fb-content {
                margin-bottom: 20px;
            }
            .forgot-create {
                margin: 20px 0px;
            }
            input[type=radio] { width: inherit; }
            input.btn {
                font-size: 16px;
                margin: 10px 0px;
            }
            .radio {
                //all: initial;
            }
        }
    }
    &.register-trainer {
        section#content {
            .container { max-width: 1200px; }
            input[type=submit] { font-size: 18px; margin: 20px; }
        }
    }
}

img.avatar {
    object-fit: cover;
}

.top-container, .bottom-container {
    .make-row();
    margin: 2.5px auto;
}

.have_points {
    margin-top: 30px;
}

@border-color: rgb(192, 192, 192);

.left-box {
    .make-md-column(9);
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: solid 1px @border-color;
}

.left-box-customer{
    width : 100%;
}

.account-header {
    .name-container {
        .make-md-column(8);
        .pull-left();
        h1 {
            margin-top: 10px;
        }
    }
    .sociallinks {
        .make-md-column(4);
        .pull-right();
        text-align: right;
    }
    .member-since {
        .regular(14px);
        color: #999;
        margin-left: 20px;
        float: left;
    }
    .account-username{
        float: left;
        margin-left: 20px;
        margin-top: 0;
    }
    .app-buttons-div{
        float: right; 
        margin-right: 20px;
        .app-button{
            max-width: 150px; 
            margin-right: 10px;
        }
    }
    .account-messages-button{
        float: right;
        margin-right: 10px;
        font-size: 16px;
    }
    .account-second-row{
        margin-top: 15px;
    }
    .show-in-schedule{
        margin-left: 20px;
    }
}

.account .info-account .info-account-header .btn {
    font-size: 16px;
    padding: 5px 15px;
}

.info-account {
    margin: 5px 0 0;
    position: relative;
    color: #666666;
    background-color: #fafafa;
    border-radius: 5px 5px 0 0;
    border: solid 1px @border-color;
    .info-account-header {
        background-color: @light-gray;
        border-radius: 5px 5px 0 0;
        border-bottom: solid 1px @border-color;
        h2 {
            margin: 0;
            padding: 15px;
            font-size: 18px;
            color: #666;
            width: 80%;
        }
        .btn {
            position: absolute;
            top: 8px;
            right: 8px;
            .btn(@cyan);
        }
    }
    .info-account-body {
        .regular(16px);
        > ul {
            margin: 0;
            padding: 15px;
            li {
                list-style: none;
                a {
                    span {
                        word-wrap: break-word;
                    }
                }
            }
        }
        table {
            width: 100%;
            thead {
                border-bottom: solid 1px @border-color;
                tr {
                    th {
                        padding: 10px 15px;
                        color: #666;
                        &.price-column {
                            text-align: center;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding: 10px 15px;
                        .dropdown {
                            display: none;
                            .dropdown-toggle {
                                .btn(@light-gray, 4px 12px);
                                color: @dark-gray;
                            }
                            .dropdown-menu {
                                right: 0;
                                left: initial;
                                border: none;
                                border-radius: 0;
                                padding: 0;
                                li a {
                                    padding: 20px 10px;
                                    color: @gray;
                                }
                            }
                        }
                        &:not(.actions-column) {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            // white-space: nowrap;
                        }
                        &.actions-column {
                            text-align: right;
                            &.for-new-clients {
                              text-align: left;
                              .new-client-action-btn {
                                margin-left: 20px;
                                margin-bottom: 4px;
                              }
                            }
                            > form {
                                input[name=action]{
                                    margin: auto 2px;
                                    font-size: 14px;
                                }
                                display: inline-block;
                            }
                            > .btn {
                                margin: auto 2px;
                                font-size: 14px;
                            }
                        }
                        &.session-price {
                            text-align: center;
                        }
                    }
                }
            }
        }
        #friend-popup {
            cursor: pointer;
        }
        #redeem-coupon {
            &-btn { margin-top: .4em; }
            padding: .4em;
            .btn-actions {
                margin: 1px 10px;
            }
        }
    }
}

.account-information-customer{
    width : 48%;
    display: inline-block;
    float: inherit;
}

.account-billing-customer{
    width : 48%;   
    display: inline-block;
    margin-left: 3%;
}


.right-box {
    .make-md-column(3);
    .next-session {
        text-align: center;
        border-radius: 5px;
        border: solid 1px @border-color;
        .next-session-header {
            border-radius: 5px 5px 0 0;
            color: white;
            background: #3399CC;
            padding: 0;
            height: auto;
            h3 {
                .bold(25px);
                margin: 0;
                padding: 15px;
                text-align: center;
            }
        }
        .next-session-body {
            border-radius: 0 0 5px 5px;
            padding:20px;
            color: @dark-gray;
            img {
                width: 70px;
                height: 70px;
                margin: 15px auto;
                display: block;
                border: 1px solid gray;
            }
            hr {
                border-width: 2px;
                border-color: #aaa;
                margin: 30px 0;
            }
            .pro-trainer-gym {
                float: none;
                margin: 0;
                padding: 10px;
                border-top: 1px solid $gray;
                p {
                    margin-bottom: 0;
                    font-size: 12px;
                    line-height: 1.6;
                    span {
                        font-weight: bold;
                    }
                }
            }
            h2 {
                line-height: 17px;
            }
            .trainer-name {
                font-size: 20px;
                color: gray;
                text-align: center;
                display: block;
            }
            i {
                font-style: normal;
            }
            .gym_name {
                small {
                    font-size: 17px;
                }
            }
        }
    }
}

#cropContainerHeader {
    width: 100%;
    height: 200px;
    position: relative;
    border: 1px solid #ccc;
    margin-bottom: 30px;
    margin-top: 20px;
}

#fbshare {
    display: inline-block;
    height: 21px;
    width: 50px;
    background-image: url('/static/images/fb-share.png');
    background-repeat: no-repeat;
    margin-right: 20px;
    margin-top: 2px;
}

#twittershare {
    display: inline-block;
    width: 78px!important;
}

#history-spinner{
    margin-top: 75px;
    margin-bottom: 150px;
    margin-left: 260px;
}

@media (max-width: @screen-xs-min) {
    .account-information-customer{
        float: initial;
        width: 100%;
    }
    .account-billing-customer{
        position: inherit;
        width: 100%;
        margin-left: 0%;
        margin-bottom : 0%;    
        margin-top: 2em;
    }
    .info-account {
        .info-account-body {
            .price-column, .savings-column {
                display: none;
            }
            table tbody tr td .dropdown .dropdown-toggle {
                padding: 1px 10px;
            }
        }
    }
    .info-account .info-account-body table tbody tr td {
        padding: 0;
    }
    #history-spinner{
        margin-left: 141px;
    }
}

@media (max-width: @screen-xs-max) {
    .account-header .sociallinks {
        float: initial;
        width: 100%;
        text-align: left;
    }
    .info-account {
        .info-account-header{
            h2, .btn {
                font-size: 14px;
            }
        }
        .info-account-body {
            table {
                table-layout: fixed;
                font-size: 10px;
                thead tr th, tbody tr td:not(.actions-column) {
                    text-align: center;
                }
                thead tr th {
                    padding: 5px 6px;
                }
                tbody tr td:not(.actions-column) {
                    padding: 20px 6px;
                }
            }
        }
    }
}
@media (min-width: @screen-md-min) {
    .info-account .info-account-body table {
        thead tr th, tbody tr td {
            &.actions-column {
                //min-width: 350px;
            }
            &.other-person-name-column {
                max-width: 150px;
            }
        }
    }
}
@media (max-width: @screen-sm-max) {
    .info-account .info-account-body table tbody tr td.actions-column {
        .dropdown {
            display: block;
            float: left;
            form {
                [type=submit] {
                    background: white;
                    border: none;
                    width: 100%;
                    text-align: left;
                    padding: 20px 10px;
                    color: @gray;
                    &:hover {
                        background-color: #f5f5f5;
                    }
                }
            }
        }
        > .btn, > form {
            display: none;
        }
    }
    .top-container {
        margin-top: 0;
        .left-box {
            border: none;
            padding-top: 0;
        }
    }
    .bottom-container {
        padding: 0 15px;
    }
    @media (min-width: @screen-xs-min) {
        .right-box .next-session {
            .next-session-body {
                // width: 100%;
                .trainer {
                    display: inline-block;
                    width: 39%;
                    vertical-align: top;
                    margin-top: 10%;
                }
                hr {
                    display: none;
                }
                .gym {
                    display: inline-block;
                    width: 60%;
                }
            }
        }
    }
}

@media (max-width: @screen-md-max){
    #map_canvas {
        height: 150px;
    }
}

@media (min-width: @screen-lg-min) {
    .info-account .info-account-body table {
        thead tr th, tbody tr td {
            padding: 10px 4px;
        }
        .th-history{
            padding: 10px 55px 10px 1px;   
        }
    }
}

#favorite-trainers {
    .trainers {
        margin: 20px 0px;
        .trainer-btns {
            margin: 10px 0px;
        }
    }
}

#parq {
    section#title {
        background: #66E49B;
    }

    section#content {
        .container {
            max-width: 1100px;
        }
    }
    .parq-notice {
        ul {
            list-style-type: disc;
        }
    }
}

#account{
    .ace-cert{
        margin-right: 0;
        margin-bottom: 3px;
    }
    @media (max-width: @screen-md-max){
        .member-since{
            font-size: 13px;
            width: 100%;
        }
        .btn-primary{
            font-size: 14px;
            margin-bottom: 5px;
        }
    }   
}

#ace-pro-profile-modal{
    .modal-body{
        margin: 20px 0 20px 20px;
    }
    h3{
        text-align:center;
        margin-bottom: 30px;
        font-weight: 700;
    }
    p{
        text-align: left;
    }
}


/***************************************************/
/*                  Pagination JS                  */
/***************************************************/

.paginationjs {
  line-height: 1.6;
  padding: 10px;
  font-family: 'Avenir Next Medium';
  font-size: 14px;
  box-sizing: initial;
}
.paginationjs:after {
  display: table;
  content: " ";
  clear: both;
}
.paginationjs .paginationjs-pages {
  float: left;
}
.paginationjs .paginationjs-pages ul {
  float: left;
  margin: 0;
  padding: 0;
}
.paginationjs .paginationjs-pages li {
  float: left;
  /*border: 1px solid #aaaaaa;
  border-right: none;
  list-style: none;*/
}
.paginationjs .paginationjs-pages li > a {
  min-width: 30px;
  height: 28px;
  line-height: 28px;
  display: block;
  background: #fff;
  font-size: 14px;
  color: #333;
  text-decoration: none;
  text-align: center;
}
.paginationjs .paginationjs-pages li > a:hover {
  background: #eee;
}
.paginationjs .paginationjs-pages li.active {
  border: none;
}
.paginationjs .paginationjs-pages li.active > a {
  height: 30px;
  line-height: 30px;
  background: #aaaaaa;
  color: #fff;
}
.paginationjs .paginationjs-pages li.disabled > a {
  opacity: .3;
}
.paginationjs .paginationjs-pages li.disabled > a:hover {
  background: none;
}
/*.paginationjs .paginationjs-pages li:first-child {
  border-radius: 3px 0 0 3px;
}
.paginationjs .paginationjs-pages li:first-child > a {
  border-radius: 3px 0 0 3px;
}
.paginationjs .paginationjs-pages li:last-child {
  border-right: 1px solid #aaaaaa;
  border-radius: 0 3px 3px 0;
}
.paginationjs .paginationjs-pages li:last-child > a {
  border-radius: 0 3px 3px 0;
}*/
.paginationjs .paginationjs-go-input {
  float: left;
  margin-left: 10px;
  font-size: 14px;
}
.paginationjs .paginationjs-go-input > input[type="text"] {
  width: 30px;
  height: 28px;
  background: #fff;
  /*border-radius: 3px;
  border: 1px solid #aaaaaa;*/
  padding: 0;
  font-size: 14px;
  text-align: center;
  vertical-align: baseline;
  outline: none;
  box-shadow: none;
  box-sizing: initial;
}
.paginationjs .paginationjs-go-button {
  float: left;
  margin-left: 10px;
  font-size: 14px;
}
.paginationjs .paginationjs-go-button > input[type="button"] {
  min-width: 40px;
  height: 30px;
  line-height: 28px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #aaaaaa;
  text-align: center;
  padding: 0 8px;
  font-size: 14px;
  vertical-align: baseline;
  outline: none;
  box-shadow: none;
  color: #333;
  cursor: pointer;
}
.paginationjs .paginationjs-go-button > input[type="button"]:hover {
  background-color: #f8f8f8;
}
.paginationjs .paginationjs-nav {
  float: left;
  height: 30px;
  line-height: 30px;
  margin-left: 10px;
  font-size: 14px;
}
.paginationjs.paginationjs-small {
  font-size: 12px;
}
.paginationjs.paginationjs-small .paginationjs-pages li > a {
  min-width: 26px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}
.paginationjs.paginationjs-small .paginationjs-pages li.active > a {
  height: 26px;
  line-height: 26px;
}
.paginationjs.paginationjs-small .paginationjs-go-input {
  font-size: 12px;
}
.paginationjs.paginationjs-small .paginationjs-go-input > input[type="text"] {
  width: 26px;
  height: 24px;
  font-size: 12px;
}
.paginationjs.paginationjs-small .paginationjs-go-button {
  font-size: 12px;
}
.paginationjs.paginationjs-small .paginationjs-go-button > input[type="button"] {
  min-width: 30px;
  height: 26px;
  line-height: 24px;
  padding: 0 6px;
  font-size: 12px;
}
.paginationjs.paginationjs-small .paginationjs-nav {
  height: 26px;
  line-height: 26px;
  font-size: 12px;
}
.paginationjs.paginationjs-big {
  font-size: 16px;
}
.paginationjs.paginationjs-big .paginationjs-pages li > a {
  min-width: 36px;
  height: 34px;
  line-height: 34px;
  font-size: 16px;
}
.paginationjs.paginationjs-big .paginationjs-pages li.active > a {
  height: 36px;
  line-height: 36px;
}
.paginationjs.paginationjs-big .paginationjs-go-input {
  font-size: 16px;
}
.paginationjs.paginationjs-big .paginationjs-go-input > input[type="text"] {
  width: 36px;
  height: 34px;
  font-size: 16px;
}
.paginationjs.paginationjs-big .paginationjs-go-button {
  font-size: 16px;
}
.paginationjs.paginationjs-big .paginationjs-go-button > input[type="button"] {
  min-width: 50px;
  height: 36px;
  line-height: 34px;
  padding: 0 12px;
  font-size: 16px;
}
.paginationjs.paginationjs-big .paginationjs-nav {
  height: 36px;
  line-height: 36px;
  font-size: 16px;
}
.paginationjs.paginationjs-theme-blue .paginationjs-pages li {
  border-color: #289de9;
}
.paginationjs.paginationjs-theme-blue .paginationjs-pages li > a {
  color: #289de9;
}
.paginationjs.paginationjs-theme-blue .paginationjs-pages li > a:hover {
  background: #e9f4fc;
}
.paginationjs.paginationjs-theme-blue .paginationjs-pages li.active > a {
  background: #289de9;
  color: #fff;
}
.paginationjs.paginationjs-theme-blue .paginationjs-pages li.disabled > a:hover {
  background: none;
}
.paginationjs.paginationjs-theme-blue .paginationjs-go-input > input[type="text"] {
  border-color: #289de9;
}
.paginationjs.paginationjs-theme-blue .paginationjs-go-button > input[type="button"] {
  background: #289de9;
  border-color: #289de9;
  color: #fff;
}
.paginationjs.paginationjs-theme-blue .paginationjs-go-button > input[type="button"]:hover {
  background-color: #3ca5ea;
}
.paginationjs.paginationjs-theme-green .paginationjs-pages li {
  border-color: #449d44;
}
.paginationjs.paginationjs-theme-green .paginationjs-pages li > a {
  color: #449d44;
}
.paginationjs.paginationjs-theme-green .paginationjs-pages li > a:hover {
  background: #ebf4eb;
}
.paginationjs.paginationjs-theme-green .paginationjs-pages li.active > a {
  background: #449d44;
  color: #fff;
}
.paginationjs.paginationjs-theme-green .paginationjs-pages li.disabled > a:hover {
  background: none;
}
.paginationjs.paginationjs-theme-green .paginationjs-go-input > input[type="text"] {
  border-color: #449d44;
}
.paginationjs.paginationjs-theme-green .paginationjs-go-button > input[type="button"] {
  background: #449d44;
  border-color: #449d44;
  color: #fff;
}
.paginationjs.paginationjs-theme-green .paginationjs-go-button > input[type="button"]:hover {
  background-color: #55a555;
}
.paginationjs.paginationjs-theme-yellow .paginationjs-pages li {
  border-color: #ec971f;
}
.paginationjs.paginationjs-theme-yellow .paginationjs-pages li > a {
  color: #ec971f;
}
.paginationjs.paginationjs-theme-yellow .paginationjs-pages li > a:hover {
  background: #fdf5e9;
}
.paginationjs.paginationjs-theme-yellow .paginationjs-pages li.active > a {
  background: #ec971f;
  color: #fff;
}
.paginationjs.paginationjs-theme-yellow .paginationjs-pages li.disabled > a:hover {
  background: none;
}
.paginationjs.paginationjs-theme-yellow .paginationjs-go-input > input[type="text"] {
  border-color: #ec971f;
}
.paginationjs.paginationjs-theme-yellow .paginationjs-go-button > input[type="button"] {
  background: #ec971f;
  border-color: #ec971f;
  color: #fff;
}
.paginationjs.paginationjs-theme-yellow .paginationjs-go-button > input[type="button"]:hover {
  background-color: #eea135;
}
.paginationjs.paginationjs-theme-red .paginationjs-pages li {
  border-color: #c9302c;
}
.paginationjs.paginationjs-theme-red .paginationjs-pages li > a {
  color: #c9302c;
}
.paginationjs.paginationjs-theme-red .paginationjs-pages li > a:hover {
  background: #faeaea;
}
.paginationjs.paginationjs-theme-red .paginationjs-pages li.active > a {
  background: #c9302c;
  color: #fff;
}
.paginationjs.paginationjs-theme-red .paginationjs-pages li.disabled > a:hover {
  background: none;
}
.paginationjs.paginationjs-theme-red .paginationjs-go-input > input[type="text"] {
  border-color: #c9302c;
}
.paginationjs.paginationjs-theme-red .paginationjs-go-button > input[type="button"] {
  background: #c9302c;
  border-color: #c9302c;
  color: #fff;
}
.paginationjs.paginationjs-theme-red .paginationjs-go-button > input[type="button"]:hover {
  background-color: #ce4541;
}
/* Hacks for IE 6~9 */
.paginationjs .paginationjs-pages li.paginationjs-next {
  *border-right: 1px solid #aaaaaa;
  border-right: 1px solid #aaa\0;
}
.paginationjs .paginationjs-go-input {
  *margin-left: 5px;
  margin-left: 5px\0;
}
.paginationjs .paginationjs-go-input > input[type="text"] {
  *line-height: 28px;
  line-height: 28px\0;
  *vertical-align: middle;
  vertical-align: middle\0;
}
.paginationjs .paginationjs-go-button {
  *margin-left: 5px;
  margin-left: 5px\0;
}
.paginationjs .paginationjs-go-button > input[type="button"] {
  *vertical-align: middle;
  vertical-align: middle\0;
}
.paginationjs.paginationjs-big .paginationjs-pages li > a {
  line-height: 36px\0;
}
.paginationjs.paginationjs-big .paginationjs-go-input > input[type="text"] {
  *height: 35px;
  height: 36px\0;
  *line-height: 36px;
  line-height: 36px\0;
}
.next-previous{
    margin: 11px;
    font-size: large;
    cursor: pointer;
}

.tr-payouts{
    td,th{
        padding-left: 1.5em !important;
    }
}

.member_confirmation_modal {
  .modal-header {
    background: #fff;
    min-height: unset;
    width: unset;
    height: unset;
    padding: 10px;
    border-radius: 0;
    text-align: start;
    .modal-title {
      font-size: 20px;
      span.session_data {
        font-size: 14px;
        color: #333333;
        font-weight: bold;
        margin-left: 3px;
      }
    }
    .close {
      padding: 0;
      color: #000;
      font-weight: 400;
      font-size: 30px;
      opacity: 0.7;
      text-shadow: 0 1px 0 rgba(0,0,0,.5);
    }
  }
  .modal-body {
    padding: 20px 40px;
    margin: unset;
    font-size: 16px;
    display: block;
    color: #000;
    label {
      text-align: start;
      font-weight: normal;
      color: #3f3f3f;
    }
    p {
      text-align: center;
    }
  }
  .btn-default:hover {
    color: white;
  }
}