#trainer-index {
  background-color: #eee;
  padding-right: 0;
  padding-left: 0;
  .first {
    box-shadow: 0 0 rgba(50, 50, 50, 0.25);
  }

  h4 {
    font-weight: 800;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .index-trainer-information h1 {
    font-size: 30px;
    font-weight: 550;
    margin-bottom: 0.4em;
  }

  .title-widget-index {
    background-color: #072a38;
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    color: white;
    padding: 15px 0 8px 0;
    line-height: 1.35em;
  }
  .title-widget-index p {
    font-size: 12.5px;
  }

  .session-widget-index {
    background-color: white;
    color: #898888;
    font-size: 14px;
    text-align: center;
    padding: 0.5em 0em;
  }

  .second-left-container .map-canvas {
    height: 350px;
  }

  .session-widget-index img {
    height: 14px;
    vertical-align: baseline;
    color: #bbbbbb;
  }

  .session-widget-index .name {
    color: #333;
    font-weight: 500;
    font-size: 15.5px;
  }

  .cant-session {
    font-weight: 700;
    font-size: 16px;
    color: #484848;
  }

  .description {
    font-size: 16px;
    color: #36b6ec;
    margin-right: 5px;
  }

  .verified {
    margin-right: 0.5em;
    width: 17.5px;
  }

  .review-avg {
    font-size: 2em;
  }

  .session-label {
    color: #ffffff;
    font-weight: 700;
    width: 60%;
    margin: 0 auto;
    p.standard {
      background-color: #36b6ec;
      border-radius: 5px;
      padding: 1px 2px 1px 2px;
      font-size: 15px;
    }
    p.flex {
      background-color: #52dabc;
      border-radius: 5px;
      padding: 1px 2px 1px 2px;
      font-size: 15px;
    }
    p.virtual {
      background-color: #ebe8ff;
      color: #333740;
      border-radius: 5px;
      padding: 1px 2px 1px 2px;
      font-size: 15px;
    }
    p.intro-price {
      background-color: #EEFE54;
      color: #333740;
      border-radius: 5px;
      padding: 1px 2px 1px 2px;
      font-size: 15px;
    }
    p.standard-disabled {
      background-color: #92D8F5;
      border-radius: 5px;
      padding: 1px 2px 1px 2px;
      font-size: 15px;
    }
  }

  p.modal-title {
    font-size: 26px;
    margin-top: 20px;
    span.flex-label {
      background-color: #52dabc;
      border-radius: 5px;
      color: #ffffff;
      padding: 0 12px 0 12px;
      font-weight: bold;
    }
    span.intro-label {
      background-color: #EEFE54;
      border-radius: 3px;
      color: #4A4A4A;
      font-size: 26px;
      line-height: 31px;
      padding: 1px 10px;
    }
  }

  p.modal-subtitle {
    color: #4A4A4A;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    margin-top: 12px;
  }

  p.modal-body-text {
    color: #535353;
    font-size: 15px;
    span.bolder {
      font-weight: bold;
    }
    &.inp {
      color: #4A4A4A;
      font-size: 16px;
      letter-spacing: 0.38px;
      line-height: 22px;
      width: 477px;
      text-align: center;
      @media (max-width: @screen-xs-max) {
        width: auto;
        padding: 0 12px;
      }
    }
  }

  .session-price {
    color: #0d0d0d;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  .session-price-intro {
    &.st {
      color: #4A4A4A;
      font-size: 21px;
      line-height: 25px;
      text-align: center;
      text-decoration-line: line-through;
    }
    &.inp {
      color: #52DABC;
      font-size: 21px;
      line-height: 25px;
      text-align: center;
    }
  }

  .session-price-intro-undertext {
    margin-top: -6px;
    &.st {
      color: #9B9B9B;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      font-family: 'Avenir Next Demi';
    }
    &.inp {
      color: #4A4A4A;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      font-family: 'Avenir Next Demi';
    }
  }

  .per-session {
    font-size: 12px;
    font-weight: 500;
  }

  .save-percent {
    font-size: 12px;
    font-weight: 500;
    color: #0d0d0d;
  }

  .save-per-session {
    color: #333;
    font-size: 12px;
    font-weight: 600;
  }

  .space-line {
    border: 0;
    border-top: 3px solid #eee;
  }

  .index-column {
    background-color: white;
    padding: 0.5em 2.5em;
  }

  .image-container {
    margin-bottom: 0em;
  }

  .trainer-verified {
    margin-right: 1em;
  }

  .reviews-index {
    font-size: 15px;
    color: #36b6ec;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 1em;
  }

  .trainer-location {
    margin: 0.45em 0em;
  }

  .fav-q-mnoey-index a {
    padding-right: 4em;
    text-decoration: none;
    cursor: pointer;
    padding-bottom: 0;
    padding-top: 0;
  }

  .index-text {
    color: #333;
    font-weight: 450;
    font-size: 17px;
  }

  .index-text:hover {
    color: #a7a7a7;
  }

  .index-text-money {
    font-weight: 600;
    font-size: 16px;
    color: #337141;
  }

  .index-text-money:hover {
    color: #1fb25be8;
  }

  .info-index {
    font-weight: 600;
    padding-bottom: 1px;
  }

  .question-sign-index {
    font-size: 13px;
    margin-left: 3px;
    color: #c2c1c0;
  }

  .bio-index {
    background-color: white;
    p {
      color: #7b7b7b;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .ace-index {
    color: #484848;
    background-color: #e9e9e9;
    font-weight: inherit;
    display: inline;
    padding: 0.3em 0.7em 0.3em;
    line-height: 3;
    border-radius: 0.25em;
    margin-right: 10px;
  }

  .heart-img {
    width: 18px;
    margin-bottom: 5px;
  }

  .ace-index span {
    font-weight: 510;
  }

  .ace-index img {
    height: 14px;
    margin-right: 0.5em;
  }

  .areas-list-index {
    margin-top: 1.5em;
  }

  .info-conteiners {
    padding: 0px;
  }

  .reviews-container {
    border-top: none;
    margin-top: 0px;
  }

  #specialties {
    color: #4b4747;
    cursor: pointer;
    font-weight: 600;
    display: inline-block;
    font-size: 13px;
    text-align: center;
    padding: 3px;
  }

  #see-areas {
    margin: 1em 0;
  }

  #a-areas,
  #a-reviews-3 {
    color: #7b7b7b;
    cursor: pointer;
    font-weight: 600;
  }

  .review-title-index {
    display: inline;
  }

  .write-edit-review {
    float: right;
  }

  .write-review {
    cursor: pointer;
    font-size: 16.5px;
    font-weight: 550;
    color: #36b6ec;
  }

  .title-reviews {
    text-decoration: none;
    color: black;
    font-size: 20px;
    font-weight: 600;
    margin-top: 25px;
  }

  .trainer-bio-link {
    border-bottom: none;
  }

  .ace-profile-index {
    position: absolute;
    z-index: 24;
    width: 24%;
    top: 0;
    right: 15px;
    background-color: rgba(218, 56, 64, 0.69);
    text-align: center;
  }

  .ace-profile-index img {
    margin: 0.25em 0em;
    padding: 0 2em;
  }

  .virtual-training {
    font-family: "Avenir Next Medium";
    text-align: center;
    background-color: #eefe54;
    font-size: 15px;
    font-weight: 800;
    padding: 2.4px 0;
    color: #333;
  }

  .breadcrumb {
    margin-bottom: 0;
    background-color: #eee;
    margin-left: 3em;
  }

  .breadcrumb li a {
    color: rgb(70, 70, 70);
  }

  .last {
    z-index: 12000;
  }

  .money-back-index img {
    width: 25.5px;
  }

  .question-icon {
    font-size: 17px;
    color: #3cb6ec;
    vertical-align: unset;
  }

  .FYT-difference {
    background-color: white;
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-top: 12.5px solid #eeeeee;
  }

  .FYT-difference img {
    width: 55px;
    height: 62.5px;
    margin: 24px 0px 24px;
  }

  .FYT-difference h2 {
    margin-bottom: 2.5px;
    color: black;
    font-size: 17.5px;
    font-weight: 700;
  }

  .FYT-difference p {
    color: rgb(163, 165, 170);
    font-size: 14px;
    font-weight: 600;
  }

  .info {
    .data {
      color: #7b7b7b;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .specialties .specialty {
    margin-bottom: 2.4px;
    .specialty_label {
      background-color: #e9e9e9;
      color: #484848;
      font-weight: 500;
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    .ace-profile-index {
      width: 40%;
      right: 0;
    }

    .virtual-training {
      font-size: 12.5px;
    }

    .fav-q-mnoey-index {
      text-align: center;
    }

    .fav-q-mnoey-index a {
      padding: 0;
    }

    .nopadding-xs {
      padding: 0 !important;
      margin: 0 !important;
    }

    .heart-img {
      width: 24px;
      margin-bottom: 0;
    }

    .money-back-index img {
      width: 25px;
      vertical-align: text-bottom;
    }

    .question-icon {
      font-size: 20px;
      padding: 0 25%;
      vertical-align: middle;
      display: inline-block !important;
      color: #3cb6ec;
    }

    .title-widget-index {
      font-size: 18px;
      padding: 8px 0 1px 0;
    }
    .title-widget-index p {
      font-size: 11px;
      margin-top: 0px;
    }

    #second-pricing-col {
      padding: 0 40px 0 0;
    }
  }
  @media (min-width: 767px) and (max-width: 992px) {
    #root-trainer-calendar .calendar-pagination-btn.prev {
      left: 0em;
    }
    #root-trainer-calendar .calendar-pagination-btn.next {
      right: 0;
    }
  }
  #theCarousel {
    .carousel-indicators {
      position: absolute;
      z-index: 15;
      right: 0;
      width: 100%;
      left: 0;
      list-style: none;
      margin: 0 10px;
      padding: 0 20px 0 0;
      li {
        width: 9px;
        height: 9px;
      }
    }
  }
}
