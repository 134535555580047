@import (reference) 'bootstrap_less/bootstrap.less';
@import (reference) 'fonts.less';
@import (reference) 'utils.less';

.gift-options-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 0;
  .gift-option-col {
    margin: 0 20px;
    height: 125px;
    width: 220px;
    cursor: pointer;
    /*.gift-option-bonus-label {
      height: 30%;
      width: 100%;
      background-color: #5340FF;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: #fff;
      font-size: 15px;
      border: 2px solid #5340FF;
      display: flex;
      align-items: center;
      justify-content: center;
    }*/
    .gift-option-bonus-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 10px;
      border: 2px solid #4DBEED;
      height: 100%;
      p.option-amount {
        font-size: 32px;
        font-weight: bold;
        color: #4DBEED;
        margin: 0;
      }
      .input-4-amount {
        margin-left: 6px;
        border: none;
        width: 90px;
        height: 40px;
        text-align: center;
      }
      #input-showing {
        display: none;
      }
      p.get-extra {
        color: #6657FF;
        font-size: 14px;
        font-weight: bolder;
        margin: 0;
      }
    }
    &.selected {
      .gift-option-bonus-content {
        background-color: rgb(208, 233, 255);
        #input-showing {
          display: block;
        }
        #bigger-price, #get-extra-showing {
          display: none;
        }
      }
    }
  }
  @media (max-width: @screen-xs-max) {
    flex-direction: column;
    .gift-option-col {
      margin: 20px 0;
    }
  }
}

.new-gift-hero {
  background-color: #2A3148;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 25px 50px;
  h2#gift-heroh2 {
    font-size: 22px;
  }
  p#gift-herop {
    font-size: 22px;
  }
  h1#gift-heroh1 {
    font-size: 44px;
    font-weight: bold;
    font-family: 'Tiempos Head Line';
    margin: .6em 0;
  }
  img {
    height: 400px;
    width: auto;
  }
  @media (max-width: @screen-xs-max) {
    flex-direction: column;
    padding: 25px 3px;
    h2#gift-heroh2, p#gift-herop, h1#gift-heroh1 {
      text-align: center;
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
}

#error-handling-div {
  text-align: center;
  font-size: 16px;
  padding: 20px;
  color: rgb(177, 18, 18);
}

.modal-backdrop {
    // TODO: can't understand why this tweak was needed
    height: 100%;
    position: fixed;
}

.gift-step-1 {
    background-color: #2d2c2b;
}

.gift-cards {
    color: #fff;
    h1 {
        padding: 0px 40px;
        margin: 30px 0 0;
        font-weight: 400;
        font-size: 30px;
        line-height: 1.3;
    }
    .tag {
        padding: 0px 40px;
        font-weight: 400;
    }
}

.gift-holidays {
    padding: 20px 0;
    h1,
    h2,
    h3 {
        color: white;
        margin-left: 5%;
        margin-right: 5%;
    }
    h2 {
        .regular(25px);
    }
    text-align: center;
    .tag {
        font-size: 25px;
        margin-bottom: 10px;
        font-size: 25px;
        margin-bottom: 10px;
    }
    h1 {
        margin-bottom: 30px;
        .bold(35px);
    }
    form.input[type=text] {
        width: 300px;
    }
    .gift-list {
        list-style-type: decimal;
        text-align: left;
        margin-left: 32%;
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .packages-container {
        .gift-package {
            display: inline-block;
            max-width: 370px;
            margin: 0 0 60px 0;
            text-align: center;
            position: relative;
            margin: 25px 1%;
            h2 {
                .bold(27px);
                margin: 10px inherit 20px;
                line-height: 24px;
                color: #D7D7D7;
                font-family: 'Avenir Next Medium';
            }
            h3 {
                .regular(15px);
                margin: 10px 80px;
                line-height: 19px;
            }
            .btn-gift-book {
                width: 215px;
                font-weight: 700;
                cursor: pointer;
                font-size: 15px;
                font-family: 'Avenir Next Medium';
            }
        }
    }
}

.gift-discount {
  text-align: center;
  padding-top: 8px;
  padding-bottom: 2.4px;
  background: #5340FF;
  color: white;
  h1.discount {
    font-size: 24px !important;
    margin-bottom: 2.4px !important;
    margin-top: 2.4px !important;
    font-weight: 700 !important;
  }
  h2.discount {
    font-size: 15px !important;
    letter-spacing: 0.24px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }
}

#gift-submission,
#thanks-gift {
    .modal-content,
    .modal-header {
        background: #555758;
    }
}

#gift-submission,
#thanks-gift {
    .modal-header {
        border: none;
    }
    .modal-body {
        display: inherit;
    }
    .floating-close {
        color: #fff;
        font-weight: 600;
        float: right;
        position: relative;
        font-size: 16px;
    }
}

#gift-submission h2,
#thanks-gift h2 {
    text-align: center;
    color: #F4F4F4;
    font-size: 27px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 30px;
}

#gift-submission form {
    text-align: center;
    input[type="text"],
    input[type="email"] {
        //margin-left: 35px;
        width: 440px;
        padding: 0 0 0 15px;
        margin-bottom: 15px;
        height: 38px;
    }
    textarea {
        //margin-left: 35px;
        height: 150px;
        width: 440px;
        padding: 10px 0 0 12px;
    }
    @media screen and (max-width: 480px) {
        input[type="text"],
        input[type="email"],
        textarea {
            width: 100%;
        }
    }
}

#gift-submission form .btn-submit,
#thanks-gift .btn-ok {
    border: none;
    width: 150px;
}

.gift-cards {
    .tag {
        font-weight: 100;
        font-size: 26px;
        margin-bottom: 20px;
        line-height: 1.5;
    }
}

.start-at {
    margin-top: 25px;
}

.gift-list {
    margin: 20px 0;
    color: #44b8ea;
    list-style: disc inside none;
}

.btn-gift-book,
.btn-submit,
.btn-ok {
    background: none repeat scroll 0 0 #44B8EA;
    color: #FFFFFF;
    display: table;
    margin: 33px auto 0;
    padding: 13px 0;
    text-align: center;
    text-transform: uppercase;
    width: 165px;
    border: none;
}

.gift-card-container {
    li {
        list-style-type: none;
        float: left;
        width: 32.4%;
        height: 300px;
        margin: 0 0 60px 0;
        text-align: center;
        position: relative;
        h1 {
            margin: 0;
            font-weight: 400;
        }
        h2 {
            font: 400 24px 'open sans condensed';
            line-height: 1;
            margin-bottom: 0;
            margin-top: 25px;
        }
        p {
            margin-bottom: 0;
            font-weight: 100;
            line-height: 1.5;
        }
        p.pay-go {
            font-weight: 400;
            font-size: 29px;
            line-height: 1.3;
        }
    }
    li.giftcard-1 {
        margin-right: 0;
        background-color: #696969;
        h1 {
            margin-top: 27px;
        }
        form {
            margin-top: 29px;
        }
    }
    li.giftcard-0 {
        background-color: #969696;
        .plan-type {
            margin-bottom: 25px;
        }
        h1 {
            margin-top: 33px;
        }
        form {
            margin-top: 34px;
        }
    }
    li.giftcard-2 {
        display: none;
    }
    li.giftcard-3 {
        background-color: #969696;
        .plan-type {
            margin-bottom: 26px;
        }
        h1 {
            margin-top: 28px;
        }
        form {
            margin-top: 45px;
        }
    }
}

.additional {
    font-size: 11px;
}

.gift-submit {
    background: none repeat scroll 0 0 #44B8EA;
    border: medium none;
    color: #FFFFFF;
    padding: 15px 55px;
    text-transform: uppercase;
    border-radius: 0;
}

gift-submit:hover {
    text-decoration: underline;
}

.btn-gift-book:hover {
    color: #fff;
}

.gift-step-2 {
    .book-appt {
        display: table;
        margin: 20px auto;
    }
    .book-container {
        background-color: white;
    }
    .container-details {
        margin-bottom: 10px;
        margin-top: 30px;
    }
}

.gift-card-bought {
    background: none repeat scroll 0 0 #FFFFFF;
    color: #808080;
    padding: 5%;
    width: 90%;
}

#trx-gift,
#trainertrx-gift {
    .hero {
        background-size: cover;
        padding: 50px;
        h3.trx {
            font-weight: normal;
            line-height: 1.3em;
            margin: 10px 2%;
        }
        h3.limited {
            .bold(18px);
            margin: 55px 2% 0 2%;
        }
        .epigraph {
            margin-bottom: 50px;
        }
    }
    h1 {
        sup {
            top: -0.8em;
            font-size: 50%;
        }
    }
    .btn-trx {
        margin: 30px 0;
    }
    b.code {
        background-color: yellow;
        color: black;
        padding: 5px;
    }
}

#trainertrx-gift {
    .hero {
        background: linear-gradient(rgba(0, 0, 0, 0.22), rgba(0, 0, 0, .5)), url(/static/img/trx/trx-trainer-hero.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}

#trx-gift {
    .hero {
        background: linear-gradient(rgba(0, 0, 0, 0.22), rgba(0, 0, 0, .5)), url(/static/img/trx/trx-hero-1.jpg);
        background-size: cover;
    }
}

#trx-gift,
#trainertrx-gift,
#further-food-perks,
#ACE,
#trainer-webinars {
    .row.promo {
        img.promo {
            width: 500px;
            max-width: 100%;
        }
        .col-img {
            text-align: center;
        }
        .col-info {
            h1,
            h3 {
                font-weight: bold;
            }
        }
        margin: 50px;
        .inline {
            display: inline-block;
        }
        a.btn-trx {
            display: block;
            max-width: 415px;
        }
        a.btn-trx2 {
            display: block;
            max-width: 215px;
            margin-top: 30px;
            padding: 10px;
        }
        .free {
            color: red;
        }
        small {
            display: block;
            font-style: italic;
        }
        span.strike {
            text-decoration: line-through;
        }
        span.red {
            color: red;
        }
        span.unbold {
            font-weight: normal;
        }
    }

    .promo-trx.last {
        margin-bottom: 150px;
    }

    .line {
        width: 90%;
        display: block;
        border-bottom: solid 3px rgba(128, 128, 128, .24);
        position: relative;
        margin: 20px auto;
    }
}

#trainer-webinars {
    .row.promo {
        img.promo {
            width: 300px;
        }
        p {
            margin: 0;
        }
        h3 {
            margin-top: 0;
        }
        a {
            color: #7acaf3;
        }
    }
}

#fyt-pro-perks,
#further-food-perks,
#ACE {
    .first-row {
        text-align: center;
        margin: 100px 0;
        .imgs-container {
            margin: auto;
            display: inline-block;
            .col-img {
                display: inline-block;
                margin: 0 50px;
                img.promo {
                    margin-bottom: 15px;
                }
                a {
                    color: black;
                }
            }
            .col-info {
                padding-left: 50px;
            }
        }
    }
    .promo-btn {
        margin-top: 30px;
        padding: 10px 30px;
    }
}

#fyt-pro-perks {
    .hero {
        background: linear-gradient(rgba(0, 0, 0, 0.22), rgba(0, 0, 0, .5)), url(/static/img/perks/FYT-Pro-Perks-Page-Hero.png);
        background-size: 100%;
        padding: 100px 200px;
    }
}

#further-food-perks {
    .hero {
        background: linear-gradient(rgba(0, 0, 0, 0.22), rgba(0, 0, 0, .5)), url(/static/img/perks/Further-Food-Perks-Hero.png);
        background-size: 100%;
        padding: 0 200px 70px 200px;
    }
}

#fresh-meal-plan,
#previnex-pro {
    .hero {
        background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, .2)), url(/static/img/previnex/newprevinex.jpg);
        background-size: 100%;
        background-size: cover;
        padding: 165px 200px;
        height: 600px;
        background-position: 100% 80%;
        h3 {
            letter-spacing: 0.5px;
            font-size: 20px;
            line-height: 1.5em;
        }
    }
    .row.promo {
        margin-top: 124px;
        img.promo {
            width: 500px;
            max-width: 100%;
            margin-bottom: 124px;
        }
        .col-img {
            text-align: center;
        }
        .col-info {
            h2 {
                font-weight: 700;
                letter-spacing: 0.4px;
                line-height: 1.4em;
                color: rgb(25, 25, 25);
            }
            p {
                font-weight: 600;
                color: #444545;
                font-size: 15.75px;
                letter-spacing: 0.4px;
                a {
                    color: rgb(88, 190, 234);
                }
            }
            .bold{
                font-size: 17px;
                font-weight: 700;
                span {
                    background-color: #cbedfd;
                    color: rgb(82, 86, 87);
                    padding: 5px 24px;
                    font-size: 20px;
                }
            }
        }
    }
    @media (max-width: 576px){
        .hero {
            background-position: 5% 0%;
            padding: 24px 0px;
            height: 500px;
        }
        .row.promo {
            margin-top: 24px;
        }
    }
    @media (min-width: 576px) and (max-width: 768px){
        .hero {
            background-position: 5% 0%;
            padding: 24px 0px;
            height: 500px;
        }
        .row.promo {
            margin-top: 24px;
        }
    }
    @media (min-width: 768px) and (max-width: 992px){
        .hero {
            background-position: 5% 0%;
            padding: 24px 8px;
            height: 500px;
        }
        .row.promo {
            margin-top: 24px;
        }
    }
}

#fresh-meal-plan {
    .hero {
        background: url(/static/img/fresh_meal_plan/FMP-Hero.jpg);
        background-size: 100%;
        background-size: cover;
        padding: 100px 200px;
        height: 550px;
        background-position: 100% 10%;
        h1 {
            font-size: 52.5px !important;
        }
    }
    @media (max-width: 576px){
        .hero {
            background-position: 0% 0%;
            padding: 24px 0px;
            height: 500px;
        }
        .row.promo {
            margin-top: 24px;
        }
    }
    @media (min-width: 576px) and (max-width: 768px){
        .hero {
            background-position: 0% 0%;
            padding: 24px 0px;
            height: 500px;
        }
        .row.promo {
            margin-top: 24px;
        }
    }
    @media (min-width: 768px) and (max-width: 992px){
        .hero {
            background-position: 0% 0%;
            padding: 24px 8px;
            height: 500px;
        }
        .row.promo {
            margin-top: 24px;
        }
    }
}

#cookunity {
  .img-banner {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .row.promo {
    margin-top: 124px;
    img.promo {
        width: 500px;
        max-width: 100%;
        margin-bottom: 124px;
    }
    .col-img {
        text-align: center;
    }
    .col-info {
        h2 {
            font-weight: 700;
            letter-spacing: 0.4px;
            line-height: 1.4em;
            color: rgb(25, 25, 25);
        }
        p {
            font-weight: 600;
            color: #444545;
            font-size: 15.75px;
            letter-spacing: 0.4px;
            a {
                color: rgb(88, 190, 234);
            }
            span {
              color: #000000;
            }
        }
        .bold{
            font-size: 17px;
            font-weight: 700;
            span {
                color: #000000;
            }
        }
    }
  }
  @media (max-width: 576px) {
      .img-banner {
        height: 100%;
      }
      .row.promo {
        margin-top: 24px;
      }
    }
    @media (min-width: 576px) and (max-width: 768px) {
      .img-banner {
        height: 100%;
      }
      .row.promo {
        margin-top: 24px;
      }
    }
    @media (min-width: 768px) and (max-width: 992px) {
      .img-banner {
        height: 100%;
      }
      .row.promo {
          margin-top: 24px;
      }
    }
}


#purple-carrot {
  .hero {
    background: url(/static/img/purple-carrot/Purple-Carrot-Hero-Hero.png);
    background-size: 100%;
    background-size: cover;
    padding: 100px 200px;
    height: 550px;
    background-position: 100% 80%;
    h3 {
        letter-spacing: 0.5px;
        font-size: 20px;
        line-height: 1.5em;
    }
  }
  .row.promo {
      margin-top: 124px;
      img.promo {
          width: 500px;
          max-width: 100%;
          margin-bottom: 124px;
      }
      .col-img {
          text-align: center;
      }
      .col-info {
          h2 {
              font-weight: 700;
              letter-spacing: 0.4px;
              line-height: 1.4em;
              color: rgb(25, 25, 25);
          }
          p {
              font-weight: 600;
              color: #444545;
              font-size: 15.75px;
              letter-spacing: 0.4px;
              a {
                  color: rgb(88, 190, 234);
              }
          }
          .bold{
              font-size: 17px;
              font-weight: 700;
              span {
                  background-color: #cbedfd;
                  color: rgb(82, 86, 87);
                  padding: 5px 24px;
                  font-size: 20px;
              }
          }
      }
  }
  @media (max-width: 576px){
      .hero {
          background-position: 5% 0%;
          padding: 24px 0px;
          height: 500px;
      }
      .row.promo {
          margin-top: 24px;
      }
  }
  @media (min-width: 576px) and (max-width: 768px){
      .hero {
          background-position: 5% 0%;
          padding: 24px 0px;
          height: 500px;
      }
      .row.promo {
          margin-top: 24px;
      }
  }
  @media (min-width: 768px) and (max-width: 992px){
      .hero {
          background-position: 5% 0%;
          padding: 24px 8px;
          height: 500px;
      }
      .row.promo {
          margin-top: 24px;
      }
  }
}

#ACE {
    .hero {
        background: url(/static/img/perks/ACE-Perks-Hero_1.jpg);
        background-size: 100%;
        padding: 200px 100px;
        h1 {
            font-size: 45px;
        }
        h3 {
            font-size: 16px;
        }
    }
    .row.promo {
        h1 {
            font-size: 30px;
        }
        h3 {
            font-size: 18px;
        }
    }
    span.code {
        color: #36b6ec;
    }
    .promo-btn {
        margin-top: 30px;
        padding: 10px 140px;
    }
    img.promo {
        width: 400px !important;
        margin-top: 30px;
    }
}

.ACE-partnership {
    .hero {
        // I don't understand this
        background-position: center !important;
        background-size: 100% !important;
        @media (max-width: @screen-xs-max) {
            background-size: cover !important;
            height: 540px;
        }
        h1 {
            margin: 0 0 1em;
        }
        h3 {
            font-weight: 600;
            font-size: 1.4em !important;
            margin-bottom: 50px;
        }
    }
    p {
        line-height: 1.9em;
    }
    .promo {
        margin: 1em;
        h2 {
            font-weight: 600;
            margin: 1em 0em;
        }
    }
    .col-info {
        margin: 4em 0em;
    }
    #ace-carousel {
        margin: 2em 0em;
        .carousel-inner {
            width: 80%;
            margin: 0 auto;
            max-height: none;
            p.info {
                font-size: 1.4em;
            }
        }
    }
}

@media (min-width: @screen-sm-min) {
    img.course-icon {
        width: 100px;
        margin: 0 0 10px 10px;
    }
}

.gifts-options-square {
  .gift-step-2 {
    .book-appt {
      display: table;
      margin: 20px auto;
    }
    .book-container {
      background-color: white;
    }
    .container-details {
      margin-bottom: 10px;
      margin-top: 30px;
    }
  }
}
.gift-card-bought {
    background: none repeat scroll 0 0 #FFFFFF;
    color: #808080;
    padding: 5%;
    width: 90%;
}
.gift-step{
    .hero{
        background-color: #f4f7f8;
        background-image: url("/static/img/gifts/gift-hero.png");
        background-size: contain;
        background-position: 15%;
        color: #000;
    }
}

.gifts-options-square {
  margin: 0 auto;
  text-align: center;
  display: table;
  vertical-align: middle;
  border: solid #46aded 2px;
  border-radius: 10px;
  width: 200px;
  height: 120px;
  padding: 15px;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #46aded;
  .label-banner {
    background-color: #5340FF;
    color: #fff;
    font-weight: 16px;
    border: 1px solid #5340FF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 20%;
  }
  p {
    display: table-cell;
    vertical-align: middle;
    font-size: xx-large;
    font-weight: 600;
  }
  /*    span.option{
      font-size: 30px;
      font-weight: bold;
      color: #46aded;
  }*/}
  span.custom {
    font-size: large;
  }

  span.custom {
      display: table-cell;
      vertical-align: middle;
      font-size: x-large;
      font-weight: 600;
    }
    .amount-chooseable {
      display: table-cell;
    }

    input.amount-chooseable, input.sessions-chooseable {
      margin-top: 0.7em;
      border: none;
      font-size: 30px;
      font-weight: 700;
      color: #46aded;
      max-width: 100px;
      text-align: center;
    }


  .gift-step {
    .order-payment {
      margin-bottom: 20px;
      background: @box-gray;
      h4 {
        margin-bottom: 30px;
      }
      table {
        //font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }

      table td {
        border-bottom: 1px solid black;
        padding: 8px;
      }

      table .total td {
        border-bottom: none;
      }

      table tr:nth-child(even) {
        font-weight: bold;
      }

      /*table tr:hover {background-color: #dddddd63;}*/
    }
  }

  #gifts-step-1 {
    font-family: 'Avenir Next Medium';
    font-size: large;

    .hero_national_home_page{
        font-size: initial !important;
    }

    .hero {
      padding: 25px 0 150px;
      font-family: 'Avenir Next Medium';
    }

    h1 {
      margin: 1em 0;
      font-family: 'Avenir Next Medium';
      font-weight: 600;
    }

    #go-step-2 {
      display: block;
      margin: 3em auto 4em auto;
      padding: 8px 30px;
    }

    @media (min-width: @screen-md-min) {
      img#gift-of-health {
        float: right;
        margin-right: 20px;
        margin-top: 50px;
      }

      .options-container {
        margin-top: 38px;
        padding-left: 50px;
        .second-row {
          margin-top: 50px;
          margin-bottom: 20px;
        }
        p {
          padding-left: 15px;
        }
      }
    }

    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      .first-choice {
        margin-left: 100px;
      }
    }

    @media (max-width: @screen-sm-max) {
      .img-container {
        text-align: center;
      }

      .row {
        .gifts-options-square {
          font-size: 20px;
        }
      }

      .second-row {
        margin-bottom: 50px;
      }

      #go-step-2 {
        margin: 20px auto;
      }
    }

    @media (max-width: @screen-xs-max) {
      img {
      }

      .first-choice {
        margin-left: 0;
      }

      .gifts-options-square {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        display: table;
        width: 100%;
        height: 100px;
        p {
          margin-top: 5%;
        }
      }

      input.amount-chooseable {
        margin-top: 0.4em;
      }
    }
  }

  #gifts-step-2 {
    .step-row {
      padding-bottom: 100px;
      h1 {
        margin-bottom: 80px;
        margin-top: 50px;
      }
      img#gift-of-health {
        width: 200px;
      }
      #complete-purchase {
        font-size: 20px;
        padding: 8px 50px;
        margin: 30px 0;
      }
      @media (max-width: @screen-sm-max) {
        .img-container {
          text-align: center;
          img {
            margin-top: 30px;
          }
        }

        .payment-security-info {
          text-align: center;
          .logos-container {
            margin-left: 250px;
          }
        }
      }
      @media (max-width: @screen-xs-max) {
        h1 {
          font-size: 30px;
        }

        .order-payment {
          padding: 0 70px;
        }

        .logos-container {
          margin-left: 120px !important;
        }
      }
    }
  }

  #gifts-step-3 {
    .first-row {
      padding-bottom: 100px;
      text-align: center;
      h1 {
        margin-bottom: 80px;
        margin-top: 50px;
      }
    }

    .second-row {
      margin-bottom: 100px;
    }

    img#gift-of-health {
      width: 200px;
    }

    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      .img-container {
        text-align: center;
      }

      .first-row {
        padding: 0 20px 100px 20px;
      }
    }

    @media (max-width: @screen-xs-max) {
      .first-row {
        padding: 0 60px 100px 60px;
      }

      .second-row {
        padding: 0 60px;
      }
    }
  }

  @media (max-width: @screen-md-max) {
    .gift-holidays .gift-list {
      //list-style-type: none;
      text-align: center;
      margin: 10px 10% 30px;
      padding: 0;
      li {
        margin: 5px 0;
      }
    }

    #ACE {
      .hero {
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    .visible-xs.inline {
      display: inline-block !important;
    }

    img.course-icon {
      width: 50px;
      margin: 0 0 20px 10px;
    }

    .col-img {
      margin-bottom: 30px;
    }

    #fyt-pro-perks,
    #ACE {
      .hero {
        padding: 0 0 50px 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
      .col-img {
        margin: 20px 0px !important;
      }
    }

    #further-food-perks {
      .hero {
        padding: 0 0 50px;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .promo-btn {
        padding: 8px;
      }
    }

    #ACE {
      .promo-btn {
        padding: 10px 50px;
      }
    }

    .gift-step {
      .hero {
        background-position-x: center;
        background-position-y: bottom;
        background-size: 22em;
        padding-bottom: 2em;
      }
    }

    #gifts-step-1 .hero {
      padding: 25px 0 240px;
    }

    .gift-epigraph {
      font-family: 'Avenir Next Medium';
      .trx {
        width: auto !important;
        font-family: 'Avenir Next Medium';
      }
    }

    .options-container {
      .row {
        width: 100% !important;
      }
      button#go-step-2 {
        width: 90% !important;
      }
    }

    .as-seen-div {
      .as-seen-title {
        padding: 12px 0 24px;
      }
      img {
        width: 100%;
      }
    }
    .christmas-banner{
        h2{
            margin-top: 20px !important;
            margin-bottom: 20px !important;
            font-size: larger !important;
        }
    }
  }

  @media (min-width: 320px) and (max-width: 723px) {
    .gift-cards {
      h1 {
        padding: 0px 10px;
      }
      .tag {
        padding: 0px 10px;
      }
      .gift-list {
        padding-left: 11px;
      }
    }

    .gift-card-container {
      margin-left: -30px;
      float: left;
      li {
        width: 98%;
        margin-bottom: 0;
        h2 {
          font-size: 35px;
        }
      }
    }
  }

  @media (min-width: 723px) {
    .btn-trx {
      margin: 30px 0;
      padding: 10px 110px;
    }

    b.code {
      margin: 5px;
    }
  }
  .gift-epigraph {
    h1 {
      font-family: 'Avenir Next Medium';
    }
    h3 {
      //margin:2em;
      font-family: 'Avenir Next Medium';
    }
    h4 {
      margin-top: 2em;
      font-family: 'Avenir Next Medium';
      //font-weight: 500 !important;
    }
    .trx {
      font-family: 'Avenir Next Medium';
      margin: auto;
      width: 50%;
      font-size: 20px;
    }
  }
  .font-bold {
    font-weight: bold;
  }
  .what-they {
    background-color: #f4f7f8;
    min-height: 10em;
    padding-bottom: 3em;
    margin-bottom: 3em;
  }
  .gift-circle {
    text-align: center;
    img {
      width: 10em;
      height: 10em;
    }
  }
  .options-container {
    .row {
      max-width: 950px;
    }
  }
.christmas-banner{
    background-color: #c51a27;
    color: white;
    text-align: center;
    width: 100%;
    min-height: 2.5em;
    display: inline-block;
    position: fixed;
    z-index: 100;
    h2{
        font-weight: 600;
        vertical-align: middle;
        margin-top: 17px;
        margin-bottom: 10px;
        font-size: 2rem;
    }
}

#keho_page {
  width: 100%;
  height: 100%;
  img.banner {
    width: 100%;
    height: 100%;
  }
  img.product {
    height: 100%;
    width: 95%;
    padding: 20px;
  }
  h1 {
    color: #000;
    font-size: 36px;
    font-weight: bolder;
    margin-bottom: 42px;
  }
  p, li {
    color: #000;
    font-size: 19px;
    width: 75%;
  }
  p {
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 8px;
  }
  ul {
    list-style: outside;
  }
  .connect-btn {
    color: #000000;
    background-color: #dcff5b;
    font-size: 22px;
    font-weight: bolder;
    margin: 90px 30% 0 30%;
    &:hover {
      color: #FFFFFF;
    }
  }
  @media screen and (max-width: @screen-xs-max) {
    .row {
      display: flex;
      flex-direction: column-reverse;
    }
    img.product {
      width: 100%;
    }
    h1 {
      font-size: 29px;
      padding: 0 20px;
      margin-bottom: 21px;
    }
    p, li {
      width: 100%;
      padding: 0 20px;
      font-size: 15px;
    }
    p {
      margin-bottom: 10px;
    }
    li {
      margin-bottom: 4px;
    }
    .connect-btn {
      margin: 10px 30%;
    }
  }
}