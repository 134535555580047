@import (reference) 'utils.less';
@import (reference) "bootstrap_less/grid.less";

#national-home, #pricing-page{
    header{
        position: fixed;
        z-index:12001;
        width: 100%;
    }
    
    #main{
        margin-top: 59px;
    }
    .center-text{
        text-align: center;
    }

}

#market-home, #national-home {
    padding-top: 0px !important;
}

#national-home, #market-home{
    padding-top:0px;
    .home-items .item .info-box-container{
        width: 1270px !important;
    }
}

header {
    //-moz-box-shadow: none !important;
    //-webkit-box-shadow: none !important;
    //box-shadow: none !important;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,.16);
    background: #fff;
    min-height: 59px;
    .menu .menu-icon {
        background: #FFFFFF;
        .bar {
            background: black;
        }
    }
}

#home-search {
    background: none;
    background-size: initial;
    padding: 0 !important;
}

.in-home-training {
    position: relative;
    color: #fff;
    text-align: center;
    padding: 200px 200px;
    background: url(../img/in-home-training.png) no-repeat;
    background-size: 100%!important;
    background-position: 0 60%;
    h1 {
        font-family: 'Avenir Next Medium';
        font-weight: 800;
    }
}

.see-why-client-love-Fyt {
    font-family: 'Avenir Next Medium';
    text-align: center;
    color: #000000; 
    h1 {
        font-size: 45px;
        margin-top: 81px;
        font-weight: 700;
    }
    p {
        font-weight: 500;
        font-size: 24px;
        margin: 50px 34px;
    }
}

.hero_national_home_page {
    .custom-home-container{
        background-color: #F4F7F8;
    }
    .padding-left{
        padding-left:30px;
        padding-right : 0px;
    }
    .primary-home-btn{
        font-family: 'Avenir Next Medium';
        font-size: 18px;
        padding: 10.5px 24px;
    }
    .home-btn-second {
        border: 2.5px solid #36B6EC;
        border-radius: 2.5px;
        padding: 8px 15px;
    }
    .div-first-middle {
        display : flex;
        align-items : center;
        padding: 24px 0;
        .titles{
            font-family: 'Avenir Next Medium';
            color:black;
            float:left;
            .title {
                font-size: 45px;
                font-weight: 700;
                margin-top: 0;
                line-height: 55px;
                color: #000;
                margin-bottom: 0;
            }
            /*.title:first-child {
                margin-bottom: 8px;
            }*/
            .title-text{
                font-weight: 500;
                font-size: 20.5px;
                line-height: 30px;
            }
            h4 {
                color: #41bdef;
                font-size: 20px;
                font-weight: 800;
            }
            h4:nth-of-type(1) {
                padding-left: 18%;
            }
        }
        .titles-mobile {
            width: 100%;
            text-align: center;
            padding: 5px 0;
            h4 {
                color: #41bdef;
                font-size: 20px;
                font-weight: 800;
            }    
        }
    }
    .fit-simple-title{
        text-align: center;
        margin-top: 81px;
        h2{
            font-family: 'Avenir Next Medium';
            color: #000000;
            font-size: 34px;
            margin-top: 0;
            margin-bottom : 81px;
            font-weight: 700;
            line-height: 42.5px;
        }
    }
    .fit-simple-div {
        display : flex;
        align-items : center;
        .fit-simple-div-img {
            text-align: center;
            align-items: center;
        }
        .intro {
            font-family: 'Avenir Next Medium';
            color: #000000;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 0;
            line-height: 27.5px;
        }
        .fit-simple-img-text {
            display: flex;
            align-items: center;
            margin-top: 15px;
            p{
                font-family: 'Avenir Next Medium';
                color: #757679;
                font-size: 16px;
            }
            a:hover, a:focus {
                text-decoration: none;
                p {
                    color:rgb(65, 189, 239);
                }
            }
        }
    }
    .yotpo_site_reviwes {
        padding-bottom: 81px;
        font-family: 'Avenir Next Medium';
        color: #000000;
        h2{
            text-align: center;
            font-size: 34px;
            font-weight: 700;
            margin-bottom : 24px;
            padding: 24px 0;
        }
        .yotpo-review-avg {
            display: inline;
            margin-right: 8px;
        }
        .yotpo-review-avg:first-child {
            vertical-align: super;
        }
        .intro-yotpo {
            padding-bottom: 24px;
            display : table;
            .real_reviews {
                display : table-cell;
                vertical-align : middle;
                span {
                    color: #000000;
                    font-weight: 700;
                    font-size: 17.5px;
                    margin-right: 8px;
                    vertical-align: bottom;
                }   
                a {
                    color:rgb(65, 189, 239);
                    font-weight: 700;
                    font-size: 17.5px;
                    text-decoration: none;
                    margin-left: 8px;
                    vertical-align: bottom;
                }
            }
            .reviews {
                display : table-cell;
                text-align: right;
                vertical-align: middle;
                .total_reviews {
                    color : rgb(6, 38, 52);
                    font-weight: 700;
                    font-size: 17.5px;
                    margin-right: 10px;
                    vertical-align: text-bottom;
                }
                a {
                    color: #474851;
                    font-weight: 950;
                    font-size: 24px;
                    margin: 0 2.5px;
                    cursor: pointer;
                    text-decoration: none;
                }
            }
        }
        .div-review {
            height: 300px;
            background-color:white;
            border-radius: 8px;
            margin: 1.5%;
            .div-intro {
                margin-top: 8px;
                display: inline-flex;
                .circle_img {
                    border-radius: 200px;
                    height: 60px;
                    width: 60px;
                    margin: 8px 0px;
                }
                .div-reference {
                    margin-left:24px;
                    .display_name {
                        color: rgb(74, 74, 120);
                        font-weight: 700;
                        font-size: 17.5px;
                        margin: 15px 0 0;
                    }
                    .date {
                        color: rgb(91, 91, 131);
                        font-size: 12.5px;
                        vertical-align: middle;
                    }
                }
            }
            .div-content {
                margin-top: 8px;
                .content-title {
                    color: rgb(6, 38, 52);
                    font-weight: 700;
                    font-size: 17.5px;
                }
                .content {
                    color: rgb(128, 129, 132);
                    font-size: 15px;
                }
            }
        }
    }
    .how-works-home {
        text-align: center;
        h2{
            font-family: 'Avenir Next Medium';
            color: #000000;
            font-size: 34px;
            font-weight: 700;
            margin-bottom : 24px;
            padding: 24px 0;
        }
        p {
            color: #000;
            font-size: 24px;
            font-weight: 750;
        }
        .desc {
            color: rgb(117, 118, 122);
            font-weight: 500;
            font-size: 17.5px;
            margin: 0;
        }
        .step {
            margin-top: 24px;
        }
        img {
            width : 75%;
        }
    }
    .how-works-home-button {
        margin: 81px 0px;
    }
    .promise {
        text-align: center;
        padding: 24px 0;
        font-family: 'Avenir Next Medium';
        color: #000000;
        h2{
            font-size: 34px;
            font-weight: 800;
        }
        h4 {
            font-size: 20px;
            letter-spacing: 0.5px;
        }
    }
    .promise-div {
        margin-top: 75px;
        text-align: center;
        display: flex;
        align-items: center;
        p {
            margin-top: 24px;
            font-family: 'Avenir Next Medium';
            color: #000000;
            font-size: 15px;
            letter-spacing: 0.25px;
        }
        p.more-margin {
            margin-top: 49px;
        }
    }
    /*.promise-div {
        display : flex;
        align-items : center;
        .promise-div-img {
            text-align: center;
            align-items: center;
            .promise-img {
                width : 70%;
            }
        }
        .promise-img-text {
            display: flex;
            align-items: center;
            margin-top: 15px;
            p{
                color: rgb(98, 99, 100);
                font-size: 18px;
            }
            .money-back-home {
                color: #000;
                font-weight: 700;
            }
            img {
                margin-bottom: 8px;
                width: 100%;
            }
        }
    }*/
}
.connect {
    background-color : #283341;
    text-align: center;
    padding: 24px 0; 
    .social-div-text{
        float: none !important;
        display: inline-block !important;
        text-align: right;
        h2 {
            color : #FFFFFF;
            font-family: 'Avenir Next Medium';
            font-size: 20px;
            font-weight: 700;
            margin : 10px 0;  
        }
    }
    .social-div-buttons {
        float: none !important;
        display: inline-block !important;
        text-align: left;
        vertical-align: super;
        img {
            margin-right: 8px;
        }
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { 
        .social-div-text{
            text-align: center;
        }
        .social-div-buttons {
            text-align: center;
        }
    }
}

.hero_national_home_page_reviews{
    &:extend(.parent-hero);
    background: url('../img/NEW-HOME-HERO.png') no-repeat;
    background-size: 100%;
    h1{
        .extrabold(4em);
    }
    h3{
        .bold(18px);
        line-height: 1.3em;
        margin: 30px 2%;
    }
    #coming-soon-email {
        height: 47px;
    }
    .epigraph {
        position: relative;
        top: 30px;
        p.market-epigraph {
            font-size: 0.7em;
            margin: 10px 0px;
        }
    }
    .locate-group{
        display: block;
    }
    .btn-cont .btn {
        margin: 5px 0px;
    }
}


.common_questions {
    font-family: 'Avenir Next Medium';
    .common_questions_div {
        border: solid 2.24px #dddedd;
        margin-bottom: 24px;
        min-height: 200px;
        h2 {
            color: #3ebcee;
            font-size: 17.5px;
            font-weight: 700;
            margin: 20px 10px;
        }
        p {
            font-size: 14px;
            margin: 10px;
            color: rgb(114, 114, 114);       
            span.show-more-answer {
                cursor: pointer;
                color: #3ebcee;
                font-size: 12.75px;
                font-weight: 700;
            }
        }
    }
}


.btn.youtube-video {
    padding: 12px 25px;
}

.glyphicon-wireframe-play {
    top: -1px;
    margin-right: .7em;
    &:before {
        content: "▷";
    }
}

.fyt-difference {
    text-align: center
}

.watch-video {
    padding: 20px 0px;
}

.opus_modal {
    .modal-header {
        background-color: white;
        border-bottom: 0px;
        span {
            color: #797979;
        }
    }
    .modal-body {
        margin-top: 27px;
        .title {
            font-weight: 700;
            font-size: 29px;
        }
        .opus-logo-container {
            padding-left: 0px;
        }
        .opus-badge-container {
            margin-top: 60px;
        }
        .opus_text_container {
            margin-top: 20px;
            .opus_text{
                text-align: left;
                line-height: 25px;
            }
        }
    }
}

.request_a_city_modal {
    .modal-header {
        background-color: white;
        border-bottom: 0px;
    }
    .modal-body {
        margin-bottom: 30px;
        margin-top: 27px;
        label.error {
            color: red;
            margin-top: 10px;
        }
        .title {
            text-align: center;
            font-weight: 700;
        }
        p {
            font-size: 15px;
        }
    }
}

.personal-trainers-in-city {
    .state_name {
        cursor: pointer;
        font-weight: initial;
        font-weight: 600;
    }
    .state_empty {
        color: #B7B7B7;
        font-weight: 400;
    }
    .close_panel {
        cursor: pointer;
        color: #ABA3A3;
        font-size: 14px;
    }
    .request_a_city {
        color: #43b8ea;
        cursor: pointer;
    }
    .panel_city_content {
        display: none;
        position: absolute;
        width: 100%;
        margin-left: 50%;
        z-index: 10;
        margin-top: -24px;
    }
    .panel_city_content:after, .panel_city_content:before {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .panel_city_content:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #ffffff;
        border-width: 10px;
        margin-top: -40px;
    }
    .panel_city_content:before {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #D2D0D0;
        border-width: 11px;
        margin-top: -41px;
    }
}

a[href="/about/in-home/"] {
    width: 240px !important;
}

footer {
    background-color: rgb(30, 41, 52);
    font-family: 'Avenir Next Medium';
    h4 {
        font-size: 18px;
        color: rgb(201, 200, 202);
        font-weight: 700;
    }
    .footer_nav li b {
        color: rgb(171, 171, 174);
        font-size: 16px;
        font-weight: 650;
    }
    .footer_nav li a {
        color: rgb(171, 171, 174);
        font-size: 15px;
    }
}

@media screen{
    @media (max-width:@screen-xs-min){
        .no-padding {
            padding: 0 15px;
        }
        .padding-left{
            padding-left:15px !important;
            padding-right : 15px !important;
        }
        .hero_national_home_page {
            .div-first-middle {
                display: initial;
                .titles {
                    color: rgb(0, 0, 0);
                    float: left;
                    margin-top: 80px;
                    .title{
                        font-size: 37.5px;
                    }
                    .title:first-child {
                        margin-bottom: 0px;
                    }
                    .title-text {
                        font-weight: 525;
                        font-size: 15px;
                    }
                }
                .home-btn {
                    border: 2px solid #36B6EC;
                    border-radius: 2.5px;
                    padding: 9px 19px;
                    font-size: 17.5px;
                    font-weight: 600;
                }
                img {
                    height: 324px;
                }
            }
            .fit-simple-title {
                text-align: center;
                margin: 24px 0px;
                h2{
                    font-size: 17.5px;
                    font-weight: 650;
                    margin: 24px 0;
                    line-height: 25px;
                }
            }
            .fit-simple-div {
                display : initial;
                .fit-simple-div-img {
                    img {
                        width: 80%;
                        margin-bottom: 24px;
                        height: 300px;
                    }
                }
                .intro{
                    font-size: 15px;
                    font-weight: 550;
                    margin: 24px 0;
                    line-height: 20px;
                }
                .fit-simple-img-text p {
                    font-size: 13px;
                    font-weight: 525;
                    line-height: 20px;
                }
                .fit-simple-img-text:last-child {
                    margin-bottom:0px;
                }

                .fit-simple-img-text {
                    img {
                        width: 80%;
                        height: 24px;
                    }
                }

            }
            .yotpo_site_reviwes .intro-yotpo .reviews {
                display: table-cell;
                text-align: right;
                vertical-align: middle;
                margin-top: 8px;
            }
            .how-works-home{
                img {
                    width : 80%;
                    height: 324px;
                }
            }
            .promise-div {
                display : initial;
                margin-top: 24px;
                p {
                    margin-bottom: 50px;
                }
                p.more-margin {
                    margin-top: 24px;
                    margin-bottom: 0px;
                }
            }
            /*.promise-div {
                display : initial;
                .promise-div-img {
                    .promise-img {
                        width: 80%;
                        margin-bottom: 24px;
                        height: 300px;
                    }
                }
                .promise-img-text {
                    p{
                        font-size: 13px;
                    }
                    img {
                        width: 90%;
                    }
                }
            }*/
            .as-seen-div{
                .as-seen-title {
                    padding: 12px 0 24px;
                }
                img {
                    width:100%;
                }
            }
            .trainer-home{
                span {
                    padding-right: 0px;
                }
            }
        }
        .connect{
            padding: 34px 0;
            .social-div-text{
                float: left !important;
                text-align: -webkit-center;
                padding: 0 0 0 15px;
            }
            .social-div-buttons {
                padding: 8px 0 0 0;
                text-align: -webkit-center;
                vertical-align: -webkit-baseline-middle;
                img {
                    margin-right: 8px;
                }
            }
        }
        .iam-trainer {
            margin-bottom: 20px;
        }
        .hero-work-with-us {
            background: ~"url('../img/small-fun-slide-with-opacity.jpg') no-repeat";
            padding: 0px 0 11px;
        }
        .personal-trainers-in-city {
            .panel_city_content {
                margin-left: 0%;
            }
        }
        .in-home-training {
            padding: 50px 0;
        }
    }
    
    @media (min-width: @screen-xs-min) and (max-width: 599px){}

    @media (min-width: 600px) and (max-width: @screen-xs-max){
        .hero_national_home_page {
            .div-first-middle {
                display: initial;
                .titles {
                    color: rgb(0, 0, 0);
                    float: left;
                    margin-top: 24px;
                    .title{
                        font-size: 42.5px;
                    }
                    .title:first-child {
                        margin-bottom: 0px;
                    }
                    .title-text {
                        font-weight: 525;
                        font-size: 16px;
                    }
                }
                .home-btn {
                    border: 2px solid #36B6EC;
                    border-radius: 2.5px;
                    padding: 9px 19px;
                    font-size: 17.5px;
                    font-weight: 600;
                }
                img {
                    height: 324px;
                }
            }
            .fit-simple-title {
                text-align: center;
                margin: 24px 0px;
                h2{
                    font-size: 17.5px;
                    font-weight: 650;
                    margin: 24px 0;
                    line-height: 25px;
                }
            }
            .fit-simple-div {
                display : initial;
                .fit-simple-div-img {
                    img {
                        width: 75%;
                        margin-bottom: 24px;
                        height: 324px;
                    }
                }
                .intro{
                    font-size: 15px;
                    font-weight: 550;
                    margin: 24px 0;
                    line-height: 20px;
                }
                .fit-simple-img-text p {
                    font-size: 13px;
                    font-weight: 525;
                    line-height: 20px;
                }
                .fit-simple-img-text:last-child {
                    margin-bottom:0px;
                }

                .fit-simple-img-text {
                    img {
                        width : 80%;
                    }
                }
            }
            .yotpo_site_reviwes .intro-yotpo .reviews {
                display: table-cell;
                text-align: right;
                vertical-align: middle;
                margin-top: 8px;
            }
            .how-works-home{
                img {
                    width : 75%;
                    height: 300px;
                }
            }
            .promise-div {
                display : initial;
                margin-top: 0px;
                p {
                    margin-bottom: 24px;
                }
                p.more-margin {
                    margin-top: 24px;
                }
            }
            /*.promise-div {
                display : initial;
                .promise-div-img {
                    .promise-img {
                        width : 75%;
                        margin-bottom : 24px;
                        height: 300px;
                    }
                }
                .promise-img-text {
                    p{
                        font-size: 15px;
                    }
                    img {
                        width: 80%;
                    }
                }
            }*/
            .as-seen-div{
                .as-seen-title {
                    padding: 24px 0 45px;
                }
                img {
                    width:100%;
                }
            }
            .trainer-home{
                span {
                    padding-right: 0px;
                }
            }
        }
        .connect{
            padding: 34px 0;
            .social-div-text{
                float: left !important;
                text-align: -webkit-center;
                padding: 0 0 0 15px;
            }
            .social-div-buttons {
                padding: 8px 0 0 0;
                text-align: -webkit-center;
                vertical-align: -webkit-baseline-middle;
                img {
                    margin-right: 8px;
                }
            }
        }
        .iam-trainer {
            margin-bottom: 20px;
        }
        .hero-work-with-us {
            background: ~"url('../img/small-fun-slide-with-opacity.jpg') no-repeat";
            padding: 0px 0 11px;
        }
        .personal-trainers-in-city {
            .panel_city_content {
                margin-left: 0%;
            }
        }
    }
    @media (min-width: @screen-xs-max) and (max-width: @screen-sm-max){
        .opus_modal {
            .modal-body {
                .title {
                    font-size: 21px;
                }
            }
        }
    }

    @media (min-width: @screen-md-min) and (max-width: @screen-md-max){
        
    }

    @media (min-width: @screen-lg){
        #neighborhood-modal {
            .modal-dialog {
                width: 750px;
            }
        }
    }
}
.as-seen-div{
        text-align: center;
        .as-seen-title{
            padding: 50px 0;
            h2{
                font-family: 'Avenir Next Medium';
                color: #000000;
                font-size: 34px;
                font-weight: 700;
            }
        }
        img {
            margin-bottom : 50px;
        }
}
.trainer-home{
    background-color : #3A4757;
    text-align: center;
    padding: 24px 0;
    font-family: 'Avenir Next Medium';
    span {
        color : #FFFFFF;
        font-size: 20px;
        font-weight: 700;
        padding-right: 24px;
    }
    .trainer-div-button {
        float: none !important;
        display: inline-block !important;
        background-color : #3A4757;
    }
}
.become-trainer-btm {
     border: 2.5px solid #FFFFFF;
     border-radius: 2.5px;
     padding: 8px 15px;
 }
.no-padding {
        padding: 0;
}