@import (reference) 'fonts.less';
@import (reference) 'colors.less';

.btn-common(@padding){
    .semibold(18px);
    color:#fff;
    display:inline-block;
    background:@gray;
    padding: @padding;
    text-decoration:none;
}
.btn(@color; @text-color: "white"; @padding: "12px 20px"){
    .btn-common(@padding);
    background:@color;
    border:@color;
    color: @text-color !important;
    .border-radius(3px);
    .transition(all 0.3s ease-in-out);
    &:hover{
        color: @text-color;
        background:lighten(@color, 5%);

    }
    &:active{
        background:lighten(@color, 15%);
    }
}

.btn{
    &-primary{
        .btn(@cyan);
    }
    &-dark{
        .btn(darken(@dark-gray, 10%));
        &:hover{
            color:#fff;
        }
        &:active{
            color:#fff;
        }
        &:visited{
            color:#fff;
        }
        &:focus{
            color:#fff;
        }
    }
    &-default{
        .btn(darken(@light-gray,6%));
    }
    &-lg{
        &.extra-width{
            padding:12px 50px;
        }
    }
    &-margin-5 {
        margin: 5px;
    }
    &-booking {
        &-24 { .btn(@12sess; @text-color: #333) }
        &-12 { .btn(@8sess; @text-color: #333) }
        &-4 { .btn(@4sess; @text-color: #333) }
    }
}

.fb-button {
    margin: 0 auto;
    padding: 10px 15px;
    display: table;
    color: #ffffff;
    font-weight: bold;
    border: 1px solid #2f4977;
    text-shadow: 0px 2px 3px #4C635D;
    .border-radius(3px);
    .box-shadow(0 2px 4px rgba(0,0,0,.19), inset 0 0 0 1px #6D81AD);
    cursor: pointer;
    background-color: #506dae;
}

.btn-green {
    color: white;
    font-weight: 600;
    background-color: @green;
    .transition(all 0.2s ease-in-out);

    &:hover {
        color: white;
        background-color: darken(@green, 5%);
    }
}

.btn-wireframe {
    &-white {
        background-color: inherit;
        color: white;
        border: 1px solid white;
        &:hover {
            color: @gray;
            background-color: white;
        }
        &.btn-lg { font-weight: 600; }
        .transition(all 0.2s ease-in-out);
    }
    &-cyan {
        background-color: inherit;
        color: @cyan;
        border: 1px solid @cyan;
        &:hover {
            color: white;
            background-color: @cyan;
        }
        &.btn-lg { font-weight: 600; }
        .transition(all 0.2s ease-in-out);
    }
    &-red {
        background-color: inherit;
        color: red;
        border: 1px solid red;
        &:hover {
            color: white;
            background-color: red;
        }
        &.btn-lg { font-weight: 600; }
        .transition(all 0.2s ease-in-out);
    }
    &-cyan2, &-cyan2:focus {
        background-color: @cyan;
        color: white;
        border: 1px solid @cyan;
        &:hover {
            color: @cyan;
            background-color: white;
        }
    }
}

.btn-heart {
    background-color: inherit;
    color: @cyan;
    border: 1px solid @cyan;
    &.favorited {
        color: red;
        border: 1px solid red;
        &:focus, &:hover { color: red; }
    }
    &:focus, &:hover { color: @cyan; }
    &.btn-lg { font-weight: 600; }
    .transition(all 0.2s ease-in-out);
}

.btn-heart2, .btn-heart2:focus {
    background-color: @cyan;
    color: white;
    border: 1px solid @cyan;
    &.favorited {
        background-color: white;
        color: red;
        border: 1px solid red;
        &:focus, &:hover { color: red; }
    }
    &:hover {
        background-color: white;
        color: @cyan;
    }
    &.btn-lg { font-weight: 600; }
    .transition(all 0.2s ease-in-out);
}

.btn-spacer { margin: 10px; }

.btn-search {
    color: white;
    background: darken(@cyan, 6%);
    .transition(all 0.3s ease-in-out);
    &:hover, &:active, &:focus {
        color: white;
        background: @cyan;
    }
}

.btn-actions {
  font-size: 12px;
  font-weight: 700;
  color: @cyan;
  background-color: white;
  border: solid 1px;
  .border-radius(3px);
  padding: 5px;
  cursor: pointer;
  @media screen and (min-width: @screen-md-min) {
    margin: 5px 10px;
  }
  &:focus, &:active, &:hover {
    border-color: @cyan;
    color: @cyan;
    outline: 0;
  }
}

.btn-add-to-calendars {
    background-color: #36B6EC;
    border-radius: 10px;
    padding: 6px;
    margin-top: 5px;
    a {
        font-size: 19px;
        color: white;
        padding: 0px 10px 0px 10px;
    }
}