@import (reference) 'bootstrap_less/grid.less';
@import (reference) 'colors.less';

body#rebook {
    a, a:hover { text-decoration: none }
    div.rebook {
        margin-top: 20px;
    }

    .avatar-title {
        text-align: center;
        padding: 10px 0px;
        background: @cyan;
        color: white;
        font-weight: 600;
    }

    .trainer-info {
        margin-bottom: 20px;
    }

    .in-app-messaging {
        margin: .5em 0em;
    }

    .new-search {
        margin-bottom: 20px;
        text-align: center;
        .shadow-container {
            .box-shadow(0 0 10px @light-gray);
            .work-out-with-trainer {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 182px;
                font-size: 1.8em;
                padding: 10px;
                line-height: 1.1em;
                font-weight: 600;
                background: @box-gray;
            }
        }
    }
}