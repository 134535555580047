@import (reference) 'colors.less';
@import (reference) 'utils.less';

.panel-message {
	position: relative;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
  box-shadow: 0 1px 1px rgba(0,0,0,.05)
}

// Add To Calendar
.atc-style-menu-wb .atcb-item-link,
.atc-style-menu-wb .atcb-item-link:hover,
.atc-style-menu-wb .atcb-item-link:active,
.atc-style-menu-wb .atcb-item-link:focus
{
    color: #000;
    padding: 5px 15px;
    font-family: 'Avenir Next Medium';
    font-size: 14px;
    text-decoration: none;
    outline: none;
}

.atc-style-menu-wb .atcb-item.hover,
.atc-style-menu-wb .atcb-item:hover {
    background: @cyan;
}