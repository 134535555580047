@import (reference) 'lesshat.less';
@import (reference) 'bootstrap_less/bootstrap.less';
@import (reference) 'bootstrap_less/mixins/grid.less';

@import (reference) 'colors.less';
@import (reference) 'fonts.less';
@import (reference) 'utils.less';

footer {
    color: #000;
    padding: 25px 0;
    h4{
        .bold(15px);
        color:@cyan;
    }
    .footer_nav{
        padding: 0;
        li{
            list-style: none;
            .regular(13px);
            a{
                color:#2a2a2a;
            }
        }
    }
    .masonry_container{
        width:90%;
        margin: 0 auto;
    }
    .footer_col{
        width: 100%;
        text-align: center;
        padding: 0 8px;
    }
    @media screen {
        @media (max-width: 480px) {
            text-align: center;
        }
        @media (min-width:480px){
            .footer_col{
                width:50%;
                text-align: left;
            }
        }
        @media (min-width:768px){
            .footer_col{
                width:20%;
                text-align: left;
            }
            .footer_brand{
                text-align: left;
                .logo_footer{
                    float: left;
                }
                .footer_inline-nav{
                    float: right;
                    margin: 0;
                }
            }
            .margin_bottom_20 {
                margin-bottom: 20px;
            }
        }
        @media (max-width: 768px) {
            .footer_nav {
                margin-bottom: 0 !important;
                margin-top: 0 !important;
            }
            .margin_bottom_15 {
                margin-bottom: 15px !important;
            }
        }
    }
}