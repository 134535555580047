@import (reference) 'fonts.less';
@import (reference) 'bootstrap_less/bootstrap.less';

@circle-size: 170px;
@offset: 20px;

.learn-more {
    display: none;
    position: relative;
    color: white;
    background-color: #82CCEC;
    text-align: center;
    padding-top: 45px;
    padding-bottom: 35px;
    .title {
        .extrabold(30px);
        margin: 0 10% 25px;
    }
    .text {
        .regular(18px);
        margin: 0 27% 40px;
    }
    .items {
        margin: 0 2%; 
        position: relative;
        min-height: 1px;
        .item {
            display: inline-block;
            margin: 15px;
            .circle {
                width: @circle-size;
                height: @circle-size;
                border: solid 3px white; 
                border-radius: 100%;
            }
            .splurge {
                position: absolute;
                width: @circle-size - @offset;
                margin-left: @offset / 2 - 2px;
                .bold(18px);
                margin-top: 60px;
            }
            &:nth-child(1){
                .splurge {
                    margin-top: 50px;
                }
            }
        }
    }
    .btn-cont {
        margin-top: 30px;
    }
    .close-button {
        position: absolute;
        background: none;
        border: none;
        right: 30px;
        top: 15px;
        color: white;
        .light(40px);
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
    }
}

@media (max-width: @screen-xs-max){
    .learn-more {
        .text {
            margin-left: 5%;
            margin-right: 5%;
        }
        .title {
            margin-top: 15px;
            margin-left: 5%;
            margin-right: 5%;
        }
        .close-button {
            right: 10px;
            top: 0;
        }
        .items {
            margin: 0;
        }
    }
}

@circle-size-xs: @circle-size - 30px;

@media (max-width: 425px){
    .learn-more .items .item {
        .circle {
            width: @circle-size-xs;
            height: @circle-size-xs;
        }
        .splurge {
            position: absolute;
            width: @circle-size-xs - @offset;
            .bold(14px);
            margin-top: 52px;
        }
        &:nth-child(1){
            .splurge {
                margin-top: 45px;
            }
        }
    }
}

@media (min-width: 861px) and (max-width: 1073px){
    .learn-more .items {
        margin: 0 12%; 
    }
}
