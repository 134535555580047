#legal {
    h1 {
        margin-bottom: 0;
        font-size: 34px;
        font-family: 'Tiempos Head Line';
        line-height: 1;
        color: inherit;
        letter-spacing: 2px;
        margin-bottom: 25px;
    }
    .hero-unit {
        margin: 25px 0;
        padding: 30px;
        font-family: 'Avenir Next Medium';
        h2 {
            color: #666;
            font-size: 20px;
            padding-left: 50px;
            margin: 30px 0 14px 0;
        }
        p, li {
            color: #666;
            font-size: 14px;
            padding: 0 65px;
            line-height: 30px;
        }
        ul {
            width: auto;
            padding: 0 40px;
            list-style-type: disc;
            list-style-position: inside;
        }
    }
    @media (max-width: @screen-xs-max) {
        .hero-unit {
            padding: 0px;
            h2 {
                padding-left: 10px;
            }
            li {
                padding: 0;
            }
            p {
                padding: 0 10px;
            }
        }
    }
}