#user-images {
    input[type=file] { margin-bottom: 1em; }
    img#preview { max-width: 100%; }

    .user-image {
        position: relative; display: inline-block;
        margin: 10px;
        background: lightgray;

        &.add-image-btn {
            text-align: center;
            vertical-align: middle;
            width: 200px;
            height: 200px;
            background: inherit;
            border: 5px dashed lightgray;
            border-radius: 5px;
        }

        span.status {
            z-index: 10;
            position: absolute;
            font-size: .8em;
            color: white;
            padding: .2em;
        }

        span.status-Pending { background: #9e9e9e; }
        span.status-Approved { background: #1aace9; }
        span.status-Rejected { background: red; }

        .avatar-icon {
            z-index: 20;
            position: absolute;
            bottom: 0;
            background-color: white;
            font-size: smaller;
            padding: .3em;
            margin: .5em;
            border-radius: 50%;
            width: 2em;
            height: 2em;
            text-align: center;
            color: grey;
            a { color: inherit; }
        }
        .remove-image {
            z-index: 40;
            position: absolute;
            right: 0;
            color: white;
            font-size: .8em;
            padding: .2em;
            span.glyphicon {
                text-shadow: 0px 0px 15px grey;
            }
        }
    }
}

#user-images, #trainer-wizard{
    @media (min-width: 411px) and (max-width: 767px) {
        #images-container{
            text-align: center;
        }
        .avpw_is_fullscreen #avpw_controls, #avpw_holder #avpw_controls {
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            transform: translate(-187.422px, 0px) scale(0.510204);
            margin-left: 5%;
        }
        #avpw_force_crop_sub_text, #avpw_force_crop_label {
            font-size: 30px;
        }
        .avpw_mode_action .avpw_button {
            position: absolute;
            top: 40%;
            margin-top: -18px;
            width: 100px;
            height: 50px;
            font-size: 30px;
            padding-top: 10px;
        }
    }


    @media (min-width: 321px) and (max-width: 410px) {
        #images-container{
            text-align: center;
        }
        .avpw_is_fullscreen #avpw_controls, #avpw_holder #avpw_controls {
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            transform: translate(-187.422px, 0px) scale(0.510204);
        }
        #avpw_force_crop_sub_text, #avpw_force_crop_label {
            font-size: 30px;
        }
        .avpw_mode_action .avpw_button {
            position: absolute;
            top: 40%;
            margin-top: -18px;
            width: 100px;
            height: 50px;
            font-size: 30px;
            padding-top: 10px;
        }
    }
    @media screen and (max-width: 321px) {
        #images-container{
            text-align: center;
        }
        .avpw_is_fullscreen #avpw_controls, #avpw_holder #avpw_controls {
            top: 5px;
            right: 5px;
            bottom: 5px;
            position: fixed;
            left: 50%;
            transform: translateX(-50%) scale(0.435374);
        }
        #avpw_force_crop_sub_text, #avpw_force_crop_label {
            font-size: 30px;
        }
        .avpw_mode_action .avpw_button {
            position: absolute;
            top: 40%;
            margin-top: -18px;
            width: 100px;
            height: 50px;
            font-size: 30px;
            padding-top: 10px;
        }
    }
}