@import (reference) 'colors.less';
@import (reference) "bootstrap_less/grid.less";

// booking pages
body {
    &.booking-flow {
        section#content {
            .trainer {
                @media (min-width: @screen-xs-max) {margin-bottom: 20px;}
                img.avatar { width: 100%; }
            }

            .session-info {
                p { margin: 0px; }
                .session-infobox {
                    background-color: @box-gray;
                    padding: 5px;
                    font-weight: 600;
                    .session-day { color: @cyan; }
                    .session-hours { color: @gray; }
                }
                .subs-infobox {
                    padding: 5px 0;
                    font-weight: 600;
                    font-size: 16px;
                    color: rgba(31,178,90,0.9098039215686274);
                }   
            }

            // STEP 1
            &.step-1 {
                .container { max-width: 620px; }
                .header { margin: 0px; }
                .price-widget {
                    margin-top: 15px;
                    margin-bottom: 0px;
                    table { table-layout:fixed; margin: 0px; }
                }
                .session-info {
                    border-top: 1px solid @light-gray;
                    border-bottom: 1px solid @light-gray;
                    @media (min-width: @screen-xs-max) {margin: 20px 0px 20px;}
                    @media (max-width: @screen-xs-max) {margin-bottom: 20px}
                    padding: 10px 0px;
                }
                .booking-it {
                    text-align: center;
                    p.or { margin: 5px 0px; }
                }
                #services-form {
                    span.error {
                        color: #a94442;
                    }
                }
                .money-back{
                    margin-top : 20px;
                    .money-back-steps{
                        text-decoration: none;
                        cursor: pointer;
                        padding-top: 15px;
                    }
                    .index-text-steps{
                        font-weight: 600;
                        font-size: 20px;
                        color: #337141;
                        margin: 0 2.5px 0 5.5px;
                    }
                    .question-icon{color:#7c8081}
                    img{width: 33px;margin-bottom: 5px;}
                }
                .index-text-steps:hover{
                    color:#1fb25be8;
                }
            }

            // STEP 2
            &.step-2 {
                .container { max-width: 1200px; }
                .order-payment {
                    margin-bottom: 20px;
                    background: @box-gray;
                    text-align: center;
                    table {
                        td.name { text-align: left; }
                        td.value { text-align: right; }
                        tr.total { border-top: 1px solid gray; }
                    }
                    .boxes-container {
                        margin-bottom: 20px;
                        .box {
                            //min-height: 130px;
                            padding: 1em;
                            border: 2px solid white;
                            &.order {
                                .promo-code {
                                    margin-top: 10px;
                                    text-align: left;
                                    input.enter-code { width: 100%; }
                                    input.submit-code {
                                        width: 100%;
                                        margin: 0px;
                                        font-size: 12px;
                                        padding: 5px;
                                    }
                                }
                            }
                            &.payment {
                                img.cards { padding: 10px; }
                                .card-info { text-align: left; }
                            }
                        }
                    }
                    .payment-security-info {
                        .logos { margin: 10px 0px; }
                    }
                }
                .money-back{
                    .money-back-steps{
                        text-decoration: none;
                        cursor: pointer;
                    }
                    .index-text-steps{
                        font-weight: 600;
                        font-size: 20px;
                        color: #337141;
                        margin: 0 2.5px 0 5.5px;
                    }
                    .question-icon{color:#7c8081}
                    img{width: 33px;margin-bottom: 5px;}
                    @media (max-width: @screen-xs-max) {margin-top: 20px;}
                }
                .index-text-steps:hover{
                    color:#1fb25be8;
                }
                .booking-it {
                    margin-bottom: 20px;
                    text-align: center;
                    .book-appt {
                        z-index: 100;
                        font-size: 1.3em;
                        padding: .5em .8em;
                        margin-top: 1em;
                        &.disabled {
                            opacity: 1;
                            color: white;
                            background: gray;
                        }
                    }
                    @media (max-width: @screen-xs-max) {
                        .book-appt {
                            width: 100%;
                            /*position: fixed;*/
                            border-radius: 0px;
                            left: 0;
                            right: 0;
                            bottom: 0
                        }
                    }
                }
            }

            // STEP 3
            &.step-3 {
                .book-message { text-align: center; }
                .footer { border-top: 1px solid @light-gray; }
            }
        }
    }

    // payment pages
    &.payment {
        section#content {
            padding: 30px 0px;
            .credit-cards { margin-bottom: 20px; }
            .alert { margin-bottom: 20px }
            input[type=submit] { margin: 10px; }
        }
    }

    #address-form label { font-weight: bold; }

}

.cc-plus {
    color: @cyan;
    font-size: 8em;
    line-height: 0.5em;
    font-weight: lighter;
}

#apple-pay-button {
    display: none;
    background-color: #dddddd;
    background-size: 100% 100%;
    background-origin: content-box;
    background-repeat: no-repeat;
    height: 50px;
    padding: 10px 0;
    margin-top: 1em;
    border-radius: 10px;
    width: 100%;    
    @media (min-width: @screen-lg-min) {    
        width: 46%;
    }
}

