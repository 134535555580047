#trainer_review_link{
	padding: 20px;
	text-align: center;
	min-height: 648px; 
	h2{
		font-weight: 700;
	}
	a{
		img{
			max-width: 120px;
    		margin: 15px;
		}
	}
	form{
		width: 700px;
    	margin: auto;
	}
	.checkbox{
		label{
			float:left; 
			margin-bottom: 15px;
		}
	}
	#thanks-review{
		display: none;
		h2 {
			padding-top: 150px;
		}
	}
	#alert{
		max-width: 700px;
    	margin: 50px auto;
	}
	@media screen{
	    @media (max-width: 767px){
	    	form{
				width: auto !important;
			}
			.modal-dialog{
				width: auto !important;
			}
	    }
	}
}

#best-trainers-reviews{
	.personal-trainer-reviews{
		margin: 50px 0;
	    padding: 0 100px;
	    text-align: center;
	    h2{
	    	margin: 50px;
	    }
	    span{
	    	font-size: 18px;
	    }
	    @media (max-width: @screen-xs-max){
			padding: 0 10px;
		}
	}
}