#clients-list{
	.info-account .info-account-body table tbody tr td, .info-account .info-account-body table thead tr th {
    	padding: 10px 15px;
	}
	.container{
		.clients-list{
			margin-top: 50px;
		}
	}
	span.badge{
		border-radius: 5px;
	}
	.btn-primary.details{
		width: 100px;
	}
}

#client-profile{
	.container{
		max-width: 900px;
	}
	.left-box{
		width:100%;
		float:none;
	}
	.account-header{
		span.badge{
			border-radius: 5px;
		}
		.account-second-row{
			.profile-header{
				float: left;
				p{
					margin-left: 20px;
					line-height: normal;
				}
				.remaining-sessions{
					p{
						float: left;
					}
					.circles{
						float: right;
    					margin-top: 2px;
    					margin-right: 40px;
    					.painted-circle, .empty-circle{
							width: 15px;
						    height: 15px;
						    -moz-border-radius: 50px;
						    -webkit-border-radius: 50px;
						    border-radius: 50px;
						    display: inline-block;
						}
						.painted-circle{
							background: #2ade75;
						}
						.empty-circle{
							background: #fff;
							border: 2px solid #c7c7c7;
						}
					}
				}
			}
			.account-messages-button {
    			margin-top: 40px;
			}
		}
	}
	span#profile_remaining_sessions{
		font-weight: normal;
	}
	.btn{
		font-size: 15px;
	}
	.show-mobile{
		display: none;
	}
}

#add-client, #edit-client{
	h2{
		font-weight: bold;
    	margin: 25px 0 25px 12px;
	}
	fieldset legend {
	    text-transform: uppercase;
	    font-weight: 700;
	    border-bottom: none;
	    font-size: 1.2em;
	}
	input[type="submit"]{
		margin: 30px 0;
    	min-width: 150px;
    	margin-left: 15px;
	}

	.second-column{
		fieldset legend{
			margin-left: 15px;
		}
	}

	.add-session{
		float: right;
    	margin-right: 30px;
    	margin-top: 30px;
	}

	.show-mobile{
		display: none;
	}

}

@media (min-width: 768px) and (max-width: 992px){
	/*.show-mobile{
		display: block !important;
	}
	.hide-mobile{
		display: none;
	}
	.save-client{
		float: left;
	}*/
	.info-account .info-account-body table tbody tr td.actions-column {
        .dropdown {
        	margin-top: 0 !important;
        }
    }
}

@media (max-width: 992px){
	.btn-primary.details{
		max-width: 50px;
		font-size: 10px;
	}
	.show-mobile{
		display: block !important;
	}
	.hide-mobile{
		display: none;
	}
	.save-client{
		float: left;
	}
	.info-account .info-account-body table tbody tr td.actions-column {
        .dropdown {
        	margin-top: 25px;
            display: block;
            float: left;
            .dropdown-menu{
            	padding-top: 15px;
            }
            form {
                [type=submit] {
                    background: white;
                    border: none;
                    width: 100%;
                    text-align: left;
                    padding: 20px 10px;
                    color: @gray;
                    &:hover {
                        background-color: #f5f5f5;
                    }
                }
            }
            .make-a-note{
            	padding: 20px 10px;
    			color: #484848;
            }
        }
        > .btn, > form {
            display: none;
        }
    }
    #client-profile .btn {
	    font-size: 10px;
	}
}

@media (max-width: 320px){
	#add-client input[type=submit], #edit-client input[type=submit] {
    	margin-left: 75px;
	}
	#add-client .add-session, #edit-client .add-session {
	    float: right;
	    margin-right: 70px;
	    margin-top: 0;
	    margin-bottom: 30px;
	}
}
