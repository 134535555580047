@import (reference) 'lesshat.less';
@import (reference) 'bootstrap_less/bootstrap.less';

@import (reference) 'colors.less';
@import (reference) 'fonts.less';

.why-a-trainer-three-boxes {
    .container-fluid();
    background-color: @light-gray;
    .items {
        .make-row();
        margin: 0 10%;
        .item-container {
            .make-md-column(4);
            margin: 50px 0;
            .item {
                padding: 35px 0;
                text-align: center;
                background-color: white;
                .title {
                    .bold(25px);
                    color: @dark-gray;
                    margin: 0 10%;
                }
                .links {
                    margin: 7% 5%;
                    .link-container {
                        text-align: center;
                        display: inline-block;
                        width: 50%;
                        margin: 5% 0;
                        padding: 0 5%;
                        .link {
                            max-width: 100%;
                            img {
                                max-width: 100%;
                                opacity: 0.6;
                            }
                        }
                    }
                }
                .text {
                    padding: 0 12.5%;
                    .regular(18px);
                    letter-spacing: 0.7px;
                }
                &.our-promise {
                    img {
                        width: inherit;
                        margin: 25px 0;
                    }
                }
            }
            &:nth-child(odd) {
                .item {
                    background-color: #F3F3F3;
                }
            }
            &:nth-child(even) {
                .item {
                    background-color: #6CC2E7;
                }
            }
        }
    }
}
@media (max-width: (@screen-lg-min + 200px)) {
    .why-a-trainer-three-boxes .items .item-container .item .text {
        .regular(16px);
    }
}
@media (max-width: (@screen-lg-min + 1px)) {
    .why-a-trainer-three-boxes .items {
        margin: 0;
        .item-container .item .text {
            .regular(14px);
        }
    }
}
@media (max-width: (@screen-md-min - 1px)) {
    .why-a-trainer-three-boxes .items {
        .item-container .item {
            .title {
                .bold(36px);
            }
            .text {
                .regular(22px);
            }
        }
    }
}
@media (max-width: (@screen-sm-min - 1px)) {
    .why-a-trainer-three-boxes .items .item-container .item {
        .title {
            .bold(30px);
        }
        .text {
            .regular(18px);
        }
    }
}
@media (max-width: 600px){
    .why-a-trainer-three-boxes .items .item-container .item .links .link-container {
        width: 100%;
    }
}
@media (min-width: 600px) and (max-width: 992px){
    .why-a-trainer-three-boxes .items .item-container .item .links .link-container img {
        width: 200px;
    }
}
@media (min-width: @screen-md-min){
    .why-a-trainer-three-boxes .items .item-container .item {
        height: 415px;
    }
}