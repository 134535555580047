@import (reference) 'bootstrap_less/bootstrap.less';
@import (reference) 'utils.less';

#scheduler_v2 {
    font-family: 'Avenir Next Medium';
    #scheduler {
        margin-bottom: 100px;
        .left-menu {
            background-color: #f7f7f7;
            ul {
                margin-top: 20px;
                li.title {
                    font-weight: bold;
                    font-size: 18px;
                }
                li.title.first {
                    padding: 1em 0;
                    /*margin: 0 -1em;*/
                    border-bottom: 1px solid #8080804d;
                }
                li.title.first a {
                    font-size: 15px;
                    /*margin-left: 50px;*/
                    text-decoration: underline;
                    color: #7acaf3;
                }
                li.item a {
                    color: #000000a8 !important;
                    font-weight: normal;
                    cursor: pointer;
                }
                a.redirect-profile {
                    float: right;
                    margin-right: 50px;
                }
            }
            ul.second-list {
                margin-top: 100px;
            }
            img.app-button {
                max-width: 150px;
                margin-top: 10px;
            }
        }
        /*spinner {
            margin-top: 10px;
        }*/
        #calendar {
            .spinner-row {
                min-height: 50px;
            }
            .fc-scroller {
                overflow-x: visible !important;
            }
            /*weird fullcalendar bug on dayclick*/
            li.item a {
                color: #000000 !important;
                font-weight: bold;
            }

            .fc td {
                border-color: rgb(226, 226, 226);
            }
        }
        .gcal,
        .ical {
            .btn-primary {
                margin-bottom: 15px;
                margin-top: 20px;
            }
        }
        .gcal {
            .gcal-form {
                text-align: center;
            }
        }
        .ical {
            textarea {
                border-color: #80808052;
                resize: none;
                padding: 10px;
            }
            p.error {
                margin-top: 10px;
                margin-bottom: 0;
            }
            p.second-p {
                margin-top: 10px;
            }
        }
        /*.menu-mobile {
            z-index: 4;
        }*/
    }
    .md-select-menu-container {
        z-index: 9000;
    }
    .btn-primary {
        border-color: #63bced !important;
        background-color: #63bced !important;
        font-weight: bold;
    }
    li.item a {
        color: #000000 !important;
        font-weight: bold;
    }
    .fc-widget-content {
        cursor: pointer;
    }
    .fc-state-default {
        color: white !important;
        background-color: #99a7a9 !important; //border-color: #99a7a9;
        border-color: rgb(235, 235, 235);
        background-image: none !important;
    }
    .error {
        color: rgba(255, 0, 0, 0.73) !important;
    }
    .success {
        color: rgba(0, 128, 0, 0.611764705882353) !important;
    }
    p.title {
        font-size: 18px;
        margin-top: 30px;
    }
    table {
        thead tr th {
            color: rgb(86, 198, 173);
            font-weight: bold; //padding: 1em 0 !important;
            border-color: white !important;
        }
        td.fc-head-container {
            border: none;
        }
        a.fc-time-grid-event {
            text-align: center;
            padding-top: 4px;
            border-radius: 0px; //width: 104%;
        }
        td.fc-time span {
            font-size: 12px;
            font-weight: 600;
            padding: 4px;
        }
        a.slot,
        a.slot:hover {
            width: 100%;
            cursor: pointer;
        }
        a.available-session,
        a.available-session:hover {
            color: #9c9c9c;
            border: 1px solid #e0e0e0;
            background-color: #dfdfdf;
        }
        a.unavailable-session,
        a.unavailable-session:hover,
        a.calendar-conflict-session,
        a.calendar-conflict-session:hover {
            color: white;
            border: 1px solid #DC143C;
            background-color: #DC143C;
        }
        a.booked-session,
        a.booked-session:hover {
            color: rgb(86, 198, 173);
            font-weight: bold;
            background-color: #94d8c7;
            border: 1px solid rgb(86, 198, 173);
            padding-top: 10px;
        }
        a.completed-session,
        a.completed-session:hover {
            color: #9c3aad;
            border: 1px solid rgba(156, 58, 173, 0.47843137254901963);
            background-color: rgba(156, 58, 173, 0.47843137254901963);
            padding-top: 10px;
        }
        .fc td {
            width: 102%;
            border-color: #e4e4e4 !important;
        }
        .fc-title {
            font-weight: 600;
            font-size: .85em !important;
        }
        .fc-time-grid-container {
            overflow-y: hidden !important;
        }
        /*tbody td.fc-today {
            background-color: white;
        }*/
    }
    .modal-dialog {
        width: 800px;
    }
    .modal-content {
        border-radius: 0;
    }
    .modal-body {
        h3 {
            text-align: center;
        }
    }
    .footer {
        text-align: center;
        .btn {
            display: block;
            border-radius: 0;
            margin: 10px auto;
            width: 200px;
        }
    }
    .date-picker {
        button.btn-default {
            border-color: rgba(204, 204, 204, 0.1411764705882353);
        }
        th {
            color: #303030;
        }
    }
    .modal-dialog {
        h3 {
            font-weight: bold;
        }
        .modal-body {
            width: 85%;
        }
        .form-control {
            border: 1px solid;
            border-color: rgba(177, 172, 172, 0.34);
        }
        .close-modal {
            position: absolute;
            font-weight: bold;
            cursor: pointer;
        }
        span {
            font-weight: bold;
        }
    }

    .gcal,
    .ical {
        margin-left: 50px;
        margin-top: 10px;
        h3 {
            font-weight: bold;
        }
        img {
            margin: 20px 0;
        }
    }

    .md-button.md-primary.md-raised {
        background-color: rgb(84, 183, 240);
        min-width: 40px;
        font-weight: bold;
    }

    .menu-mobile {
        background: white;
        border-radius: 4px;
        box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
        width: 256px;
        height: 80vh;
        overflow-y: scroll;
    }
    .menu-mobile-item {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 48px;
        padding: 0 16px;
        position: relative;
        transition: background 0.15s linear;
        width: auto;
        a {
            text-decoration: none;
            color: #434343;
        }
    }
    .menu-mobile-item:hover,
    .menu-mobile-item:focus {
        background-color: rgb(238, 238, 238);
    }
    .menu-mobile-item.selected {
        color: rgb(16, 108, 200);
    }
    .menu-mobile-item.title {
        font-weight: bold;
    }

    #recurring-table {
        line-height: 2em;
        padding-bottom: 4em;
        .day {
            border-bottom: 1px solid lightgray;
            padding: 1.2em 0em;
        }
        .header {
            font-weight: 600;
            font-size: 1.2em;
            color: gray;
        }
        .recurring-actions a:hover {
            cursor: pointer;
            text-decoration: none !important;
        }
    }

    .md-icon-button+.md-datepicker-input-container {
        margin-left: 0;
    }

    @media (min-width: @screen-md-min) {
        .left-menu {
            float: right;
            width: 70%;
            /*padding-top: 50px;*/
        }
        #calendar {
            margin: 50px;
            margin-right: 200px;
        }
        #recurring-table {
            margin: 70px;
        }
        .close-modal {
            right: -40px;
        }
        .search-box {
            margin-right: 180px;
        }
        .spinner-container-desk {
            min-height: 50px;
        }
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        .left-menu {
            width: 90%;
            margin: auto;
            ul {
                padding: 0 20px;
            }
        }
        #calendar {
            margin-top: 30px;
            padding: 0 15px;
        }
    }
    @media (max-width: @screen-xs-max) {
        /*.fc-toolbar .fc-right {
            margin: 20px 5px;
        }*/
        .fc-left {
            margin: 0 5px;
        }
        .fc-today-button {
            margin-left: 15px;
            height: 30px;
            width: 60px;
        }
        tr{
            height: 30px;
        }
        .modal-dialog {
            width: auto !important;
            .close-modal {
                right: 0;
                font-size: 30px;
            }
            .header {
                margin-top: 40px;
            }
        }
        /*.date-picker {
            width: 250px;
            margin-top: -3px auto auto auto;
            ul.dropdown-menu {
                width: 300px;
                table {
                    width: 100%;
                }
            }
            .input-group {
                width: 150px;
                float: left;
            }
            .fc-today-button {
                float: right;
            }
        }*/
        /*#calendar-day-picker {
            width: 50px;
            overflow: hidden;
            .md-datepicker-input-container{
                display: none;
            }
            .md-datepicker-button {
                margin-top: -5px;
            }
        }*/
        .ical,
        .gcal,
        #recurring-table {
            margin: 0 30px;
            .day-time {
                font-size: 12px;
            }
        }
        .day-title {
            color: #56c6ad;
            font-weight: 700;
            margin: 5px 20px;
        }
        .spinner-container-mobile {
            min-height: 50px;
            margin-bottom: 10px;
        }
        .fixed-date-bar {
            position: fixed;
            top: 0;
            width: 100%;
            background: white;
            z-index: 2;
            padding: 15px 0;
        }
    }
}

#booked-completed-modal,
#confirm_delete_modal {
    /*MODALS*/
    h3,
    h4 {
        font-weight: bold;
    }
    .modal-content {
        border-radius: 0;
    }
    .header {
        text-align: center;
    }
    .body {
        width: 80%;
        text-align: left;
    }
    .body p {
        text-align: left;
    }
    hr {
        border-top: 1px solid #8080805c;
    }
    .footer {
        display: block;
    }
    /*#confirm_delete_modal .footer{
        text-align: center;
    }*/
    input,
    select {
        border-radius: 0px;
    }
    .footer {
        .btn.btn-primary {
            background-color: #46aded;
            border-color: #46aded;
        }
        .btn.btn-yes {
            background-color: #5ce5c2;
            color: white;
            font-weight: bold;
        }
        .btn.right,
        .btn-yes {
            display: inline-block;
        }
        @media (min-width: @screen-md-min) {
            .btn.right {
                margin-left: 20px;
            }
        }
    }
    /*END MODALS*/
}

#delete-unavailable-modal {
    .body {
        text-align: center;
    }
}

#confirm_delete_modal {
    button {
        display: inline-block !important;
    }
}

#create-modal {
    /*width: 85%;*/
    label.left {
        float: left;
    }
    .row {
        margin-top: 20px;
    }
    input,
    select {
        border-radius: 0px;
    }
    input[type=radio] {
        margin-right: 5px;
    }
    /*.form-control{
        border: 1px solid;
        border-color: rgba(177, 172, 172, 0.34);
    }*/
    .footer {
        margin-top: 40px;
        button {
            float: right;
            width: 150px !important;
        }
    }
}

.ical,
.gcal {
    h3 {
        font-size: 20px;
    }
    hr {
        border-top: 1px solid #d4cbcb;
        margin: 40px 200px 40px 0;
    }
}