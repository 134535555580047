#press-section{
	text-align: center;
	padding-top: 30px;
	.title{
		color: @dark-gray;
       	.semibold(30px);
	}
	h2{
		margin: 50px 0;
	}
	.current-content{
		min-height: 200px;
		.active-press-description{
			font-size: 30px;
		    color: #45b9ea;
		    font-weight: 700;
		    max-width: 800px;
		    margin: 50px auto 30px auto;
		    min-height: 84px;
		}
		#active-press{
			
		}
	}
	.press-row{
		border-bottom: 0.5px solid #d4d4d4;
		margin-top: 30px;
	}
	.column-press{
		padding: 0;
		cursor: pointer;
		height: 136px;
		background-color: #f3f3f3;
		border-top: 0.5px solid #d4d4d4;
		border-right: 0.5px solid #d4d4d4;
	}
	.press-img{
		margin: 50px 0;
	}
	.current-press{
		background-color: #93d0ea;
	}
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	#press-section{
		.current-content{
			.active-press-description{
			    max-width: 700px;
			}
		}
	}
}


@media (max-width: @screen-xs-max) {
	#press-section{
		.press-img{
			margin: 35px 0;
			max-width: 100px;
		}
		.column-press{
			height: 100px;
		}
		.current-content{
			min-height: 150px;
			.active-press-description{
				font-size: 20px;
			    max-width: 400px;
			    margin: 30px auto 30px;
			    min-height: 56px;
			}
		}
	}
}