@import (reference) 'utils.less';
@import (reference) "bootstrap_less/grid.less";

.prevents{
    font-family: 'Avenir Next Medium';
    color:black;
    .primary-home-btn{
        font-size: 18px;
        padding: 10.5px 24px;
    }
    .home-btn-second {
        border: 2.5px solid #36B6EC;
        border-radius: 2.5px;
        padding: 8px 15px;
    }
    .prevents_and_treat {
        background-color: #F4F7F8;
        padding: 50px 0;
        h1 {
            font-size: 45px;
            font-weight: 700;
            margin-top: 0;
            line-height: 50px;
            color: #345af5;
            margin-top: 50px;
            margin-bottom: 0;
        }
        h3 {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: black;
        }
    }
    .lets_beat {
        .title {
            text-align: center;
            margin-top: 81px;
            h2 {
                font-size: 34px;
                font-weight: 700;
                margin-top: 0;
                line-height: 45px;
                margin-bottom: 80px;
            }
        }
        .content {
            h3 {
                font-size: 27.5px;
                font-weight: 700;
                margin-bottom: 24px;
            }
            h4 {
                font-size: 19px;
                margin-bottom: 0;
                line-height: 27.5px;
            }
            p {
                color: #666;
                font-size: 15px;
                letter-spacing: 0.75px;
                font-weight: 800;
                margin: 10px 0;
            }
            ul {
                list-style-type: disc;
                color : rgb(102, 102, 102);
                -webkit-padding-start: 24px;
                li {
                    font-size: 17.5px;
                    margin-bottom: 8px;
                }
            }
            .ace {
                margin-top: 24px;
                font-size: 15px;
                letter-spacing: 0.5px;
                img {    
                    margin-top: 12.5px;
                }
            }
        }
        .invest {
            text-align: center;
            margin: 40px 0;
            h2 {
                font-size: 27.5px;
                font-weight: 700;
                margin-bottom: 40px;
            }
        }
    }
    .prevents_reviews {
        background-color: #F4F7F8;
        .title {
            text-align: center;
            margin-top: 81px;
            h2 {
                font-size: 34px;
                font-weight: 700;
                margin-top: 0;
                line-height: 45px;
                margin-bottom: 50px;
            }
        }
        .carousel {
            margin-bottom: 60px;
            #quote-carousel {
                padding: 30px 0;
            }
            .carousel-inner-prevents {
                width: 90%;
                margin-left: 5%;
            }
            .item-prevents {
                background: white;
                padding: 50px 0;
                min-height: 324px;
            }
            /* Changes the position of the indicators */
            #quote-carousel .carousel-indicators {
                right: 50%;
                top: auto;
                bottom: -24px;
                margin-right: -19px;
            }
            /* Changes the color of the indicators */
            #quote-carousel .carousel-indicators li {
                background: #c0c0c0;
            }
            #quote-carousel .carousel-indicators .active {
                background: #333333;
            }
            #quote-carousel .carousel-control {
                background: 0 0;
                color: #222;
                font-size: 2.3em;
                text-shadow: none;
                margin-top: 150px;
                width: 5%;
            }
            #quote-carousel .carousel-control.left {
                left: -30px;
            }
            #quote-carousel .carousel-control.right {
                right: -30px !important;
            }
            .item blockquote {
                border-left: none; 
                margin: 0;
            }
            .reviewer_name {
                color: rgb(52, 90, 245);
                font-size: 15px;
                font-weight: 800;
                letter-spacing: 0.5px;
                margin-top: 24px;
            }
            @media (min-width: 768px) { 
                #quote-carousel {
                    margin-bottom: 0;
                    padding: 30px 0;
                }
            }
        }
    }
}
@media screen{
    @media (max-width: @screen-xs-max){
        .prevents img {
            width: 100%;
            height: 300px;
            object-fit: contain;
        }
        .prevents .prevents_and_treat h2 {
            font-size: 37.5px;
            line-height: 45px;
            margin-top: 24px;
        }
        .home-btn {
            border: 2px solid #36B6EC;
            border-radius: 2.5px;
            padding: 9px 19px;
            font-size: 17.5px;
            font-weight: 600;
        }
        .content {
            margin-right: 15px;
            margin-left: 15px;
        }
        .prevents .lets_beat .title {
            margin-top: 30px;
        }
        .prevents .lets_beat .title h2 {
            font-size: 24px;
            line-height: 40px;
            margin-bottom: 34px;
        }
        .prevents .lets_beat .content h4 {
            font-size: 17.5px;
        }
        .prevents .lets_beat .content .ace {
            font-size: 15px;
            margin-bottom: 24px;
        }
        .prevents .lets_beat .ace img {
            margin-top: 75% !important;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        .prevents .lets_beat .invest h2 {
            font-size: 24px;
        }
        .prevents .prevents_reviews .title h2 {
            font-size: 30px;
            margin-bottom: 30px;
        }
        .prevents .prevents_reviews .carousel .item-prevents {
            padding: 0 0 24px;
        }
        .prevents .prevents_reviews .carousel .item-prevents img {
            width: 80%;
            height: 200px;        
        }
        .prevents .prevents_reviews .carousel #quote-carousel .carousel-control {
            margin-top: 340px;
        }
        .prevents .prevents_reviews .carousel #quote-carousel .carousel-control.right {
            right: -24px!important;
        }
    }
    @media (max-width: @screen-md-max){
        .prevents .lets_beat .ace img {
            margin: 15% 25% 25%!important;
            width: 70%;
            height: 50%;
            object-fit: contain;
        }
    }
    @media (min-width: @screen-xs-max) and (max-width: @screen-md-min) {
        .prevents .lets_beat .ace img {
            margin: 5% 25% 25%!important;
            width: 50%;
            height: 50%;
            object-fit: contain;
        }
    }
}