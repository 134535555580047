@import (reference) 'colors.less';
@import (reference) 'fonts.less';


.newsletter{
	background: @dark-gray;
	color:#fff;
	text-align: center;
	padding: 10px 0 25px;
    z-index: 10001;
    position: relative;
	.form-inline {
        > * {
            margin: 3px 2px;
        }
        .form-group{
            width:100%;
            input[type=email] {
                width: 100%;
                text-align: center;
                .placeholder(#ccc);
            }
            .form-control {
                .border-radius(3px);
                border:none;
            }
        }
    }
    .btn-lg {
        display: block;
        width: 100%;
        color: lighten(@dark-gray, 10%);
        &:hover {
            color: @dark-gray;
        }
        &:disabled {
            color: lighten(@dark-gray, 50%);
        }
    }
    h2 {
        .bold(30px);
        margin-bottom: 25px;
    }
}

@media screen{
    @media (min-width:768px){
        .newsletter{
            .form-inline .form-group{
                width:50%;
            }
            .btn-lg{
                display: inline-block;
                width: auto;
            }
        }
    }
}