@import (reference) 'colors.less';
@import (reference) 'utils.less';

@color-border: #E8E8E8;

#pricing {
    .pricing-back-gray {
        background-color: #F4F6F8;
    }
    .header-pricing {
        text-align: center;
        padding-top: 30px;
        h1 {
            color: #000000;
            font-family: 'Tiempos Head Line';
            font-size: 46px;
            font-weight: 600;
            letter-spacing: 0.72px;
            line-height: 60px;
        }
        h2.subtitle {
            color: #575459;
            font-family: 'Avenir Next Medium';
            font-size: 21px;
            font-weight: 400;
            letter-spacing: 0.67px;
            line-height: 32px;
        }
    }
    .pricing-boxes {
        margin-bottom: 2em;
        .pricing-box-container {
            &.shadow {.box-shadow(0px 0px 20px gray);}
            text-align: center;
            float: none;
            display: inline-block;
            vertical-align: top;
            margin: 1em 0em;
            padding: 0em 0em;
            .box {
                .title {
                    padding: .24em;
                    h3 {
                        color: #FFFFFF;
                        font-family: 'Avenir Next Medium';
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0.28px;
                        line-height: 22px;
                        text-align: center;
                    }
                }
                .price {
                    background: white;
                    padding: .5em;
                    h2 {
                        color: #545156;
                        font-family: 'Avenir Next Medium';
                        font-size: 24px;
                        font-weight: 500;
                        letter-spacing: 0.38px;
                        line-height: 29px;
                        text-align: center;
                    }
                    h4 {
                        color: #545156;
                        font-family: 'Avenir Next Medium';
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0.28px;
                        line-height: 22px;
                        text-align: center;
                        margin-bottom: 30px;
                    }
                }
                .epigraph {
                    padding: .5em;
                    background-color: #F1F1F1;
                    font-size: 14px;
                    font-weight: 600;
                    p { margin: 0 }
                    .btn{
                        width: 60%;
                        margin: 20px;
                        font-size: 15px;
                    }
                }
                .stripe {
                    height: 2em;
                }
                .items {
                    background: white;
                    ul {
                        margin: 0;
                        li {
                            font-size: 12px;
                            text-align: left;
                            border-top: 1px solid @color-border;
                            padding: 10px 10px 10px 15px;
                        }
                    }
                }
            }
            &.pricing-fyt-difference {
                border: 1px solid #eee;
                .title {
                    margin: 1em;
                }
                .line-spacer {
                    margin: 1em;
                    border-bottom: 1px solid lightgray;
                }
                .content {
                    margin: 1em;
                    line-height: 1.5em;
                    text-align: left;
                }
                .stripe {
                    height: .8em;
                    background: darken(@cyan, 10%);
                }
                p { margin: 1em; }
                @media screen and (min-width: 480px) {
                    margin-right: 2em;
                }
            }
        }
    }
    .pricing-last {
        color: #545156;
        font-family: 'Avenir Next Medium';
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.28px;
        line-height: 30px;
        text-align: center;
        padding: 24px 0 40px;
    }
    .bottom-div {
        text-align: center;
        h4 {
            margin-top: 24px;
            padding: 0 10%;
        }
        .find-your-match-button {
            background-color: #5340FF;
            border-radius: 5px;
            height: 52.5px;
            color: #FFF;
            font-family: 'Avenir Next Medium';
            font-size: 17.5px;
            font-weight: 700;
            letter-spacing: 0.8px;
            line-height: 24px;
            width: 190px;
            text-align: center;
            margin-bottom: 24px;
            border: 0;
        }
    }
}

.included-in-every-plan {
    padding: 30px 0;
    h2 {
        color: #000000;
        font-family: 'Tiempos Head Line';
        font-size: 46px;
        font-weight: 600;
        letter-spacing: 0.72px;
        line-height: 60px;
        text-align: center;
    }
    .included-container {
        margin: 50px 0;
        .included-item {
            display: flex;
            align-items: center;
            padding: 15px 0;
            img {
                height: 30px;
                width: 30px;
            }
            p {
                color: #545156;
                font-family: 'Avenir Next Medium';
                font-size: 19px;
                font-weight: 400;
                letter-spacing: 0.28px;
                line-height: 30px;
                margin: 0 0 0 10px;
            }
        }
    }
    .unused-sessions-are {
        color: #545156;
        font-family: 'Avenir Next Medium';
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.28px;
        line-height: 30px;
        text-align: center;
    }
    .bottom-div {
        text-align: center;
        .find-your-match-button {
            background-color: #5340FF;
            border-radius: 5px;
            height: 52.5px;
            color: #FFF;
            font-family: 'Avenir Next Medium';
            font-size: 17.5px;
            font-weight: 700;
            letter-spacing: 0.8px;
            line-height: 24px;
            width: 190px;
            text-align: center;
            margin-bottom: 24px;
            border: 0;
        }
    }
}

.the-fyt-guarantee {
    padding: 30px 0;
    h2 {
        color: #000000;
        font-family: 'Tiempos Head Line';
        font-size: 46px;
        font-weight: 600;
        letter-spacing: 0.72px;
        line-height: 60px;
        text-align: center;
        margin-bottom: 24px;
    }
    h4 {
        color: #545156;
        font-family: 'Avenir Next Medium';
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.28px;
        line-height: 30px;
        text-align: center;
    }
    div {
        text-align: center;
        .yotpo-review-avg {
            display: inline;
            margin-right: 8px;
        }
        .yotpo-review-avg:first-child {
            vertical-align: super;
        }
        .reviews {
            color: #545156;
            font-family: 'Avenir Next Medium';
            font-size: 19px;
            letter-spacing: 0.28px;
            line-height: 50px;
        }
        a {
            color: #3FA2F7;
            font-family: 'Avenir Next Medium';
            font-size: 18px;
            letter-spacing: 0.28px;
            line-height: 50px;
            margin-left: 10px;
        }
    }
    h3 {
        color: #545156;
        font-family: 'Avenir Next Medium';
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0.44px;
        line-height: 40px;
        text-align: center;
        margin: 50px 0 10px;
    }
    h5 {
        color: #545156;
        font-family: 'Avenir Next Medium';
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.28px;
        line-height: 50px;
        text-align: center;
    }
}

.search-top-trainers, .search-bottom-trainers{
    background-color: #635e5e;
    text-align: center;
    padding-top: 30px;
    position: relative;
    @media screen and (max-width: @screen-xs-max) {
            z-index: 0;
        }
    h2{
        font-family: inherit;
        font-size: 36px;
        font-weight: 400;
        margin: 0 auto;
        color: white;
        padding: 5px 0;
    }
    h3{
        color: white;
        padding: 0 30px;
    }
    .locate-group{
        position: relative;
        background: none;
    }
}

@media screen{
    @media (max-width:@screen-xs-min) {
        #pricing { 
            .header-pricing {
                text-align: center;
                padding-top: 24px;
                margin: 0 8px;
            }
            .pricing-boxes {
                margin-bottom: 2em;
                margin: 0 15px;
            }
            .pricing-last {
                padding: 24px 10px 40px;
            }
            .included-in-every-plan .unused-sessions-are {
                margin: 0 15px;
            }
            .the-fyt-guarantee {
                padding: 30px 10px;
            }
        }
    }
    @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
        #pricing { 
            .header-pricing {
                text-align: center;
                padding-top: 24px;
                margin: 0 8px;
            }
            .pricing-boxes {
                margin-bottom: 2em;
                margin: 0 15px;
            }
            .pricing-last {
                padding: 24px 10px 40px;
            }
            .included-in-every-plan .unused-sessions-are {
                margin: 0 15px;
            }
            .the-fyt-guarantee {
                padding: 30px 10px;
            }
        }
    }
    @media (min-width: @screen-xs-max) and (max-width: @screen-sm-max) {
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    }
}