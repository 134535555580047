@import (reference) 'bootstrap_less/bootstrap.less';
@import (reference) 'fonts.less';

.modal-backdrop {
    // TODO: can't understand why this tweak was needed
    height: 100%;
    position: fixed;
}

#gym-submission, #thanks {
    .modal-content {
        background: #fff;
        //width: 100%;
    }
    .modal-header {
        border: none;
        background: none;        
    }
    .close {
        color: #333;
        font-weight: 600;
        right: -4px;
        position: absolute;
        font-size: 16px;
    }
}


#thanks {
    .modal-body {
        text-align: center;
    }
    .close {
        position: relative;
    }
}
 
#gym-submission {
    .form-error-msg {
      color: #DF4C4C;
      display: none;
      text-align: center;
      margin: 12px 0px;
      font-weight: 600; 
    }
    .title {
      width: 100%;
      text-align: center;
      h3 {
        font-size: 25px;
        font-weight: 700;        
      }
      p {}
    }
    h1 {
        text-align: center;
        color: #F4F4F4;
        font-size: 27px;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 30px;
    }
    form  {
      input[type="text"], input[type="email"], input[type="tel"] {
          margin-left: 35px;
          width: 498px;
          padding: 0 0 0 15px;
          margin-bottom: 15px;
          height: 38px;
      }
      textarea {
          margin-left: 35px;
          height: 150px;
          width: 440px;
          padding: 10px 0 0 12px;
      }
      .btn-submit, .btn-ok {
          border: none;
          width: 150px;
      }
      label {
          width: 100%;
          font-weight: 600;
          margin-left: 35px;
      }
      input.short-input{
          width: 140px;
      }
      input[id=phone] {
        width: 44%;
      }
      input[id=how_user_found_us] {
        margin-left: 20px; 
        width: 44%;
      }
    }
}


@media screen{
    @media (min-width: 0) and (max-width: 480px){
        
      #gym-submission {
          .modal-body {
            display: inline;
          }
          .form-error-msg {
            color: #DF4C4C;
            display: none;
            text-align: center;
            margin: 12px 0px;
            font-weight: 600; 
          }
          .title {
            text-align: center;
            h3 {
              font-size: 16px;
              font-weight: 700;
              margin-top: 0px;        
            }
          }
          h1 {
              text-align: center;
              color: #F4F4F4;
              font-size: 27px;
              font-weight: 400;
              line-height: 32px;
              margin-bottom: 30px;
          }
          form  {
            input[type="text"], input[type="email"], input[type="tel"] {
                margin: 0px 20px;
                width: 85%;
                padding: 0 0 0 15px;
                margin-bottom: 10px;
                height: 30px;
            }
            textarea {
                margin-left: 35px;
                height: 150px;
                width: 85%;
                padding: 10px 0 0 12px;
            }
            .btn-submit, .btn-ok {
                border: none;
                width: 150px;
                margin: auto;
            }
            label {
                display: none;              
            }
            input.short-input{
                width: 38%;
                margin-right: 0px;
            }
            input[id=phone] {
              width: 85%;
            }
            input[id=how_user_found_us] {
              width: 85%;
            }
          }
      }
    }
}