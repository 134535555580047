@import (reference) 'colors.less';
@import (reference) 'bootstrap_less/grid.less';

.modal-body-index{
    margin: 0 !important; 
}

.box.modal-pricing {
    padding: 10px 15px 10px 10px;
}

.session-widget {
    color: @text-grey;
    font-size: 14px;
    p { margin: 0px; }

    &.whitebox {
        background: white;
        border: 1px solid @light-gray;
        padding: 15px;
        .session-box { width: 100%; }
    }

    .session-box {
        &.no-padding{
            padding: 0px;
        }
        vertical-align: middle;
        margin-bottom: 15px;
        .session-header {
            img.icon {
                height: 14px;
            }
            span.name {
                font-weight: 600;
                font-size: 14px;
                color: @gray;
            }
            span.help {}
            @media screen and (max-width: @screen-md-max) {
                img.icon{ height: 12px; }
                span.name { font-size: 12px; }
            }
        }
        .session-header-new {
            text-align: center;
            img.icon {
                vertical-align: baseline;
                height: 14px;
            }
            span.name {
                font-weight: bold;
                font-size: 16px;
                color: @gray;
            }
        }
        p.session-description {}
        p.session-price {
            span {
                color: @cyan;
                font-size: 20px;
                font-weight: 700;
            }
        }
        .choose-header{
            text-align: center;
            span.choose {
                font-weight: 600;
                font-size: 11.5px;
            }
            .glyphicon-question-sign {}
        }
        .grid-subscription{
            display:grid;
            grid-template-areas: "session price"
                                   "discount per";
        }
        .session-cant-header{
            grid-area:session;
            span{
                font-weight: 650;
                font-size: 14px;
                float: left;
                color: #484848;
            }
        }
        .price-header{
            grid-area :price;
            span.session-price {
                float:right;
                color: @cyan;
                font-size: 20px;
                font-weight: 700;
            }
        }
        .discount-header{
            grid-area : discount;
            margin-top: -12px;
            span{
                font-weight: 600;
                font-size: 11.5px;
            }
        }
        .per-session-header{
            grid-area: per;
            margin-top: -5px;
            span{
                float:right;
                font-weight: 550;
                font-size: 11.5px;
            }
        }
        .hr-header {
            margin-top: 8px;
            margin-bottom: 10px;
        }
    }

    .partner-header{
            color:#337AC4;
            font-weight: 600;
            font-size: 10.75px;
            margin-right: 2px;
    }

    .session-box:last-child { margin: 0px; }

    @media screen and (max-width: @screen-xs-max) {
        font-size: 12px;
        padding: 0em 2.75em;
        .choose-header{text-align:center;}
    }
}

.share-calendat-container {
    margin-top: 15px;
}
.content-list-pricing{
    text-align: -webkit-auto;
    ul{
        li{
            margin-bottom: 0.5em;
            font-size: 1.1em;
        }
    }
}
.modal-pricing{background-color: #f5f5f5}
.model-title{
    text-align: center;
    margin: 30px;
    img.icon {
        vertical-align: baseline;
        height: 24px;
    }
    b {
        font-weight: 600;
        font-size: 20px;
        color: @gray;
    }
    h2{
        b{
            font-weight: 800;
            font-size: 25px;
        }
    }
}
.content-list-sessions{
    margin-left: -30px !important;
    ul {
        text-align:center;
        li{color:black}
    }
}
.title-widget-mobile{
    font-weight: 700;
    font-size: 16px;
    color: #484848;
    text-align: center;
    img.icon {
        vertical-align: baseline;
        height: 14px;
    }
}
.partner-header-mobile{text-align: center;}

.small-group-trainig{
    margin: 15px 0 10px !important;
}

.partner-training{
    margin: 15px !important;
}

@media screen and (max-width: @screen-xs-max) {
    .modal-body-index{
        margin: 0 !important;
    }
    .pricing-box-container-index{
        margin: 0 !important;
    }
    .content-list-pricing{
        text-align: -webkit-auto;
        margin-left: -2em;
    }
    .model-title{
        img.icon {
            margin-left: -1em;
        }
        h2{
            b{
                display: inline-block;
            }
        }
    }
    .content-list-sessions{
        margin-left: 0 !important;
        ul {
            li{margin-left:-3em;}
        }
    }

    .small-group-trainig, .partner-training{
        .icon{
            height: 20px !important;
        }
    }
}
