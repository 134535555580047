@import (reference) 'bootstrap_less/bootstrap.less';

.survey-form-title {
	text-align: center;
	width: 700px;
	margin: 30px auto;
}
#survey-trainer {
	width: 450px;
	max-width: 100%;
	margin: 0 auto 20px auto;
	label {
		font-size: 14px;
		font-weight: bold;
		line-height: 20px;
		color: #555;
		display: block;
		margin-bottom: 5px;
	}
    .single_inputs {
    	width: 213px;
    }
    label[for=id_send_partners_notification]{
    	float: left;
    }
    #id_send_partners_notification {
    	margin-left: 10px;
    }
	textarea {
        width: 430px;
    }
    input, textarea {
    	margin-bottom: 10px;
    }
	[type=submit] {
		margin: 0 auto;
		display: table;
	}
}

@media (min-width: @screen-sm-min) {
	#survey-trainer {
		.single_inputs {
	    	width: 208px;
	    	margin-right: 5px;
	    }
	}
}

@media (max-width: @screen-xs-max) {
	.survey-form-title {
		width: 90%;
	}
    #survey-trainer {
		width: 80%;
		.single_inputs {
			width: 100%;
		}
		textarea {
	        width: 100%;
	    }
	}
}