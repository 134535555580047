.total-gcal {
	margin-top: 10px;
    margin-left: -30px;
}
.parent-hero{
    position: relative;
    color:#fff;
    text-align: center;
    background-size: cover;
    padding: 25px 0 200px;
}
@font-face {
    font-family: 'Tiempos Head Line';
    src: url('../fonts/TiemposHeadlineWeb-Semibold.eot');
    src: url('../fonts/TiemposHeadlineWeb-Semibold.woff') format('woff'),
         url('../fonts/TiemposHeadlineWeb-Semibold.woff2') format('woff2');
    font-weight:normal;
    font-style:normal;
    font-display: swap;
}
@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-Regular.woff2') format('woff2'),
        url('AvenirNext-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNext-Bold.woff2') format('woff2'),
        url('../fonts/AvenirNext-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNext-BoldItalic.woff2') format('woff2'),
        url('../fonts/AvenirNext-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Avenir Next Demi';
    src: url('../fonts/AvenirNext-DemiBold.woff2') format('woff2'),
        url('../fonts/AvenirNext-DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Avenir Next Demi';
    src: url('../fonts/AvenirNext-DemiBoldItalic.woff2') format('woff2'),
        url('../fonts/AvenirNext-DemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNext-Italic.woff2') format('woff2'),
        url('../fonts/AvenirNext-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Avenir Next Medium';
    src: url('../fonts/AvenirNext-Medium.woff2') format('woff2'),
        url('../fonts/AvenirNext-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Avenir Next Medium Italic';
    src: url('../fonts/AvenirNext-MediumItalic.woff2') format('woff2'),
        url('../fonts/AvenirNext-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}