#monthly-sweepstakes {
    @media (min-width: @screen-sm-min) {
        background-color: #46aded4d;
        #main {
            padding-top: 20px;
            footer {
                background-color: white;
            }
        }
    }
}

body#dropbox {
    .hero {
        background: url("../img/dropbox/hero-01.jpg") center 0 no-repeat;
        background-size: cover;
        z-index: -1;
        #brands {
            margin: auto;
            background-color: rgba(255, 255, 255, 0.6);
            img#dropbox {
                background: none;
                width: 100%;
            }
            img#exubrancy {
                width: 100%;
            }
            .plus-sign {
                font-size: 60px;
                color: black;
                display: inline-block;
                font-weight: bold;
            }
        }
        h3 {
            margin-top: 50px;
        }
    }
    section#trainers {
        background-color: white;
    }
    @media (min-width: @screen-md-min) {
        #brands {
            width: 500px;
        }
    }
    @media (min-width: @screen-sm-min) {
        .hero {
            padding: 120px;
            #brands {
                padding: 30px 0;
                .plus-sign {
                    margin-top: 50px;
                }
            }
        }
    }
    @media (max-width: @screen-xs-max) {
        #brands {
            @media (min-width: 321px) {
                width: 300px;
            }
            padding: 30px 0;
            .col-xs-2 {
                padding: 0;
            }
            img {
                display: inline-block;
                &#dropbox {
                    width: 100px;
                }
                &#exubrancy {
                    width: 120px;
                }
            }
        }
        .plus-sign {
            margin-top: 5px;
        }
    }
}

#info {
    .row{
        margin-top: 50px;
        text-align: center;
        h1, h2{
            font-size: 30px;
            font-weight: bold;
        }
    }
    form#requestNewGym {
        .form-group {
            width: 200px;
            margin: 10px auto;
        }
    }
    form#contact-us {
        width: 500px;
        margin: 20px auto;
        label {
            float: left;
        }
        input {
            width: 100%;
            height: 35px;
        }
        textarea {
            width: 100%;
        }
    }
    @media (max-width: @screen-xs-max) {
        form#contact-us {
            width: 90%;
        }
        p {
            padding: 0 12px;
        }
    }
}

#social-landing-page {
    .first-section {
        padding: 55px 0;
    }
    .second-section {
        padding: 55px 0;
        background-color: #ECEFF3;
    }
    .btn-fyt-social {
        background-color: #5340FF;
        border-radius: 6px;
        padding: 10px 20px;
        height: 47px;
        color: #FFFFFF;
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 0.48px;
        line-height: 23px;
        text-align: center;
        margin-top: 10px;
        &:hover {
            background-color: #5a48ff;
            text-decoration: underline #ffffff;
        }
    }
    .bg-light {
        background-color: #ECEFF3;
    }
    .bg-darkish {
        background-color: #1D2835;
    }
    h1 {
        color: #1D2835;
        font-size: 46px;
        font-family: 'Tiempos Head Line';
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 52px;
    }
    h2 {
        color: #1D2835;
        font-family: 'Tiempos Head Line';
        font-size: 36px;
        letter-spacing: 0.56px;
        line-height: 45px;
        text-align: left;
        margin-bottom: 20px;
    }
    p.--subtitle {
        color: #1D2835;
        font-size: 21px;
        margin-top: 23px;
    }
    p.sections-text {
        color: #1D2835;
        font-size: 16px;
        letter-spacing: 0.47px;
        line-height: 24px;
        width: 487px;
        text-align: left;
        margin-bottom: 20px;
    }
    img.--fyt-logos {
        height: 48px;
        width: auto;
    }
    .fyt-logo {
        height: 53px;
        width: auto;
        margin-bottom: 20px;
    }
    .head-img-div {
        height: 230px;
        width: 303px;
        display: flex;
        justify-content: center;
        align-items: center;
        #first-img {
            height: 100%;
            width: 100%;
        }
        #second-img {
            height: 80%;
            width: auto;
        }
    }
    .sub-text-div {
        text-align: center;
        width: 303px;
        background-color: #ffffff;
        padding-bottom: 20px;
        img {
            height: 40px;
            width: auto;
            margin-top: 30px;
        }
        h3 {
            font-size: 22px;
            font-weight: bold;
            color: #1D2835;
        }
        p {
            color: #545156;
            font-size: 14px;
            letter-spacing: 0.41px;
            line-height: 24px;
        }
    }
    .adjust-padding {
        padding: 0 5%;
    }
    .col-md-3 {
        padding: 45px 15px 45px 0;
    }
    .align-items-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .fyt-promise-item {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        img {
            height: 83px;
            width: auto;
        }
        p {
            margin: 0 12px;
        }
    }
    .nopr {
        padding-right: 0;
    }  
    .nopl {
        padding-left: 0;
    }
    .bottom-section {
        position: relative;
        text-align: center;
        .text-centered-oimg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            h1 {
                color: #ffffff;
            }
            p.--subtitle {
                color: #ffffff;
            }
            .btn-fyt-social {
                width: 295px;
                margin: 20px;
            }
        }
    }
    @media (max-width: @screen-xs-max) {
        .fyt-logo {
            display: none;
        }
        .col-md-3 {
            padding: 0 15px;
        }
        h1 {
            text-align: center;
            letter-spacing: 0.47px;
            line-height: 36px;
            font-size: 30px;
            margin-top: 0;
        }
        p.--subtitle {
            font-size: 16px;
            text-align: center;
            line-height: 28px;
            width: 342px;
            margin: 20px auto;
        }
        .head-img-div {
            width: 100%;
            #first-img {
                width: auto;
            }
        }
        .sub-text-div {
            width: 100%;
            margin-bottom: 40px;
        }
        .bg-darkish {
            margin-left: auto;
            margin-right: auto;
        }
        .bg-light {
            flex-direction: column;
        }
        .main-text-hero {
            padding: 5% 0;
        }
        .align-items-center {
            flex-direction: column;
            text-align: center;
            .btn-fyt-social {
                width: 100%;
            }
        }
        h2 {
            font-size: 30px;
            letter-spacing: 0.47px;
            line-height: 36px;
            text-align: center;
            width: 352px;
            margin: 20px auto;
        }
        p.sections-text {
            line-height: 28px;
            text-align: center;
            width: 352px;
        }
        .adjust-padding {
            padding: 20px 0;
        }
        .column-reverse-xs {
            flex-direction: column-reverse;
        }
        .fyt-promise-item {
            flex-direction: column;
        }
        .nopl {
            padding-left: 15px;
        }
        .nopr {
            padding-right: 15px;
        }
        .bottom-section {
            .text-centered-oimg {
                top: 60%;
                .btn-fyt-social {
                    margin: 10px;
                }
            }
        }
    }
}

#social-landing-page-blue365 {
    .first-section {
        padding: 55px 0;
    }
    .second-section {
        padding: 55px 0;
        background-color: #ECEFF3;
    }
    .btn-fyt-social {
        background-color: #5340FF;
        border-radius: 6px;
        padding: 10px 20px;
        height: 47px;
        color: #FFFFFF;
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 0.48px;
        line-height: 23px;
        text-align: center;
        margin-top: 10px;
        &:hover {
            background-color: #5a48ff;
            text-decoration: underline #ffffff;
        }
    }
    .bg-light {
        background-color: #ECEFF3;
    }
    .bg-darkish {
        background-color: #1D2835;
    }
    p.--welcome-blue365 {
        color: #1D2835;
        font-size: 22px;
        margin-top: 23px;
    }
    h1 {
        color: #1D2835;
        font-size: 46px;
        font-family: 'Tiempos Head Line';
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 52px;
    }
    h2 {
        color: #1D2835;
        font-family: 'Tiempos Head Line';
        font-size: 36px;
        letter-spacing: 0.56px;
        line-height: 45px;
        text-align: left;
        margin-bottom: 20px;
    }
    p.--subtitle {
        color: #1D2835;
        font-size: 21px;
        margin-top: 23px;
    }
    p.sections-text {
        color: #1D2835;
        font-size: 16px;
        letter-spacing: 0.47px;
        line-height: 24px;
        width: 487px;
        text-align: left;
        margin-bottom: 20px;
    }
    img.--fyt-logos {
        height: 48px;
        width: auto;
    }
    .blue365-logo {
        height: 53px;
        width: auto;
        margin-bottom: 20px;
    }
    .head-img-div {
        height: 230px;
        width: 303px;
        display: flex;
        justify-content: center;
        align-items: center;
        #first-img {
            height: 100%;
            width: 100%;
        }
        #second-img {
            height: 80%;
            width: auto;
        }
    }
    .sub-text-div {
        text-align: center;
        width: 303px;
        background-color: #ffffff;
        padding-bottom: 20px;
        img {
            height: 40px;
            width: auto;
            margin-top: 30px;
        }
        h3 {
            font-size: 22px;
            font-weight: bold;
            color: #1D2835;
        }
        p {
            color: #545156;
            font-size: 14px;
            letter-spacing: 0.41px;
            line-height: 24px;
        }
    }
    .adjust-padding {
        padding: 0 5%;
    }
    .col-md-3 {
        padding: 45px 15px 45px 0;
    }
    .align-items-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .fyt-promise-item {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        img {
            height: 83px;
            width: auto;
        }
        p {
            margin: 0 12px;
        }
    }
    .nopr {
        padding-right: 0;
    }  
    .nopl {
        padding-left: 0;
    }
    .bottom-section {
        position: relative;
        text-align: center;
        .text-centered-oimg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            h1 {
                color: #ffffff;
            }
            p.--subtitle {
                color: #ffffff;
            }
            .btn-fyt-social {
                width: 295px;
                margin: 20px;
            }
        }
    }
    @media (max-width: @screen-xs-max) {
        .fyt-logo {
            display: none;
        }
        .col-md-3 {
            padding: 0 15px;
        }
        h1 {
            text-align: center;
            letter-spacing: 0.47px;
            line-height: 36px;
            font-size: 30px;
            margin-top: 0;
        }
        p.--subtitle {
            font-size: 16px;
            text-align: center;
            line-height: 28px;
            width: 342px;
            margin: 20px auto;
        }
        .head-img-div {
            width: 100%;
            #first-img {
                width: auto;
            }
        }
        .sub-text-div {
            width: 100%;
            margin-bottom: 40px;
        }
        .bg-darkish {
            margin-left: auto;
            margin-right: auto;
        }
        .bg-light {
            flex-direction: column;
        }
        .main-text-hero {
            padding: 5% 0;
        }
        .align-items-center {
            flex-direction: column;
            text-align: center;
            .btn-fyt-social {
                width: 100%;
            }
        }
        h2 {
            font-size: 30px;
            letter-spacing: 0.47px;
            line-height: 36px;
            text-align: center;
            width: 352px;
            margin: 20px auto;
        }
        p.sections-text {
            line-height: 28px;
            text-align: center;
            width: 352px;
        }
        .adjust-padding {
            padding: 20px 0;
        }
        .column-reverse-xs {
            flex-direction: column-reverse;
        }
        .fyt-promise-item {
            flex-direction: column;
        }
        .nopl {
            padding-left: 15px;
        }
        .nopr {
            padding-right: 15px;
        }
        .bottom-section {
            .text-centered-oimg {
                top: 60%;
                .btn-fyt-social {
                    margin: 10px;
                }
            }
        }
    }
}