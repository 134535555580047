@import (reference) 'lesshat.less';
@import (reference) 'bootstrap_less/bootstrap.less';

@import (reference) 'colors.less';
@import (reference) 'fonts.less';

.number-mixin(@diameter){
    .bold(@diameter * 0.5);
    width: @diameter;
    height: @diameter;
    padding-top: @diameter * 0.1;
    border-width: @diameter * 0.02;
}

.how-it-works {
    .container-fluid();
    text-align: center;
    background-color: white;
    padding: 32px 12%;
    .title {
        color: @dark-gray;
        .extrabold(30px);
        margin: 0;
    }
    .items {
        .make-row();
        .item-container {
            margin: 32px 0;
            .make-sm-column(6);
            .make-lg-column(3);
            .item {
                position: relative;
                background-color: @dark-gray;
                .image {
                    width: 100%;
                }
                .second-container {
                    height: 220px;
                    .icon {
                        margin: 25px 0;
                    }
                    .text {
                        margin: 0;
                        padding: 0 8% 25px;
                        color: white;
                        .regular(16px);
                    }
                }
                .number {
                    .number-mixin(90px);
                    position: absolute;
                    color: white;
                    top: 0;
                    bottom: 60%;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background-color: rgba(0, 0, 0, 0.4);
                    border: solid white;
                    border-radius: 100%;
                    cursor: default;
                    .user-select(none);
                }
            }
        }
    }
}
@media (max-width: @screen-md-max) {
    .how-it-works .items .item-container .item .second-container {
        .icon {
            margin-top: 40px;
        }
        .number {
            .number-mixin(130px);
        }
    }
}
@media (max-width: (@screen-xs-min - 1px)) {
    .how-it-works .items .item-container .item .second-container {
        .number {
            .number-mixin(110px);
        }
    }
}