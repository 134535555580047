@import (reference) 'utils.less';

.account_v2 {
    font-family: 'Avenir Next Medium';
    background-color:#eeeeee;
    padding-top: 8px;
    padding-bottom: 50px; 
    .custom-box {
        background-color: white;
        border-radius: 5px;
        margin-top: 20px;
        padding: 25px 20px;
    }
    .account-info {
        display: flex;
        #avatar-container {
            position: relative;
            #add-avatar {
                display: none;
                position: absolute;
                top: 8%;
                left: 32.5%;
                color: #42b7ea;
                font-weight: 700;
                font-size: 30px;
                z-index: 9;
            }
            img.avatar {
                object-fit: cover;
            }
            .img-circle {
                border-radius: 50%;
            }
        }
        .info-container {
            margin-left: 17.5px;
            margin-top: 5px;
            padding-bottom: 15px;
            p {
                color: #4a4a4a;
                font-size: 15.75px;
                font-weight: 800;
                margin: 0 0 2.5px;
            
            }
            a {
                color: #42b7ea;
                font-size: 12px;
                font-weight: 550;
                cursor: pointer;
            }
        }
    }
    .account-links {
        hr {
            margin-top: 8px;
            border-top: 1px solid #eee;
        }
        p {
            a {
                color: #4a4a4a;
            }
            a:focus, a:hover {
                text-decoration: none;
            }
            a.bold {
                font-weight: 800;
            }
        }
    }
    .rewards-balance {
        color: #4a4a4a;
        h2 {
            font-size: 18px;
            font-weight: 800;
            margin: 0 0 10px;
        }
        span.swell-point-balance {
            font-size: 24px;        
            font-weight: 800;
        }
        p {
            margin: 10px 0 5px;
            font-size: 12.5px;
        }
        a {
            color: #42b7ea;
            font-size: 13.5px;
            cursor: pointer;
        }
    }
    .new-messages-container {
        p { 
            font-size: 12.5px;
            color: #4a4a4a;
        }
        .new-message-text {
            color: #4a4a4a;
            font-size: 16px;
            font-weight: 800;
        }
        .messages-badge {
            display: inline-block;
            min-width: 10px;
            padding: 1.25px 6px;
            font-size: 12px;
            color: #fff;
            font-weight: 800;
            background-color: #42b7ea;
            border-radius: 10px;
        }
        .message-row {
            margin: 0;
            .dot {
                height: 10px;
                width: 10px;
                margin: 0 2.4px;
                background-color: #42b7ea;
                border-radius: 50%;
                display: inline-block;
            }
            .recipient {
                color: #4a4a4a;
                font-size: 13px;
                font-weight: 800;
            }
        }
        hr.custom-hr {
            margin-top: 5px;
            margin-bottom: 10px;            
        }
        a.all-message {
            color: #42b7ea;
            font-size: 12.5px;
        }
    }
    .progress-tracker-bars-div {
        h2 {
            color: #4a4a4a;
            font-size: 18px;
            font-weight: 800;
            margin: 0 0 10px;
        }
        a.update-track-progress {
            color: #42b7ea;
            border: 1.25px solid #42b7ea;
            padding: 10px 24px;
            border-radius: 8px;
            font-size: 13px;
            font-weight: 800;
            cursor: pointer;
        }
        .progress-tacker-div {
            margin-top: 24px;
            h2 {
                text-align: center;
                font-size: 15px;
                margin-top: -30px;
                color: #89daff;
                font-weight: 800;
            }
            .progress-tacker {
                width: 100%;
                display: flex;
                justify-content: center;
                span {
                    color: #89daff;
                    font-size: 30px;
                    position: relative;
                    top: -30px;
                    font-weight: 800;
                }
                .progress-bar-custom {
                    position: relative;
                    float: left;
                    text-align: center;
                }
                .barOverflow { 
                    position: relative;
                    overflow: hidden; 
                    width: 150px; height: 70px; 
                    margin-bottom: -14px;
                }
                .bar {
                    position: absolute;
                    top: 0; left: 0;
                    width: 150px; height: 150px; 
                    border-radius: 50%;
                    box-sizing: border-box;
                    border: 15px solid #e6e6e6;       
                    border-bottom-color: #89daff; 
                    border-right-color: #89daff;
                    transform: rotate(45deg);
                }
            }
        }
        .progress-row {
            display: flex;
            margin-bottom: 24px;
            img.icon {
                width: 12.5%;
                height: 42.5px;
            }
            .progress-bar-div {
                margin-left: 2.5%;
                width: 75%;
                p {
                    font-size: 12.5px;
                    font-weight: 800;
                    margin-bottom: 5px;
                }
                .progressbartrackprogress {
                    background-color: #e6e6e6;
                    border-radius: 13px;
                    /* (height of inner div) / 2 + padding */
                    padding: 2px;
                }
                .progressbartrackprogress > div {
                    width: 0%;
                    height: 12.5px;
                    border-radius: 10px;
                }
                .progressbartrackprogress > div.yelow {
                    background-color: #fdda02;
                }
                .progressbartrackprogress > div.pink {
                    background-color: #eecccc;
                }
                .progressbartrackprogress > div.orange {
                    background-color: #f5c465;
                }
                .progressbartrackprogress > div.blue {
                    background-color: #8eadef;
                }
                .progressbartrackprogress > div.green {
                    background-color: #afeeca;
                }
                .progressbartrackprogress > div.violet {
                    background-color: #d3ccec;
                }
            }
        }
    }
    .refer-a-firend {
        display: flex;
        .refer-text {
            color: #4a4a4a;
            font-size: 12.5px;
            font-weight: 800;
            background-color: #efee7a;
            padding: 8px;
            border-radius: 2.5px;
        }
        p {
            font-size: 12.5px;
            margin: 12.5px 0 0 0;
        }
        .invete-button-container {
            text-align: center;
            padding-top: 15px;
            .invete-button {
                color: #42b7ea;
                border: 1.25px solid #42b7ea;
                padding: 10px 24px;
                border-radius: 8px;
                font-size: 13px;
                font-weight: 800;
                cursor: pointer;
            }
        }
    }
    .next-session-container {
        h2 {
            color: #4a4a4a;
            font-size: 24px;
            font-weight: 600;
            margin: 0;
        }
        .start-datetime-div {
            display: flex;
            h3 {
                color: #4a4a4a;
                font-size: 15px;
                font-weight: 600;
                margin: 0;
            }
            .trainer-info-container {
                display: flex;
                .trainer-info {
                    margin-left: 15px;
                    h4 {
                        color: #4a4a4a;
                        font-size: 15px;
                        font-weight: 600;
                        margin: 0;
                    }
                    p {
                        color: #4a4a4a;
                        font-size: 12.5px;
                        margin: 0;
                    }
                    a.edit-location {
                        color: #42b7ea;
                        font-size: 12.5px;
                        font-weight: 600;
                        margin: 0;
                        cursor: pointer;
                    }
                }
            }
            .trainer-buttons {
                text-align: center;
                min-height: 115px;
                button.green-button {
                    width: 75%;
                    background-color: #79e9d1;
                    color: white;
                    font-size: 12.5px;
                    font-weight: 600;
                    border: 1px solid #79e9d1;
                    border-radius: 2.5px;
                    padding: 7px;
                    margin-bottom: 8px;
                }
                button.gray-button {
                    width: 75%;
                    font-size: 12.5px;
                    background-color: white;
                    color: #b7b0b0;
                    border: 1px solid #b7b0b0;
                    border-radius: 2.5px;
                    padding: 7px;
                    margin-bottom: 8px;
                }
                button.gray-button:last-child {
                    margin-bottom: 0;
                }
            }
        }
        a.view-all-session {
            color: #42b7ea;
            font-size: 15px;
            font-weight: 600;
            margin: 0;
            cursor: pointer;
        }
    }
    .book-a-session {
        hr {
            margin: 0;
        }
        h3 {
            color: #4a4a4a;
            font-size: 20px;
            font-weight: 600;
            margin: 15px 0 0;
        }
        .trainer-div {
            text-align: center;
            margin-top: 24px;
            h4 {
                color: #4a4a4a;
                font-size: 15px;
                font-weight: 600;
                margin: 15px 0 0 0;
            }
        }
    }
    .your-trainer-panel {
        h3 {
            color: #4a4a4a;
            font-size: 20px;
            font-weight: 600;
            margin: 0;
        }
        .trainer-div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: center;
            margin-top: 15px;
            h4 {
                color: #4a4a4a;
                font-size: 15px;
                font-weight: 600;
                margin: 15px 0 0 0;
            }
            .btn-primary {
                margin-left: 20px;
            }
        }
    }
    .recommended {
        hr {
            margin: 0;
        }
        h3 {
            color: #4a4a4a;
            font-size: 20px;
            font-weight: 600;
            margin: 0 0 10px;
        }
        .trainers-div {
            display: flex;
            .trainer {
                margin: 20px 0;
                min-width: 100%;
                .image-trainer {
                    position: relative;
                    min-width: 100%;
                    max-width: 100%;
                    min-height: 190px;
                    max-height: 190px;
                    overflow: hidden;
                    .fav {
                        position: absolute;
                        right: 5px;
                        cursor: pointer;
                        a {
                            .heart-img {
                                width: 35px;
                                max-width: 35px;
                                min-width: 35px;
                                height: 35px;
                                min-height: 35px;
                            }
                        }
                    }
                    img {
                        width: 100%;
                        max-width: 100%;
                        min-width: 100%;
                        min-height: 190px;
                    }
                }
                .border-trainer {
                    border: 1.25px solid #c4c4c4;
                    padding-bottom: 10px;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    max-height: 95px;
                    min-height: 95px;
                    a {
                        cursor: pointer;
                        h4 {
                            color: #4a4a4a;
                            font-size: 17.5px;
                            text-align: left;
                            font-weight: 600;
                            margin-left: 8px;
                            min-height: 40px;
                        }
                        span {
                            border: 2px solid #c4c4c4;
                            border-radius: 50%;
                            padding: 0px 5px;
                            color: #c4c4c4;
                            font-weight: 800;
                            font-size: 15px;
                            margin-left: 85%;
                        }
                    }
                    a:focus, a:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        a.view-all {
            color: #42b7ea;
            font-size: 15px;
            font-weight: 600;
            margin: 0;
            cursor: pointer;
        }
        .not-trainer-matches {
            p { font-size: 12.5px; }
            .get-started-container {
                text-align: center;
                a {
                    background-color: #79e9d1;
                    color: #fff;
                    font-size: 12.5px;
                    font-weight: 600;
                    border: 1px solid #79e9d1;
                    border-radius: 5px;
                    padding: 10px 34px;
                    margin-top: 30px;
                }
            }
        }
        .not-favorite-trainers {
            p { font-size: 12.5px; }
            .search {
                width: 70%;
                padding-bottom: 25px;
                .btn-search {
                    color: #989898;
                    background: #fff;
                    border: 1px solid #ccc;
                    border-right: none;
                }
                .form-control  {
                    border-left: none;
                }
                .form-control:focus {
                    border-color: #ccc;
                    outline: 0;
                }
            }
        }
    }
    .sessions-info-boxs {
        min-height: 50em;
        .sessions-header {
            span {
                color: #4a4a4a;
                font-size: 24px;
                font-weight: 800;;
            }
            a {
                float: right;
                color: #42b7ea;
                font-size: 15px;
                font-weight: 600;
                margin: 10px 0 0 0;
                cursor: pointer;
            }
        }
        #session-history {
            color: #4a4a4a;
            table {
                width: 100%;
                th {
                    padding: 15px 0 5px;
                }
                tr.border {
                    border-top: 0.15px solid #4a4a4a36;
                    td {
                        padding: 10px 0;
                    }
                }
            }
        }
    }
    .account-settings {
        .account-settings-form {
            h2.settings-title {
                color: #4a4a4a;
                font-size: 24px;
                font-weight: 800;
                margin: 0;
            }
            h4.form-title {
                color: #4a4a4a;
                font-size: 17.5px;
                font-weight: 800;
                margin-bottom: 17.5px;
            }
        }
        button.green-button {
            width: 25%;
            background-color: #79e9d1;
            color: white;
            font-size: 12.5px;
            font-weight: 600;
            border: 1px solid #79e9d1;
            border-radius: 2.5px;
            padding: 7px;
            margin-top: 30px;
        }
        .change-password-form {
            margin-top: 35px;
            a {
                color: #4a4a4a;
                font-size: 17.5px;
                font-weight: 800;
                margin-bottom: 17.5px;
            }
        }
    }
    .billing-and-subscription {
        display: block;
        color: #4a4a4a;
        h2.billing-title {
            font-size: 24px;
            font-weight: 800;
            margin: 0;
        }
        h4.billing-subtitles {
            font-size: 17px;
            font-weight: 800;
            margin: 0;
        }
        .your-subscription, .your-payment {
            display: flex;
            h5 {
                padding-top: 12px;
                margin-bottom: 5px;
                font-weight: 600;
                font-size: 13.5px;            
            }
            .no-padding {
                padding: 5px !important;
            }
        }
        .your-subscription form#redeem-coupon {
            h3 {
                margin: 0 0 8px 0;
                font-size: 17.5px;
            }
            input {
                width: 100%;
                border-radius: 0;
                border: 1px solid #ccc;
                padding: 4px 6px;
            }
            input.submit-code {
                width: 100%;
                background-color: #79e9d1;
                color: #fff;
                font-size: 12.5px;
                font-weight: 600;
                border: 1px solid #79e9d1;
                border-radius: 5px;
                padding: 8px;
                margin-top: 15px;
            }
        }
        a.update-payment {
            color: #79e9d1;
            border: 1.25px solid #79e9d1;
            padding: 10px 15px;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 800;
            margin-top: 20px;
            cursor: pointer;
        }
        table {
            width: 95%;
            margin-left: 2.5%;
            th {
                padding: 15px 0 5px;
            }
            tr.border {
                border-top: 0.15px solid #4a4a4a36;
                td {
                    padding: 10px 0;
                }
            }
        }
    }
    .your_fyt_profile {
        h3 {
            color: #4a4a4a;
            font-size: 17.5px;
            font-weight: 800;
            margin: 0 0 15px;
        }
        p {
            font-size: 12.5px;
            letter-spacing: 0.4px;
        }
        span {
            cursor: pointer;
        }
        span.green {
            margin-left: 40px;
            color: #79e9d1;
            font-weight: 550;
        }
        a {
            color: #4a4a4a;
            cursor: pointer;
        }
        .par-q {
            margin-top: 10px; 
            span.red {
                float: right;
                font-size: 12.5px;
                letter-spacing: 0.4px;
                color: red;
                cursor: no-drop;
            }
            a.blue {
                float: right;
                font-size: 12.5px;
                letter-spacing: 0.4px;
                color: #8aefff;
                font-weight: 550;
                margin-left: 15px;
            }
        }
    }
    .pre-session-info {
        h2 {
            color: #4a4a4a;
            font-size: 24px;
            font-weight: 800;
            margin: 0;
        }
        form {
            width: 90%;
            max-width: 100%;
            margin: 0 auto 20px;
            #survey-trainer {
                width: 100%;
                max-width: 100%;
                input, textarea {
                    border-radius: 0;
                    border: 1px solid #ccc;
                    padding: 4px 6px;
                }
                input, textarea {
                    width: 100%;
                }
                label {
                    margin: 15px 0 12.5px;
                }
            }
            button.green-button {
                width: 25%;
                background-color: #79e9d1;
                color: #fff;
                font-size: 12.5px;
                font-weight: 600;
                border: 1px solid #79e9d1;
                border-radius: 2.5px;
                padding: 7px;
                margin-top: 30px;
            }
        }
    }
    .faq-s {
        color: #4a4a4a;
        p {
            font-size: 15px;
        }
        h2 {
            font-size: 24px;
            font-weight: 800;
            margin: 0;
        }
        form {
            width: 95%;
            margin-left: 2.5%;
            #faqs-form {
                label  {
                    font-weight: 600;
                    font-size: 14px;
                    margin-top: 5px;
                }
                ul {
                    padding-left: 5px;
                    li label {
                        font-weight: 500;
                    }
                }
            }
            .point {
                display: list-item;
                list-style-type: disc;
                list-style-position: inside;
            
            }
            button.green-button {
                width: 25%;
                background-color: #79e9d1;
                color: #fff;
                font-size: 12.5px;
                font-weight: 600;
                border: 1px solid #79e9d1;
                border-radius: 2.5px;
                padding: 7px;
                margin-top: 30px;
            }
        }
    }
    .progress-tracker-info {
        .progress-tracker-info-header {
            display: flex;
            justify-content: space-between;
            h2 {
                font-size: 17.5px;
                margin-top: 0;
                font-weight: 800;
            }
            #view-progress-header, #track-progress-header {
                display: flex;
                cursor: pointer;
                a {
                    color: #4cbef5;
                    font-weight: 800;
                    text-decoration: none;
                    font-size: 13px;
                    margin-left: 8px;
                }
            }
            #view-progress-header {
                align-items: end;
            }
            #track-progress-header {
                align-items: baseline;
            }
        }
        p {
            font-size: 12.5px;
            letter-spacing: 0.24px;
            margin-top: 24px;
        }
        .progress-tacker-div {
            margin-top: 24px;
            h2 {
                text-align: center;
                font-size: 15px;
                margin-top: -30px;
                color: #89daff;
                font-weight: 800;
            }
            .progress-tacker {
                width: 100%;
                display: flex;
                justify-content: center;
                span {
                    color: #89daff;
                    font-size: 30px;
                    position: relative;
                    top: -30px;
                    font-weight: 800;
                }
                .progress-bar-custom {
                    position: relative;
                    float: left;
                    text-align: center;
                }
                .barOverflow { 
                    position: relative;
                    overflow: hidden; 
                    width: 150px; height: 70px; 
                    margin-bottom: -14px;
                }
                .bar {
                    position: absolute;
                    top: 0; left: 0;
                    width: 150px; height: 150px; 
                    border-radius: 50%;
                    box-sizing: border-box;
                    border: 15px solid #e6e6e6;       
                    border-bottom-color: #89daff; 
                    border-right-color: #89daff;
                    transform: rotate(45deg);
                }
            }
        }
    }
    .progress-tracker-form {
        display: block;
        h2 {
            font-size: 24px;
            margin-top: 0;
            font-weight: 800;
        }
        button.green-button {
            width: 25%;
            background-color: #79e9d1;
            color: #fff;
            font-size: 12.5px;
            font-weight: 600;
            border: 1px solid #79e9d1;
            border-radius: 2.5px;
            padding: 7px;
            margin-top: 50px;
        }
        p.one-per-day {
            margin-top: 50px;
            text-align: center;
            font-size: 15px;
            color: #89daff;
            font-weight: 800;
        }
        p#submission-saved {
            margin-top: 35px;
            font-size: 15px;
            color: #89daff;
            font-weight: 800;
        }
        .progress-row {
            display: flex;
            padding-top: 34px;
            img.icon {
                width: 50%;
                margin: 24px 25% 2.5px;
            }
            p.icon-title {
                font-weight: 800;
                font-size: 14px;
                text-align: center;
            }
            p.ask {
                font-weight: 800;
                font-size: 15px;
                margin-bottom: 2.5px;
            }
            input[type="range"] {
                -webkit-appearance: none;
                -moz-apperance: none;
                 outline:none !important;
                border-radius: 6px;
                height: 8px;
                width:100%;
            }
            input[type="range"].yellow {
                background-image: -webkit-gradient(
                    linear,
                    left top,
                    right top,
                    color-stop(0, #fdda02),
                    color-stop(0, #C5C5C5)
                );
            }
            input[type="range"].pink {
                background-image: -webkit-gradient(
                    linear,
                    left top,
                    right top,
                    color-stop(0, #eecccc),
                    color-stop(0, #C5C5C5)
                );
            }
            input[type="range"].orange {
                background-image: -webkit-gradient(
                    linear,
                    left top,
                    right top,
                    color-stop(0, #f5c465),
                    color-stop(0, #C5C5C5)
                );
            }
            input[type="range"].blue {
                background-image: -webkit-gradient(
                    linear,
                    left top,
                    right top,
                    color-stop(0, #8eadef),
                    color-stop(0, #C5C5C5)
                );
            }
            input[type="range"].green {
                background-image: -webkit-gradient(
                    linear,
                    left top,
                    right top,
                    color-stop(0, #afeeca),
                    color-stop(0, #C5C5C5)
                );
            }
            input[type="range"].violet {
                background-image: -webkit-gradient(
                    linear,
                    left top,
                    right top,
                    color-stop(0, #d3ccec),
                    color-stop(0, #C5C5C5)
                );
            }
            input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none !important;
                cursor:pointer;
                height: 20px;
                width: 20px;
                border-radius:50%;
                transition:100ms;
            }
            input[type="range"].yellow::-webkit-slider-thumb {
                background-color: #fdda02;
                border: 1px solid #fdda02;
            }
            input[type="range"].pink::-webkit-slider-thumb {
                background-color: #eecccc;
                border: 1px solid #eecccc;
            }
            input[type="range"].orange::-webkit-slider-thumb {
                background-color: #f5c465;
                border: 1px solid #f5c465;
            }
            input[type="range"].blue::-webkit-slider-thumb {
                background-color: #8eadef;
                border: 1px solid #8eadef;
            }
            input[type="range"].green::-webkit-slider-thumb {
                background-color: #afeeca;
                border: 1px solid #afeeca;
            }
            input[type="range"].violet::-webkit-slider-thumb {
                background-color: #d3ccec;
                border: 1px solid #d3ccec;
            }
            input[type='range']:hover::-webkit-slider-thumb {
                -webkit-appearance: none !important;
                cursor:pointer;
                height: 25px;
                width: 25px;
                border-radius:50%;
            }
            input[type="range"].yellow:hover::-webkit-slider-thumb {
                background-color: #fdda02;
                border: 1px solid #fdda02;
            }
            input[type="range"].pink:hover::-webkit-slider-thumb {
                background-color: #eecccc;
                border: 1px solid #eecccc;
            }
            input[type="range"].orange:hover::-webkit-slider-thumb {
                background-color: #f5c465;
                border: 1px solid #f5c465;
            }
            input[type="range"].blue:hover::-webkit-slider-thumb {
                background-color: #8eadef;
                border: 1px solid #8eadef;
            }
            input[type="range"].green:hover::-webkit-slider-thumb {
                background-color: #afeeca;
                border: 1px solid #afeeca;
            }
            input[type="range"].violet:hover::-webkit-slider-thumb {
                background-color: #d3ccec;
                border: 1px solid #d3ccec;
            }
            /*Firefox */
            input[type="range"]::-moz-range-thumb {
                height: 20px;
                width: 20px;
                border-radius:50%;
                cursor:pointer;
            }
            input[type="range"].yellow::-moz-range-thumb {
                background-color: #fdda02;
                border: 1px solid #fdda02;
            }
            input[type="range"].pink::-moz-range-thumb {
                background-color: #eecccc;
                border: 1px solid #eecccc;
            }
            input[type="range"].orange::-moz-range-thumb {
                background-color: #f5c465;
                border: 1px solid #f5c465;
            }
            input[type="range"].blue::-moz-range-thumb {
                background-color: #8eadef;
                border: 1px solid #8eadef;
            }
            input[type="range"].green::-moz-range-thumb {
                background-color: #afeeca;
                border: 1px solid #afeeca;
            }
            input[type="range"].violet::-moz-range-thumb {
                background-color: #d3ccec;
                border: 1px solid #d3ccec;
            }
            input[type="range"]:hover::-moz-range-thumb {
                height: 25px;
                width: 25px;
                border-radius:50%;
                cursor:pointer;
            }
            input[type="range"].yellow:hover::-moz-range-thumb {
                background-color: #fdda02;
                border: 1px solid #fdda02;
            }
            input[type="range"].pink:hover::-moz-range-thumb {
                background-color: #eecccc;
                border: 1px solid #eecccc;
            }
            input[type="range"].orange:hover::-moz-range-thumb {
                background-color: #f5c465;
                border: 1px solid #f5c465;
            }
            input[type="range"].blue:hover::-moz-range-thumb {
                background-color: #8eadef;
                border: 1px solid #8eadef;
            }
            input[type="range"].green:hover::-moz-range-thumb {
                background-color: #afeeca;
                border: 1px solid #afeeca;
            }
            input[type="range"].violet:hover::-moz-range-thumb {
                background-color: #d3ccec;
                border: 1px solid #d3ccec;
            }
            input[type=range]::-moz-range-track {
                background:none;
            }
            /*IE and Edge */
            input[type=range]::-ms-thumb{
                height: 20px;
                width: 20px;
                border-radius:50%;
                cursor: hand;
            }
            input[type="range"].yellow::-ms-thumb{
                background-color: #fdda02;
                border: 1px solid #fdda02;
            }
            input[type="range"].pink::-ms-thumb{
                background-color: #eecccc;
                border: 1px solid #eecccc;
            }
            input[type="range"].orange::-ms-thumb{
                background-color: #f5c465;
                border: 1px solid #f5c465;
            }
            input[type="range"].blue::-ms-thumb{
                background-color: #8eadef;
                border: 1px solid #8eadef;
            }
            input[type="range"].green::-ms-thumb{
                background-color: #afeeca;
                border: 1px solid #afeeca;
            }
            input[type="range"].violet::-ms-thumb{
                background-color: #d3ccec;
                border: 1px solid #d3ccec;
            }
            input[type=range]::-ms-fill-upper {
                background-color: #C5C5C5;
            }
            input[type="range"].yellow::-ms-fill-lower {
                background-color: #fdda02;
            }
            input[type="range"].pink::-ms-fill-lower {
                background-color: #eecccc;
            }
            input[type="range"].orange::-ms-fill-lower {
                background-color: #f5c465;
            }
            input[type="range"].blue::-ms-fill-lower {
                background-color: #8eadef;
            }
            input[type="range"].green::-ms-fill-lower {
                background-color: #afeeca;
            }
            input[type="range"].violet::-ms-fill-lower {
                background-color: #d3ccec;
            }
            input[type=range]::-ms-track {
                border:none;
                color:transparent;
                height:8px;
            }
            input[type="range"]::-ms-tooltip {
                display: none; /*tooltip makes thumb sliding lagy*/
            }
            div.space-between {
                display: flex;
                justify-content: space-between;
            }
            div.extremes {
                padding-bottom: 10px;
            }
            div.numbers {
                padding-top: 10px;
            }
        }
    }
    .progress-tracker-charts {
        display: inline-block;
        h2 {
            font-size: 24px;
            margin-top: 0;
            font-weight: 800;
        }
        h4 {
            font-size: 17.5px;
            font-weight: 800;
            margin-top: 10px;
            margin-left: 8px;
        }
        canvas {
            margin-top: 20px;
        }
        .charter-title-row {
            display: flex;
            align-items: center;
            margin-top: 15px;
            .icon-chart {
                width: 35px;
                height: 35px;
                margin-right: 8px;
            }
            span.icon-title {
                font-size: 17.5px;
                font-weight: 800;
            }
        }
    }
}

@media (max-width: @screen-xs-max) {
    .account_v2 {
        padding-right: 0 !important;
        padding-left: 0 !important;
        padding-top: 0;
        .custom-box {
            margin-top: 8px;
            border-radius: 2.5px;
        }
        .refer-a-firend .invete-button-container .invete-button {
            padding: 10px 100px;
        }
        .account-settings button.green-button {
            width: 50%;
        }
        .no-padding-xs {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
        .custom-margin-top-xs {
            margin-top: 2.5px !important;
        }
        .recommended .trainers-div .trainer .image-trainer {
            min-height: 280px;
            max-height: 280px;
            img {
                min-height: 280px;
            }
        }
        .recommended .not-favorite-trainers .search {
            width: 100%;
        }
        .next-session-container .start-datetime-div .trainer-buttons {
            min-height: unset;
            margin-top: 12.5px;
        }
        .pre-session-info form button.green-button {
            width: 60%;
        }
        .faq-s form button.green-button {
            width: 60%;
        }
        .pre-session-info form {
            width: 100%;
        }
        .progress-tracker-bars-div .progress-row {
            img.icon {
                width: 10%;
                height: 34px;
                margin-top: 5px;
            }
            .progress-bar-div {
                margin-left: 5%;
                width: 85%;
            }        
        } 
        .progress-tracker-form {
            button.green-button {
                width: 50%;
            }
            h2 {
                font-size: 17px;
                margin-bottom: 10px;
            }
            .progress-row {
                padding-top: 24px;
                display: grid;
                img.icon {
                    width: 50%;
                    margin: 15px 25% 0;
                }
                div.extremes span {
                    font-size: 13px;
                }
                p.ask {
                    font-size: 13px;
                    margin-bottom: 10px;
                }
                p.icon-title {
                    font-size: 13px;
                }
            }
        }
        .progress-tracker-charts {
            display: block;
        }
    } 
}

#newClientTipsModal {
    p.modal-title {
        font-size: 26px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    
    ul {
        list-style-type: disc;
        
        li {
            color: #535353;
            font-size: 17px;
            line-height: 23px;
            margin-bottom: 8px;
        }
    }
}