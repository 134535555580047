@import (reference) 'bootstrap_less/bootstrap.less';
@import (reference) 'fonts.less';

.container-error {
    max-width: 1170px;
    margin: 5% auto;
    h1 {
        .bold(50px);
    }
    p {
        .regular(20px);
    }
    ul {
        li {
            .regular(16px);
        }
    }
}
@media (max-width: @screen-xs-max){
    .container-error {
        h1 {
            .bold(30px);
            text-align: center;
        }
        p {
            .regular(18px);
            text-align: center;
        }
        ul {
            li {
                .regular(14px);
            }
        }
    }
}