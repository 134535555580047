@import (reference) 'utils.less';
@import (reference) "bootstrap_less/grid.less";
@import (reference) 'colors.less';

.hero{
    &:extend(.parent-hero);
    h1{
        .extrabold(2.2em);
    }
    h3{
        .bold(18px);
        line-height: 1.3em;
        margin: 30px 2%;
    }
    background: url('../img/NEW-HOME-HERO.png') center 0 no-repeat;
    .epigraph {
        position: relative;
        top: 30px;
        p.market-epigraph {
            font-size: 0.7em;
            margin: 10px 0px;
        }
    }
}
@media screen{
    @media (max-width: @screen-sm-max){
        .hero{
            background: url('../img/NEW-HOME-HERO-MOBILE.png') no-repeat;
        }
    }
    @media (min-width:600px){
        .hero{
            padding: 160px 0;
            h1{
                .extrabold(55px);
            }
        }
    }
    @media (min-width: @screen-xs-min) and (max-width: 599px){
        .hero {
            h3 {
                margin: 30px 10%;
            }
        }
    }
    @media (min-width: 600px) and (max-width: @screen-xs-max){
        .hero {
            h3 {
                margin: 30px 20%;
            }
        }
    }
}


.personal-trainers-in-city {
    .neighborhood_name {
        font-weight: initial;
    }
    .footer_col {
        margin-top: 25px;
        float: left !important;
    }
}

.locate-group{
    position: absolute;
    bottom: 0;
    left: 0;
    background: fade(@dark-gray, 70%);
    width:100%;
    padding: 15px 0;
    & > * {
        height: 48px;
        &:focus {
            outline: none;
        }
        line-height: 100%;
        display: inline-block;
        vertical-align: middle;
        margin: auto 4px;
    }
    .query{
        .regular(16px);
        margin: 5px auto;
        padding: 18px;
        min-width: 450px;
        border: 0;
        color: @gray;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        display: block;
        line-height: 200%;
        .placeholder(@color: #ccc);
    }
    .submit-search-trainer{
        padding: 0 18px;
        font-size: 17px;
    }
}

.training_by {
    text-align: center;
    padding: 3%;
    background-color: @light-gray;
    p.title {
        font-size: 25px;
        font-weight: 600;
    }
    img.fytpro {
        margin: 40px 0px;
    }
    .see-all {
        margin-top: 20px;
    }
}

@media screen{
    @media (min-width:600px){
        .locate-group{
            .btn-group .dropdown-toggle.with-caret {
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;
            }
            .query{
                display: inline-block;
                width: auto;
                /*-webkit-border-radius: 0 3px 3px 0;
                -moz-border-radius: 0 3px 3px 0;
                border-radius: 0 3px 3px 0;*/
            }
        }
    }
}

@media screen{
    @media (max-width: 599px){
        .locate-group{
            .btn-group, .query, .submit-search-trainer{
                width: 300px;
            }
            .query{
                min-width: 0;
            }
            .btn-group {
                .find-trainer {
                    width: 250px;
                }
                .dropdown-toggle.with-caret {
                    width: 50px;
                }
                .dropdown-menu {
                    border: solid 1px white;
                }
            }
        }
    }
}

.prefooter{
    h2 {
        .bold(30px);
    }
    background: lighten(@dark-gray, 10%);
    color:#fff;
    text-align: center;
    padding: 10px 0 25px;
    position: relative;
    // margin-top: 20px;
    .e-mail_link a{
        color:#fff;
        font-size: 18px;
        .semibold(25px);
    }
}
.social{
    text-decoration: none;
}
.sp{
    background: url(../img/social_icons_sprite.png) 0 0 no-repeat;
    margin: 10px 20px;
    &:hover{
        opacity:0.8;
    }
    &.fb{ background-position: 0 0; width: 16px; height: 26px; }
    &.ig{ background-position: 0 -31px; width: 29px; height: 29px; }
    &.pt{ background-position: 0 -65px; width: 22px; height: 27px; }
    &.tw{ background-position: 0 -97px; width: 27px; height: 21px; }
}

.home-items, .why-a-trainer {
    .owl-theme .owl-controls {
        margin: 0;
        padding: 0 3%;
        position: absolute;
        top: 50%;
        width: 100%;
        .owl-nav {
            .owl-prev,
            .owl-next {
                background: none;
                float: left;
                opacity:0.8;
            }
            .owl-next {
                background: none;
                float: right;
                opacity:0.8;
            }
        }
    }
}

.messages {
    width: 35%;
    margin-left: 62.5%;
    list-style-type: none;
    position: absolute;
    margin-top: 8%;
    z-index: 100;
    -moz-animation: cssAnimation 0s ease-in 8s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 8s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 8s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 8s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    .alert-error {
        .alert-danger;
    }
}
@media screen{
    @media (max-width:@screen-xs-min) {
        .messages {
            width: 100%;
            margin-left: 0;
            margin-top: 80px;
        }
    }
    @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
        .messages {
            width: 60%;
            margin-left: 37.5%;
            margin-top: 80px;
        }
    }
    @media (min-width: @screen-xs-max) and (max-width: @screen-sm-max) {
        .messages {
            width: 50%;
            margin-left: 47.5%;
            margin-top: 80px;
        }
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        .messages {
            width: 50%;
            margin-left: 47.5%;
            margin-top: 80px;
        }
    }
}

@keyframes cssAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
}
@-webkit-keyframes cssAnimation {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
}

.homepage-trainers {
    .trainer {
        margin-bottom: 10px;
        background: @la-fafafa;
        text-align: left;
        font-size: 1em;
        .review-avg {
            img {
                display: inline-block;
                vertical-align: middle;
                width: 12px; height: 12px;
            }
        }
        .trainer-thumbnail {
            padding: 0px;
            // width: 12%;
            // overriding col-md-2 width
        }
        .trainer-name {
            font-size: medium;
            display: inline-block;
            vertical-align: middle;
            margin: 5px 0 0 0;
            a, a:hover, a:visited {text-decoration: none; color: @cyan;}
            h2 { // for seo purposes the name should be inside the h2 tag
                font-size: inherit;
                line-height: inherit;
                margin: 0;
            }
        }
        .specialites {
            line-height: 2em;
        }
        @media screen and (max-width: @screen-xs-max) {
            padding: 5px;
        }
    }
    .hidden-trainer{
        display: none;
    }
}

// PHILLY
