@import (reference) "bootstrap_less/bootstrap.less";
@import (reference) 'utils.less';

.have_points{
	text-align:center;
	font-family: 'Avenir Next Medium';
    color: #000000;
    margin-top: 81px;
    h2 {
        font-size: 37.5px;
        font-weight: 700;
        margin-top: 0;
	}
	.primary-redeem-btn{
		padding: 10px 24px;
		font-size: 17.5px;
		margin:24px 8px;
	    background: rgb(90, 204, 160);
	}
	.primary-rewards-btn {
		padding: 10px 10px;
		font-size: 17.5px;
		margin: 24px 0;	
	}
	hr {
		margin: 20px 24%;
	}
}
.VIPs_earn {
	text-align:center;
	font-family: 'Avenir Next Medium';
    color: #000000;
    padding: 50px 0 50px;
    h2 {
        font-size: 34px;
        font-weight: 700;
        line-height: 24px;
	}
	h3 {
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.25px;
	}
	.vip_classes {
	    margin-top: 60px;
    	h3 {
    		font-weight: 700;
    		font-size: 24px;
			line-height: 24px;
    	}
		.current{
			color: rgb(90, 204, 160);
		    font-size: 15px;
		    font-weight: 700;
		}
		.you_need {
			font-size: 15px;
    		font-weight: 700;		
    	}
    	.points {
    		font-size: 17.5px;
    		font-weight: 700;	
    	}
    	.star {
    		color: rgb(90, 204, 160);
    	}
    	.star_points {
    		color: rgb(153, 153, 153);
    		font-size: 17.5px;
    		font-weight: 700;
    	}
    	.advance {
    		color: rgb(129, 129, 129);
    	}
    	.elite {
    		margin-left: 24px;
    		color: rgb(164, 164, 164);
    	}
    	.points_needed {
    		color: rgb(208, 208, 208);
    		font-size: 17.5px;
    		font-weight: 700;
    	}
    	.multiplier {
    		font-size: 17.5px;
    		letter-spacing: 0.25px;
    		margin: 15px 0;
    		line-height: 28px;
    	}
    	.multiplier_user {
    		color : #818181c4;
    	}
	}
}
.refer_frined_and_check{
	text-align:center;
	font-family: 'Avenir Next Medium';
	margin-top: 24px;
	.refer_friend_div {
		.modal_friend {
		    padding: 24px 124px !important;
		}
		.refeer_a_friend {
			padding: 24px 0;
			background-color: rgb(58, 175, 231);
			color: rgb(255, 255, 255);
			h4 {
				font-weight: 600;
			    font-size: 24px;
			}
			h3 {
				font-weight: 600;
	    		font-size: 50px;
			}
			p {
				font-size: 17.5px;
			    margin: 0;
			    letter-spacing: 0.5px;
			}
		}
		.share_with_friend {
			margin-top: 0;
		    border: solid;
		    border-width: 0.1px 1px 1px;
		    border-color: rgb(216, 214, 214);
		    padding: 24px 0;
		    h4 {
		    	color: black;
		    }
		    .shared_emails_input {
			    width: 80%;
			    height: 50px;
			    padding: 8px 10px;
			    margin: 10px 0;
			    font-size: 17.5px;
			    line-height: 1.42857143;
			    background-color: #fff;
			    border: 1px solid #ccc;
			    border-radius: 4px;
			    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
			    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
			    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
			    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
			    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
		    }
		    .shared_emails_input:focus {
			    border-color: #66afe9;
			    outline: 0;
			    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
			    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
			}
			.shared_emails_input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			    color: rgb(174, 174, 173);
			    opacity: 1; /* Firefox */
			}
			.shared_emails_btn {
				width: 80%;
			    height: 50px;
			    padding: 8px 10px;
			    margin: 10px 0;
			    font-size: 17.5px;
			    font-weight: 600;
			    color: white;
			    line-height: 1.42857143;
			    background-color: rgb(35, 48, 63);
			    border: 1px solid rgb(35, 48, 63);
			    border-radius: 4px;
			    -webkit-box-shadow: inset 0 1px 1px rgb(35, 48, 63);
			    box-shadow: inset 0 1px 1px rgb(35, 48, 63);
			    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
			    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
			    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
			}
			p {
				font-size: 15px;
			    margin: 24px 0 24px;
			    color: rgb(99, 99, 99);
			}
			a{
				color:rgb(99, 99, 99);
				font-size: 17.5px;
				cursor : pointer;
			}
			a:hover {
			    color: #337ab7 !important;
			    text-decoration: none;
			}
			a:nth-child(1) {
				margin-right: 50px;
			}
			a:nth-child(2) {
				margin-right: 50px;
			}
			img {
				height: 24px;
			}
		}
	}
	.check_div {
		text-align:center;
		font-family: 'Avenir Next Medium';
		.check_fyt {
			background-color: rgb(35, 48, 63);
			padding: 24px 0;
			h3 {
				color: #fff;
			    margin: 8px 0;
			    font-size: 24px;
			    font-weight: 600;
			}
		}
		.your_referrals {
			border: solid;
		    border-width: 0.1px 1px 0.1px 1px;
		    border-color: rgb(216, 214, 214);
			h3 {
				font-weight: 600;
			    font-size: 24px;
			    color:#636363;
			    margin : 24px 0;
			}
		}
		.your_referrals_details_div {
			text-align: -webkit-auto;
			color : rgb(99, 99, 99);
			margin-bottom: 34px;
			.title_details {
				font-weight: 600;
			    font-size: 15.5px;
			    margin-bottom: 5px;
			}
			#your_referrals_details {
			    height: 167px;
			    overflow-y: scroll;
			    overflow-x: hidden;
			    p {
			    	font-size: 13px;
			    }
			    .purchased {
			    	color: #3aafe7;
			    }
				.doesnt_have_referrals {
    				font-size: 17.5px;
				    font-weight: 600;
				    margin: 15% 12%;
				    color: #23303f;
				}
			}
			#your_referrals_details::-webkit-scrollbar {
			    width: 10px;
			}
			 
			#your_referrals_details::-webkit-scrollbar-track {
			    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
			    border-radius: 10px;
			}
			 
			#your_referrals_details::-webkit-scrollbar-thumb {
			    border-radius: 10px;
			    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
			    background-color: #3aafe7;
			    background-clip: content-box;
			}
			hr {
				margin: 3px 0;
			}
		}
		.reedem_your_fyt{
			border: solid;
		    border-width: 1px 0.1px 1px 0.1px;
		    border-color: rgb(216, 214, 214);
		    h3 {
				margin: 47px 0;
		    }
		}
	}
}
.fyt_rewards-text {
	text-align: left;
	font-family: 'Tiempos Head Line';
    color: #242A4E;
	padding: 100px 0;
	padding-left: 100px;
	padding-bottom: 40px;
    background-color: rgb(245, 247, 248);
    background-position: 85%;
    background-repeat: no-repeat;
	background-size: 40%;
	h1 {
		font-size: 47px;
		font-weight: 700;
        line-height: 45px;
        letter-spacing: 1.5px;
        margin-bottom: 22px;
	}
	h3 {
		font-family: 'Avenir Next Medium';
		font-size: 20px;
		line-height: 34px;
		letter-spacing: 0.25px;
	}
	h5 {
		font-family: 'Avenir Next Medium';
		font-size: 17px;
    	font-weight: 600;
	}
	.primary-rewards-btn {
		background-color: #5D66FE;
		/*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
		text-shadow: 0 -1px 0 #354C8C;
		width: 200px;
	}
	.your_points {
		color: #242A4E;
		text-decoration: underline;
		font-size: 15px;
		font-weight: 600;
	}
}
.fyt_rewards-img {
	color: #242A4E;
	padding: 50px 60px 20px;
    background-color: rgb(245, 247, 248);
    background-position: 85%;
    background-repeat: no-repeat;
	background-size: 40%;
}
.fyt_rewards-img img {
	width: 90%;
	object-fit: cover;
	object-position: left;
	margin-right: 50px;
}
.how_rewards_work {	
	text-align:center;
	font-family: 'Avenir Next Medium';
	h2 {
		color: #000000;
        font-size: 34px;
        font-weight: 700;
        line-height: 42.5px;
        margin: 81px 0 34px;
	}
	.number_circle {
		height: 40px;
		width: 40px;
		background-color: #232a4e;
		border-radius: 50%;
		display: inline-block;
		font-size: 27px;
		font-weight: 600;
		color: #fff;
		margin-top: 24px;
	}
	.works_div {
		padding: 17.5px 0;
		h3 {
			font-weight: 600;
    		color: black;
    		margin: 34px 0 24px;
		}
		p {
			font-size: 17.5px;
			color: black;
			margin: 10px 0px 24px;
			letter-spacing: 1.24px;
		}
	}
	.works_div:nth-child(1) {
		background-image: url(../img/rewards/Step-1.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50%;
	}
	.works_div:nth-child(2) {
		background-image: url(../img/rewards/Step-2.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50%;
	}
	.works_div:nth-child(3) {
		background-image: url(../img/rewards/Step-3.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50%;
	}
	.second-rewards-btn {
		font-family: 'Avenir Next Medium';
		padding: 10px 24px;
		font-size: 20px;
		margin: 70px 0 0;
	}		
}
.how_earn_points {
	text-align:center;
	font-family: 'Avenir Next Medium';
	a:hover, a:focus {
		text-decoration: none;
	}
	a:hover .how_earn_divs {
		background-color: #cfedf6;
	}
	h2 {
		color: #000000;
        font-size: 34px;
        font-weight: 700;
        line-height: 42.5px;
        margin: 81px 0 50px;
	}
	.padding-reduced {
	    padding-right: 6px;
    	padding-left: 6px;
	}
	.how_earn_divs {
		background-color: rgb(245, 247, 248);
	    min-height: 300px;
	}
	.how_earn_divs-img {
		padding-top: 120px;
	}
	.how_earn_divs img {

	}
	.how_refer_fiv {
		background-color: rgb(207, 237, 246);
		min-height: 300px;
	}
	.earn_img {
		margin-top: 12.5%;
		width: 80px;
		margin-bottom: 24px;
	}
	.referer_first_div{
	    margin: 10% 0 5%;
		.referer_fried_img {
			padding: 0px 0 0 30px  !important;
			img {
				width: 100px;
			}
		}
		.referer_title{
			color:rgb(58, 175, 231);
			font-size: 24px;
	    	font-weight: 600;
	    	margin: 24px 0;
		}
	}
	.share-referral-email {
		font-size: 16px;
	    color: #000;
	    text-decoration: underline;
	    cursor: pointer;
	}
	.points {
		color:rgb(58, 175, 231);
		font-size: 24px;
    	font-weight: 600;
    	margin: 0;
	}
	.title {
        font-size: 18px;
    	color: black;
	}
	.fyt_earn {
		padding: 32% 0;
		color: #000;
		font-size: 24px;
		span {
			color: #36B6EC;
		}
	}
}
.what_you_get {
	text-align:center;
	font-family: 'Avenir Next Medium';
    color: #000000;
    padding: 50px 0 0;
    h2 {
        font-size: 34px;
        font-weight: 700;
        line-height: 42.5px;
	}
	h3 {
		font-size: 20px;
		line-height: 34px;
		letter-spacing: 0.25px;
	}
	.what_you_get_divs {
		padding: 50px 0;
		.get_div {
			background-color: rgb(245, 247, 248);
			border-color: rgb(217, 217, 217);
    		border-style: solid;
    		border-width: thin;
			border-radius: 4%;
			padding: 24px;
 			.cash {
				color: #3aafe7;
			    font-size: 34px;
			    font-weight: 600;
			    margin: 0;
			}
			.fyt_cash{
				font-size: 27.5px;
				font-weight: 600;
				letter-spacing: 0.5px;
				margin: 0 0 8px;
			}
			.points {
				color:rgb(58, 175, 231);
				font-size: 17.5px;
		    	font-weight: 600;
			}
		}
	}
	.primary-what-get-btn {
		font-family: 'Avenir Next Medium';
		padding: 10px 24px;
		font-size: 20px;
		margin:24px 0;
	}
}
.referrals_container{
	background-color: rgb(245, 247, 248);
    background-image: url(../img/rewards/Trainer-Client-Header.png), url(../img/rewards/Background1.png);
    background-position: 17.5% 50% , right bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: 310px 370px, auto;
	.referrals {
		width:35%;
		margin: auto;
		padding :100px 0 124px;
		.refer_frined_and_check .refer_friend_div .share_with_friend { 
			background-color:white;
		}
		.customers-input {
		    width: 80%;
		    height: 50px;
		    padding: 8px 10px;
		    margin: 10px 0;
		    font-size: 17.5px;
		    line-height: 1.42857143;
		    background-color: #fff;
		    border: 1px solid #ccc;
		    border-radius: 4px;
		    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
		    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
		    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
		}
		.customers-send-btn {
			width: 80%;
		    height: 50px;
		    padding: 8px 10px;
		    margin: 10px 0;
		    font-size: 17.5px;
		    font-weight: 600;
		    color: white;
		    line-height: 1.42857143;
		    background-color: rgb(35, 48, 63);
		    border: 1px solid rgb(35, 48, 63);
		    border-radius: 4px;
		    -webkit-box-shadow: inset 0 1px 1px rgb(35, 48, 63);
		    box-shadow: inset 0 1px 1px rgb(35, 48, 63);
		    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
		    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
		    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
		}
		.shared_emails_input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		    color: rgb(174, 174, 173);
		    opacity: 1; /* Firefox */
		    margin-left: 18px;
		}
	}
}
.referrals_auth_container {
	background-color: rgb(245, 247, 248);
    background-image: url(../img/rewards/Background1.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
    .referrals_auth{
    	padding: 81px 0;
		.refer_frined_and_check .refer_friend_div .share_with_friend {
			background-color:white;
		}
		.refer_frined_and_check .check_div .reedem_your_fyt {
	    	background-color:white;
		}
		.refer_frined_and_check .check_div .your_referrals {
			background-color:white;
		}
    }
}
#redeem_points_modal{
	font-family: 'Avenir Next Medium';
	.redem_points_header {
		background-image: url(../img/home-hero-small.jpg);
		background-position: inherit;
		background-size: 100%;
		background-repeat: no-repeat;
	}
	.redeem_points_title{
	    color: #000000;
	    font-size: 34px;
	    font-weight: 700;
	    margin : 0 0 15px;
	    padding-top: 60px;	
	}
	.points_modal{
	    font-size: 24px;
    	padding-bottom: 50px;
    	color: #000000;
	}
	.choices_modal{
		text-align:center;
		font-family: 'Avenir Next Medium';
	    color: #fff;
	    background-color: #36B6EC;
	    padding: 12.5px 0;
	    span {
	    	font-size: 24px;
	    	cursor: pointer;
	    }
	    span:hover {
	    	font-weight:600;
	    }
	}
	#get_rewards_modal {
		font-weight: bold;
	}
	#get_redeem_points_modal{
		margin-top: 24px;
		text-align:center;
		font-family: 'Avenir Next Medium';
		a:hover, a:focus {
			text-decoration: none;
		}
		a:hover .redeem_points_divs {
			background-color: #cfedf6;
		}
		.padding-reduced {
	    	padding-right: 6px;
    		padding-left: 6px;
		}
		.redeem_points_divs {
			cursor:pointer;
			background-color: rgb(245, 247, 248);
		    min-height: 250px;
		    margin-bottom: 10px;
		}
		.redeem_points_icon {
		    margin-top: 8%;
		    margin-bottom: 24px;
		    font-size: 24px;
		    color: #fff;
		    background-color: #3cb6ec;
		    padding: 15px;
		    border-radius: 50%;
		}
		.points {
			color:rgb(58, 175, 231);
			font-size: 24px;
	    	font-weight: 600;
	    	margin: 0;
		}
		.title {
	        font-size: 18px;
	    	color: black;
		}
	}
	#faqs_modal{
		height: 500px;
	    overflow-y: scroll;
	    overflow-x: hidden;
	    margin: 24px 0 15px;
	    padding: 0 50px;
	    .questions_rewards_modal {
	    	font-weight: 600;
	    	color: #000000;
	    	margin: 20px 0;
	    	font-size: 15px;
	    }
	    .questions_rewards_modal:nth-child(1){
	    	margin: 8px 0 20px ;
	    }
	}
	#faqs_modal::-webkit-scrollbar {
	    width: 10px;
	}
	 
	#faqs_modal::-webkit-scrollbar-track {
	    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
	    border-radius: 10px;
	}
	 
	#faqs_modal::-webkit-scrollbar-thumb {
	    border-radius: 10px;
	    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
	    background-color: #3aafe7;
	    background-clip: content-box;
	}
}
#fyt_rewards__text_img-row {
	display: flex;
    flex-direction: row;
}

@media screen{
    
    @media (max-width: @screen-xs-max) { 
        .fyt_rewards-text {
			padding: 24px 0px;
			text-align: center;
		}
		.fyt_rewards-img {
			padding: 24px 0px;
			text-align: center;
			padding-left: 50px;
		}
		.fyt_rewards-img img {
			width: 60%;
			object-fit: cover;
			object-position: center;
		}
        .how_rewards_work .works_div {
		    padding: 24px 0;
		}
		.how_earn_points .referer_first_div {
		    margin: 0% 0 5%;
		}
		.how_earn_points .how_refer_fiv {
		    margin-bottom: 10px;
		}
		.VIPs_earn {
		    padding: 50px 15px;
		}
		.VIPs_earn .vip_classes .elite {
		    margin-left: 0px;
		}
		.what_you_get {
		    padding: 24px;
		}
		.refer_frined_and_check .refer_friend_div .modal_friend {
		    padding: 24px 0 !important;
		}
		.refer_frined_and_check .refer_friend_div .refeer_a_friend p {
		    font-size: 15px;
		    margin: 5px 10px;
		}
		.refer_frined_and_check .refer_friend_div .share_with_friend a:nth-child(1) {
		    margin-right: 50px;
		}
		.refer_frined_and_check .refer_friend_div .share_with_friend a:nth-child(2) {
		    margin-right: 0px;
		}
		.refer_friend_div {
			margin-bottom : 24px;
		}
		.refer_frined_and_check .check_div .reedem_your_fyt h3 {
		    margin: 47px 24px;
		}
		.refer_frined_and_check .check_div .your_referrals_details_div #your_referrals_details .doesnt_have_referrals {
		    margin: 12.5% 8%;
		}
		.referrals_container .referrals {
		    width: 100%;
		    margin: auto;
		    padding: 24px;
		}
		.refer_frined_and_check .refer_friend_div .share_with_friend h4 {
		    padding: 0 5px;
		}
		.referrals_container {
		    background-image: url(../img/rewards/Background1.png);
		}
		.referrals_auth_container .referrals_auth {
		    padding: 50px 0;
		}
		#redeem_points_modal .redem_points_header {
			background-size: 124%; 
			background-position: right top;
		}
		#redeem_points_modal .redeem_points_title {
		    padding-top: 8px;
		}
		#redeem_points_modal .points_modal {
		    padding-bottom: 0px;
		}
		#redeem_points_modal .choices_modal span {
		    font-size: 20px;
		}
		#fyt_rewards__text_img-row {
			flex-direction: column-reverse;
		}	
    }
    
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { 
		.how_rewards_work .works_div {
		    margin: 34px 0;
		}
		.how_earn_points .referer_first_div {
		    margin: 0% 0 5%;
		}
		.how_earn_points .how_refer_fiv {
		    margin-bottom: 10px;
		    width: 75%;
    		margin-left: 12.5%;
		}
		.how_rewards_work .second-rewards-btn {
		    margin: 24px 0 0;
		}
		.how_earn_points .how_earn_divs {
		    width: 75%;
		    margin: 0 12.5%;
		}
		.VIPs_earn {
		    padding: 24px 0;
		}
		.VIPs_earn .vip_classes .multiplier {
		    display: none;
		}
		.VIPs_earn .vip_classes .elite {
		    margin-left: 0px;
		}
		.vip_classes img {
			display: none;
		}
		.what_you_get .what_you_get_divs .get_div {
		    width: 50%;
		    margin: 0 25%;
		    margin-bottom: 8px;
		}
		.refer_friend_div {
			margin-bottom : 24px;
		}
		.refer_frined_and_check .check_div .your_referrals_details_div #your_referrals_details .doesnt_have_referrals {
		    margin: 12.5% 8%;
		}
		#redeem_points_modal .redeem_points_title {
		    padding-top: 8px;
		}
		#redeem_points_modal .choices_modal span {
		    font-size: 20px;
		}
		#redeem_points_modal .points_modal {
		    padding-bottom: 0px;
		}
    }
    
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { 

    }
    
    @media (min-width: @screen-lg-min) { 

	}
}