@link-search-color: #41baed;

#search-results-v2 {
    #filtersModal {
        #close {
            z-index: 2;
            margin-left: 40px;
        }
        .modal-body {
            margin: 0;
            display: block;
        }
    }
    h4.search-subt {
        color: #000000;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 17px;
        margin-bottom: 12px;
        @media screen and (max-width: @screen-xs-max) {
            font-size: 14px;
        }
    }
    .filter-btn {
        color: #ffffff;
        font-size: 15px;
        letter-spacing: 0.65px;
        font-weight: bold;
        background-color: #5340ff;
        border-radius: 5px;
        position: fixed;
        padding: 10px 0;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 0;
        display: none;
        width: 145px;
        @media screen and (max-width: @screen-xs-max) {
            display: block;
        }
    }
    #first-col {
        border-right: 1px solid #e7e6e7;
        .reviews-box {
            border-bottom: 1px solid #e7e6e7;
            padding: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-right: -15px;
            .text {
                color: #565656;
                font-size: 16px;
                font-weight: bold;
                margin: 5px 0;
            }
            a {
                color: @link-search-color;
                font-size: 14px;
                font-weight: bold;
            }
        }
        .fyt-difference {
            border-bottom: 1px solid #ebebeb;
            display: flex;
            align-items: center;
            padding: 15px 0;
            justify-content: center;
            img {
                height: 55px;
                margin-left: 15px;
            }
            .title {
                color: #565656;
                font-size: 16px;
                font-weight: bold;
                margin: 5px 0 8px;
                text-align: left;
            }
            .text {
                color: #aeaeae;
                font-size: 14px;
                text-align: left;
                line-height: 17px;
            }
        }
    }
    .myhr {
        border: 1px solid #ebebeb;
        margin: 0 15px 10px;
        @media screen and (max-width: @screen-xs-max) {
            margin: 0 -15px 10px -15px;
        }
    }
    .filters {
        .apply-btn {
            color: @link-search-color;
            background-color: #ffffff;
            border: 1px solid @link-search-color;
            font-weight: 500;
            padding: 2px 12px;
            font-size: 16px;
            border-radius: 4px;
            margin-left: 15px;
            margin-bottom: 3px;
            &:hover {
                background-color: @link-search-color;
                color: #ffffff;
            }
        }
        padding: 20px 20px 15px 30px;
        .title {
            align-items: center;
            display: flex;
            margin-bottom: 12px;
            .main-text {
                color: #000000;
                font-size: 21px;
                font-weight: 600;
                letter-spacing: 0.75px;
                margin: 0;
            }
            .second-text {
                margin-left: auto;
                order: 2;
                .reset {
                    cursor: pointer;
                    color: @link-search-color;
                    font-size: 16px;
                    margin-left: 10px;
                    &:hover {
                        text-decoration: underline @link-search-color;
                    }
                }
            }
        }
        .filter-group {
            margin-bottom: 12px;
            display: flex;
            flex-direction: column;
            .title {
                font-size: 16px;
                font-weight: bold;
            }
            label {
                color: #4b4b4b;
                display: flex;
                margin-right: 5px;
                margin-left: 7px;
                span {
                    font-size: 15px;
                    margin-left: 8px;
                    margin-top: 1.3px;
                }
            }
        }
        #loc-options {
            .icon-loc{
                height: 20px;
                margin-left: -4px;
            }
        }
        @media screen and (max-width: @screen-xs-max) {
            padding: 5px;
            .title {
                .main-text {
                    font-size: 19px;
                }
                .second-text {
                    .reset {
                        font-size: 16px;
                    }
                }
            }
            .filter-group {
                label span {
                    font-size: 14px;
                }
            }
        }
    }
    .less {
        display: none;
    }
    .more, .less {
        color: @link-search-color;
        cursor: pointer;
        margin-left: 7px;
        font-size: 15px;
        i {
            margin-top: 5px;
            margin-left: 5px;
            font-size: 12px;
        }
        &:hover {
            text-decoration: underline @link-search-color;
        }
        &.is-city {
            margin-left: 0;
        }
        @media screen and (max-width: @screen-xs-max) {
            .more, .less {
                font-size: 14px;
            }
        }
    }
    .cities-near, .from-blog-search {
        padding: 10px 20px 20px 30px;
        p.item-link {
            font-size: 14px;
            color: @link-search-color;
            &.is-city {
                margin-bottom: 4px;
            }
            &:hover {
                text-decoration: underline @link-search-color;
            }
        }
    }
    .trainers-found {
        margin-top: 30px;
        padding: 0 4%;
        .head-search-div {
            display: flex;
            align-items: center;
            h2.tfound {
                color: #000;
                font-size: 19px;
                font-weight: 600;
                letter-spacing: .75px;
                margin: 0;
            }
            .breadcrumbs {
                color: #4c4c4c;
                font-size: 14px;
                font-weight: bold;
                margin: 18px 0 0 2px;
                a {
                    color: #4c4c4c;
                    &:hover {
                        color: @link-search-color;
                        text-decoration: underline @link-search-color;
                    }
                }
            }
            #sortby {
                margin-left: auto;
                order: 2;
                text-align: right;
                padding: 0;
                label {
                    color: #4c4c4c;
                    padding: 7px 0 0 0;
                }
                select {
                    height: 30px;
                    border: 1px solid #d7d7d7;
                    padding: 4px 12px;
                }
            }
            @media screen and (max-width: @screen-xs-max) {
                align-items: flex-start;
                flex-direction: column;
                h2.tfound {
                    font-size: 17px;
                }
                .breadcrumbs {
                    font-size: 12px;
                }
                #sortby {
                    order: unset;
                    text-align: unset;
                    margin-top: 16px;
                    font-size: 12px;
                    margin-left: 2px;
                    padding: 0 15px;
                }
            }
        }
        #map {
            margin-top: 20px;
            .map-container {
                //background: @light-cyan;
            }
            .header-map {
                width: auto;
                margin-left: auto;
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 12;
            }
            #map-canvas {
                height: 350px;
            }
            @media screen and (max-width: @screen-xs-max) {
                background-color: white;
                display: none;
                margin-top: 10px;
            }
        }
        .search-results-v2-trainers {
            margin-top: 15px;
            padding: 0;
            .trainer-item {
                display: inline-flex;
                .trainer-pricing span.one {
                    font-size: 13px;
                }
            }
        }
    }
}