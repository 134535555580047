@import (reference) 'colors.less';
@import (reference) 'utils.less';
@import (reference) 'bootstrap_less/variables.less';

.our-partners{
    font-family: 'Avenir Next Medium';
	padding: 3%;
	.title {
		text-align: center;
		font-size: 25px;
  		font-weight: 600;
	}
	background-color: @la-fafafa;
    .promoted-partners {
        display: block;
        text-align: center;
    }
	.partner {
		display: inline-block;
		vertical-align: middle;
		padding: 22px 33px;
		img {
			opacity: 0.7;
			margin: 0 auto;
			max-height: 200px;
		}
	}
}
.gym-list-container {
    display: block;
	margin: 2em 0px;
    padding: 0 3%;
    .gym-list {
        margin-bottom: 2em;
        a {
            color: @cyan;
            font-size: 15px;
        }
        .gym-item {
            margin-bottom: 6px;
        }
    }
}
@media screen{
    @media (max-width:480px){
        .gym-list {
            text-align: center;
        }
    }
}
