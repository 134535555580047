.trainer-signup-form {
    width: 70%;
    margin: 20px auto;
    label {
        font-weight: bold;
    }
    .helptext {
        color: gray;
    }
    fieldset {
        legend {
            font-weight: bold;
        }
        margin-top: 20px;
    }
    .training-since {
        .fields .field {
            display: inline-block;
            width: 45%;
            vertical-align: top;
            &> * {
                display: inline-block;
            }
            label {
                font-weight: normal;
            }
            &:last-child {
                margin-left: 5%;
            }
        }
        margin-bottom: 15px;
    }

    #div_id_training_style_other {
        display: none;
    }

    #div_id_miles_willing_to_travel {
        .controls {
            margin-top: 15px;
        }
    }

    #div_id_personal_webpage {
        div.controls {
            margin-top: 15px;
        }
    }

    #div_id_prior_training_experience {
        div.controls {
            margin-top: -10px;
        }
    }
}

#cropContainerHeader{
  width: 320px;
  height: 320px;
  position: relative;
  border: 1px solid #ccc;
  margin-bottom: 30px;
  margin-top: 20px;
}

#profile-image{
    width: 320px;
    height: 320px;
    position: relative;
}

#div_id_where_do_you_train input[type="checkbox"] {
    position: static;
    margin-right: 6px;
    margin-left: 0;
}

.trainer-signup-form label.radio{
    font-weight: normal;
}

#croppic-container{
    margin-bottom: 20px;
}

#trainer-wizard, #edit-trainer{
    background-color: #f4f4f4;
    ul.wizard-menu{
        background-color: #ffffff;
        margin-bottom: 0;
        li > a{
            color: #716e6e;
            text-decoration: none;
            word-wrap: break-word;
        }
        li.wizard-active{
            background-color: #ededed;
        }
        li.menu-first-item, li.menu-item{
            border: none;
            border-radius: 0;
            border-bottom: 3px solid #d7d7d7;
            @media (min-width: 800px){
                a.preview-profile{
                    margin-left: 50px;
                }
            }
            @media (max-width: 415px){
                a.preview-profile{
                    button{
                        font-size: 10px;
                        padding: 6px 6px;
                        margin-top: 5px;
                    }
                }
            }
        }
        li.menu-item{
            font-weight: bold;
        }
    }
    .add-photo{
        font-size: 15px;
        margin: 10px 0;
        cursor: pointer;
    }
    ul.guidelines{
        list-style-type: disc;
        margin-top: 20px;
    }
    .col-form{
        margin: 20px 0;
    }

    .primary_location {
        .row-map{
            padding: 15px;
        }
    }

    .addl_locations_section.editing {
        .row-map{
            padding: 15px;
        }
    }

    #images-container {
        margin-bottom: 1em;
    }

    div[id$="where_do_you_train"], div[id$="requeriments"] {
        margin-left: 20px;
    }

    fieldset {
        legend {
            text-transform: uppercase;
            font-weight: bold;
            border-bottom: none;
            font-size: 1.2em;
        }
    }

    .specialties_and_services, .in_home_location, .details_about_you{
        label{
            font-weight: bold;
        }
    }

    input.btn-margin-5{
        font-size: 16px;
    }
    button.btn-margin-5{
        float: left;
    }

    input[type="radio"][readonly]{
        pointer-events: none;
    }

    #add-location{
        margin: 20px 0;
    }

    .addl_locations_section{
        margin-top: 20px;
    }

    .fields{
        padding-left: 15px;
    }

    .location-type-row{
        margin: 20px 0 0 20px;
    }

    .addl-loc{
        padding-left: 10px;
        margin-bottom: 50px;
        .location-type{
            padding-left: 40px;
        }
    }

    .steps-menu{
        button.steps-menu{
            float: left;
            font-size: 20px;
            width: 40px;
        }
        ul.wizard-menu{
            margin-top: 40px;
        }
    }

    @media (min-width: 768px) {
        li.menu-first-item{
            padding: 20px 0 20px 20px;
            font-size: 17px;
        }
        li.menu-item{
            padding: 20px 0 20px 40px;
            font-weight: bold;
            font-size: 15px;
        }
        .col-form{
            padding: 0 0 0 50px;
            margin-bottom: 20px;
        }
        .show-xs{
            display: none;
        }
        .show-s{
            display: none;
        }
    }

    @media (min-width: 374px) and (max-width: 767px) {
        li.menu-first-item, li.menu-item{
            padding: 10px;
            font-size: 12px;
        }
        button.btn-margin-5{
            float: left;
            font-size: 12px;
            padding: 6px 4px;
        }
        input.btn-margin-5{
            font-size: 12px;
            padding: 6px 4px;
        }
        .show-xs{
            display: none;
        }
        .show-s{
            display: block;
        }
        .hide-s{
            display: none !important;
        }
    }
    @media screen and (max-width: 373px){
        li.menu-first-item, li.menu-item{
            padding: 10px;
            font-size: 11px;
        }
        .show-s{
            display: block;
        }
        .hide-s{
            display: none !important;
        }
        .hide-xs{
            display: none !important;
        }
        .show-xs{
            display: block;
        }
        button.btn-margin-5{
            float: left;
            font-size: 10px;
            padding: 6px 6px;
        }
        input.btn-margin-5{
            font-size: 11px;
        }
        p.help-block{
            font-size: 11px;
        }
        label{
            font-size: 12px;
        }
        fieldset legend {
            font-size: 1em;
            margin-bottom: 0;
        }
        p, fieldset{
            font-size: 12px;
        }
    }
}

#edit-trainer{

    .text-left{
        margin: 0 0 10px 15px;
    }

    .remove-loc, .make-primary, .remove-primary-location, .cancel-location{
        font-size: 15px;
        margin-top: 25px;
        float: right;
        margin-right: 10px;
    }

    @media (min-width: 415px) (max-width: 1199px){
        ul.wizard-menu{
            li.menu-first-item, li.menu-item{

                a.preview-profile{
                    margin-left: 0px !important;
                }
            }
        }
    }
    @media (max-width: 414px){

    }
}