@import (reference) 'utils.less';
@import (reference) 'colors.less';
@import (reference) 'bootstrap_less/bootstrap.less';

.trustpilot-homepage {
	padding: 40px 5%;
	background: @la-fafafa;
	text-align: center;
	.title{
		margin: 0px;
		color: @dark-gray;
        .semibold(30px);
	}
	.trustpilot-widget{
		margin-top: 50px;
	}
}

.hero-in-home {
	&:extend(.parent-hero);
	background: url('../images/in-home-hero.png') center 0 no-repeat;
	background-size: 100% !important;
	padding: 10px 0 260px;
	.container {
		margin-top: 150px;
		p {
			font-family: 'Avenir Next Medium';
  			font-weight: 800;
  			font-size: 40px;
		}
	}
}

.hero-group-training {
	&:extend(.parent-hero);
	background: url('../images/About-Partner-and-Small-Group.png') center 0 no-repeat;
	background-size: 100% !important;
	padding: 10px 0 170px;
	.container {
		margin-top: 150px;
		p {
			font-family: 'Avenir Next Medium';
  			font-weight: 800;
  			font-size: 40px;
		}
	}
}

.splitter {
	border-bottom: 1px solid #CCCCCC;
}

.in-home-info {
	.container-fluid();
    background-color: white;
    padding: 0px 12%;
    .info-container {
    	margin-top: 25px;
	    .topic-title {
	    	p {
	    		font-weight: 600;
	    		font-size: 20px;
	    	}
	    }
	    .topic-info {
	    	font-size: 17px;
	  		font-weight: 300;
	  		padding-bottom: 30px;
	  		ul {
	  			list-style: initial;
	  			padding-left: 25px;
	  		}
	    }
	    h3.epigraph {
	    	line-height: 1.4em;
	    	color: @text-grey;
	    }
	}
}

@media (max-width: @screen-xs) {
	.hero-in-home {
		background: url('../images/in-home-hero-mobile.png') center 0 no-repeat;
		padding: 16px 0 50px;
		.container {
			margin-top: 0px;
			p {
	  			font-size: 24px;
			}
		}
	}
}

@media (min-width: @screen-xs) and (max-width: @screen-xs-max){
	.hero-in-home {
		padding: 15px 0 100px;
		.container {
			margin-top: 40px;
			p {
	  			font-size: 34px;
			}
		}
	}
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max){
	.hero-in-home {
		padding: 15px 0 100px;
		.container {
			margin-top: 40px;
			p {
	  			font-size: 50px;
			}
		}
	}
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max){
	.hero-in-home {
		padding: 15px 0 100px;
		.container {
			margin-top: 104px;
			p {
	  			font-size: 50px;
			}
		}
	}
}