@import (reference) 'utils.less';

.container.gray{
    background-color: #f5f5f5;
}
#inbox {
    margin: 0;
    @media screen and (max-width: @screen-xs-max){
        margin: 0;
    }

    * {
        padding: 0;
        box-sizing: border-box;
    }

    .btn {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 6px;
        color: white;
        // background-color: @main-color;
        // border-color: @main-color;
        background-color: #79e9d1;
        padding: 8px 50px;  
        &:hover {
            background-color: lighten(@main-color, 10%);
            border-color: lighten(@main-color, 10%);
        }
        @media screen and (max-width: @screen-xs-max){
            width: 100%;
        }
    }

    #hangout {
        background: transparent;
        @media screen and(max-width: @screen-xs-max){
            background: #f5f5f5;
        }
        height: 80vh;
        width: 100%;
        transition: all 0.444s cubic-bezier(0.7, 0, 0.3, 1);
        overflow: hidden;
        margin: 2% auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media screen and (max-width: @screen-xs-max){
            margin: 0;
            border-top: 1px solid #d3d3d3; 
        }
        .chat-column{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .column-head{
                height: 75px;
                flex-shrink: 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding-left: 20px;
                border-bottom: 1px solid #d3d3d3;
                background-color: #fff; 
                @media screen and (min-width: @screen-xs-max){ 
                    border-radius: 5px 5px 0 0; 
                }
                @media screen and(max-width: @screen-xs-max){
                    height: 50px;
                }
            }
        }
        .chat-left-column{
            width: 30%;
            // background: red;
            @media screen and (max-width: @screen-xs-max){
                width: 100%;
            }
            h3.message-title{
                font-family: 'Avenir Next Medium';
                font-weight: bold;
                font-size: 16px;
                margin: 0;
                padding: 0;
            }
            #content{
                flex-shrink: 0;
                flex-grow: 0;
                height: auto;
                background-color: #fff;
                @media screen and (min-width: @screen-xs-max){
                    border-radius: 0 0 5px 5px;   
                }
            }
        }
        .chat-right-column{
            width: 68%;
            @media screen and (max-width: @screen-xs-max){
                display: none;
                width: 100%;
                height: 80vh;
            }
            #content-right{
                flex-shrink: 0;
                background-color: #fff;
                @media screen and (min-width: @screen-xs-max){
                    border-radius: 0 0 5px 5px;
                }
                
                #message-chat{
                    width: 95%;
                    margin: 0 auto;
                    background-color: #f5f5f5;
                    padding: 20px;
                    height: auto;
                    @media screen and(max-width: @screen-xs-max){
                        width: 100%;
                        padding: 20px 20px 40px 20px;
                    }
                    form{
                        background-color: transparent;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        width: 100%;
                        margin: 0 auto;
                        textarea{
                            padding: 10px;
                            width: 100%;
                            margin-top: 0;
                            @media screen and(max-width: @screen-xs-max){
                                width: 100%;
                            }
                        }
                    }
                }
            }
        } 
    }

    .msg-head{
        display: flex;
        flex-direction: row;
    }

    #warning-box {
        border: 4px solid red;
        margin: 2% auto;
        padding: 15px;
        font-size: 16px;
    }

    #inboxhead {
        min-height: 63px;
        left: 0;
        top: 0;
        width: 100%;
        height: 10%;
        z-index: 90;
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        transition: all 0.333s ease-in-out;
    }

    #content {
        float: left;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background-color: white;
        transition: all 0.444s cubic-bezier(0.7, 0, 0.3, 1);
    }
    #content.chat {
        background-color: #e5e5e5;
    }


    .list-chat {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 20px;
        @media screen and(max-width: @screen-xs-max) {
            padding-bottom: 0;
        }
    }

    .list-chat .meta-bar {
        border-bottom: 1px solid #7daefe;
        padding: 0 0 0 25px;
        height: 40px;
        position: relative;
        width: 100%;
        background-color: white;
    }

    .list-chat .meta-bar.chat {
        background: white;
        border-bottom: none;
    }

    ul.chat {
        width: 100%;
        height: 55%;
        overflow-y: scroll;
        flex-shrink: 0;
        margin-bottom: 0;
    }

    #new-message-form {
        height: 100%;
    }

    ul.chat li {
        padding: 15px 25px 15px 25px;
        display: inline-flex;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        transition: all 0.18s ease-in;
        position: relative;
    }
    ul.chat li img {
        height: 42px;
        width: 42px;
        border-radius: 100%;
        @media screen and(max-width: @screen-xs-max){
            height: 25px;
            width: 25px;
        }
    }
    ul.chat li .message {
        max-width: 85%;
        margin: 0 10px;
        padding: 10px 10px 10px 20px;
        font-size: 1em;
        min-width: 50%;
        position: relative;
        background: white;
        font-family: 'Avenir Next Medium';
        color: #656565;
    }
    ul.chat li .message:not(.color-sended) {
        border: 1px solid #e3e3e3;
    }
    ul.list {
        width: 100%;
        padding-bottom: 90px;
    }
    ul.list li {
        cursor: pointer;
        padding: 25px 15px;
        display: inline-flex;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        transition: all 0.18s ease-in;
        position: relative;
        overflow: hidden;
    }
    ul.list li.li-msg {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }
    ul.list li.context {
        box-shadow: inset -1px 20px 4px -14px rgba(50, 50, 50, 0.3);
        color: white;
        animation: anim-context 0.35s 1 ease-out;
        overflow: hidden;
        animation-fill-mode: forwards;
    }
    ul.list li.context i {
        opacity: 0.5;
        font-size: 1.2em;
        margin-right: 20px;
        transition: opacity 0.3s ease-out;
    }

    ul.list li img {
        height: 42px;
        width: 42px;
        border-radius: 100%;
    }
    ul.list li .name {
        font-weight: bold;
        padding: 10px 0 10px 20px;
        display: flex;
        justify-content: space-between;
    }
    ul.list li .content-container .name {
        font-weight: bold;
        padding: 0 0 0 20px;
        font-size: 1.2em;
    }

    ul.list li .removeInSent {
        font-size: 0.9em;
        margin: 13px 0 0 auto;
    }

    ul.list li .reply {
        font-size: 0.9em;
        margin: 13px 0 0 auto;
    }
    ul.list li .restore,
    ul.list li .removeInInbox {
        font-size: 0.9em;
        margin: 3% 0 0 97%;
        ;
    }
    ul.list li .time {
        color: #2E2E2E;
        font-size: .9em;
        font-family: 'Avenir Next Medium';
        flex-shrink: 0;
    }
    .time.current{
        color:  #000;
    }
    ul.navmsgs {
        list-style-type: none;
        width: 100%;
        height: 50px;
        border-top: 1px solid #eceded;
        background-color: white;
        z-index: 10;
        bottom: 0;
        transition: all 0.333s ease-in-out;
    }
    ul.navmsgs li {
        height: 50px;
        overflow: hidden;
        display: inline-block;
        width: 49.8%;
        text-align: center;
        font-size: 1.4em;
        color: #676767;
        cursor: pointer;
        padding: 10px 0 10px 0;
        transition: all 0.18s ease-in;
        position: relative;
    }
    .sended-time {
        float: left;
        font-size: x-small;
    }
    .position-sended {
        flex-direction: row;
    }
    .position-received {
        flex-direction: row-reverse;
    }
    .color-received {
        background-color: rgba(67, 216, 84, 0.57) !important;
    }
    .color-sended {
        color: #656565;
        background-color: #fafafa !important;
    }
    .remove {
        color: rgba(255, 0, 0, 0.48);
    }
    .restore {
        color: rgb(26, 172, 233);
    }
    .restore.current {
        color: rgb(173, 243, 254) !important;
    }
    .remove:hover {
        cursor: pointer;
    }
    .left {
        float: left;
    }
    .empty-inbox {
        margin-top: 50px;
        color: rgba(66, 139, 202, 0.54);
    }

    .FYT-difference {
        background-color: white;
        padding: 10px 20px;
        border-radius: 5px;
        margin-top: 10px;
      }
      
      .FYT-difference img {
        width: 80%;
      }
      
      .FYT-difference h2 {
        margin-bottom: 10px;
        margin-top: 10px;
        color: black;
        font-size: 15px;
        font-weight: 700;
        font-family: 'Avenir Next Medium';
      }
    
      .FYT-difference p {
        color: rgb(115, 115, 115);
        font-size: 14px;
        font-weight: 600;
        padding-left: 10px;
      }
    

    @media screen and (max-width: 818px) {
        ul.navmsgs li {
            height: 50px;
            overflow: hidden;
            display: inline-block;
            width: 49.5%;
            text-align: center;
            font-size: 1.4em;
            color: #676767;
            cursor: pointer;
            padding: 10px 0 10px 0;
            transition: all 0.18s ease-in;
            position: relative;
        }
    }
    .content-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 140px;
    }

    #content::-webkit-scrollbar,
    ul.chat::-webkit-scrollbar {
        width: 5px;
    }
    #content::-webkit-scrollbar-track,
    ul.chat::-webkit-scrollbar {
        background-color: #eaeaea;
        border-radius: 10px;
    }
    #content::-webkit-scrollbar-thumb,
    ul.chat::-webkit-scrollbar {
        background-color: #ccc;
    }
    #content::-webkit-scrollbar-thumb:hover,
    ul.chat::-webkit-scrollbar:hover {
        background-color: rgba(83, 139, 202, 0.47);
    }
    #id_content::-webkit-scrollbar {
        display: none;
    }
    #content-right {
        width: 100%;
        height: calc(~'100% - 90px');
    }

    #read-unread {
        float: left;
        margin-right: 10px;
    }

    a.current {
        color: white !important;
    }
    a.title-current-list {
        padding: 20px !important;
    }
    .width100 {
        width: 100%;
    }

    .nav-tabs>.active>a,
    .nav-tabs>.active>a:hover,
    .nav-tabs>.active>a:focus {
        background-color: #E0E0E0 !important;
    }
    a {
        text-decoration: none !important;
    }

    #listhead {
        height: 100%;
        width: 100%;
        float: left;
    }

    #msghead {
        height: 100%;
        float: right;
        width: 100%;
    }

    div.container-li-msg.current,
    div.container-li-msg.current.unreadmsg {
        background-color: #fafafa;
    }
    div.container-li-msg.unreadmsg {
        background-color: rgba(139, 139, 139, 0.12);
    }
    a.msgs,
    a.list-items,
    a.title-current-list {
        color: rgba(8, 8, 8, 0.87);
        font-family: 'Avenir Next Medium';
    }
    a.list-items {
        padding: 3px 20px;
    }

    .invisible {
        visibility: hidden;
    }

    li.li-msg {
        border-bottom: 1px solid #d3d3d3;
        a {
            width: 85%;
            padding-right: 10px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            span.msg-title{
                width: 100%;
                height: 33px;
                overflow: hidden;
                display: block;
                color: #9b9b9b;
                @media screen and(max-width: @screen-xs-max) {
                    height: 16px;
                }
            }
            div.avatar-container,
            div.module {
                display: inline-block;
            }
            div.module {
                width: 80%;
            }
        }
    }

    .right {
        float: right;
    }

    #msg-head-menu {
        display: none;
    }

    .unstyledbutton {
        font-family: 'Avenir Next Medium';
        border: none;
        background-color: transparent;
    }

    .back-to-msgs {
        display: none;
    }

    .msg-head-left {
        float: left;
        width: 70%;
    }
    .mobile-head {
        display: none;
    }

    .xs-head {
        display: none;
    }

    #ul-dropwdown-head-menu {
        display: none;
    }

    .module,
    .emptymodule {
        overflow: hidden;
        min-width: 145px;
    }
    .module {
        margin: 0;
    }
    .emptymodule {
        margin: 0 auto 1em auto;
        color: #36C3F7;
    }

    .line-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    div.current.line-clamp {
        color: #000;
        background-color: transparent;
    }
    div.unreadmsg.line-clamp {
        font-weight: bold;
    }
    .module p {
        margin: 0 0 0 10px;
    }
    .sender-name {
        font-size: 14px;
        font-family: 'Avenir Next Medium';
        font-weight: 600;
    }
    .normal-head {
        display: flex;
        align-items: center;
        a {
            position: absolute;
            right: 21px;
        }
        .btn-to-profile {
            background-color: #5340FF;
            border-radius: 5px;
            height: 52.5px;
            color: #FFF;
            font-family: 'Avenir Next Medium';
            font-size: 17.5px;
            font-weight: 700;
            letter-spacing: 0.8px;
            line-height: 24px;
            width: auto;
            padding: 0 10px;
            text-align: center;
            border: 0;
            &:hover {
                background-color: #5d4bff;
                text-decoration: underline transparent;
            }
        }
    }
    .message-recipient-title{
        font-size: 26px;
        font-family: 'Avenir Next Medium';
        font-weight: 600;
        margin-top: 10px;
    }
    .msg-title {
        font-size: 12px;
        font-family: 'Avenir Next Medium';
    }
    .sender-title {
        min-width: 300px;
        margin-top: 10px;
        font-family: 'Avenir Next Medium';
    }

    #id_content {
        width: 85%;
        border-color: inherit;
        box-shadow: none;
        border: none;
        resize: none;
        margin-top: 10px;
        flex-shrink: 0;
        height: 110px;
    }

    #replymsgspinner {
        padding-top: 20px;
    }

    @media (min-width: @screen-sm-min) {
        .show-xs {
            display: none;
        }
    }

    @media (min-width: @screen-md-min) {
        #warning-box {
            width: 1200px;
        }
    }

    @media (max-width: @screen-xs-max) {
        .hide-xs {
            display: none;
        }
        #buttonsendmsg{
            width: 100%;
        }
    }

    @media only screen and (min-width:768px) and (max-width: 1200px) {
        #hangout {
            width: 100% !important;
        }
        #content,
        #listhead {
            width: 37%;
        }
        #content-right,
        #msghead {
            width: 63%;
        }
        .content-container {
            width: 113px;
        }
        #msg-head-buttons {
            display: none;
        }
        #msg-head-menu {
            display: inline;
        }
        #dropdownMsgHead {
            margin-top: 10px;
        }
    }
    @media (max-width: 767px) {
        #hangout {
            width: 100% !important;
        }
        #msghead {
            width: 100% !important;
        }
        #listhead {
            width: 100% !important;
        }
        #content-right {
            width: 100% !important;
        }
        #content {
            width: 100% !important;
        }
        #msg-head-buttons {
            display: none;
        }
        #msg-head-menu {
            display: inline;
        }
        #dropdownMsgHead {
            margin-top: 10px;
        }
        .back-to-msgs {
            display: inline;
            //float: left;
            margin: 15px 20px 10px 0;
            ;
            font-size: 18px;
            color: #8B8B8B;
        }
        ul.list li .removeInInbox {
            font-size: 0.9em;
        }
        ul.chat li .message {
            max-width: 250px;
            p {
                word-wrap: break-word;
            }
        }
    }

    @media (max-width: 370px) {
        .normal-head {
            display: none;
        }
        .xs-head {
            display: block;
            font-weight: bold;
            font-family: 'Avenir Next Medium';
            margin-top: 15px;
            font-size: 15px;
        }
        .msg-head {
            .msg-head-left {
                width: 65%;
            }
            .right {
                width: 35%;
                a {
                    margin-top: 10px;
                }
            }
        }
    }
}
@media (max-width: @screen-xs-max) {
    div.p-0{
        padding: 0;
    }
}