@import (reference) 'bootstrap_less/bootstrap.less';

@import (reference) 'colors.less';
@import (reference) 'fonts.less';

.sub-nav-container {
    //background: white;
    .sub-nav {
        margin: 0;
        min-height: initial;
        border: none;
        .nav {
            li {
                float: left;
                a {
                    &.active { font-weight: bold !important; }
                    text-transform: uppercase;
                    .regular(16px);
                    color: #666;
                    padding-left: 0;
                    font-weight: bold;
                    &:hover, &:focus {
                        background-color: transparent;
                        color: #333;
                    }
                }
                @media screen and (max-width: @screen-xs-max) {
                    a {
                        .regular(14px);
                    }
                }
            }
        }
    }
}
@media screen {
    @media (max-width: (@screen-xs-min - 1)){
        .sub-nav-container .sub-nav .nav li a .trainers{
           display: none;
        }
    }
}