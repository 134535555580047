@import (reference) 'utils.less';

#intro {
    color:#464646;
    background:#f4f4f4;
}

.become-fyt-pro{
    position: relative;
    //padding: 25px 0 200px;

    .engage {
        //position: relative;
        //top: 120px;
        margin-top: 46%;
        text-align: left;

        h1{
            .extrabold(2.3em);
        }
        h3{
            .bold(16px);
            line-height: 1.3em;
            margin: 30px 0;
        }
    }
    .trainers{
        background-size: cover;
        background: url('../img/trainer-hero.jpg') center 0 no-repeat;
    }
}

.become-fyt-spot{
    position: relative;
    .engage {
        //position: relative;
        //top: 120px;
        margin-top: 35px;
        text-align: left;

        h1{
            .extrabold(2.3em);
        }
        h3{
            .bold(16px);
            line-height: 1.3em;
            margin: 30px 0;
        }
    }
    .trainers{
        background-size: cover;
        background: url('../img/trainer-hero.jpg') center 0 no-repeat;
    }
    .gym-logos {
      .link-container {
        width: 49%;
        float: left;
        min-height: 60px;
      }
      margin: 20px 0;
      img {
        opacity: 0.6;
      }
    }
}

.fyt-pro{
  position: relative;
  background: none;
  h2 {
    width: 100%;
    position: absolute;
    top: 4%;
    z-index: 3000;
    color: #fff;
    font-size: 20px;
    font-weight: 800;
  }
  .carousel-container {
      .carousel-item {
          .info {
              .quote {
                  margin: 20% 20% 1%;
              }
          }
      }
   }
  .btn-cont {
      width: 100%;
      position: absolute;
      bottom: 100px;
      z-index: 3000;
  }
}

#next-steps {
  background: #f4f4f4;
  color:#464646;
  padding-top: 50px;
  h3 {
    font-size: 2.1em;
    font-weight: 800;
    margin-bottom: 40px;
  }

  #who-we-are-looking {
    b {
      font-size: 14px;
      margin-bottom: 20px;
      float: left;
    }
    ul {
      float: left;
      padding: 0px 18px;
      li {
        margin-bottom: 15px;
      }
    }
  }

  #how-to-become-fytpro{
    ul {
      font-size: 15px;
      list-style: none;
      padding: 0px;
      li {
        margin-bottom: 20px;
      }
    }
  }
  .btn-cont {
    text-align: center;
    padding: 30px 0px;
  }

}

//NEW

#trainer-submit{
  // NEW AT 04/30/2020
  #become-fyt-pro-v2 {
    font-family: 'Avenir Next Medium';
    h1 {
      font-family: 'Tiempos Head Line';
      color: #000000;
      font-size: 42px;
    }
    p {
      color: #5b585d;
      font-size: 20px;
      line-height: 34px;
    }
    .btn-trainer {
      width: 295px;
      height: 50px;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      border: 1px solid transparent;
      border-radius: 6px;
      background-color: #5340ff;
      color: #ffffff;
      padding: 12px 0;
    }
    .need-clients-row {
      padding: 40px 0 40px 80px;
      background-color: #f4f6f8;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .need-clients-col {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        &#first-col {
          margin-left:160px;
        }
        img {
          width: 735px;
          height: 535px;
        }
      }
    }
    .work-with-best {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      p {
        color: #5b585d;
        font-size: 18px;
        line-height: 34px;
      }
      img {
        margin-top: 55px;
        height: 410px;
        width: 720px;
      }
    }

    .ticks {
      display: flex;
      justify-content: center;
      img.check {
        margin-top: 3px;
        height: 25px;
        width: 25px;
      }
      p {
        margin-left: 10px;
        text-align: left;
        font-size: 18px;
        width: 70%;
      }
    }

    .why-a-trainer.fyt-pro{
      margin-top: 50px;
      h1 {
        margin-bottom: 35px;
      }
      p {
        color: #ffffff;
      }
    }

    #our-press{
      padding-bottom: 50px;
      p {
        font-size: 18px;
      }
      img{
        margin: 50px auto;
      }
    }

    #how-it-works{
      margin: 50px 0;
      text-align: center;
      .item-container {
        font-size: 16px;
        margin-top: 30px;
        .item {
          margin: 0 20px;
          .img-div {
            margin: 0 auto;
            background-color: #f4f6f8;
            border-radius: 50%;
            height: 80px;
            width: 80px;
            img {
              height: 35px;
              width: auto;
              margin: 22px;
            }
          }
          .second-container {
            h1 {
              font-size: 24px;
              margin-bottom: 20px;
              line-height: 30px;
            }
            p {
              font-size: 18px;
            }
          }
        }
      }
      .btn-cont {
        margin: 50px 0;
      }
    }

    @media(max-width:767px){
      .need-clients-row {
        padding: 10px;
        flex-direction: column;
        .need-clients-col {
          &#first-col {
            margin-left: 0;
            align-items: center;
            margin-bottom: 20px;
            p {
              padding: 0 10px;
            }
          }
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .work-with-best {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  
  //////////////////////////////////////////////////////////////////

  //NEW
  header.become-fyt-pro{
    .container{
      .header-right{
        padding: 10px;
        a{
          float: right;
        }
      }
    }
  }

  .section{
    position: relative;
    color: #fff;
    text-align: center;
    background-size: cover;
    padding: 25px 0 200px;
    h1, h2 {
      font-family: 'Avenir Next Medium';
      font-weight: 800;
      font-size: 55px;
    }
    h3 {
      font-family: 'Avenir Next Medium';
      font-weight: 700;
      font-size: 18px;
      line-height: 1.3em;
      margin: 30px 2%;
    }
  }

  #intro{
    color: #fff;
    height: 600px;
    background-image: url(../images/trainers-full-hero.jpg);
    background-size:cover;
    .epigraph{
      margin-top: 150px;
    }
  }

  #cobranding{
    color: #494545;
    padding-bottom: 100px;
    img{
      margin: auto;
    }
    h2{
      font-weight: bold;
      font-size: 35px;
    }
    .epigraph{
      margin-bottom: 60px;
    }
    .btn-cont{
      margin-top: 50px;
    }
    ul{
      line-height: 24px;
      margin-top: 20px;
      li{
        font-weight: bold;
        .bullet{
          font-size: 10px;
        }
      }
    }
  }

  #faq{
    background-color: #e6e6e6;
    padding-bottom: 50px;
    color: #000000;
    h2{
      margin-top: 40px;
      font-size: 2.2em;
      padding-top: 40px;
    }
    .container{
      /*background-color: rgba(128, 128, 128, 0.1);*/
      li{
        padding: 15px;
        font-size: 18px;
      }
      h3{
        font-size: 20px;
      }
      .expand-all, .collapse-all{
        text-align: right;
        p{
          margin-right: 20px;
        }
      }
      a, a:hover{
        color: #333333;
        text-decoration: none;
        cursor: pointer;
      }
      .expand{
        float: right;
        margin-right: 15px;
        /*background-color: #36b6ec;*/
        color: #36b6ec;
        padding: 5px;
        border-radius: 5px;
      }
      ul.answer{
        padding-left: 10px;
      }
      ul.answer > li {
        margin-top: 20px;
        padding: 15px 80px 15px 15px;
      }
      padding: 20px 0;
    }
  }

  .prefooter{
    display: none;
  }

}

@media screen{
    @media (min-width:990px){ //minimun of 600px apply this
        #next-steps {
          #how-to-become-fytpro{
            padding-right: 10%;
          }
          #who-we-are-looking {
            padding-right: 5%;
          }
        }
    }
    @media (min-width: 0) and (max-width: 599px){
        .quote {
            margin: 10% 20% 1%;
        }
        .become-fyt-pro {
           .engage {
              margin: 25px 0;
           }
        }
        .fyt-pro .btn-cont {
            bottom: 60px;            
        }
        #trainer-submit{
          header.become-fyt-pro{
            .container{
              .header-right{
                display: none;
              }
            }
          }
          #intro{
            background-image: url(../img/trainer-hero.jpg);
            background-size:cover;
            .epigraph{
              margin-top: 100px;
            }
          }
          #cobranding{
            margin-top: 50px;
            padding-bottom: 50px;
            ul{
              padding-left: 0;
            }
            img{
              margin-top: 20px;
              margin-bottom: 20px;
            }
          }
          #how-it-works{
            h2{ padding: 15px; }
          }
          #our-press{
            h1{ font-size: 40px; }
            padding-bottom: 50px;
          }
        }
    }

    @media (min-width: 600px) and (max-width: 990px){
        .become-fyt-pro {
           .engage {
              margin: 27px 0;
           }
        }
        #trainer-submit{
          #intro{
            background-image: url(../img/trainer-hero.jpg);
          }
          #cobranding{
            margin-top: 50px;
            padding-bottom: 50px;
            img{
              margin-top: 20px;
              margin-bottom: 20px;
            }
          }
          #our-press{
            h1{ font-size: 40px; }
            padding-bottom: 50px;
          }
        }
    }

}
