@import (reference) 'utils.less';

.hero-work-with-us {
    &:extend(.parent-hero);
    h1{
        .extrabold(2.2em);
    }
    h3{
        .bold(18px);
        line-height: 1.3em;
        margin: 30px 2%;
    }   
    background: ~"url('../img/fun-slide-with-opacity.jpg') 84% 0/cover no-repeat";
    .btn-cont {
        .btn:first-child {
            margin-right: 60px;
        }
    }
}