@import (reference) 'bootstrap_less/grid.less';
@import (reference) 'colors.less';

#search-results,
#dropbox {
    background: @background-grey;

    section#header {
        .best-personal-trainers{
            background: url(../img/search/search-bg-banner.png) no-repeat;
            background-size: 100%;
            height: 224px;
            margin-bottom: 20px;
            padding: 15px;
            h1 {
                font-size: 26px;
                font-weight: 700;
                color: #FFF;
                letter-spacing: 0.8px;
            }
            h2 {
                color: #FFF;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: .8px;
                line-height: 1.24;
            }
            p, a {
                color: #FFF;
                #toggle-map, #hide-filters {
                    text-decoration: underline;
                }
            }
            .review {
                height: 224px;
                padding-right: 0px;
                padding-left: 30px;
                margin-top: -15px;
                background-color: #26334275;
                opacity: 1;
                img {
                    margin: 50px 0 15px;
                }
                p {
                    font-size: 15px;
                    font-weight: 600;
                    margin: 5px 0 5px;
                }
            }
            @media screen and (max-width: @screen-xs-max) { 
                background-size: unset;
                background-position: 66% 0%;
                height: auto;
                padding: 8px;
                margin: 0 -15px;
                h1 {
                    font-size: 20px;
                }
                h2 {
                    font-size: 12.5px;
                }
                .review {
                    height: auto;
                    padding-left: 0;
                    bottom: -8px;
                    div:first-child {
                        padding-right: 0px!important;
                        padding-left: 15px!important;
                    }
                    img {
                        margin: 20px 0;
                        max-width: 100%;
                        height: auto;                    
                    }
                    p {
                        font-size: 10.5px;
                        font-weight: 600;
                        margin: 10px 0 10px;
                    }
                }
            }
        }

        #map {
            .map-container {
                //background: @light-cyan;
            }
            .header-map {
                width: auto;
                margin-left: auto;
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 12;
            }
            #map-canvas {
                height: 350px;
            }
            @media screen and (max-width: @screen-xs-max) {
                background-color: white;
                display: none;
                margin-top: 10px;
            }
        }

        #filters {
            background-color: rgb(234, 234, 234);
            .search-filters {
                @media screen and (max-width: @screen-xs-max) { margin-bottom: 1em; }
                display: inline-block;
                .search-filter {
                    background-color: rgb(255, 255, 255);
                    color: @text-grey;
                    margin-bottom: 20px;
                    margin-right: .5em;
                    display: inline-block;
                    @media screen and (max-width: @screen-xs-max) {
                        display: block;
                    }
                    .btn-filter {
                        font-weight: 600;
                        border-radius: 5px;
                        width: 100%;
                        padding: .5em;
                        background-color: inherit;
                        border-radius: 0;
                        font-size: 15px;
                        letter-spacing: 0px;
                        border: 1px solid @light-gray;
                        text-align: left;
                        .caret {
                            float: right;
                            margin-top: 7px;
                            border-top: 6px solid @light-gray;
                            border-bottom: none;
                            border-right: 6px solid transparent;
                            border-left: 6px solid transparent;
                        }
                    }
                    .btn-filter:focus {
                        color: inherit;
                    }
                    span.name {
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        display: block;
                        text-overflow: ellipsis;
                    }
                    span.name:after {
                        position: relative;
                        right: 0;
                        margin-left: .4em;
                        content: '▼';
                    }
                }
                ul.filter-options {
                    position: absolute;
                    color: @text-grey;
                    z-index: 10;
                    font-weight: 600;
                    float: left;
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-top: .4em;
                    min-height: 62px !important;
                    max-height: 124px !important;
                    min-width: 120px;
                    width: 100%;
                    overflow: auto;
                    border-radius: 0;
                    border: 1px solid @light-gray;
                    .box-shadow(0px 10px 20px @light-gray);
                    ul {
                        padding-left: 18px;
                    }
                    .filter {
                        label {
                            font-size: 13px;
                            font-weight: 600;
                            margin-top: 2px;
                            margin-bottom: 2px;
                        }
                        .checkbox {
                            margin: 0;
                        }
                    }
                }
                @media screen and (max-width: 480px) {
                    margin-top: 15px;
                }
            }
            .dropdown-backdrop {
                position: static;
            }
            #refine-results-btn-container {
                margin-left: 3em;
                @media screen and (max-width: @screen-xs-max) {
                    margin-left: 0em;
                    text-align: center;
                }
            }
            #hide-filters {
                margin: 15px 0px;
            }
            p.filter-results-by {
                margin: 10px 0px;
            }

        }
    }
    .trainers-book{
        line-height: 3.5em;
        height: 2.5em;
        font-size: 17.5px;
        font-weight: 500;
        color : black;
        .shows{display:none}
        .loc-filters{
            background-color:rgb(182, 189, 193);
            @media screen and (max-width: @screen-xs-max) {
                font-size: 12px;
                .trainers-in{
                    padding-right: 5px !important;
                    padding-left: 5px !important;
                }
                a{
                    color: white;
                    font-weight: 800;
                }
                .shows{
                    display:block;
                    padding:0;
                }
            }
            @media screen and (max-width: 320px) {
                font-size: 10px;
            }
        }
        .title-widget-search{
            font-weight: 700;
            font-size: 18px;
            text-align: center;
            color: white;
            background-color: #072a38;
        }
    }
    .margines{
        margin-right: -15px;
        margin-left: -15px;
    }
    .is-sticky {
        .calendar-pagination-container {
            transition: all .4s;
            // background: #072a39;
            // color: white;
            background: white;
        }
    }
    .space-line-search{
        border: 0;
        border-top: 0.25em solid #eee;
    }
    section#pagination{
        background: white;
        border-bottom: 8px solid #eee;
    }
    section#trainers {
        min-height: 20px;
        .trainer {
            border-top: 1px solid @light-gray;
            .profile {
                padding: 15px 0px;
                padding-right: 1em;
            }
            .trainer-location {
                display: flex;
                margin-bottom: 0px;
            }
            .session-widget {
                margin-top: 10px;
                margin: none;
            }
            .table-responsive {
                margin: 0px;
            }
            .open-calendar {
                display: none;
                text-align: center;
                margin-bottom: 15px;
            } // hide calendars on mobile
            @media screen and (max-width: @screen-xs-max) {
                .trainer-availability {
                    display: none;
                }
                .open-calendar {
                    display: inline-block;
                }
                a.check-availability {
                    margin: 1em 0 !important;
                }
            }
            div.check-availability {
                text-align: center;
            }
            a.check-availability {
                margin: 65px 0;
                min-width: 300px;
            }
            .virtual-ace-search-profile-container {
                display: inline-block;
                position: relative;
                .ace-search-profile{
                    background-color: rgba(218,56,64,.47);
                    position: absolute;
                    bottom:0;
                    width: 100%;
                    img{
                        margin : 0.25em 0em;
                        padding:0 1em;
                    }
                    .virtual-training {
                        font-family: 'Avenir Next Medium';
                        text-align: center;
                        background-color: #EEFE54;
                        font-size: 15px;
                        font-weight: 800;
                        color: #333;
                        @media screen and (max-width: @screen-sm-max) {
                            font-size: 10.5px;
                        }
                    }
                }
            }
            .trainer-info-index{
                margin-bottom: 0;
                .trainer-info{
                    img.verified{
                        width: 15px;
                        display: inline-block;
                        margin: 2.4px 5px 0;
                    }
                }
                .total-reviews{
                    font-size: 15px;
                    color: #36b6ec;
                    font-weight: 400;
                    cursor: pointer;
                    text-decoration: underline;
                    margin-left: 1em;
                }
            }
            .mobile-styles-results{
                @media screen and (max-width: @screen-sm-max) {
                    width: 100%;
                    float: left;
                    margin-top: 8px;
                }
            }
            .price-fav {
                padding: 0 !important;
                margin: 0 !important;
                .trainer-max-discount{
                    float: right;
                    text-align: right;
                    margin-top: 0.85em;
                    #price{
                        color: #5bcca0;
                        font-size: 24px;
                        font-weight: 700;
                    }
                    p{
                      margin-bottom: -3.25px;
                      margin-top: -0.5em;
                      color: #898888c7;
                      font-weight: 625;
                      font-size: 13.5px;
                    }
                } 
                .fav{
                    float : right;
                    margin-top: 3.5em;
                    .fav-search{
                        border: 2px solid #ececec;
                        font-size: 20.5px;
                        padding: 0px;
                        .heart-img{
                            width: 24px;
                            margin: 1px 5px;
                        }
                    }
                    .no-logged{
                        display:none;
                    }
                }
                @media screen and (max-width: @screen-sm-max) {
                    float: right;
                    .trainer-max-discount{
                        margin-top: 1.5em;
                    }
                    .discount-not-trainer{
                        margin-top: 0em;   
                    }
                    .fav{
                        margin-top: 0.5em;
                    }
                    .fav-not-trainer{
                        margin-top: 5em;
                    }

                }
                @media screen and (max-width: @screen-xs-max) {
                    float: right;
                    .trainer-max-discount{
                        margin-top: 4px;
                    }
                    .discount-not-trainer{
                        margin-top: 0em;   
                    }
                    .fav{
                        margin-left : 2em;
                        margin-top: 1.5em;
                    }
                    .fav-not-trainer{
                        margin-top: 5em;
                    }
                }

            }
            .trainer-bio{
                margin-top: -5em;
                @media screen and (max-width: @screen-sm-max) {
                    margin-top: 2em;
                }
                @media screen and (max-width: @screen-xs-max) {
                    margin-top: 0em;
                    padding-right: 8px;
                }
            }
        }
    }
    .calendar-pagination {
        position: relative;
        background-color: white;
    }
    .search-by-keywords {
        padding-bottom: 2em;
        padding-right: 15px;
    }
}
.search-bar {
    padding: 0px 15px;
    margin-top: 20px;
}