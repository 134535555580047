@import (reference) "utils.less";

.carousel-matches-left {
  height: 40%;
  top: 17.5%;
  width: 40%;
  span {
    left: 24% !important;
  }
}

.carousel-matches-right {
  right: 0;
  left: auto;
  height: 40%;
  width: 40%;
  top: 17.5%;
  span {
    right: 24% !important;
  }
}

.matches-chevron {
  top: 30% !important;
  font-size: 40px;
  color: #3db6ec !important;
}

#sortby_label {
  padding-top: 7px;
  font-weight: bolder;
  color: #484848;
}

section#pagination {
  background: white;
  border-bottom: 8px solid #eee;
}

.pagination > li > a,
.pagination > li > span {
  padding: 5px 10px;
  margin-left: -1px;
  background-color: #fff;
  border: none;
}

.tot-matches {
  font-weight: 700;
  color: #000;
  margin: 2.5px;
  font-size: 25px;
  padding: 24px;
  letter-spacing: 1px;
  .views-div {
    text-align: right;
    padding: 0;
    #view-favorites {
      font-size: 15.5px;
      color: #36b6ec;
      font-weight: 650;
      cursor: pointer;
      text-decoration: underline;
      //margin-right: 1.5em;
    }
    #view-all {
      font-size: 15.5px;
      color: #36b6ec;
      font-weight: 650;
      cursor: pointer;
      text-decoration: underline;
      //margin-left: 1.5em;
    }
  }
}

.match-box {
  height: 450px;
  margin: 18px 0;
  padding: 1%;
  .img-badge {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    position: absolute;
    top: -15px;
    left: 2px;
    z-index: 2;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(128,128,128,1);
    -moz-box-shadow: 1px 1px 5px 0px rgba(128,128,128,1);
    box-shadow: 1px 1px 5px 0px rgba(128,128,128,1);
    img {
      height: 100%;
      width: auto;
    }
  }
  .trainer-image {
    position: relative;
    min-width: 100%;
    max-width: 100%;
    min-height: 300px;
    max-height: 300px;
    overflow: hidden;
    .virtual-only-badge {
      position: absolute;
      top: 8px;
      left: 8px;
      background-color: #EBE8FF;
      padding: 3px 8px;
      color: #494C56;
      font-size: 15px;
      .icon {
        margin-bottom: 3px;
        margin-right: 3px;
        height: 10px;
        width: auto;
      }
    }
    .fav {
      position: absolute;
      top: 10px;
      right: 10px;
      .fav-search {
        font-size: 20.5px;
        padding: 0;
        .heart-img {
          width: 24px;
          margin: 2.5px 7.5px;
        }
      }
    }
    .ace-search-view-matches {
      background-color: rgba(218, 56, 64, 0.47);
      position: absolute;
      text-align: center;
      width: 100%;
      bottom: 0;
      img {
        width: 70%;
        padding: 8px 10px;
      }
      .virtual-training {
        font-family: 'Avenir Next Medium';
        background-color: #EBE8FF;
        color: #333740;
        font-size: 15px;
        font-weight: 800;
        padding: 2.4px 0;
      }
    }
    .trainer-avatar {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      min-height: 300px;
    }
  }
  .trainer-information {
    height: 150px;
    max-height: 150px;
    min-height: 150px;
    border: 1px solid #757575;
    border-top: transparent;
    border-radius: 0 0 4px 4px;
    padding: 8px;
    a.trainer-name {
      color: rgb(70, 70, 70);
      text-decoration: none;
      font-size: 20px;
      font-weight: 700;
      margin-right: 8px;
    }
    img.verified {
      width: 18px;
      vertical-align: bottom;
    }
    img.bolt-icon {
      width: 24px;
      vertical-align: top;
    }
    .total_reviews {
      font-size: 17.5px;
      color: #36b6ec;
      font-weight: 400;
      margin-left: 8px;
      vertical-align: middle;
    }
    .review-div {
      margin: 5px 0 0 5px;
    }
    .price-div {
      position: absolute;
      left: 30px;
      bottom: 1px;
      p {
        color: #737373;
        font-size: 14px;
        font-weight: bolder;
      }
      span.price {
        color: #53dabc;
        font-size: 19px;
      }
    }
    #profile_redirect_circle {
      border: 1px solid #545156;
      border-radius: 50%;
      padding: 0 5px 0 4px;
      margin-right: 5px;
      font-weight: bold;
      font-size: 85%;
      color: #545156;
      position: absolute;
      bottom: 15px;
      right: 18px;
      a {
        text-decoration: none;
        color: #757575;
      }
    }
  }
}

.view_matches_container_v2 {
  background-color: #f5f5f5;
  padding: -15px;
  .view_matches_header {
    text-align: center;
    padding-top: 45px;
    padding-bottom: 45px;
    h2 {
      font-family: "Tiempos Head Line";
      font-size: 42px;
      font-weight: bolder;
      color: black;
      margin: 2.5px;
    }
    p {
      font-size: 18px;
      font-weight: 500;
      color: #545156;
    }
    a {
      font-size: 18px;
      color: #4dbef5;
      text-decoration: underline;
    }
  }
}

.separation {
  padding-left: 5%;
  padding-right: 5%;
}

.custom_box {
  background-color: #fff;
  border-radius: 3px;
  -webkit-box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.08);
  box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 2em;
  margin-bottom: 20px;
  color: #545156;
  &.matches {
    padding: 2em 1em;
  }
  h1 {
    font-size: 24px;
    font-weight: bolder;
  }
  h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    &.area {
      margin-bottom: 10px;
    }
  }
  p {
    font-size: 15px;
    #bolder {
      font-weight: bold;
    }
    &.area {
      margin-bottom: 20px
    }
  }
  input {
    border: 1px solid #9c9c9c;
    border-radius: 3px;
  }
}

#sortBy {
  margin-left: 3px;
}

.btn-outline-info {
  background-color: transparent;
  transition: all 0.5s;
  color: #5bc0de;
  width: 80%;
  font-weight: bold;
}

#number_circle {
  border: 1px solid #545156;
  border-radius: 50%;
  padding: 0 5px 0 4px;
  margin-right: 5px;
  font-weight: bold;
  font-size: 85%;
  color: #545156;
}


@media screen {
  @media (max-width: @screen-xs-max) {
    .view_matches_container_v2 {
      .view_matches_header {
        padding: 14px 4px 6px 4px;
        h2 {
          font-size: 24px;
        }
        a,
        p {
          display: none !important;
        }
      }
    }
    #trainers_header {
      padding-left: 2em;
      h1 {
        margin: 20px 0 0 0;
      }
    }
    .custom_box#mobile_custom_box {
      padding: 0;
    }
    hr {
      margin-bottom: 0;
    }

    .match-box {
      padding: 8px;
      .img-badge {
        left: -3px;
      }
    }

    ////////////////////////////////////////////////////////////////////////

    .multi-item-carousel {
      overflow: hidden;
      .carousel-inner {
        width: 150%;
        left: -25%;
        padding-bottom: 10px;
      }

      .item__third {
        float: left;
        position: relative;
        width: 33.33333333%;
      }

      .item.next,
      .item.active.right {
        -webkit-transform: translate3d(33%, 0, 0);
        transform: translate3d(33%, 0, 0);
      }

      .item.prev,
      .item.active.left {
        -webkit-transform: translate3d(-33%, 0, 0);
        transform: translate3d(-33%, 0, 0);
      }
    }

    #myCarousel .carousel-control.left,
    #myCarousel .carousel-control.right {
      width: 10%;
      background-image: none;
      font-size: 30px;
    }

    ////////////////////////////////////////////////////////////////////////////
  }
  @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
    .view_matches_container_v2 {
      .view_matches_header {
        padding: 14px 4px 6px 4px;
        h2 {
          font-size: 24px;
        }
        a,
        p {
          display: none !important;
        }
      }
    }
    #trainers_header {
      padding-top: -5px !important;
      padding-bottom: -5px !important;
    }
    .trainer_full_name {
      margin-bottom: 0;
    }
    .trainer-location {
      margin-top: 0.5em !important;
    }
    .view_matches_hearder {
      margin: 0px -15px;
      p {
        margin: 0 8px 10px;
      }
    }
    #scroll {
      margin: 0 -15px;
    }
    .matches_search {
      .query {
        margin-left: auto;
      }
    }
    .bio_truncate {
      padding-left: 0;
    }
    .trainer-img {
      padding-right: 5px !important;
      padding-left: 5px !important;
      margin-bottom: 8px;
    }
    .mobile-styles {
      width: 100%;
      float: left;
    }
    .session-fav {
      margin-top: 2px !important;
      padding: 0;
      .trainer-max-discount p {
        font-size: 10.5px !important;
      }
      .fav {
        margin-left: 0 !important;
        padding-left: 30px;
        margin-top: 3em !important;
      }
    }
    #footer-view {
      padding: 0.25em 0em 1em !important;
      .xs-not-weight {
        font-weight: 550;
      }
    }

    #footer-view-money {
      padding: 35px 0;
      align-items: center;
      .footer-MBG-img {
        padding-left: 5%;
        img {
          padding-right: 5%;
          width: 80px;
        }
      }
      .footer-MBG {
        padding: 0 5%;
        span {
          font-size: 17px;
        }
      }
    }
    .check-availability {
      margin-top: 8px !important;
      font-size: 14.5px;
    }

    .match-box {
      height: 450px;
      padding: 0;
    }

    .tot-matches {
      margin: 0px;
      font-size: 15.5px;
      padding: 14px;
      letter-spacing: 0px;
      .views-div {
        text-align: right;
        #view-favorites {
          font-size: 12px;
          margin-right: 0em;
        }
        #view-all {
          font-size: 12px;
          margin-left: 0em;
        }
      }
    }
  }
}
