/******************************************************************************
    Price managaer
******************************************************************************/
#price-manager{
    .header > li {
        .box-shadow(0 0 3px 0 rgba(0,0,0,.75));
        width: 107px;
        height: 44px;
        line-height: 44px;
        padding: 0;
        text-align: center;
        &:first-child {
            width: 107px;
        }
    }
    .header-time-period {
        .box-shadow(0 0 3px 0 rgba(0,0,0,.75));
        ul {
            li {
                height: 44px;
                padding: 0;
                line-height: 44px;
                text-align: center;
            }
        }
    }
    .trainer-picker{
        max-width: 250px;
        margin: 50px auto 0px auto;
        #trainerlist{
            margin-bottom: 10px;
        }
        img{
            width: 250px; height: 250px;
        }
    }

    .trainer-prices{
        text-align: center;
        max-width: 539px;
        .prices-alerts{
            min-height: 32px;
            #success_price_select{
                color: #2fa20d;
                margin-bottom: 10px;
                padding: 5px;
                display: none;
            }
            #error_price_select{
                color: #ff7373;
                margin-bottom: 10px;
                padding: 5px;
                display: none;
            }
        }
        form{
            display: inline-block;
            #priceslist{
                max-width: 58%;
                float: left;
                margin-top: 2px;
            }
            .btn{
                margin-left: 5px;
            }
        }
        @media (max-width: 767px) {
            #prices-table{
                td, th{
                    padding: 4px;
                }
            }
        }
    }



    /**********
    PRICE GRID
    **********/
    .trainer-price-table{
        margin-top: 20px;
        ul.time-slot-column{
            padding: 0;
            color: #fff;
        }
        #week_day_heading{
            padding: 0;
            margin: 0;
            list-style: none;
            .hour-day {
                background: #fff;
                float: left;
                width: 107px;
                margin-left: 0;
                height: 40px;
                line-height: 40px;
                -webkit-border-radius: 5px 0 0 0;
                -moz-border-radius: 5px 0 0 0;
                border-radius: 5px 0 0 0;
                -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
                font-weight: 600;
                font-size: 19px;
                color: #999;
                text-align: center;
                position: relative;
                z-index: 2;
            }
            .day-and-date{
                background: #fff;
                float: left;
                margin-left: 8px;
                height: 40px;
                line-height: 40px;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;
                -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
                font-weight: 600;
                font-size: 19px;
                color: #999;
                text-align: center;
                position: relative;
                z-index: 1;
            }
            .price-column #session-data {
                background-color: #f1f1f1;
                background-image: -moz-linear-gradient(top, #fefefe, #dedede);
                background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fefefe), to(#dedede));
                background-image: -webkit-linear-gradient(top, #fefefe, #dedede);
                background-image: -o-linear-gradient(top, #fefefe, #dedede);
                background-image: linear-gradient(to bottom, #fefefe, #dedede);
                background-repeat: repeat-x;
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFEFEFE', endColorstr='#FFDEDEDE', GradientType=0);
                width: auto;
                height: auto;
                margin: 0;
                padding: 0;
            }
        }
        #week_day_columns{
            float: left;
            margin: 0;
            position: relative;
            width: 100%;
            .time-slot-container {
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
                float: left;
                margin-left: 0;
                position: relative;
                width: 107px;
                z-index: 1;
                .time-slot {
                    background-color: #808080;
                    background-image: linear-gradient(to bottom, #8A8A8A, #717171);
                    background-repeat: repeat-x;
                    font-size: 13px;
                    font-weight: 100;
                    height: 50px;
                    line-height: 50px;
                    padding: 0;
                    text-align: center;
                }
            }
            .price-container{
                ul.price-data{
                    margin-bottom: 0;
                    .price-column {
                        float: left;
                        margin-left: 8px;
                        .container-data {
                            height: 50px;
                            line-height: 50px;
                            padding: 0;
                            text-align: center;
                            -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.75);
                            -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.75);
                            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.75);
                        }
                        #session-data {
                            background-color: #f1f1f1;
                            background-image: -moz-linear-gradient(top, #fefefe, #dedede);
                            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fefefe), to(#dedede));
                            background-image: -webkit-linear-gradient(top, #fefefe, #dedede);
                            background-image: -o-linear-gradient(top, #fefefe, #dedede);
                            background-image: linear-gradient(to bottom, #fefefe, #dedede);
                            background-repeat: repeat-x;
                            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFEFEFE', endColorstr='#FFDEDEDE', GradientType=0);
                            width: auto;
                            height: auto;
                            margin: 0;
                            padding: 0;
                            &.changed-price {
                                background-color: #c0ce9b;
                                background-image: -moz-linear-gradient(top, #d0dbb0, #a8bb7b);
                                background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#d0dbb0), to(#a8bb7b));
                                background-image: -webkit-linear-gradient(top, #d0dbb0, #a8bb7b);
                                background-image: -o-linear-gradient(top, #d0dbb0, #a8bb7b);
                                background-image: linear-gradient(to bottom, #d0dbb0, #a8bb7b);
                                background-repeat: repeat-x;
                                li{
                                    color: #316205;
                                }
                            }
                            &.alert-price,
                            &.greater-than.alert-price,
                            &.less-than.alert-price {
                                background: #ffb9a6;
                                li {
                                    color: #c22203;
                                    span {
                                        color: #c22203;
                                    }
                                }
                            }
                            &.less-than {
                                background: #88bee8;
                            }
                            &.greater-than {
                                background: #ffe89a;
                            }
                            li {
                                width: 50%;
                                height: auto;
                                margin: 0;
                                padding: 0;
                                display: inline;
                                color: $grayDark;
                                &:first-child {
                                    padding-right: 5px;
                                    border-right: 1px solid $grayLight;
                                }
                                &:last-child {
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    [contenteditable="true"]:focus {
        background: $grayLighter;
        padding: 2px 4px;
        border: 1px solid $grayLight;
        .border-radius(5px);
        //outline-color: rgb(91, 157, 217);
        outline-style: none;
        //outline-width: 5px;
    }

    #savechanges,
    #cancelchanges {
        display: none;
    }

    @media (max-width: 991px) {
        .trainer-price-table.container{
            width: 700px;
            overflow-x: scroll;
        }
        .day-and-date, .container-data{
            width: 72px;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        .container{
            max-width: 940px;
        }
        .day-and-date, .container-data{
            width: 106px;
        }
    }

    @media (min-width: 1200px){
        .price-trainer img {
            max-width: 219px;
        }
        .day-and-date, .container-data{
            width: 139px;
        }
        .container{
            max-width: 1170px;
        }
    }
}
