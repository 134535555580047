@iphone5-to-723px: ~"only screen and (min-width: 320px) and (max-width: 723px)";
@import (reference) "bootstrap_less/grid.less";
@import (reference) 'colors.less';
@import (reference) 'utils.less';
// hard-fix crazy padding-right
// from Bootstrap modal.js
// updated to latest version
body {
    padding-right: 0px !important;
}

.modal {
    overflow-y: auto !important;
}

.modal-backdrop {
    z-index: 1000;
}

.glyphicon-question-sign {
    cursor: pointer;
}

#dialog-overlay {
    display: none;
}

.modal-signup {
    transition: all 0.5s;
    margin-top: 60px;
    h5 {
        text-align: center;
    }
    .modal-header {
        background: none;
        .close {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}

.signup-coerce {
    text-align: center;
    .alert-error {
        display: none;
        width: 225px;
        margin: 20px auto 10px auto;
    }
}

#mini-modal {
    .modal-header {
        background: white;
        border-bottom: 0px;
    }
}

#modal-request-availability {
    .modal-header {
        background-color: white;
        border-bottom: 0px;
        .close {
            color: #eeeeee;
        }
        p {
            padding-top: 30px;
            font-size: 40px;
            font-weight: 700;
        }
    }
    .modal-body {
        #label_get_started {
            margin-top: 5px;
        }
        .steps {
            margin-top: 20px;
            p {
                text-align: left;
            }
        }
    }
    .modal-footer {
        text-align: center;
        border-top: 0px;
    }
}

#request-availability {
    p {
        margin-bottom: 5px;
        color: @gray;
    }
    .accordion-heading {
        .accordion-toggle {
            padding-bottom: 7px;
            &.minus {
                background-position: left 0;
            }
        }
    }
    .accordion-group {
        margin-bottom: 3px;
        padding-bottom: 3px;
    }
    .accordion-inner {
        padding: 0;
    }
    .hint {
        margin-bottom: 8px;
        color: #FA6F4E;
    }
    .day-time {
        float: none;
        margin: 15px auto 0;
        width: auto;
        &.well {
            float: left;
            margin: 6px 0;
            display: block;
        }
        p {
            float: left;
            margin: 6px 0 0;
        }
        .checkbox {
            float: left;
            width: 95%;
            margin: 0;
        }
    }
    .day-collapse {
        border-bottom: 1px solid #e5e5e5;
        a {
            color: #4c4c4c;
            font-size: 20px;
            text-decoration: none;
        }
    }
}

#modal-request-consult {

    .modal-header {
        background-color: white;
        border-bottom: 0px;
        .close {
            color: #eeeeee;
        }
        p {
            padding-top: 30px;
            font-size: 40px;
            font-weight: 700;
        }
    }
    .modal-body {
        .steps {
            margin-top: 20px;
            padding: 0 2em 0 2em;
            p {
                text-align: left;
            }
        }
    }
}

#modal-profile-faqs, #modal-contact-us {
    @media screen and (min-width: @screen-md-min) {
        .modal-dialog {

            width: 900px;
        }
    }
    @media screen and (max-width: @screen-xs-max) {
        .modal-header {
            p {
                font-size: 25px !important;
            }
            span {
                padding: 15px;
            }
        }
    }
    .modal-header {
        background-color: white;
        border-bottom: 0px;
        .close {
            color: #eeeeee;
        }
        p {
            padding-top: 30px;
            font-size: 32px;
            font-weight: 700;
        }
    }
    .modal-body {
        width: 80%;
        margin-bottom: 50px;
        ul {
            background: #e5e3e3;
            padding: 0;
            li {
                border-top: 1px solid #c1c1c1;
                border-right: 1px solid #c1c1c1;
                border-left: 1px solid #c1c1c1;
                div.question {
                    padding: 15px;
                    font-size: 18px;
                    font-weight: 600;
                    span.glyphicon {
                        float: right;
                        margin-right: 15px;
                    }
                    &.expanded {
                        background-color: #9ecee8;
                    }
                }
                div.card-body {
                    background-color: white;
                    padding: 10px;
                    border-top: 1px solid #c1c1c1;
                }
            }
            li:last-of-type {
                border-bottom: 1px solid #c1c1c1;
            }
        }
    }
}

#trainer-bio,
#club-description {
    .modal-body {
        p {
            text-align: left;
        }
    }
}

// Background
#landing {
    .modal-backdrop.fade.in {
        opacity: 0.9;
    }
}

#user-address {
    .address-form-actions {
        text-align: center;
    }
    .save-address {
        display: inline;
    }
    .modal-body .title {
        text-align: center;
    }
}

.modal-header {
    background: #1f5c7a;
    min-height: 0px;
    width: auto;
    height: auto;
    padding: 0;
    border-radius: 6px 6px 0 0;
    text-align: center; // Close icon
    .close {
        padding: 6px 12px;
        color: white;
        font-weight: normal;
        font-size: 33px;
        opacity: 0.7;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
        &:hover {
            opacity: 1;
        }
    } // Heading
    h1 {
        margin: 0;
        padding: 5px 0;
        color: white;
        font-size: 24.5px;
        font-weight: 400;
        line-height: 1.3;
    }
    h3 {
        margin: 0;
        line-height: 30px;
        color: white;
    }
}

// Body (where all modal content resides)
.modal-body {
    margin: 0 auto;
    position: relative;
    padding: 15px;
    text-align: left;
    color: @gray;
    display: table;
    p {
        text-align: center;
    }
    .not-logged-in {
        p {
            margin: 10px 0;
            text-align: center;
        }
    }
}

.day-collapse {
    margin: 10px 0px 10px 0px;
    a.accordion-toggle:hover {
        text-decoration: none;
    }
    a.accordion-toggle:visited:after {
        text-decoration: none;
    }
}

#register_btn {
    margin: 10px 0px 10px 0px;
}

@media @iphone5-to-723px {

    #request-availability {

        .days-container {
            margin-left: 0px;
            width: 100%;
        }

        .day-time {
            .checkbox label {
                padding-left: 9px;
            }
        }
    }

    .modal-footer {
        .btn-default {
            margin-bottom: 15px;
        }
    }
}

button.floating-close {
    position: absolute;
    right: 0;
    margin-right: 10px;
    background: transparent;
    border: 0;
    z-index: 999;
    font-size: 2em;
    font-weight: 600;
}

#modal-youtube {
    .modal-content {
        border: none;
        background: black;
    }

    button.floating-close {
        color: white;
    }

    @media screen and (orientation:landscape) {
        .modal-dialog {
            margin: 0 auto;
        }
    }
}

#modal-state-places {
    .modal-dialog {
        @media screen and (min-width: @screen-sm-min) {
            width: 350px;
        }
    }
    .modal-content {
        .modal-header {
            padding: 10px;
            background: none;
            .close {
                color: black;
            }
        }
        .modal-body {
            width: 100%;
            margin: 0px;
            .tab-content {
                overflow-x: hidden;
                overflow-y: auto;
            }
        }
    }
    #places-list {
        // "All trainers in ..."
        ul>li:first-child {
            font-size: 16px;
            margin-bottom: 5px;
            font-weight: 600;
        }
    }
}

// session box minimodals
.sessionbox-modal {
    margin: 80px 0px;
    h2.title {
        margin: 20px 0px;
        font-weight: 600;
    }
}

#new-message-modal-trainer-profile {
    @media (min-width: 768px) {
        .modal-dialog {
            width: 50% !important;
        }
    }

    .modal-body {
        margin: 1em !important;
    }
}
#RescheduleModalAccount, #CancelModalAccount, #session-address, #new-message-modal {
    font-family: 'Avenir Next Medium';
    .modal-content {
        border-radius: 0;
        .modal-header {
            background: #fff;
            border-radius: 0;
            padding: 10px 0;
            h4 {
                color: #4a4a4a;
                font-size: 17.5px;
                font-weight: 800;
                padding-top: 5px;
                padding-left: 15px;
            }
            button {
                opacity: 1;
                color: #4a4a4a;
                font-size: 32.5px;
                font-weight: 500;
                padding: 0 10px;
            }
            button:focus {
                outline: 0;
            }
        }
        .modal-body {
            text-align: center;
            color: #4a4a4a;
            h4 {
                font-size: 15px;
                margin: 5px 0;
                font-weight: 600;
            }
            p {
                font-size: 15px;
            }
            h3 {
                font-size: 17.5px;
                margin: 5px;
                font-weight: 800;
            }
            .next-session-info {
                display: inline-flex;
                margin-top: 15px;
                img {
                    margin-right: 10px;
                }
                p {
                    text-align: left;
                    margin: 0;
                    font-size: 13px;
                }
            }
            #address-form {
                text-align: left;
                label {
                    font-weight: 500;
                    font-size: 15px;
                }
                form .form-control, form input, form textarea {
                    border-radius: 5px;
                    border: 1.25px solid #f3f3f3;
                    padding: 4px 6px;
                }
            }
            #new-message-form {
                text-align: left;
                form .form-control, form input, form textarea {
                    border-radius: 0;
                    border: 1.25px solid #f3f3f3;
                    padding: 0;
                }
                #submit-message {
                    color: white;
                    font-size: 15px;
                    font-weight: 800;
                    padding: 6px 40px;
                    background: #79e9d1;
                    border: 1px solid #79e9d1;
                }
            }
        }
        .modal-footer {
            padding: 15px;
            text-align: center;
            border-top: 0;
            border: 0;
            button.gray {
                background: white;
                border: 1px solid #dfdfdf;
                color: #868585;
                font-size: 15px;
                font-weight: 500;
                padding: 6px 40px;
                @media (max-width: @screen-xs-max) {
                    padding: 6px 70px;
                    margin-bottom: 24px;
                }
            }
            form {
                display: contents;
                button {
                    color: white;
                    font-size: 15px;
                    font-weight: 800;
                    padding: 6px 40px;
                }
                button.green {
                    background: #79e9d1;
                    border: 1px solid #79e9d1;
                }
                button.red {
                    background: #f57f7a;
                    border: 1px solid #f57f7a;
                    @media (max-width: @screen-xs-max) {
                        padding: 6px 60px;
                    }
                }
            }
        }
    }
    @media (min-width: 768px) {
        .modal-dialog {
            width: 600px;
            margin: 15% auto;
        }
    }
}