@import (reference) 'utils.less';

.national_home_page_v2 {
    button.home-page-v2 {
        background-color: #5340FF;
        border-radius: 5px;
        height: 52.5px;
        color: #FFF;
        font-family: 'Avenir Next Medium';
        font-size: 17.5px;
        font-weight: 700;
        letter-spacing: 0.8px;
        line-height: 24px;
        width: 190px;
        text-align: center;
        margin-top: 24px;
        border: 0;
    }
    span.home-italicized {
        font-family: 'Avenir Next Medium Italic';
    }
    #homeCarousel {
        .carousel-review {
            margin-top: 50px;
            display: flex;
            img {
                width: auto;
                height: 650px;            
            }
            .review {
                align-items: center;
                background-color: #F4F6F8;
                display: flex;
                justify-content: center;
                flex-direction: column;
                div {
                    h3 {
                        color: #545156;
                        font-family: 'Tiempos Head Line';
                        font-size: 24px;
                        font-weight: 600;
                        letter-spacing: 0.44px;
                        line-height: 40px;
                        width: 602px;
                        text-align: center;
                        padding: 0 20px;
                    }
                    h4 {
                        color: #545156;
                        font-family: 'Avenir Next Medium';
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.28px;
                        line-height: 50px;
                        text-align: center;
                    }
                }
            }
        }
        .carousel-control.left, .carousel-control.right {
            background-image:none !important;
            filter:none !important;
            top: 50%;
        }
        .carousel-control.left {
            left: -4%;
        }
        .carousel-control.right {
            right: -4%;
        }
        .carousel-arrow-imgs {
            height: 30px;
        }
        span.glyphicon {
            color: black;
        }
        .carousel-indicators {
            left: 72%;
            li {
                background-color: #E1E6EB;
                border-radius: 100%;
                width: 15px;
                height: 15px;
            }
            li.active {
                background-color: #B8BFC6;
                border-radius: 100%;
                width: 15px;
                height: 15px;
            }
        }
        @media (max-width: @screen-xs-max) {
            .carousel-review {
                flex-direction: column;
                img {
                    height: 100%;
                    width: 100%;
                }
                .review {
                    padding: 30px 20px;
                    div {
                        h3 {
                            width: auto;
                            font-size: 17px;
                            padding: 0 10px;
                        }
                    }
                }
                .reviews {
                    font-size: 17px;
                }
            }
            .carousel-indicators {
                left: 50%;
            }
        }
    }
    .your-partner {
        height: 750px;
        background: url(../img/home/home-hero-run.webp) no-repeat;
        background-size: 100%;
        background-position-y: 40%;
        &.lincoln {
            height: 750px;
            background: url(../img/lincoln/virtual-home-hero.jpg) no-repeat;
            background-size: 100%;
            background-position-y: 40%;
        }
        div {
            padding-top: 2.24%;
            padding-left: 5%;
            &.lincoln {
                padding-top: 2.24%;
                padding-left: 3%;
                img {
                    background-color: #fff;
                    height: 90px;
                    width: auto;
                    padding: 10px;
                    border-radius: 10px;
                    position: absolute;
                    top: 4%;
                    &.alliance {
                        height: 70px;
                        padding: 20px 10px;
                    }
                    &.amenify {
                        padding: 10px 35px;
                    }
                    &.greentree {
                        padding: 25px 16px;
                    }
                }
            }
            h1 {
                margin-top: 25rem;
                color: #FFFFFF;
                font-family: 'Tiempos Head Line';
                font-style: normal;
                font-size: 46px;
                font-weight: 600;
                letter-spacing: 1.24px;
                line-height: 52.5px;
            }
            h2 {
                color: #FFFFFF;
                font-family: 'Avenir Next Medium';
                font-weight: 500 !important;
                font-style: normal;
                font-size: 21px !important;
                line-height: 30px;
                margin-bottom: 24px;
            }
            #home-quiz {
                position: relative;
                display: table;
                border-collapse: separate;
                padding: 0;
                width: 30%;
                input.home-quiz-input {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    height: 52.5px;
                    &::placeholder {
                        font-size: 16px;
                        color: #6b6b6b;
                    }
                }
                span {
                    button.home-quiz-button {
                        background-color: #5340FF;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        height: 52.5px;
                        color: #FFF;
                        font-family: 'Avenir Next Medium';
                        font-size: 18px;
                        font-weight: 700;
                        letter-spacing: .8px;
                        line-height: 24px;
                        width: auto;
                        text-align: center;
                        border: 0;
                        padding: 0 15px;
                    }
                }
            }
            #home-quiz-alert {
                width: 30%;
            }
            #home-quiz-alert, #home-quiz-error {
                padding: 5px;
                margin: 5px 0;
            }
        }
    }
    .manifesto {
        margin-top: 81px;
        margin-bottom: 81px;
        .this-whole-working {
            color: #000000;
            font-family: 'Tiempos Head Line';
            font-size: 46px;
            font-weight: 600;
            letter-spacing: 0.72px;
            line-height: 60px;
            text-align: center;
        }
        .fyts-personal-train {
            color: #575459;
            font-family: 'Avenir Next Medium';
            font-weight: 500;
            font-style: normal;
            font-size: 21px;
            line-height: 35px;
            text-align: center;
            padding: 0 8%;
        }
    }
    .section-1 {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        margin-top: 81px;
        img {
            width: 739px;
            height: 534px;
        }
        .section-1-flex {
            flex: 1;
            display: flex;
            justify-content: center;
            div {
                .workouts-that-actual {
                    color: #000000;
                    font-family: 'Tiempos Head Line';
                    font-size: 46px;
                    font-weight: 600;
                    letter-spacing: 0.72px;
                    line-height: 55px;
                }
                .we-believe-real-chan {
                    width: 561px;
                    color: #545156;
                    font-family: 'Avenir Next Medium';
                    font-size: 17px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    line-height: 30px;
                }
            }
        }
    }
    .section-2 {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: 124px;
        margin-bottom: 124px;
        .section-2-flex {
            flex: 1;
            display: flex;
            justify-content: center;
            div {
                .expert-guidance {
                    color: #000000;
                    font-family: 'Tiempos Head Line';
                    font-size: 46px;
                    font-weight: 600;
                    letter-spacing: 0.72px;
                    line-height: 55px;
                }
                .fyt-pros-are-real {
                    width: 497px;
                    color: #545156;
                    font-family: 'Avenir Next Medium';
                    font-size: 21px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    line-height: 30px;
                }
            }
        }
        img {
            width: 640px;
            height: 640px;
        }
    }
    .expert-guidance-and-support {
        margin-top: 70px;
        .egas-title {
            color: #000000;
            font-family: 'Tiempos Head Line';
            font-size: 46px;
            font-weight: 600;
            letter-spacing: 0.72px;
            line-height: 55px;
            text-align: center;
        }
        h4 {
            color: #575459;
            font-family: 'Avenir Next Medium';
            font-size: 21px;
            font-style: normal;
            font-weight: 500;
            line-height: 35px;
            margin-top: 20px;
            padding: 0 10px;
            text-align: center;
        }
        .section-container {
            display: flex;
            flex-direction: row;
            margin-top: 35px;
            .fullcol {
                width: 100%;
                height: auto;
            }
        }
        .midcol {
            width: 100%;
            height: auto;
        }
        #second-col-expert-and-guidance {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .bottom-div {
            text-align: center;
            h4 {
                margin-top: 24px;
                padding: 0 10%;
            }
            .find-your-match-button {
                background-color: #5340FF;
                border-radius: 5px;
                height: 52.5px;
                color: #FFF;
                font-family: 'Avenir Next Medium';
                font-size: 17.5px;
                font-weight: 700;
                letter-spacing: 0.8px;
                line-height: 24px;
                width: 190px;
                text-align: center;
                margin-top: 24px;
                border: 0;
            }
        }
        .img-container-div {
            position: relative;
            h5 {
                color: #ffffff;
                font-weight: bold;
                font-size: 17px;
                letter-spacing: 0.5px;
                line-height: 20px;
            }
            p {
                color: #ffffff;
                margin-top: -6px;
            }
            .bottom-left {
                position: absolute;
                bottom: 8px;
                left: 16px;
                h5 {
                    text-align: left;
                }
            }
            .bottom-right {
                position: absolute;
                bottom: 8px;
                right: 16px;
                h5, p {
                    text-align: right;
                }
              }
        }
        @media (max-width: @screen-xs-max) {
            h4 {
                line-height: 30px;            
                font-size: 17px;
                padding: 0 20px;
            }
            .egas-title {
                line-height: 30px;
                font-size: 27.5px;
                padding: 0 20px;
            }
        }
    }
    .our-clients {
        .our-clients-love-get {
            color: #000000;
            font-family: 'Tiempos Head Line';
            font-size: 46px;
            font-weight: 600;
            letter-spacing: 0.72px;
            line-height: 55px;
            text-align: center;
            h2 {
                font-family: 'Tiempos Head Line';
            }
        }
        div {
            text-align: center;
            .yotpo-review-avg {
                display: inline;
                margin-right: 8px;
            }
            .yotpo-review-avg:first-child {
                vertical-align: super;
            }
            .reviews {
                color: #545156;
                font-family: 'Avenir Next Medium';
                font-size: 19px;
                letter-spacing: 0.28px;
                line-height: 50px;
            }
            a {
                color: #3FA2F7;
                font-family: 'Avenir Next Medium';
                font-size: 18px;
                letter-spacing: 0.28px;
                line-height: 50px;
                margin-left: 10px;
            }
        }
    }
    .how-it-work {
        h2 {
            margin-top: 81px;
            margin-bottom: 65px;
            color: #000000;
            font-family: 'Tiempos Head Line';
            font-size: 46px;
            font-weight: 600;
            letter-spacing: 0.72px;
            line-height: 55px;
            text-align: center;
        }
        .works {
            display: flex;
            justify-content: space-around;
            flex-flow: row wrap;
            div {
                .find-your-match-link {
                    color: #000000;
                }
                img {
                    margin: 0 35%;
                    width: 100px;
                    height: 100px;
                }
                h3 {
                    color: #000000;
                    font-family: 'Tiempos Head Line';
                    font-size: 24px;
                    font-weight: 600;
                    letter-spacing: 0.38px;
                    line-height: 40px;
                    text-align: center;
                }
                h4 {
                    color: #545156;
                    font-family: 'Avenir Next Medium';
                    font-size: 17px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    line-height: 30px;
                    width: 300px;
                    text-align: center;
                }
            }
        }
    }
    .the-fyt-promise-section {
        margin-top: 75px;
        margin-bottom: 75px; 
        .promise-row {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .promise-title {
            color: #000000;
            font-family: 'Tiempos Head Line';
            font-size: 46px;
            font-weight: 600;
            letter-spacing: 0.72px;
            line-height: 55px;
            text-align: left;
        }
        .promise-div {
            display: flex;
            align-items: center;
            flex-direction: row;
        }
        img.promise-icon {
            height: 75px;
            width: auto;
        }
        h3 {
            color: #545156;
            font-family: 'Avenir Next Medium';
            font-size: 17px;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 30px;
            text-align: left;
            padding-right: 12px;
        }
        h4 {
            color: #575459;
            font-family: 'Avenir Next Medium';
            font-size: 21px;
            font-style: normal;
            font-weight: 500;
            line-height: 35px;
            margin-top: 20px;
            text-align: left;
        }
        #promise-img-col {
            padding-right: 0;
        }
        @media (max-width: @screen-xs-max) {
            .promise-row {
                flex-direction: column-reverse;
            }
            #promise-img-col {
                padding: 0;
            }
            h3 {
                text-align: center;
            }
            h4 {
                line-height: 28px;            
                font-size: 17px;
                padding: 0 20px;
                text-align: center;
            }
            .promise-title {
                line-height: 30px;
                font-size: 27.5px;
                padding: 0 20px;
                text-align: center;
            }
            .promise-div {
                flex-direction: column;
                margin: 25px 0;
                justify-content: center;
            }
        }
    }
    .as-seen-in {
        background-color: #F4F6F8;
        height: 224px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        padding-left: 100px;
        padding-right: 100px;
        margin-top: 81px;
        h3 {
            color: #7C8999;
            font-family: 'Tiempos Head Line';
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.31px;
            line-height: 55px;
            width: auto;
            text-align: center;
        }
    }
    .ready-to-get-started {
        height: 900px;
        background: url(../img/home/bottom-cta.webp) no-repeat;
        background-size: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        h2 {
            color: #FFFFFF;
            font-family: 'Tiempos Head Line';
            font-size: 70px;
            font-weight: 600;
            letter-spacing: 1.09px;
            line-height: 55px;
            text-align: center;
        }
        .ready-button-div {
            margin-top: 50px;
            width: 75%;
            text-align: center;
            margin-left: 12.5%;
            input {
                height: 52.5px;
            }
            input::placeholder {
                font-family: 'Avenir Next Medium';
            }
            .home-page-search-v2 {
                background-color: #5340FF;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                height: 52.5px;
                color: #FFF;
                font-family: 'Avenir Next Medium';
                font-size: 17.5px;
                font-weight: 700;
                letter-spacing: .8px;
                line-height: 24px;
                width: 190px;
                text-align: center;
                border: 0;
            }
        }
    }
    .common_questions {
        margin-bottom: 24px;
        h2 {
            color: #000000;
            font-family: 'Tiempos Head Line';
            font-size: 32px;
            font-weight: 600;
            letter-spacing: 0.5px;
            line-height: 55px;
            text-align: center;
            margin-top: 81px;
            margin-bottom: 50px;
        } 
        .common_questions_div {
            border: 0;
            min-height: 200px;
            h2 {
                color: #000000;
                font-family: 'Avenir Next Medium';
                font-size: 19px;
                font-weight: 700;
                letter-spacing: 0.4px;
                line-height: 30px;
                text-align: left;
                margin-top: 0;
                margin-bottom: 0;
            }
            p {
                color: #545156;
                font-family: 'Avenir Next Medium';
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.3px;
                line-height: 28px;
                span.show-more-answer {
                    cursor: pointer;
                    color: #4f92e2;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }
    }
}
#top-trainers-market, .search-results-v2-trainers {
    // currently used in market_home_page_v2.html and results_v2.html
    margin-top: 100px;
    padding: 0 10%;
    width: 100%;
    h1 {
        color: #000000;
        font-family: 'Tiempos Head Line';
        font-size: 32px;
        text-align: center;
        margin-bottom: 50px;
    }
    div.locations {
        margin-bottom: 12px;
        a {
            color: #4e4e4e;
            font-weight: bold;
        }
    }
    .trainer-item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        padding: 23px 0;
        border-bottom: 2px solid #e8e7e8;
        width: 100%;
        .trainer-img-container {
            height: 180px;
            width: 180px;
            max-width: 180px;
            margin-left: 2px;
            position: relative;
            flex-grow: 1;
            img.avatar {
                height: 100%;
                width: 100%;
            }
            .heart-icon {
                cursor: pointer;
                position: absolute;
                height: 29px;
                width: auto;
                top: 5px;
                right: 9px;
                z-index: 2;
            }
            .labels{
                background-color: rgba(218,56,64,.47);
                position: absolute;
                bottom: 0;
                width: 100%;
                img{
                    margin : 0.25em 0em;
                    padding:0.18em 1em;
                }
                .virtual-training {
                    font-family: 'Avenir Ne@media (max-width: @screen-xs-max)xt Medium';
                    text-align: center;
                    background-color: #EEFE54;
                    font-size: 15px;
                    font-weight: 800;
                    color: #333;
                }
            }
        }
        .trainer-info {
            flex-grow: 3;
            display: flex;
            flex-direction: column;
            margin-left: 30px;
            a:hover {
                color: #38b7ec;
                text-decoration: underline;
            }
            h2 {
                color: #38b7ec;
                font-size: 19px;
                height: 20px;
                font-weight: 700;
                margin-top: 4px;
                line-height: 20px;
                margin-bottom: 2px;
                letter-spacing: 0.4px;
            }
            img.verified {
                height: 24px;
                margin-top: 1px;
                width: auto;
                margin-left: 4px;
            }
            img.vaccinated {
                height: 16px;
                margin-bottom: 4px;
            }
            span.trainer-reviews {
                img { height: 12px;}
            }
            span.reviews-avg {
                img { height: 14px; }
                margin-left: 10px;
                vertical-align: middle;
            }
            span.total-reviews {
                a {
                    text-decoration: underline;
                    color: #68c7f1;
                    margin-left: 10px;
                }
            }
            div.trainer-location {
                font-size: 16px;
                margin: 8px 0 5px 0;
                color: #1c2127;
                .vo-badge {
                    background-color: #EBE8FF;
                    color: #333740;
                    width: auto;
                    padding: 1px 6px 1px 4px;
                    border-radius: 2px;
                    .vo-icon {
                        margin: 0 3px 3px 3px;
                    }
                }
                .tz-badge {
                    color: #333740;
                    background-color: #EBE8FF;
                    width: auto;
                    padding: 1px 6px 1px 4px;
                    border-radius: 2px;
                }
                span {
                    margin-right: 6px;
                    .icon-virtual {
                        height: 10px;
                        margin-bottom: 5px;
                        width: auto;
                        margin-right: 5px;
                    }
                    .icon-loc {
                        height: 22px;
                        margin-bottom: 2px;
                        width: auto;
                    }
                    .icon-home {
                        height: 14px;
                        margin-bottom: 5px;
                        width: auto;
                        margin-right: 5px;
                    }
                }
            }
            p {
                color: #535353;
                font-size: 13px;
                max-width: 500px;
                margin-top: 12px;
                a {
                    color: #606060;
                }
            }
        }
        .trainer-pricing {
            flex-grow: 1;
            margin-left: auto;
            order: 2;
            text-align: right;
            span.one {
                color: #aeaeae;
                font-size: 14px;
                font-weight: 600;
            }
            span.price {
                color: #52dabc;
                font-size: 28px;
                font-weight: bold;
            }
            .btn-profile {
                color: #fff;
                background-color: #36b6ec;
                border-radius: 6px;
                margin-top: 60px;
                font-weight: bolder;
                padding: 8px 18px 6px 18px;
                font-size: 14px;
            }
        }
    }
    .home-page-v2 {
        width: auto;
        padding: 0 9%;
    }
    @media (max-width: @screen-xs-max) {
        padding: 0 3%;
        .trainer-item {
            padding: 12px 0;
            flex-wrap: wrap;
            .trainer-img-container {
                height: 100px;
                width: auto;
                max-width: 100px;
            }
            .trainer-info {
                margin-left: 10px;
                width: 50%;
                h2 {
                    font-size: 17px;
                }
                h3.trainer-location {
                    font-size: 12px;
                }
                span.total-reviews {
                    a {
                        font-size: 12px;
                    }
                }
            }
            .trainer-pricing {
                flex-grow: unset;
                margin-left: unset;
                order: unset;
                display: flex;
                flex-direction: row;
                text-align: unset;
                align-items: center;
                width: 100%;
                p {
                    width: 70%;
                    color: #535353;
                    font-size: 13px;
                    max-width: 500px;
                    margin-top: 12px;
                    margin-bottom: 0;
                    a {
                        color: #606060;
                    }
                }
                .price-div {
                    flex-grow: 1;
                    margin-left: auto;
                    order: 2;
                    text-align: right;
                    span.one {
                        color: #aeaeae;
                        font-size: 12px;
                        font-weight: 600;
                    }
                    span.price {
                        color: #52dabc;
                        font-size: 24px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
#from-blog {
    h2 {
        color: #000;
        font-family: 'Tiempos Head Line';
        font-size: 32px;
        font-weight: 600;
        letter-spacing: .5px;
        line-height: 55px;
        text-align: center;
        margin-top: 81px;
        margin-bottom: 50px;
    }
    h3 {
        color: #000;
        font-family: 'Tiempos Head Line';
        font-size: 28px;
        a {
            text-decoration: none;
            color: #000;
        }
    }
    img {
        height: 250px;
        width: auto;
    }
    p {
        color: #545156;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: .3px;
        line-height: 24px;
        margin-top: 20px;
        width: 90%;
        a {
            color: #4f92e2;
            font-weight: 700;
            &:hover {
                color: #4f92e2;
            }
        }
    }
}
#market-description {
    margin-bottom: 35px;
    margin-top: 0;
    h2 {
        color: #000;
        font-family: 'Tiempos Head Line';
        font-size: 32px;
        font-weight: 600;
        letter-spacing: .5px;
        line-height: 55px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 50px;
    }
    p {
        color: #545156;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: .3px;
        line-height: 24px;
        margin-top: 20px;
    }
}
#virtual-training {
    .custom-margin {
        margin-top: 240px;
    }
    h1 {
        text-align: center;
        color: #000000;
        font-family: 'Tiempos Head Line';
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 24px;
    }
    p {
        color: #545156;
        font-family: 'Avenir Next Medium';
        font-size: 16px;
    }
}
@media screen{
    @media (max-width:@screen-xs-min) {
        .national_home_page_v2 {
            button.home-page-v2 {
                width: 100%;
            }
            .your-partner {
                background-image: url(../img/home/mobile-hero2.webp);
                background-size: 100%;
                height: 600px;
                background-position-y: 0;
                &.lincoln {
                    height: 550px;
                    background: url(../img/lincoln/virtual-home-hero-mobile.jpg) no-repeat;
                    background-size: 100%;
                    background-position-y: 0%;
                }
                div {
                    &.lincoln {
                        img {
                            height: 70px;
                            margin-left: 10px;
                            top: 19%;
                        }
                    }
                    padding-top: 0;
                    padding-left: 2%;
                    padding-right: 2%;
                    position: absolute;
                    h1 {
                        margin-bottom: 0!important;
                        font-size: 32px;
                        padding-left: 7px;
                        letter-spacing: 1px;
                        line-height: 42px;
                    }
                    h2 {
                        padding: 0 12px;
                        margin-top: 10px !important;
                        font-size: 19px;
                    }
                    #home-quiz {
                        width: 100%;
                        input.home-quiz-input {
                            width: 100%;
                            margin: 0;
                            border-radius: 5px;
                            height: 52.5px;
                        }
                        span {
                            display: contents;
                            button.home-quiz-button {
                                width: 100%;
                                border-radius: 5px;
                                margin-top: 15px;
                            }
                        }
                    }
                    #home-quiz-alert {
                        width: 100%;
                    }
                }
            }
            .manifesto {
                margin-top: 0;
                margin-bottom: 40px;
                padding-left: 5%;
                padding-right: 5%;
                .this-whole-working {
                    font-size: 27.5px;
                    line-height: 30px;
                }
                .fyts-personal-train {
                    font-size: 19px;
                    line-height: 30px;
                    padding: 0;
                }
            }
            .section-1 {
                padding-left: 5%;
                padding-right: 5%;
                img {
                    width: 100%;
                    height: auto;
                }
                .section-1-flex div {
                    .workouts-that-actual {
                        font-size: 30px;
                        line-height: 40px;
                        text-align: center;
                    }
                    .we-believe-real-chan {
                        width: 100%;
                        font-size: 17px;
                        letter-spacing: .5px;
                        line-height: 30px;
                        margin-top: 0;
                    }
                } 
            }
            .section-2 {
                align-items: flex-start;
                flex-direction: column-reverse;
                margin-top: 50px;
                margin-bottom: 24px;
                padding-left: 5%;
                padding-right: 5%;
                img {
                    width: 100%;
                    height: auto;
                }
                .section-2-flex {
                    margin-top: 24px;
                    div {
                        .expert-guidance {
                            font-size: 27.5px;
                            letter-spacing: 0.5px;
                            line-height: 40px;
                            text-align: center;
                        }
                        .fyt-pros-are-real {
                            width: 100%;
                            font-size: 17px;
                            letter-spacing: .5px;
                            line-height: 30px;
                            margin-top: 0;
                            text-align: center;
                        }
                    }
                }
            }
            .our-clients {
                .our-clients-love-get {
                    font-size: 27.5px;
                    line-height: 35px;
                    padding: 0 10%;
                }
                div .reviews {
                    line-height: 1.24;
                }
            }
            .how-it-work {
                h2 {
                    margin-top: 24px;
                    margin-bottom: 50px;
                    font-size: 34px;
                    line-height: 40px;
                }
                .works div {
                    padding-left: 10%;
                    padding-right: 10%;
                    margin-bottom: 24px;
                    img {
                        width: 100px;
                        height: 100px;
                    }
                    h3 {
                        font-size: 20px;
                        line-height: 24px;
                    }
                    h4 {
                        width: 100%;
                        line-height: 24px;
                    }
                }
            }
            .promise {
                margin-top: 20px;
                flex-direction: column-reverse;
                padding-left: 5%;
                padding-right: 5%;
                #fyt-promise-img-col {
                    padding: 0;
                }
                img.promise-img {
                    width: 100%;
                    height: auto;
                }
                .the-fyt-promise {
                    div {
                        h1 {
                            width: 100%;
                            text-align: center;
                            font-size: 34px;
                            line-height: 42px;
                        }
                        h2 {
                            width: 100%;
                            font-size: 16px;
                            margin-bottom: 0;
                            text-align: center;
                            line-height: 26px;
                        }
                        .promises {
                            display: block;
                            margin-top: 24px;
                            text-align: center;
                            padding-right: 0;
                            img {
                                margin-right: 10px;
                            }
                            h3 {
                                text-align: center;
                            }
                        }
                    }
                }
            }
            .as-seen-in {
                padding-left: 0;
                padding-right: 0;
                margin-top: 24px;
                padding-left: 5%;
                padding-right: 5%;
                height: auto;
                justify-content: space-around;
                padding-bottom: 10px;
                h3 {
                    width: 100%;
                    font-size: 24px;
                }
                img {
                    margin-bottom: 17.5px;
                    margin-left: 1%;
                    margin-right: 1%;
                    width: 27.5%;
                    height: auto;
                }
            }
            .ready-to-get-started {
                height: 550px;
                background-size: 900px;
                background-position-x: 55%;
                background-position-y: 0;
                div {
                    h2 {
                        font-size: 42.5px;
                        line-height: 45px;
                    }
                    .ready-button-div {
                        margin-top: 24px;
                        width: 100%;
                        text-align: center;
                        margin-left: 0;
                        .search-form {
                            padding-left: 5%;
                            padding-right: 5%;
                            padding-bottom: 24%;
                            span {
                                display: contents;
                            }
                        }
                        button.home-page-search-v2 {
                            width: 80%;
                            margin: 0 10%;
                            border-radius: 5px;
                        }
                        input {
                            width: 80%;
                            margin: 0 10% 24px;
                            border-radius: 5px;
                        }
                    }
                }
            }
            .common_questions h2 {
                margin-top: 24px;
                margin-bottom: 24px;
                line-height: 35px;
                font-size: 27.5px;
            }
        }
        #virtual-training {
            .custom-margin {
                margin-top: 100px;
            }
            h1 {
                font-size: 19px;
            }
            p {
                font-size: 17.5px;
            }
        }
    }
    @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
        .national_home_page_v2 {
            button.home-page-v2 {
                width: 100%;
            }
            .your-partner {
                height: 625px;
                background-size: 975px;
                background-position-x: 72.5%;
                background-position-y: 0;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                &.lincoln {
                    height: 625px;
                    background-size: 975px;
                    background-position-x: 72.5%;
                    background-position-y: 0;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                }
                div {
                    padding-top: 0;
                    padding-left: 0;
                    padding-right: 0;
                    &.lincoln {
                        padding-top: 0;
                        padding-left: 0;
                        padding-right: 0;
                    }
                    h1 {
                        font-size: 40px;
                    }
                    h2 {
                        font-size: 20px;
                        line-height: 30px;
                        padding-right: 30%;
                    }
                    #home-quiz {
                        width: 100%;
                        input.home-quiz-input {
                            width: 100%;
                            margin: 0;
                            border-radius: 5px;
                            height: 52.5px;
                        }
                        span {
                            display: contents;
                            button.home-quiz-button {
                                width: 100%;
                                border-radius: 5px;
                                margin-top: 15px;
                            }
                        }
                    }
                    #home-quiz-alert {
                        width: 100%;
                    }
                }
            }
            .manifesto {
                margin-top: 0;
                margin-bottom: 40px;
                margin-left: 5%;
                margin-right: 5%;
                .this-whole-working {
                    font-size: 27.5px;
                    line-height: 30px;
                }
                .fyts-personal-train {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
            .section-1 {
                padding-left: 5%;
                padding-right: 5%;
                img {
                    width: 100%;
                    height: auto;
                }
                .section-1-flex div {
                    .workouts-that-actual {
                        font-size: 30px;
                        letter-spacing: .72px;
                        line-height: 40px;
                        text-align: center;
                    }
                    .we-believe-real-chan {
                        width: 100%;
                        font-size: 17px;
                        letter-spacing: .5px;
                        line-height: 30px;
                        margin-top: 0;
                    }
                } 
            }
            .section-2 {
                align-items: flex-start;
                flex-direction: column-reverse;
                margin-top: 50px;
                margin-bottom: 24px;
                padding-left: 5%;
                padding-right: 5%;
                img {
                    width: 100%;
                    height: auto;
                }
                .section-2-flex {
                    margin-top: 24px;
                    div {
                        .expert-guidance {
                            font-size: 34px;
                            line-height: 40px;
                            text-align: center;
                        }
                        .fyt-pros-are-real {
                            width: 100%;
                            font-size: 17px;
                            letter-spacing: .5px;
                            line-height: 30px;
                            margin-top: 0;
                        }
                    }
                }
            }
            .our-clients {
                .our-clients-love-get {
                    font-size: 34px;
                    line-height: 40px;
                }
                div .reviews {
                    line-height: 1.24;
                }
            }
            .how-it-work {
                h2 {
                    margin-top: 24px;
                    margin-bottom: 24px;
                    font-size: 34px;
                    line-height: 40px;
                }
                .works div {
                    margin-bottom: 24px;
                }
            }
            .promise {
                margin-top: 0;
                flex-direction: column-reverse;
                padding-left: 5%;
                padding-right: 5%;
                img.promise-img {
                    width: 100%;
                    height: auto;
                }
                .the-fyt-promise {
                    div {
                        h1 {
                            width: 100%;
                            text-align: center;
                            font-size: 34px;
                        }
                        h2 {
                            width: 100%;
                            font-size: 16px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .ready-to-get-started {
                height: 550px;
                background-size: 900px;
                background-position-x: 55%;
                background-position-y: 0;
                div {
                    h2 {
                        font-size: 42.5px;
                        line-height: 45px;
                    }
                    .ready-button-div {
                        margin-top: 24px;
                        width: 100%;
                        text-align: center;
                        margin-left: 0;
                        .search-form {
                            padding-left: 5%;
                            padding-right: 5%;
                            padding-bottom: 24%;
                            span {
                                display: contents;
                            }
                        }
                        button.home-page-search-v2 {
                            width: 80%;
                            margin: 0 10%;
                            border-radius: 5px;
                        }
                        input {
                            width: 80%;
                            margin: 0 10% 24px;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }
    @media (min-width: @screen-xs-max) and (max-width: @screen-sm-max) {
        .national_home_page_v2 {
            .your-partner {
                height: 550px;
                background-position-y: 0;
                &.lincoln {
                    height: 500px;
                    background-position-y: 0;
                }
                div {
                    &.lincoln {
                        img {
                            top: 2.2%;
                        }
                        h1 {
                            margin-top: 20%; 
                        }
                    }
                    h1 {
                        margin-top: 23%;
                        font-size: 40px;
                    }
                    h2 {
                        font-size: 19px;
                        line-height: 28px;
                    }
                    #home-quiz {
                        width: 50%;
                    }
                    #home-quiz-alert {
                        width: 50%;
                    }
                }
            }
            .section-1 img {
                margin: auto;
            }
            .section-2 {
                flex-direction: column-reverse;
                margin-top: 50px;
                margin-bottom: 24px;
                img {
                    width: 700px;
                    height: 600px;
                }
            }
            .promise {
                margin-top: 0;
                flex-direction: column-reverse;
                img.promise-img {
                    margin: 24px auto;
                }
            }
            .ready-to-get-started {
                height: 550px;
            }
        }
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        .national_home_page_v2 {
            .your-partner {
                height: 700px;
                background-position-y: 0;
                &.lincoln {
                    height: 700px;
                    background-position-y: 0;
                }
                div {
                    &.lincoln {
                        h1 {
                            margin-top: 26%;
                        }
                    }
                    h1 {
                        margin-top: 32%;
                        font-size: 40px;
                    }
                    h2 {
                        font-size: 19px;
                        line-height: 28px;
                    }
                    #home-quiz {
                        width: 40%;
                    }
                    #home-quiz-alert {
                        width: 40%;
                    }
                }
            }
            .section-1 img {
                margin: auto;
            }
            .section-2 {
                flex-direction: column-reverse;
                margin-top: 50px;
                margin-bottom: 24px;
            }
            .promise {
                margin-top: 0;
                flex-direction: column-reverse;
                img.promise-img {
                    margin: 24px auto;
                }
            }
            .ready-to-get-started {
                height: 550px;
            }
        }
    }
}

.carousel-multi-home-page-v2 {
    background-color: #F4F6F8;
    margin-top: 70px;
    .carousel-inner .active.left { left: -33%; }
    .carousel-inner .next        { left:  33%; }
    .carousel-inner .prev        { left: -33%; }
    .carousel-control.left,.carousel-control.right {
        background-image:none;
        img {
            height: 24px;
            position: absolute;
            top: 42%;
        }
    }
    .carousel-control .glyphicon-chevron-right {margin-right: -50px; color: #000000;}
    .carousel-control .glyphicon-chevron-left {margin-left: -50px; color: #000000;}
    .carousel-inner{
        width: 90%;
        margin: 0 auto;
        > .item {
            transition: 200ms ease-in-out;
            padding: 40px;
            > .media-card{
                background: transparent;
                border-right: 10px solid #fff;
                display: table-cell;
                width: 1%;
                text-align: center;
                border-color: #F4F6F8;
                p {
                    margin: 20px auto 0 auto;
                    color: #6c696e;
                    font-weight: bold;
                    font-size: 16px;
                    width: 80%;
                }
                a {
                    color: #3ea2f7;
                    font-size: 16px;
                }
                
                &:last-of-type{
                border-right: none;
                }
            }
        }
        .active{
            display: table;
        &.left{
            left:-33%;
        }
        &.right{
            left:33%;
        }
        }
        .next{
            left: 33%;
        }
        .prev{
            left: -33%;
        }
        @media all and (transform-3d), (-webkit-transform-3d) {
            > .item{
                transition: 100ms ease-in all;
                backface-visibility: visible;
                transform: none!important;
            }
        }
        @media (max-width: @screen-xs-max) and (transform-3d), (-webkit-transform-3d) {
            > .item {
                transition: 100ms ease-in all;
            }
        }
    }

    @media (max-width: @screen-xs-max) { 
        .carousel-control.left {margin-left: 20px;}
        .carousel-control.right {margin-right: 20px;}
    }
}

.fytduo-panel {
    padding: 45px 0;
    background-color: #F4F6F8;
    img {
        height: 365px;
        width: auto;
        float: right;
        margin-right: 45px;
    }
    h2 {
        color: #000000;
        font-family: 'Tiempos Head Line';
        font-size: 32px;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 40px;
        margin-bottom: 30px;
    }
    p {
        font-size: 18px;
        color: #545156;
        width: 540px;
        line-height: 32px;
    }
    button.home-page-v2 {
        background-color: #5340FF;
        border-radius: 5px;
        height: 52.5px;
        color: #FFF;
        font-family: 'Avenir Next Medium';
        font-size: 17.5px;
        font-weight: 700;
        letter-spacing: 0.8px;
        line-height: 24px;
        width: 190px;
        text-align: center;
        margin-top: 24px;
        border: 0;
    }
    @media screen and (max-width:@screen-xs-min) {
        img {
            height: 100%;
            width: 100%;
            float: none;
            margin-right: 0;
            padding: 10px;
        }
        h2 {
            margin-bottom: 20px;
            padding-left: 16px;
            font-size: 27.5px;
            line-height: 36px;
        }
        p {
            width: 100%;
            padding: 0 16px;
            line-height: 26px;
            font-size: 17px;
        }
        button.home-page-v2 {
            width: 94%;
            margin: 12px;
        }
    }
}

.promotion_banner{
    font-family: 'Avenir Next Medium';
    text-align: center;
    font-weight: bold;
    background-color: #FF9262;
    padding: 8px 0px;
    font-size: 15px;
    line-height: 21px;
    color: #333;
    letter-spacing: 0.75px;
    a {
        text-decoration: underline;
        text-decoration-color: white;
        color: white;
        cursor: pointer;
    }
    @media (max-width: @screen-xs-min) {
        font-size: 15px;
    }
}