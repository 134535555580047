@import (reference) 'utils.less';

#faqs, #faq-question, #faq-topic{
	.faqs-container{
		.search-form{
    		background: linear-gradient(rgba(0,0,0,.22), rgba(0,0,0,.5)), url(../img/trainer-and-pushups.jpg);
    		background-size: cover;
		}
		.hero{
			padding: 25px 0 100px;
			h3{
				font-weight: 800;
    			font-size: 55px;
			}
		}
		min-height: 500px;
		// h2{
		// 	margin-left: 150px;
		// 	margin-top: 30px;
		// }
		a.question, a.question:hover, a.topic, a.topic:hover, a.more{
			background-color: inherit;
			color: black;
		}
		.row.content{
			margin-top: 30px;
			h1{
			    margin-top: 30px;
			    font-weight: bold;
			}
			.left-nav{
				float: left;
				ul.faqs{
					display: inline-block;
					li{
						padding: 5px;
						border-left: 2px solid #F1F1F1;
						&.active{
							border-left: 2px solid @main-color;
							color: @main-color;
							a, a:hover{
								background-color: inherit;
								color: @main-color;
							}
						}
					}
				}
			}
			div.questions{
				display: inline-block;
				padding-left: 40px;
				ul.questions{
					padding: 0;
				}
				.question{
					margin: 40px 0;
				}
			}
		}
	}

	/* enable absolute positioning */
	.inner-addon { 
	    position: relative; 
	}

	/* style icon */
	.inner-addon .glyphicon {
		position: absolute;
		padding: 10px;
		pointer-events: none;
		color: @main-color;
		padding: 15px 10px;
	}

	/* align icon */
	.left-addon .glyphicon  { left:  0px;}
	.right-addon .glyphicon { right: 0px;}

	/* add padding  */
	.left-addon input  { 
		padding-left: 30px;
    	height: 45px;
    	border-radius: 5px;
	}
	.right-addon input { padding-right: 30px; }

	.ui-menu-item {
    	border-top: none;
    	border-bottom: none;
    	font-size: 18px;
    	transform: none;
    	background: inherit;
    	transform: none;
    	&:hover, &:active{
    		transform: none;
    		background-color: rgba(129, 186, 237, 0.18);
    	}
	}

	.ui-menu .ui-menu-item a{
		padding: 15px;
		font-size: 18px;
		font-family: 'Avenir Next Medium';
		border-radius: 0 !important;
		transform: none;
	}

	.topics{
		margin-top: 30px;
		h3{
			font-weight: bold;
		}
		ul{
			padding: 0;
			margin-top: 30px;
			li{
				margin: 10px 0;
    			font-size: 16px;
			}
		}
		a.more{
			font-weight: bold;
		}
		.topic{
			margin-bottom: 50px;
		}
	}

	@media (min-width: @screen-md-min){
		form#search-question{
			max-width: 600px;
    		margin: 33px auto;
		}
		.faqs-container{
			.row.content{
				.left-nav{
					width: 30%;
					ul.faqs{
						margin: 30px 0 50px 150px;
						li > a {
							font-size: 16px;
						}
					}
				}
				div.questions{
					width: 70%;
					margin-bottom: 50px;
				}
			}
		}
	}

	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max){
		.faqs-container{
			/*h2{
				margin-left: 50px;
			}*/
			.hero{
				h1{
					margin-top: 30px !important;
					font-size: 30px;
				}
				#search-question{
					max-width: 400px;
    				margin: 30px auto;
				}
			}
			.row.content{
				margin: 30px 0;
				ul.faqs{
					margin: 30px 0;
				}
				div.questions, ul.questions{
					padding: 0;
				}
			}
			div.topic{
				text-align: center;
				ul{
					padding: 0 10px;
				}
			}
		}	
	}

	@media (max-width: @screen-xs-max){
		.hero{
			h3{
				font-weight: 800;
    			font-size: 2.2em !important;
			}
		}
		form#search-question{
			padding: 0 30px;
    		margin-top: 0;
		}
		.faqs-container{
			h2{
				margin: 30px 0;
				text-align: center;
			}
			.row.content{
				margin: 30px 0;
				div.left-nav{
					width: 40%;
					ul.faqs{
						margin: 0;
						li{
							padding: 5px 0;
						}
					}
				}
				div.questions{
					padding: 0;
					width: 60%;
					ul.questions{
						padding: 0 15px;
						li{
							margin-bottom: 20px;
							.question{
								margin: 10px 0;
				    			font-size: 20px;
							}
						}
					}
					h3{
						margin-bottom: 10px;
						font-size: 20px;
					}
					.answer{
						font-size: 15px;
						padding: 10px;
					}
				}
			}
			.row.topics{
				padding: 0 10px;
				div.topic{
					text-align: center;
				}
			}
		}
	}
}

#faq-question{
	div.questions{
		max-width: 60%;
		h3{
			margin-bottom: 40px;
			font-weight: bold;
		}
		.breadcrumb{
			padding-left: 0;
			background-color: inherit;
			a:hover{
				text-decoration: none !important;
			}
			a.active{
				color: @main-color;
			}
		}
		.answer{
			font-size: 20px;
			ul{
				list-style: inherit;
			}
		}
		@media (min-width: @screen-md-min){
			padding: 30px 20px 50px;
		}
	}
}

#faq-topic{
	ul.questions > li{
		margin: 20px 0;
    	font-size: 20px;
	}
}